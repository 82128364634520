<app-header *ngIf="!(location == '/error' || location == '/coming-soon' 
    || location == '/sign-in' 
    || location == '/sign-up'
    ||location == '/forgot-password')">
</app-header>
<div class="container">
    <div class="ui middle aligned center aligned grid">
        <div class="ui eight wide column">

            <form class="ui large form" (submit)="onPaymentFaild()">

                <div class="ui icon negative message">
                    <i class="warning icon"></i>
                    <div class="content">
                        <div class="header">
                            Oops! Something went wrong.
                        </div>
                        <p>While trying to reserve money from your account</p>
                    </div>

                </div>

                <span><input type="submit" class="ui large teal submit fluid button">Try again</span>

            </form>
        </div>
    </div>
</div>