import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, EmailValidator } from '@angular/forms';
import { NavigationCancel, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { UtilityService } from 'src/app/services/utility-service/utility.service';
import { AlertService } from 'ngx-alerts';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
  providers: [
    Location, {
        provide: LocationStrategy,
        useClass: PathLocationStrategy
    }
]
})
export class SignInComponent implements OnInit {

  loginFrom: FormGroup;
  get f() { return this.loginFrom.controls };
  isSubmitted: boolean;
  isLoginAction: boolean = false;
  location: any;
  routerSubscription: any;

  constructor(
    private formBuilder: FormBuilder
    , private _router: Router
    , private _utilityService: UtilityService
    , private _authService: AuthenticationService
    , private alertService: AlertService
  ) { }

  ngOnInit(): void {
    this._utilityService.getAppSettings().subscribe(appSettings => {
      if (appSettings) {
        if (!appSettings.isAdmin) {
          this._router.navigate(['/']);
        }
      }
    })
    this.initForm();
    this.recallJsFuntions();
  }

  private initForm() {
    this.loginFrom = this.formBuilder.group({
      emailAddress: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });
  }

  public onSignIn() {
    this.isSubmitted = true;

    if (this.loginFrom.invalid) { return; }
    const emailAddress = this.loginFrom.controls.emailAddress.value;
    const password = this.loginFrom.controls.password.value;
    this.isLoginAction = true;
    this._authService.signIn(environment.agencyCode, emailAddress, password).subscribe(response => {
    
      this.isLoginAction = false;
      if (!response?.isAdmin) {
        if (response.isActive) {
          this._utilityService.setAppSettings(response);
          this._utilityService.saveWebSettings(response.accessToken);
          this._router.navigate(['/']);
        } else {
          // alert('Account has been de-activated.');
          this.alertService.warning('Account has been de-activated.');
        }
      } else {
        // alert('Invalid Username/Password');
        this.alertService.warning('Invalid Username/Password');
      }
    }, error => {
      // alert('Invalid Username/Password');
      this.alertService.warning('Invalid Username/Password');
      this.isLoginAction = false;
    });
  }

  recallJsFuntions() {
    this.routerSubscription = this._router.events
        .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
        .subscribe(event => {
            $.getScript('../assets/js/main.js');
            this.location = this._router.url;
            if (!(event instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0);
        });
}

}
