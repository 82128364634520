import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { WebSettings } from 'src/app/models/constants/config.constants';
import { WebSettings as AppSettings } from '../../models/utility/web-settings.model';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  constructor() { }

  private appSettings: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  public setAppSettings(appSettings: any) {
    this.appSettings.next(appSettings);
  }

  public getAppSettings(): BehaviorSubject<any> {
    return this.appSettings;
  }

  saveWebSettings(accessToken: string): void {
    if (accessToken) {
      let webSettings: AppSettings = {
        accessToken: accessToken,
        timeStamp: new Date()
      }
      localStorage.setItem(WebSettings.sessionName, JSON.stringify(webSettings));
    }
  }

  getWebSettings(): AppSettings {
    let session = localStorage.getItem(WebSettings.sessionName);
    return session ? JSON.parse(session) : null;
  }

  getToken(): string {
    let settings: AppSettings = this.getWebSettings();
    return (settings) ? settings.accessToken : '';
  }

  isGoingToExpire(): boolean {
    return false;
  }

}
