<app-header-one></app-header-one>
<div class="menu_bar">
    <div class="container">
        <div class="page-title-content">
            <h2>Our Fleets</h2>
        </div>
    </div>
</div>
<div class="container-fluid">
    <div class="row filter res_modify " >
        <div class="col-xs-6 search_details">
            <p class="text-color"  style="border-right:1px solid white ;font-size: 14px ; padding-right: 10px;">Muscat International Airport</p>
            
        </div>
        <div class="col-xs-6 search_details  " style=" padding-left: 10px;">
           
            <p class="text-color"  style="text-align:left !important;font-size: 14px ; padding-right: 10px;">Muscat International Airport</p>
        </div>
        
    </div>
    <div class="row filter res_modify " >
        <div class="col-xs-6 search_details text-color "  style="border-right:1px solid white ;">
            <p class="text-color2 date_time_one">01-02-2020  00:30</p>
           
        </div>
        <div class="col-xs-6 search_details text-right" style=" padding-left: 10px;">
            <p class="text-color2">01-02-2020  00:30</p>
        </div>
        
    </div>

     <div class="row  modify_bar ">
        <div class="col-md-2 col-xs-2 col-sm-6">
            <p class="text-color">Muscat International Airport</p>
        </div>
        <div class="col-md-2 col-xs-2 col-sm-6">
            <p class="text-color2">Muscat International Airport</p>
        </div>
        <div class="col-md-2 col-sm-3">
            <i class='search_deatil_icon bx bxs-calendar'></i>
            <p class="text-color2">Pickup Date <br>01-02-2020</p>

        </div>
        <div class="col-md-1 col-sm-2">
            <i class='search_deatil_icon bx bxs-time'></i>
            <p class="text-color2"> Time <br>00.30</p>

        </div>
        <div class="col-md-2 col-sm-3">
            <i class='search_deatil_icon bx bxs-calendar'></i>
            <p class="text-color2">Retutn Date <br>01-02-2020</p>

        </div>
        <div class="col-md-1 col-sm-2">
            <i class='search_deatil_icon bx bxs-time'></i>
            <p class="text-color2">Time <br>00.30</p>

        </div>
        <div class="col-md-2 col-sm-2">

            <button class="btn btn-warning">Modify Search</button>
        </div>
    </div>  
</div>


<div class="container-fluid ">
    <div class="row filter">
        <div class="sort_col">
            <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAT0lEQVRIiWNgGAWDFfyHYlzixODBaQHVwIBZQHHQDLgFVAODMg6GhgVUAzSxIIEBt/cXoFlMdtBgs2QBkjzFFqBbsgCvSgpAAi0NHwX0BQAFnWedzPskewAAAABJRU5ErkJggg==" loading="lazy"/>
            <a>Sort</a> 
        </div>
        <div class="filter_col">
            <!-- Button trigger modal -->
            <a type="button" class="filter_show" (click)="filter_show1()">
                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAZklEQVRIiWNgGAUkggsMDAz/KcTn8VlAqeEwPIwtOEAFww/gs0CAgbKIvgA1Ay8g1xKiDCfXEpIMJ9USsgwn1hKKDCdkCVUMx2UJVQ1HtgRmAdUNhwGCOXXUArpYsICWFtDU8MEJAMQbqSf0SlKHAAAAAElFTkSuQmCC" loading="lazy"/>
                Filter
            </a>
 
        </div>
    </div>

    
   



    <div class="row row_top">

        <div class="col-md-3 col-sm-12 ">

            <div class="sidebox sidebox1">
                <div class="text-right close">
                    <a type="button" class="filter_show close_button " (click)="filter_show1()" >
                       
                        X 
                    </a>
                </div>
                <div class="side_top">SEARCH FILTERS</div>
                <div class="side_header">PRICE RANGE</div>
                <div class="slider_pad">
                    <ng5-slider [(value)]="value" [(highValue)]="highValue" [options]="options"></ng5-slider>
                </div>


                <div class="side_header">MANUFACTURES</div>
                <div class="container_frame">
                    <label class="container">Ford
                        <input type="checkbox" checked="checked">
                        <span class="checkmark"></span>
                        <span class="item_count">1</span>
                    </label>
                    <label class="container">Reno
                        <input type="checkbox" checked="checked">
                        <span class="checkmark"></span>
                        <span class="item_count">3</span>
                    </label>
                    <label class="container">Toyoto
                        <input type="checkbox" checked="checked">
                        <span class="checkmark"></span>
                        <span class="item_count">1</span>
                    </label>
                    <label class="container">Chevrolet
                        <input type="checkbox" checked="checked">
                        <span class="checkmark"></span>
                        <span class="item_count">1</span>
                    </label>
                    <label class="container">BMW
                        <input type="checkbox" checked="checked">
                        <span class="checkmark"></span>
                        <span class="item_count">17</span>
                    </label>
                </div>
                <div class="side_header">NUMBER OF PASSANGERS</div>
                <div class="slider_pad">
                    <ng5-slider [(value)]="value2" [(highValue)]="highValue2" [options]="options2"></ng5-slider>
                </div>
                <div class="side_header">VEHICLE TYPE</div>
                <div class="container_frame">
                    <label class="container">Economy
                        <input type="checkbox" checked="checked">
                        <span class="checkmark"></span>
                        <span class="item_count">1</span>
                    </label>
                    <label class="container">Compact
                        <input type="checkbox" checked="checked">
                        <span class="checkmark"></span>
                        <span class="item_count">1</span>
                    </label>
                    <label class="container">Intermediate
                        <input type="checkbox" checked="checked">
                        <span class="checkmark"></span>
                        <span class="item_count">1</span>
                    </label>
                    <label class="container">Spot
                        <input type="checkbox" checked="checked">
                        <span class="checkmark"></span>
                        <span class="item_count">2</span>
                    </label>

                </div>
                <div class="side_header">TRANSMISSION TYPE</div>
                <div class="container_frame">
                    <label class="container">Manual
                        <input type="checkbox" checked="checked">
                        <span class="checkmark"></span>
                    </label>
                    <label class="container">Automatic
                        <input type="checkbox" checked="checked">
                        <span class="checkmark"></span>
                    </label>


                </div>
              <div class="text-center filter_button">
                <button type="submit" class="btn btn-warning">Apply</button>  
              </div>
                
               
                
            </div>

        </div>
        <div class="col-md-9 col-sm-12">
          
        



            <div class="fleet_box">
                <div class="row car_row">
                    <div class="col-md-4 col-sm-3 first_col right_border">
                        
                        <h6>NISSAN SUNNY </h6>
                        <span class="small_text"> (or similar)</span><br>
                        <img alt="Image canot Displayed"
                            src="https://images-na.ssl-images-amazon.com/images/I/61UYdfUBAoL._SX425_.jpg"
                            class="img-fluid img_size">
                        <br> <span class="small_text"> Economy</span>
                    </div>
                    <div class="col-md-5 col-sm-5 right_border">
                            
                        <!-- for mobile -->
                                <div class="row car_details_show">
                                        <div class="col-xs-3 car_deatails0">
                                            <p> <img [src]=seats_icon> 5 Seats </p>
                                            <p> <img [src]=door_icon> 5 Door </p>
                                           
                                        </div>
                                        <div class="col-xs-3 car_deatails1">
                                            <p><img [src]=bag_icon> 2 Luggage(s) </p>
                                            <p><img [src]=settings_icon> 1.5 L Engine </p>
                                           
                                        </div>
                                        <div class="col-xs-2 car_deatails2">
                                            <p> <img [src]=trans_icon> Auto </p>
                                            <p> <img [src]=ac_icon> AC </p>
                                        </div>
                                        
                                </div>
                                <div class="row car_details_show row_top_border">
                                    <div class="col-xs-4 car_deatails">
                                        <i class='bx bxs-map sub_icon'></i>
                                        <p class=" float_left">Current Location <br>Muscat International Airport</p>
                                        <span class="total_charges">TOTAL CHARGES</span>
                                        <h3>9.26 OMR</h3>
                                    </div>
                                    <div class="col-xs-4 car_deatails">
                                        <i class='bx bxs-coin-stack sub_icon'></i>
                                        <p class=" float_left">Delivery Charge <br>00.20 OMR</p>
                                       
                                        <div class="refundable">Refundable</div>
                                        <button routerLink="/newbooking" class=" button_margin btn btn-warning btn-lg">Book Now</button>
                                    </div>
                            </div>

                        <!-- end for mobile -->

                        <div class="row car_details_desk">
                            <div class="third_col_pad  col-md-6  col-sm-6 ">
                                <p> <img [src]=seats_icon> 5 Seats </p>
                                <p> <img [src]=door_icon> 5 Door </p>
                                <p> <img [src]=trans_icon> Auto </p>

                            </div>
                            <div class=" third_col_pad right_border col-md-6 col-sm-6 ">
                                <p><img [src]=bag_icon> 2 Luggage(s) </p>
                                <p><img [src]=settings_icon> 1.5 L Engine </p>
                                <p> <img [src]=ac_icon> AC </p>
                            </div>
                        </div>  
                        <div class="row row_top_border car_details_desk">
                            <div class="third_col_pad  col-md-6  col-sm-6 ">
                                <i class='bx bxs-map sub_icon'></i>
                                <p class=" float_left">Current Location <br>Muscat International Airport</p>
                            </div>
                            <div class="third_col_pad  col-md-6  col-sm-6 ">
                                <i class='bx bxs-coin-stack sub_icon'></i>
                                <p class=" float_left">Delivery Charge <br>00.20 OMR</p>
                            </div>


                        </div>
                    </div>

                    <div class=" third_col_pad col-md-3 col-sm-4 car_details_desk">
                        <span class="total_charges">TOTAL CHARGES</span>
                        <h3>9.26 OMR</h3>
                        <div class="refundable">Refundable</div>
                        <button routerLink="/newbooking" class=" button_margin btn btn-warning btn-lg">Book Now</button>
                    </div>
                </div>
            </div>

            <!-- second row -->
           
            <div class="fleet_box">
                <div class="row car_row">
                    <div class="col-md-4 col-sm-3 first_col right_border">
                        <div class="offer_cover">
                            <img [src]=offer>
                        </div>
                        <h6>NISSAN SUNNY </h6>
                        <span class="small_text"> (or similar)</span><br>
                        <img alt="Image canot Displayed"
                            src="https://images-na.ssl-images-amazon.com/images/I/61UYdfUBAoL._SX425_.jpg"
                            class="img-fluid img_size">
                        <br> <span class="small_text"> Economy</span>
                    </div>
                    <div class="col-md-5 col-sm-5 right_border">
                            
                        <!-- for mobile -->
                                <div class="row car_details_show">
                                        <div class="col-xs-3 car_deatails0">
                                            <p> <img [src]=seats_icon> 5 Seats </p>
                                            <p> <img [src]=door_icon> 5 Door </p>
                                           
                                        </div>
                                        <div class="col-xs-3 car_deatails1">
                                            <p><img [src]=bag_icon> 2 Luggage(s) </p>
                                            <p><img [src]=settings_icon> 1.5 L Engine </p>
                                           
                                        </div>
                                        <div class="col-xs-2 car_deatails2">
                                            <p> <img [src]=trans_icon> Auto </p>
                                            <p> <img [src]=ac_icon> AC </p>
                                        </div>
                                        
                                </div>
                                <div class="row car_details_show row_top_border">
                                    <div class="col-xs-4 car_deatails">
                                        <i class='bx bxs-map sub_icon'></i>
                                        <p class=" float_left">Current Location <br>Muscat International Airport</p>
                                        <span class="total_charges">TOTAL CHARGES</span>
                                        <h3>9.26 OMR</h3>
                                    </div>
                                    <div class="col-xs-4 car_deatails">
                                        <i class='bx bxs-coin-stack sub_icon'></i>
                                        <p class=" float_left">Delivery Charge <br>00.20 OMR</p>
                                       
                                        <div class="refundable">Refundable</div>
                                        <button routerLink="/newbooking" class=" button_margin btn btn-warning btn-lg">Book Now</button>
                                    </div>
                            </div>

                        <!-- end for mobile -->

                        <div class="row car_details_desk">
                            <div class="third_col_pad  col-md-6  col-sm-6 ">
                                <p> <img [src]=seats_icon> 5 Seats </p>
                                <p> <img [src]=door_icon> 5 Door </p>
                                <p> <img [src]=trans_icon> Auto </p>

                            </div>
                            <div class=" third_col_pad right_border col-md-6 col-sm-6 ">
                                <p><img [src]=bag_icon> 2 Luggage(s) </p>
                                <p><img [src]=settings_icon> 1.5 L Engine </p>
                                <p> <img [src]=ac_icon> AC </p>
                            </div>
                        </div>  
                        <div class="row row_top_border car_details_desk">
                            <div class="third_col_pad  col-md-6  col-sm-6 ">
                                <i class='bx bxs-map sub_icon'></i>
                                <p class=" float_left">Current Location <br>Muscat International Airport</p>
                            </div>
                            <div class="third_col_pad  col-md-6  col-sm-6 ">
                                <i class='bx bxs-coin-stack sub_icon'></i>
                                <p class=" float_left">Delivery Charge <br>00.20 OMR</p>
                            </div>


                        </div>
                    </div>

                    <div class=" third_col_pad col-md-3 col-sm-4 car_details_desk">
                        <span class="total_charges">TOTAL CHARGES</span>
                        <h3>9.26 OMR</h3>
                        <div class="refundable">Refundable</div>
                        <button routerLink="/newbooking" class=" button_margin btn btn-warning btn-lg">Book Now</button>
                    </div>
                </div>
            </div>

            <!-- second row -->
          
            <div class="fleet_box">
                <div class="row car_row">
                    <div class="col-md-4 col-sm-3 first_col right_border">
                        <div class="offer_cover">
                            <img [src]=offer>
                        </div>
                        <h6>NISSAN SUNNY </h6>
                        <span class="small_text"> (or similar)</span><br>
                        <img alt="Image canot Displayed"
                            src="https://images-na.ssl-images-amazon.com/images/I/61UYdfUBAoL._SX425_.jpg"
                            class="img-fluid img_size">
                        <br> <span class="small_text"> Economy</span>
                    </div>
                    <div class="col-md-5 col-sm-5 right_border">
                            
                        <!-- for mobile -->
                                <div class="row car_details_show">
                                        <div class="col-xs-3 car_deatails0">
                                            <p> <img [src]=seats_icon> 5 Seats </p>
                                            <p> <img [src]=door_icon> 5 Door </p>
                                           
                                        </div>
                                        <div class="col-xs-3 car_deatails1">
                                            <p><img [src]=bag_icon> 2 Luggage(s) </p>
                                            <p><img [src]=settings_icon> 1.5 L Engine </p>
                                           
                                        </div>
                                        <div class="col-xs-2 car_deatails2">
                                            <p> <img [src]=trans_icon> Auto </p>
                                            <p> <img [src]=ac_icon> AC </p>
                                        </div>
                                        
                                </div>
                                <div class="row car_details_show row_top_border">
                                    <div class="col-xs-4 car_deatails">
                                        <i class='bx bxs-map sub_icon'></i>
                                        <p class=" float_left">Current Location <br>Muscat International Airport</p>
                                        <span class="total_charges">TOTAL CHARGES</span>
                                        <h3>9.26 OMR</h3>
                                    </div>
                                    <div class="col-xs-4 car_deatails">
                                        <i class='bx bxs-coin-stack sub_icon'></i>
                                        <p class=" float_left">Delivery Charge <br>00.20 OMR</p>
                                       
                                        <div class="refundable">Refundable</div>
                                        <button routerLink="/newbooking" class=" button_margin btn btn-warning btn-lg">Book Now</button>
                                    </div>
                            </div>

                        <!-- end for mobile -->

                        <div class="row car_details_desk">
                            <div class="third_col_pad  col-md-6  col-sm-6 ">
                                <p> <img [src]=seats_icon> 5 Seats </p>
                                <p> <img [src]=door_icon> 5 Door </p>
                                <p> <img [src]=trans_icon> Auto </p>

                            </div>
                            <div class=" third_col_pad right_border col-md-6 col-sm-6 ">
                                <p><img [src]=bag_icon> 2 Luggage(s) </p>
                                <p><img [src]=settings_icon> 1.5 L Engine </p>
                                <p> <img [src]=ac_icon> AC </p>
                            </div>
                        </div>  
                        <div class="row row_top_border car_details_desk">
                            <div class="third_col_pad  col-md-6  col-sm-6 ">
                                <i class='bx bxs-map sub_icon'></i>
                                <p class=" float_left">Current Location <br>Muscat International Airport</p>
                            </div>
                            <div class="third_col_pad  col-md-6  col-sm-6 ">
                                <i class='bx bxs-coin-stack sub_icon'></i>
                                <p class=" float_left">Delivery Charge <br>00.20 OMR</p>
                            </div>


                        </div>
                    </div>

                    <div class=" third_col_pad col-md-3 col-sm-4 car_details_desk">
                        <span class="total_charges">TOTAL CHARGES</span>
                        <h3>9.26 OMR</h3>
                        <div class="refundable">Refundable</div>
                        <button routerLink="/newbooking" class=" button_margin btn btn-warning btn-lg">Book Now</button>
                    </div>
                </div>
            </div>


            <!-- second row -->
          
            <div class="fleet_box">
                <div class="row car_row">
                    <div class="col-md-4 col-sm-3 first_col right_border">
                       
                        <h6>NISSAN SUNNY </h6>
                        <span class="small_text"> (or similar)</span><br>
                        <img alt="Image canot Displayed"
                            src="https://images-na.ssl-images-amazon.com/images/I/61UYdfUBAoL._SX425_.jpg"
                            class="img-fluid img_size">
                        <br> <span class="small_text"> Economy</span>
                    </div>
                    <div class="col-md-5 col-sm-5 right_border">
                            
                        <!-- for mobile -->
                                <div class="row car_details_show">
                                        <div class="col-xs-3 car_deatails0">
                                            <p> <img [src]=seats_icon> 5 Seats </p>
                                            <p> <img [src]=door_icon> 5 Door </p>
                                           
                                        </div>
                                        <div class="col-xs-3 car_deatails1">
                                            <p><img [src]=bag_icon> 2 Luggage(s) </p>
                                            <p><img [src]=settings_icon> 1.5 L Engine </p>
                                           
                                        </div>
                                        <div class="col-xs-2 car_deatails2">
                                            <p> <img [src]=trans_icon> Auto </p>
                                            <p> <img [src]=ac_icon> AC </p>
                                        </div>
                                        
                                </div>
                                <div class="row car_details_show row_top_border">
                                    <div class="col-xs-4 car_deatails">
                                        <i class='bx bxs-map sub_icon'></i>
                                        <p class=" float_left">Current Location <br>Muscat International Airport</p>
                                        <span class="total_charges">TOTAL CHARGES</span>
                                        <h3>9.26 OMR</h3>
                                    </div>
                                    <div class="col-xs-4 car_deatails">
                                        <i class='bx bxs-coin-stack sub_icon'></i>
                                        <p class=" float_left">Delivery Charge <br>00.20 OMR</p>
                                       
                                        <div class="refundable">Refundable</div>
                                        <button routerLink="/newbooking" class=" button_margin btn btn-warning btn-lg">Book Now</button>
                                    </div>
                            </div>

                        <!-- end for mobile -->

                        <div class="row car_details_desk">
                            <div class="third_col_pad  col-md-6  col-sm-6 ">
                                <p> <img [src]=seats_icon> 5 Seats </p>
                                <p> <img [src]=door_icon> 5 Door </p>
                                <p> <img [src]=trans_icon> Auto </p>

                            </div>
                            <div class=" third_col_pad right_border col-md-6 col-sm-6 ">
                                <p><img [src]=bag_icon> 2 Luggage(s) </p>
                                <p><img [src]=settings_icon> 1.5 L Engine </p>
                                <p> <img [src]=ac_icon> AC </p>
                            </div>
                        </div>  
                        <div class="row row_top_border car_details_desk">
                            <div class="third_col_pad  col-md-6  col-sm-6 ">
                                <i class='bx bxs-map sub_icon'></i>
                                <p class=" float_left">Current Location <br>Muscat International Airport</p>
                            </div>
                            <div class="third_col_pad  col-md-6  col-sm-6 ">
                                <i class='bx bxs-coin-stack sub_icon'></i>
                                <p class=" float_left">Delivery Charge <br>00.20 OMR</p>
                            </div>


                        </div>
                    </div>

                    <div class=" third_col_pad col-md-3 col-sm-4 car_details_desk">
                        <span class="total_charges">TOTAL CHARGES</span>
                        <h3>9.26 OMR</h3>
                        <div class="refundable">Refundable</div>
                        <button routerLink="/newbooking" class=" button_margin btn btn-warning btn-lg">Book Now</button>
                    </div>
                </div>
            </div>


            <!-- second row -->
          
            <div class="fleet_box">
                <div class="row car_row">
                    <div class="col-md-4 col-sm-3 first_col right_border">
                        <div class="offer_cover">
                            <img [src]=offer>
                        </div>
                        <h6>NISSAN SUNNY </h6>
                        <span class="small_text"> (or similar)</span><br>
                        <img alt="Image canot Displayed"
                            src="https://images-na.ssl-images-amazon.com/images/I/61UYdfUBAoL._SX425_.jpg"
                            class="img-fluid img_size">
                        <br> <span class="small_text"> Economy</span>
                    </div>
                    <div class="col-md-5 col-sm-5 right_border">
                            
                        <!-- for mobile -->
                                <div class="row car_details_show">
                                        <div class="col-xs-3 car_deatails0">
                                            <p> <img [src]=seats_icon> 5 Seats </p>
                                            <p> <img [src]=door_icon> 5 Door </p>
                                           
                                        </div>
                                        <div class="col-xs-3 car_deatails1">
                                            <p><img [src]=bag_icon> 2 Luggage(s) </p>
                                            <p><img [src]=settings_icon> 1.5 L Engine </p>
                                           
                                        </div>
                                        <div class="col-xs-2 car_deatails2">
                                            <p> <img [src]=trans_icon> Auto </p>
                                            <p> <img [src]=ac_icon> AC </p>
                                        </div>
                                        
                                </div>
                                <div class="row car_details_show row_top_border">
                                    <div class="col-xs-4 car_deatails">
                                        <i class='bx bxs-map sub_icon'></i>
                                        <p class=" float_left">Current Location <br>Muscat International Airport</p>
                                        <span class="total_charges">TOTAL CHARGES</span>
                                        <h3>9.26 OMR</h3>
                                    </div>
                                    <div class="col-xs-4 car_deatails">
                                        <i class='bx bxs-coin-stack sub_icon'></i>
                                        <p class=" float_left">Delivery Charge <br>00.20 OMR</p>
                                       
                                        <div class="refundable">Refundable</div>
                                        <button routerLink="/newbooking" class=" button_margin btn btn-warning btn-lg">Book Now</button>
                                    </div>
                            </div>

                        <!-- end for mobile -->

                        <div class="row car_details_desk">
                            <div class="third_col_pad  col-md-6  col-sm-6 ">
                                <p> <img [src]=seats_icon> 5 Seats </p>
                                <p> <img [src]=door_icon> 5 Door </p>
                                <p> <img [src]=trans_icon> Auto </p>

                            </div>
                            <div class=" third_col_pad right_border col-md-6 col-sm-6 ">
                                <p><img [src]=bag_icon> 2 Luggage(s) </p>
                                <p><img [src]=settings_icon> 1.5 L Engine </p>
                                <p> <img [src]=ac_icon> AC </p>
                            </div>
                        </div>  
                        <div class="row row_top_border car_details_desk">
                            <div class="third_col_pad  col-md-6  col-sm-6 ">
                                <i class='bx bxs-map sub_icon'></i>
                                <p class=" float_left">Current Location <br>Muscat International Airport</p>
                            </div>
                            <div class="third_col_pad  col-md-6  col-sm-6 ">
                                <i class='bx bxs-coin-stack sub_icon'></i>
                                <p class=" float_left">Delivery Charge <br>00.20 OMR</p>
                            </div>


                        </div>
                    </div>

                    <div class=" third_col_pad col-md-3 col-sm-4 car_details_desk">
                        <span class="total_charges">TOTAL CHARGES</span>
                        <h3>9.26 OMR</h3>
                        <div class="refundable">Refundable</div>
                        <button routerLink="/newbooking" class=" button_margin btn btn-warning btn-lg">Book Now</button>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>