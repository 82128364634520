import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RoutingConstants } from 'src/app/app.routing.contents';
import { GenericService } from '../generic-service/generic.service';
import { Location } from '../../models/location/location.model';
import { GoogleLocationModel } from 'src/app/models/location/googl-location.model';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  constructor(private genericService: GenericService) { }

  getAll(): Observable<Array<Location>> {
    const serviceUrl = RoutingConstants.location.getAll;
    return this.genericService.getService(serviceUrl, true);
  }

  googleLocation(input: string): Observable<GoogleLocationModel> {
    const serviceUrl = RoutingConstants.location.googleLocation + '?input=' + input;
    return this.genericService.getService(serviceUrl, true);
  }

  locationByBookingModule(moduleId: number) {
    const serviceUrl = RoutingConstants.location.getLocationByBookingModule + '?bookingmoduleid=' + moduleId;
    return this.genericService.getService(serviceUrl, true);
  }
  getAllLocation(input: string){
    const serviceUrl = RoutingConstants.location.getAllLocation +'?placeId='+input;
    return this.genericService.getService(serviceUrl, true);
  }

}
