<head>
    <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/@splidejs/splide@latest/dist/css/splide.min.css">
</head>

<app-self-drive-nav *ngIf="!(location == '/error' || location == '/coming-soon' 
    || location == '/sign-in' 
    || location == '/sign-up'
    ||location == '/forgot-password')">
</app-self-drive-nav>

<div class="page-title-area page-title-bg3">
    <div class="container">
        <div class="page-title-content">
            <h2>Vehicle  Category</h2>
        </div>
    </div>
</div>

<section class="about-area ">
    <div class="container">

        <div class="about-inner-area">
            <div class="row">
                <div class="">
                    
                </div>
            </div>
        </div>

        <div class="container main-services">
            <div class="row">
                <div class="col-lg-4 col-sm-12 col-md-6">
                    <div class="single-listing-item">
                        <div class="listing-image">
                                <img src="https://marutisuzukiarenaprodcdn.azureedge.net/-/media/images/maruti/marutisuzuki/car/car-profile-shots/dzireoxfordbluemergednew-image-black-v3.ashx?rev=f07df5b8c6904180b034f13b73ca3b85&h=398&w=680&la=en&hash=A760EAFF21FEC468D10221293AFC98B2" alt="image" style="height: 170px;width: 100%;" loading="lazy">
                        </div>
                        <div class="listing-content">
                            <div class="listing-author d-flex align-items-center">
                            </div>
                            <p class="car-name">SUZAKI DZIRE 2020</p>
                            <p class="car-descp"><span style="color: #8a7043;font-weight: bolder; font-size: 15px;">Type:</span>Micro Type</p>
                            <p class="car-descp"><span style="color: #8a7043;font-weight: bolder;font-size: 15px">Capacity:</span>Max. 2 Persons Only With Driver</p>
                            <p class="car-descp"><span style="color: #8a7043;font-weight: bolder;font-size: 15px">Bike Career Allowable:</span>Max. Double Career (2 Bikes)</p>
                            <p class="car-descp"><span style="color: #8a7043;font-weight: bolder;font-size: 15px">Route Plan Suitability:</span>Smooth </p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-sm-12 col-md-6">
                    <div class="single-listing-item">
                        <div class="listing-image">
                                <img src="https://imgd.aeplcdn.com/664x374/ec/94/C0/9725/img/m/Nissan-Sunny-Right-Front-Three-Quarter-48975_ol.jpg?v=201711021421&q=85" alt="image" style="height: 170px;width: 100%;" loading="lazy">
                        </div>
                        <div class="listing-content">
                            <div class="listing-author d-flex align-items-center">
                            </div>
                            <p class="car-name">NISSAN SUNNY 2019</p>
                            <p class="car-descp"><span style="color: #8a7043;font-weight: bolder;font-size: 15px">Type:</span>Micro Type</p>
                            <p class="car-descp"><span style="color: #8a7043;font-weight: bolder;font-size: 15px">Capacity:</span>Max. 2 Persons Only With Driver</p>
                            <p class="car-descp"><span style="color: #8a7043;font-weight: bolder;font-size: 15px">Bike Career Allowable:</span>Max. Double Career (2 Bikes)</p>
                            <p class="car-descp"><span style="color: #8a7043;font-weight: bolder;font-size: 15px">Route Plan Suitability:</span>Smooth </p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-sm-12 col-md-6">
                    <div class="single-listing-item">
                        <div class="listing-image">
                                <img src="https://automobile-assets.s3.amazonaws.com/automobile/body/kia-cerato-2013-2016-1583228985.3.jpg" alt="image" style="height: 170px;width: 100%;" loading="lazy">
                        </div>
                        <div class="listing-content">
                            <div class="listing-author d-flex align-items-center">
                            </div>
                            <p class="car-name"> KIA CERATO 2015</p>
                            <p class="car-descp"><span style="color: #8a7043;font-weight: bolder;font-size: 15px">Type:</span>Micro Type</p>
                            <p class="car-descp"><span style="color: #8a7043;font-weight: bolder;font-size: 15px">Capacity:</span>Max. 2 Persons Only With Driver</p>
                            <p class="car-descp"><span style="color: #8a7043;font-weight: bolder;font-size: 15px">Bike Career Allowable:</span>Max. Double Career (2 Bikes)</p>
                            <p class="car-descp"><span style="color: #8a7043;font-weight: bolder;font-size: 15px">Route Plan Suitability:</span>Smooth </p>
                        </div>
                    </div>
                </div>
        
            </div>
        </div>

        
        

        
    </div>
</section>




<section class="about-area ">
    <div class="container">

        <div class="about-inner-area">
            <div class="row">
                <div class="">
                    <div class="about-text">
                        <h3>Remarks:</h3>
                        <ul>
                            <li>All vehicles are with automatic transition and AC Conditioner  Manual transition vehicle is available on request.</li>
                            <li>In case booked vehicle is not available during pick up date/time, similar category-type vehicle will be delivered or better one.</li>
                            <li>Roof or back ranks luggage’s will be provided for Vehicle types CUV, SUV and Super 4WD  for  person  capacity  3  and  above   Ranks  size/capacity  will  be  vary  from  vehicle  type  to  another.</li>
                            <li>All vehicle provided at this product is with full insurance  Please read more about Insurance Terms and Conditions and upgrading options.</li>
                            <li>Vehicle brands may vary from time to time (please refer to us to check updated options and availabilities.</li>
                        </ul>
                        
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>

<app-self-drive-footer *ngIf="!(location == '/error' || location == '/coming-soon' || location == '/sign-in'
    || location == '/sign-up'
    ||location == '/forgot-password')">
    </app-self-drive-footer>