import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  sectionId: any;

  constructor() { }

  public setSelectedCar(selectedItem: any) {
    localStorage.setItem('SelectedItem', JSON.stringify(selectedItem));
    
  }

  public getSelectedCar(): any {
    const selectedItem = localStorage.getItem('SelectedItem');
    return (selectedItem) ? JSON.parse(selectedItem) : null;
  }
}
