<html lang="en">

<head>

</head>

<body>
    <app-self-drive-nav *ngIf="!(location == '/error' || location == '/coming-soon'
        || location == '/sign-in'
        || location == '/sign-up'
        ||location == '/forgot-password')">
    </app-self-drive-nav>

    <div class="page-title-area page-title-bg3">
        <div class="hekaya-container">
            <div class="page-title-content">
                <h2>Hekayat Category</h2>
            </div>
        </div>
    </div>



    <div id="drivingCategory" class="hekaya-container">
        <div class="row text-center hekayat-titl top-row-margin">
            <div class="col-md-12 col-sm-12 col-lg-12">
                <h2 class="hekayat-titl head-color"><span class="hekayat-color">Hekayat</span> Driving Categories</h2>
                <p>At Hekayat, we have made categories for different aspects to give you more options to choose.<br>
                    Driving, upon skills required</p>

            </div>
        </div>
        <div class="row ct top-row-margin">
            <div class="col-md-3 col-lg-3 col-sm-12">
                <img src="../../../../assets/img/self-drive/hekayat-category/DRVsmoothdrive (1).jpg"
                    class="img-fluid image-radius img-width " loading="lazy" />
                <p>Smooth Driving</p>
                   
            </div>
            <div class="col-md-3 col-lg-3 col-sm-12">
                <img src="../../../../assets/img/self-drive/hekayat-category/DRVLightAdvnture (1).jpg"
                    class="img-fluid image-radius img-width " loading="lazy" />
                <p>Light Adventure Driving</p>
                    
            </div>
            <div class="col-md-3 col-lg-3 col-sm-12">
                <img src="../../../../assets/img/self-drive/hekayat-category/DRVMediumdrv (1).jpg"
                    class="img-fluid image-radius img-width " loading="lazy" />
                <p>Medium Adventure Driving</p>
                    
            </div>
            <div class="col-md-3 col-lg-3 col-sm-12">
                <img src="../../../../assets/img/self-drive/hekayat-category/DRVAdvanDrvCat (1).jpg"
                    class="img-fluid image-radius img-width" loading="lazy"/>
                <p>Advanced Adventure Driving</p>
                
            </div>
        </div>
    </div>

    <section>
        <div class="text-center">
          <button class="btn text-center explore-button btn-md"
              style="margin-top: 50px;margin-bottom: 30px;" (click)="ondrivingCategory(1)">Read More</button>
        </div>
    </section>

    <div class="hekaya-container">
        <div class="row top-row-margin">
            <div class="col-md-12 col-lg-12 col-sm-12">
                <header class="hero2">
                    <div class="center-content">
                        <h1>Experience hospitality at its finest </h1>

                    </div>
                    <div class="center-content2">
                      
                    </div>
                </header>
            </div>
        </div>
    </div>


    <div class="hekaya-container">
        <div class="row text-center hekayat-titl top-row-margin">
            <div class="col-md-12 col-sm-12 col-lg-12">
                <h2 class="hekayat-titl head-color"><span class="hekayat-color">Hekayat</span> Habitat Categories</h2>
                <p> Habitats represent as the Sultanate, as each habitat required
                    different driving skills and has different risk potential.</p>

            </div>
        </div>
    </div>



    <section id="habitatCategory" class="listing-area  pb-50" style="padding-top: 50px;">
        <div class="container">
            <div class="listing-slides owl-carousel owl-theme text-center">
                <div class="single-listing-box">
                    <a  class="listing-image">
                        <img src="../../../../assets/img/self-drive/habitat-category/HBTMountaintAsphatedHills (1).jpg" class="card-img-top" style="height: 250px;" alt="image" loading="lazy">
                    </a>

                    <div class="listing-content">
                        <div class="content">
                            <h3><a >Mountain Asphalted Hills</a></h3>
                        </div>
                    </div>
                </div>
                <div class="single-listing-box">
                    <a  class="listing-image">
                        <img src="../../../../assets/img/self-drive/habitat-category/HBTBeaches (1).jpg" class="card-img-top" style="height: 250px;" alt="image" loading="lazy">
                    </a>

                    <div class="listing-content">
                        <div class="content">
                            <h3><a >Beaches</a></h3>
                        </div>
                    </div>
                </div>
                <div class="single-listing-box">
                    <a  class="listing-image">
                        <img src="../../../../assets/img/self-drive/habitat-category/HBTwetlandandMarshials (1).jpg" class="card-img-top" style="height: 250px;" alt="image" loading="lazy">
                    </a>

                    <div class="listing-content">
                        <div class="content">
                            <h3><a >Wetland & Marshes</a></h3>
                        </div>
                    </div>
                </div>
                <div class="single-listing-box">
                    <a  class="listing-image">
                        <img src="../../../../assets/img/self-drive/habitat-category/HBTWadies (1).jpg" class="card-img-top" style="height: 250px;" alt="image" loading="lazy">
                    </a>

                    <div class="listing-content">
                        <div class="content">
                            <h3><a >Wadis</a></h3>
                        </div>
                    </div>
                </div>
                <div class="single-listing-box">
                    <a  class="listing-image">
                        <img src="../../../../assets/img/self-drive/hekayat-category/SandyDunes.jpg" class="card-img-top" style="height: 250px;" alt="image" loading="lazy">
                    </a>

                    <div class="listing-content">
                        <div class="content">
                            <h3><a >Sandy Dunes</a></h3>
                        </div>
                    </div>
                </div>
                <div class="single-listing-box">
                    <a  class="listing-image">
                        <img src="../../../../assets/img/self-drive/habitat-category/HBTMountaintOffRoading.jpg" class="card-img-top" style="height: 250px;" alt="image" loading="lazy">
                    </a>

                    <div class="listing-content">
                        <div class="content">
                            <h3><a >Mountain Off-Roadings</a></h3>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </section>
    <section>
        <div class="text-center">
          <button class="btn text-center explore-button btn-md"
              style="margin-top: 50px;margin-bottom: 30px;" (click)="onHabitatClick(2)" >Read More</button>
        </div>
      </section>

    <div class="hekaya-container">
        <div class="row top-row-margin">
            <div class="col-md-12 col-lg-12 col-sm-12">
                <header class="hero3">
                    <div class="center-content">
                        <h1>Your perfect Experience of Oman</h1>
                        <a href=" https://hekaya.co/WebInbound/Default.aspx" target="_blank"><button class="btn text-center explore-button btn-md" >Experience Oman</button></a>
                    </div>
                    <div class="center-content2">

                    </div>
                </header>

            </div>
        </div>
    </div>

    

    <div class="hekaya-container">
        <div class="row text-center hekayat-titl top-row-margin">
            <div class="col-md-12 col-sm-12 col-lg-12">
                <h2 class="hekayat-titl head-color"><span class="hekayat-color">Hekayat</span> Gear Categories</h2>
                <p>At Hekayat, we have divided camping equipment’s and others to
                    three main categories which are:</p>
             
            </div>
        </div>
    </div>

    <div id="gearSection" class="hekaya-container" style="margin-top: 50px;">
        <div class="row">
          <div class="col-md-4 col-lg-4 col-sm-12 grid-3p row-margin">
            <div class="card equipment-cad-PCE ">
                <div class="card-body text-center">
                   
                    <h5 class="card-title" style="color: #298b43;">Vehicle Camp Equipment’s</h5>
                    <p class="text-justify">This is by default equipment’s you will get per
                        rented vehicle including essential camp equipment’s
                        will be required for the entire group.</p>
                </div>
            </div>
          </div>
          <div class="col-md-4 col-lg-4 col-sm-12 grid-3p row-margin ">
            <div class="card equipment-cad-VCE ">
                <div class="card-body text-center">
                    
                    <h5 class="card-title" style="color: #00aeef;">Personal Camp Equipment’s</h5>
                    <p class="text-justify">This is by default equipment’s you will get per
                        person including essential personal camp equipment’s will be required for
                        each group member.</p>
                </div>
            </div>
          </div>
          <div class="col-md-4 col-lg-4 col-sm-12  grid-3p row-margin">
            <div class="card equipment-cad-OEL ">
                <div class="card-body text-center">
                   
                    <h5 class="card-title" style="color: #b58e5f;">Other Equipment’s List</h5>
                    <p class="text-justify">This is other equipment’s available on request only including more options of
                        camping equipment’s and others used for different activities.</p>
                </div>
            </div>
          </div>
        </div>
      </div>

    <div class="hekaya-container">
        <div class="row justify-content-center">

            <button class="btn text-center explore-button btn-md" (click)="onClick(3)">Read More</button>
        </div>
    </div>


    <div class="hekaya-container">
        <div class="row top-row-margin">
            <div class="col-md-12 col-lg-12 col-sm-12">
                <header class="hero4">
                    <div class="center-content">
                        <h1>Your perfect Experience of Oman</h1>
                    </div>
                    <div class="center-content2">

                    </div>
                </header>

            </div>
        </div>
    </div>


    <div class="hekaya-container">
        <div class="row text-center hekayat-titl top-row-margin">
            <div class="col-md-12 col-sm-12 col-lg-12">
                <h2 class="hekayat-titl head-color"><span class="hekayat-color">Hekayat</span> Car Categories</h2>
                <p>Vehicle, as it is essential to choose the right vehicle category
                    for each route plan, habitats, number of passengers and so on.</p>
            </div>
        </div>
    </div>

    <div class="hekaya-container">
        <div class="row justify-content-center">
            <button class="btn text-center explore-button btn-md" routerLink="/selfdrive">Book Now</button>

        </div>
    </div>



    <div class="hekaya-container" style="margin-top: 70px;">
        <div class="row">
            <div class="col-md-12 text-center">
                <div class="tab faq-accordion-tab">
                    <ul class="tabs d-flex flex-wrap ">
                        <li><a href="#"> <span>MICRO TYPE</span></a></li>

                        <li><a href="#"> <span>SEDAN TYPE</span></a></li>

                        <li><a href="#"> <span>CUV TYPE</span></a></li>

                        <li><a href="#"> <span>SMALL SUV</span></a></li>

                        <li><a href="#"> <span>MEDIUM SUV</span></a></li>

                        <li><a href="#"> <span>SUPER SUV</span></a></li>
                    </ul>

                    <div class="tab-content">
                        <div class="tabs-item">
                            <div class="faq-accordion" id="accordionEx" role="tablist" aria-multiselectable="true" >
                                <h3>Micro Types</h3>
                                <div class="container">
                                    <div class="row">
                                        <div class="col-md-4 col-lg-4 col-sm12">
                                            <div class="card car-cad">
                                                <div class="card-body text-center">
                                                    <img src="../../../../assets/img/self-drive/cars/micro_types/nizan_sunny.jpg"
                                                        class="img-fluid" style="height: auto !important;" loading="lazy" />
                                                    <h5 class="card-title">NISSAN SUNNY 2019 or similar</h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4 col-lg-4 col-sm12">
                                            <div class="card car-cad">
                                                <div class="card-body text-center">
                                                    <img src="../../../../assets/img/self-drive/cars/micro_types/kia_cerato.jpg"
                                                        class="img-fluid" style="height: auto !important;"  loading="lazy"/>
                                                    <h5 class="card-title">KIA CERATO 2015 or similar</h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4 col-lg-4 col-sm12">
                                            <div class="card car-cad">
                                                <div class="card-body text-center">
                                                    <img src="../../../../assets/img/self-drive/cars/micro_types/mada.jpg"
                                                        class="img-fluid" style="height: auto !important;" loading="lazy"/>
                                                    <h5 class="card-title">MADZA 2 2016 or similar</h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4 col-lg-4 col-sm12">
                                            <div class="card car-cad">
                                                <div class="card-body text-left">
                                                    <p><b>Capacity: </b>Max. 2 Persons Only With Driver </p>
                                                    <p><b>Bike Career Allowable:</b> Max. Dou-ble Career (2 Bikes)</p>
                                                    <p><b>Route Plan Suitability:</b> Smooth</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="tabs-item">
                            <div class="faq-accordion" id="accordionEx" role="tablist" aria-multiselectable="true" >
                                <h3>Zedan Types</h3>
                                <div class="container">
                                    <div class="row">
                                        <div class="col-md-4 col-lg-4 col-sm12">
                                            <div class="card car-cad">
                                                <div class="card-body text-center">
                                                    <img src="../../../../assets/img/self-drive/cars/sedan/NISSAN_ALTIMA_2015.jpg"
                                                        class="img-fluid" style="height: auto !important;" loading="lazy" />
                                                    <h5 class="card-title">NISSAN ALTIMA 2015 or similar</h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4 col-lg-4 col-sm12">
                                            <div class="card car-cad">
                                                <div class="card-body text-center">
                                                    <img src="../../../../assets/img/self-drive/cars/sedan/TOYOTA_CAMRY_2015.jpg"
                                                        class="img-fluid" style="height: auto !important;" loading="lazy" />
                                                    <h5 class="card-title">TOYOTA CAMRY 2015 or similar</h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4 col-lg-4 col-sm12">
                                            <div class="card car-cad">
                                                <div class="card-body text-left">
                                                    <p><b>Capacity: </b>Max. 2 Persons Only With Driver </p>
                                                    <p><b>Bike Career Allowable:</b>Max. Double Career (2 Bikes)</p>
                                                    <p><b>Route Plan Suitability:</b> Smooth </p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="tabs-item">
                            <div class="faq-accordion" id="accordionEx" role="tablist" aria-multiselectable="true" >
                                <h3>CUV Type</h3>
                                <div class="container">
                                    <div class="row">
                                        <div class="col-md-4 col-lg-4 col-sm12">
                                            <div class="card car-cad">
                                                <div class="card-body text-center">
                                                    <img src="../../../../assets/img/self-drive/cars/CUV/TOYOTA_RAV_2016.jpg"
                                                        class="img-fluid" style="height: auto !important;" loading="lazy"/>
                                                    <h5 class="card-title"> TOYOTA RAV4 2016 or similar</h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4 col-lg-4 col-sm12">
                                            <div class="card car-cad">
                                                <div class="card-body text-left">
                                                    <p><b>Capacity: </b>Max. 4 Persons With Driver </p>
                                                    <p><b>Bike Career Allowable:</b>Max. Double Career (2 Bikes)</p>
                                                    <p><b>Route Plan Suitability:</b> Smooth and Light Adventure Driving </p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="tabs-item">
                            <div class="faq-accordion" id="accordionEx" role="tablist" aria-multiselectable="true" >
                                <h3>Small SUV</h3>
                                <div class="container">
                                    <div class="row">
                                        <div class="col-md-4 col-lg-4 col-sm12">
                                            <div class="card car-cad">
                                                <div class="card-body text-center">
                                                    <img src="../../../../assets/img/self-drive/cars/small_suv/JEEB_WRANGLER_2017.jpg"
                                                        class="img-fluid" style="height: auto !important;" loading="lazy" />
                                                    <h5 class="card-title"> JEEB WRANGLER 2017 or similar</h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4 col-lg-4 col-sm12">
                                            <div class="card car-cad">
                                                <div class="card-body text-center">
                                                    <img src="../../../../assets/img/self-drive/cars/small_suv/NISSAN_PATHFINDER_2015.jpg"
                                                        class="img-fluid" style="height: auto !important;" />
                                                    <h5 class="card-title">NISSAN PATHFINDER 2015 or similar</h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4 col-lg-4 col-sm12">
                                            <div class="card car-cad">
                                                <div class="card-body text-center">
                                                    <img src="../../../../assets/img/self-drive/cars/small_suv/TOYOTA_FJ 2017.jpg"
                                                        class="img-fluid" style="height: auto !important;" loading="lazy" />
                                                    <h5 class="card-title">TOYOTA FJ 2017 or similar</h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4 col-lg-4 col-sm12">
                                            <div class="card car-cad">
                                                <div class="card-body text-left">
                                                    <p><b>Capacity: </b>Max. 5 persons with driver</p>
                                                    <p><b>Bike Career Allowable:</b>Max. 4 Career (4 Bikes)</p>
                                                    <p><b>Route Plan Suitability:</b> Smooth, Light, Medium and Advance Adventure  </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="tabs-item">
                            <div class="faq-accordion" id="accordionEx" role="tablist" aria-multiselectable="true" >
                                <h3>Medium SUV</h3>
                                <div class="container">
                                    <div class="row">
                                        <div class="col-md-4 col-lg-4 col-sm12">
                                            <div class="card car-cad">
                                                <div class="card-body text-center">
                                                    <img src="../../../../assets/img/self-drive/cars/medium_suv/MITSUBISHI_PAJERO_2016.jpg"
                                                        class="img-fluid" style="height: auto !important;" loading="lazy"/>
                                                    <h5 class="card-title">MITSUBISHI PAJERO 2016 or similar</h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4 col-lg-4 col-sm12">
                                            <div class="card car-cad">
                                                <div class="card-body text-center">
                                                    <img src="../../../../assets/img/self-drive/cars/medium_suv/TOYOTA_PRADO_2015 .jpg"
                                                        class="img-fluid" style="height: auto !important;" loading="lazy" />
                                                    <h5 class="card-title">TOYOTA PRADO 2015 or similar</h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4 col-lg-4 col-sm12">
                                            <div class="card car-cad">
                                                <div class="card-body text-left">
                                                    <p><b>Capacity: </b>Max. 7 persons with driver</p>
                                                    <p><b>Bike Career Allowable:</b>Max. 4 Career (4 Bikes)</p>
                                                    <p><b>Route Plan Suitability:</b> Smooth , Light, Medium and Advance Adventure </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="tabs-item">
                            <div class="faq-accordion" id="accordionEx" role="tablist" aria-multiselectable="true" >
                                <h3>Supper SUV</h3>
                                    <div class="container">
                                        <div class="row">
                                            <div class="col-md-4 col-lg-4 col-sm12">
                                                <div class="card car-cad">
                                                    <div class="card-body text-center">
                                                        <img src="../../../../assets/img/self-drive/cars/super_suv/TOYOTA_LAND_CRUISER_2013.jpg"
                                                            class="img-fluid" style="height: auto !important;" loading="lazy"/>
                                                        <h5 class="card-title"> TOYOTA LAND CRUISER 2013 or similar</h5>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4 col-lg-4 col-sm12">
                                                <div class="card car-cad">
                                                    <div class="card-body text-left">
                                                        <p><b>Capacity: </b> Max. 7 persons with driver</p>
                                                        <p><b>Bike Career Allowable:</b>Max. 4 Career (4 Bikes)</p>
                                                        <p><b>Route Plan Suitability:</b> Smooth, Light, Medium and Advance Adventure</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    </div>








    <app-self-drive-footer *ngIf="!(location == '/error' || location == '/coming-soon' || location == '/sign-in'
    || location == '/sign-up'
    ||location == '/forgot-password')">
    </app-self-drive-footer>







</body>

</html>
