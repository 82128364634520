import { Component, OnInit } from '@angular/core';
import * as $ from "jquery";
@Component({
  selector: 'app-booking',
  templateUrl: './booking.component.html',
  styleUrls: ['./booking.component.scss']
})
export class BookingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  
  change_collection()
   {
    if($("#collection1").prop('checked') == true)
    {
      $(".collection_city").removeClass("hide_control");
      $(".collection_address").removeClass("hide_control");
    }else
     {
      $(".collection_city").addClass("hide_control");
      $(".collection_address").addClass("hide_control");
     }
   }

   change_return()
    {
      if($("#return_check").prop('checked')==true)
       {
         $(".return_loc").removeClass("hide_control");
       }
       else
       {
        $(".return_loc").addClass("hide_control");
       }
    }

}
