import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Ng5SliderModule } from 'ng5-slider';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgxAutocompleteModule } from 'ngx-angular-autocomplete';
import { DatePipe, HashLocationStrategy, LocationStrategy } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/views/home/home.component';
import { HeaderComponent } from './components/layouts/header/header.component';
import { ModalModule } from 'ngx-bootstrap/modal';



import { FooterComponent } from './components/layouts/footer/footer.component';
import { AppDownloadComponent } from './components/common/app-download/app-download.component';
import { FeedbackComponent } from './components/common/feedback/feedback.component';
import { VideoComponent } from './components/common/video/video.component';
import { ProcessComponent } from './components/common/process/process.component';
import { HeaderOneComponent } from './components/layouts/header-one/header-one.component';
import { PartnerComponent } from './components/common/partner/partner.component';
import { HeaderTwoComponent } from './components/layouts/header-two/header-two.component';
import { InstagramComponent } from './components/common/instagram/instagram.component';
import { HeaderThreeComponent } from './components/layouts/header-three/header-three.component';
import { AboutComponent } from './components/pages/about/about.component';
import { FunfactsComponent } from './components/common/funfacts/funfacts.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { CheckoutComponent } from './components/pages/checkout/checkout.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';
import { LoginComponent } from './components/pages/login/login.component';
import { RegisterComponent } from './components/pages/register/register.component';
import { FleetComponent } from './components/pages/fleet/fleet.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BookingComponent } from './components/pages/booking/booking.component';
import { NewbookingComponent } from './components/pages/newbooking/newbooking.component';
import { SignInComponent } from './components/views/sign-in/sign-in.component';
import { SignUpComponent } from './components/views/sign-up/sign-up.component';
import { ForgotPasswordComponent } from './components/views/forgot-password/forgot-password.component';
import { ServicesComponent } from './components/pages/services/services.component';
import { AlertModule } from 'ngx-alerts';
import { PaymentComponent } from './components/pages/payment/payment.component';
import { ClickOutsideDirective } from './models/shared/dropdown.directive';
import { SearchFilterPipe } from './models/shared/filter-pipe';
import { LetterBoldPipe } from './models/shared/letter-bold.pipe';
import { PaymentFailureComponent } from './components/views/payment-failure/payment-failure.component';
import { WhatIsHekayatComponent } from './components/pages/what-is-hekayat/what-is-hekayat.component';
import { WhyHekayatComponent } from './components/pages/why-hekayat/why-hekayat.component';
import { PlanninYourRoadTripComponent } from './components/pages/plannin-your-road-trip/plannin-your-road-trip.component';
import { SelfDriveExploreComponent } from './components/pages/self-drive/self-drive.component';
import { SelfCampComponent } from './components/pages/self-camp/self-camp.component';
import { OmanClimateComponent } from './components/pages/oman-climate/oman-climate.component';
import { HekayaRouteComponent } from './components/pages/hekaya-route/hekaya-route.component';
import { HekayatCategoriesComponent } from './components/pages/hekayat-categories/hekayat-categories.component';
import { CampingGearComponent } from './components/pages/camping-gear/camping-gear.component';
import { HekayatGuidenceComponent } from './components/pages/hekayat-guidence/hekayat-guidence.component';
import { HabitatCategoryComponent } from './components/pages/habitat-category/habitat-category.component';
import { VehicleCategoryComponent } from './components/pages/vehicle-category/vehicle-category.component';
import { HekayaRouteMapComponent } from './components/pages/hekaya-route-map/hekaya-route-map.component';
import { TermsAndConditionComponent } from './components/pages/terms-and-condition/terms-and-condition.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { SelfDriveNavComponent } from './components/layouts/self-drive-nav/self-drive-nav.component';
import { SelfDriveFooterComponent } from './components/layouts/self-drive-footer/self-drive-footer.component';
import { HekayatGuidenceTwoComponent } from './components/pages/hekayat-guidence-two/hekayat-guidence-two.component';
import { HekayatGuidenceThreeComponent } from './components/pages/hekayat-guidence-three/hekayat-guidence-three.component';
import { HekayatGuidenceThreeInnerComponent } from './components/pages/hekayat-guidence-three-inner/hekayat-guidence-three-inner.component';
import { MapDetailPageComponent } from './components/pages/map-detail-page/map-detail-page.component';
import { WebUseTermsComponent } from './components/pages/web-use-terms/web-use-terms.component';
import { SelfdriveWebUseTermsComponent } from './components/pages/selfdrive-web-use-terms/selfdrive-web-use-terms.component';
import { DefaultHomeComponent } from './components/views/default-home/default-home.component';
import { SelfDriveComponent } from './components/views/self-drive/self-drive/self-drive.component';
import { SelfdriveHomeComponent } from './components/views/selfdrive-home/selfdrive-home.component';
import { PickDropHeaderComponent } from './components/layouts/pick-drop-header/pick-drop-header.component';
import { PickDropFooterComponent } from './components/layouts/pick-drop-footer/pick-drop-footer.component';
import { PickDropComponent } from './components/views/pick-drop/pick-drop/pick-drop.component';
import { MatSelectModule } from '@angular/material/select';
import { NeedDeliveryComponent } from './components/views/selfdrive-home/need-delivery/need-delivery.component';
import { NeedGearDeliveryComponent } from './components/views/selfdrive-home/need-gear-delivery/need-gear-delivery.component';
import { ReqOneWayTransferComponent } from './components/views/pick-drop/pick-drop/new-pick-drop-search-form/req-one-way-transfer/req-one-way-transfer.component';
import { ReqTransferOnReturnComponent } from './components/views/pick-drop/pick-drop/new-pick-drop-search-form/req-transfer-on-return/req-transfer-on-return.component';
import { PickUpLocationSearchFormComponent } from './components/views/pick-drop/pick-drop/new-pick-park-search-form/pick-up-location-search-form/pick-up-location-search-form.component';
import { SelfParkAtHubSearchFormComponent } from './components/views/pick-drop/pick-drop/new-pick-park-search-form/self-park-at-hub-search-form/self-park-at-hub-search-form.component';
import { SharedModule } from './components/shared/shared.module';
import { ResetPasswordComponent } from './components/views/reset-password/reset-password/reset-password.component';
import { HomeNizwaComponent } from './components/views/home-nizwa/home-nizwa.component';
import { Home1Component } from './components/views/home1/home1.component';
import { Home2Component } from './components/views/home2/home2.component';
// import { SitemapComponent } from './components/views/sitemap/sitemap.component';
import { HomeSalalahComponent } from './components/views/home-salalah/home-salalah.component';
import { HomeSoharComponent } from './components/views/home-sohar/home-sohar.component';
import { HomeJebelAkhdarComponent } from './components/views/home-jebel-akhdar/home-jebel-akhdar.component';




@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HomeComponent,
    HeaderComponent,
    AppDownloadComponent,
    FeedbackComponent,
    VideoComponent,
    ProcessComponent,
    HeaderOneComponent,
    PartnerComponent,
    HeaderTwoComponent,
    InstagramComponent,
    HeaderThreeComponent,
    AboutComponent,
    FunfactsComponent,
    ContactComponent,
    CheckoutComponent,
    FaqComponent,
    ErrorComponent,
    ComingSoonComponent,
    LoginComponent,
    RegisterComponent,
    FleetComponent,
    BookingComponent,
    NewbookingComponent,
    SignInComponent,
    SignUpComponent,
    ForgotPasswordComponent,
    ServicesComponent,
    PaymentComponent,
    ClickOutsideDirective,
    SearchFilterPipe,
    LetterBoldPipe,    
    PaymentFailureComponent,
    WhatIsHekayatComponent,
    WhyHekayatComponent,
    PlanninYourRoadTripComponent,
    SelfDriveExploreComponent,
    SelfCampComponent,
    OmanClimateComponent,
    HekayaRouteComponent,
    HekayatCategoriesComponent,
    CampingGearComponent,
    HekayatGuidenceComponent,
    HabitatCategoryComponent,
    VehicleCategoryComponent,
HekayaRouteMapComponent,
    TermsAndConditionComponent,
    PrivacyPolicyComponent,
    SelfDriveNavComponent,
    SelfDriveFooterComponent,
    HekayatGuidenceTwoComponent,
    HekayatGuidenceThreeComponent,
    HekayatGuidenceThreeInnerComponent,
    MapDetailPageComponent,
    WebUseTermsComponent,
    SelfdriveWebUseTermsComponent,
    DefaultHomeComponent,
    SelfdriveHomeComponent,
    PickDropHeaderComponent,
    PickDropFooterComponent,
    PickDropComponent,
    NeedDeliveryComponent,
    NeedGearDeliveryComponent,
    ReqOneWayTransferComponent,
    ReqTransferOnReturnComponent,
    PickUpLocationSearchFormComponent,
    SelfParkAtHubSearchFormComponent,
    ResetPasswordComponent,
    HomeNizwaComponent,
    Home1Component,
    Home2Component,
    HomeSalalahComponent,
    HomeSoharComponent,
    HomeJebelAkhdarComponent,


  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    Ng5SliderModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    BsDatepickerModule.forRoot(),
    ModalModule.forRoot(),
    AlertModule.forRoot({ maxMessages: 5, timeout: 5000, positionX: 'right' }),
    NgxAutocompleteModule,
    MatSelectModule,
    SharedModule

  ],
  providers: [DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
