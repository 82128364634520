import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertService } from 'ngx-alerts';
import { Prediction } from 'src/app/models/location/googl-location.model';
import { vehicleType } from 'src/app/models/vehicle-type/vehicleType.model';
import { LocationService } from 'src/app/services/location/location.service';
import { PickAndDropService } from '../../../services/pick-and-drop.service';
import {ResultService} from '../../../../../../services/pick-park-service/pick-park-result/result.service';

@Component({
  selector: 'app-pick-up-location-search-form',
  templateUrl: './pick-up-location-search-form.component.html',
  styleUrls: ['./pick-up-location-search-form.component.scss']
})
export class PickUpLocationSearchFormComponent implements OnInit {

  public minDatePickUp: Date = new Date();
  public maxDatePickUp: Date;
  public minDateReturn: Date;
  public maxDateReturn: Date;
  public pickUpFromHubValidForm: any;
  public pickUpCities: Prediction[] = [];
  public dropOffCities: Prediction[] = [];
  public pickUpTime = [];
  public returnTime = [];
  public noOfDays: any;

  @Input() public times: Array<string>;
  @Input() public vehiclesTypes: Array<vehicleType>;
  @Input() public parkOnlylocation: any;

  public returnHubLocation: any;

  public transferPurpose = [
    'I am traveling via airport/port and need my vehicle to be transferred',
    'Vehicle transfer (just need my vehicle to be transferred)',
    'Vehicle for regular service at Agency/Workshop',
    // 'Vehicle for major service at Agency/Workshop',
    // 'Vehicle for regular maintenance at Agency/Workshop',
    // 'Vehicle for tire change at Agency/Worksop',
    'Vehicle for usual cleaning',
    'Vehicle for advance cleaning or polish',
    'Vehicle for accessories installation'
  ];


  //#endregion

  constructor(
    private alertService: AlertService
    , private _route: Router
    , private formBuilder: FormBuilder
    , private datepipe: DatePipe
    , private _locationService: LocationService
    , private _service: ResultService
  ) {
    this.minDatePickUp = new Date((new Date()).getTime() + (1 * 24 * 60 * 60 * 1000));
    this.minDateReturn = new Date((new Date()).getTime() + (1 * 24 * 60 * 60 * 1000));
   }

  ngOnInit(): void {
    this.initFormElements();
  }

  checkTime(i) {
    return (i < 10) ? "0" + i : i;
  }

  startTime() {
    let currentDate = new Date((new Date()).getTime() + (1 * 24 * 60 * 60 * 1000)).getDate();
    if( new Date(this.pickUpFromHubValidForm.pickUpDate).getDate() === currentDate){
      var today = new Date(),
        h = this.checkTime(today.getHours()),
        m = this.checkTime(today.getMinutes())
        this.generateTime(h,m);
    } else {
      this.pickUpTime = this.times;
    }
  }

  generateTime(h,m){
    for (let i = h; i < 24; i++) {
      if (i < 10) {
        this.pickUpTime.push('0' + i + ':00');
        this.pickUpTime.push('0' + i + ':30');
      } else {
        this.pickUpTime.push(i + ':00');
        this.pickUpTime.push(i + ':30');
      }
    }
  }

  getReturnTime(){
    this.returnTime = [];
    if( new Date(this.pickUpFromHubValidForm.returnDate).getDate() === new Date(this.pickUpFromHubValidForm.pickUpDate).getDate()){
      this.returnTime = this.pickUpTime.filter(x => x > this.pickUpFromHubValidForm.pickUpTime);
        // let pickUpTime = this.pickUpFromHubValidForm.pickUpTime;
        // pickUpTime = pickUpTime.split(':');
        // let h = this.checkTime(parseInt(pickUpTime[0]))
        // let m = this.checkTime(parseInt(pickUpTime[1]))
        // this.generateReturnTime(h,m);
    } else {
        this.returnTime = this.times;
    }
    
  }

  generateReturnTime(h,m){
    for (let i = h; i < 24; i++) {
      if (i < 10) {
        this.returnTime.push('0' + i + ':00');
        this.returnTime.push('0' + i + ':' + m);
      } else {
        this.returnTime.push(i + ':00');
        this.returnTime.push(i + ':' + m);
      }
    }

  
  }

  getNoOfDays(){
    this.getReturnTime();
    let pickUpDate: Date;
    let returnDate: Date;
    pickUpDate = new Date(this.pickUpFromHubValidForm.pickUpDate);
    returnDate = new Date(this.pickUpFromHubValidForm.returnDate);
    this.noOfDays = (Math.ceil(Math.abs(returnDate.getTime() - pickUpDate.getTime()) / (1000 * 3600 * 25))) + 1;
  }

  public filteredReturnlocation(){
    this.returnHubLocation = this.parkOnlylocation.filter(x => x.id !== parseInt(this.pickUpFromHubValidForm.dropOffLocation));
  }


  public dateSelect(event: Date) {
    this.minDateReturn = event;
    this.startTime();
  }

  public onEnterLocation(event, type: number) {
    this.pickUpCities = [];
    this.dropOffCities = [];
    if (event?.target?.value) {
      const value = event.target.value;
      if (value?.length > 2) {
        this._locationService.googleLocation(value).subscribe(result => {
          if (result?.data?.predictions?.length > 0) {
            if (type === 1) {
              this.pickUpCities = result.data.predictions;
            } else if (type === 2) {
              this.dropOffCities = result.data.predictions;
            }
          }
        });
      }
    }

  }

  public onSelectItem(event: any, type: number) {
    if (event) {
      if (type === 1) {
        this.pickUpFromHubValidForm.pickUpLocation = event;
      } else if (type === 2) {
        this.pickUpFromHubValidForm.returnLocation = event;
      }
    }

  }

  private validateForm() {
    if (!this.pickUpFromHubValidForm.isEnableSearchPickUpLocation) {
      if (!this.pickUpFromHubValidForm.pickUpLocationID) {
        this.alertService.danger('Please select pick-up hub location.');
        return false;
      }
    }

    if (this.pickUpFromHubValidForm.isEnableSearchPickUpLocation) {
      if (!this.pickUpFromHubValidForm.pickUpLocation) {
        this.alertService.danger('Please enter pick-up city.');
        return false;
      }
    }

    if (!this.pickUpFromHubValidForm.dropOffLocation) {
      this.alertService.danger('Please select drop-off hub location.');
      return false;
    }

    if (!this.pickUpFromHubValidForm.vehicleType) {
      this.alertService.danger('Please select vehicle type.');
      return false;
    }

    if (!this.pickUpFromHubValidForm.pickUpDate) {
      this.alertService.danger('Please select pick-up date.');
      return false;
    }

    if (!this.pickUpFromHubValidForm.pickUpTime) {
      this.alertService.danger('Please select pick-up time.');
      return false;
    }

    if (!this.pickUpFromHubValidForm.pickVehicleMyself && !this.pickUpFromHubValidForm.isReturnToFirstPickUp && !this.pickUpFromHubValidForm.isReturnToAnotherLocaton) {
      this.alertService.danger('Please select one option.');
      return false;
    }

    if (this.pickUpFromHubValidForm.pickVehicleMyself) {
      if (!this.pickUpFromHubValidForm.returnDate) {
        this.alertService.danger('Please select return date.');
        return false;
      }

      if (!this.pickUpFromHubValidForm.returnTime) {
        this.alertService.danger('Please select return time.');
        return false;
      }
    }

    if (this.pickUpFromHubValidForm.isReturnToFirstPickUp) {
      if (!this.pickUpFromHubValidForm.returnDate) {
        this.alertService.danger('Please select return date.');
        return false;
      }

      if (!this.pickUpFromHubValidForm.returnTime) {
        this.alertService.danger('Please select return time.');
        return false;
      }
    }

    if (this.pickUpFromHubValidForm.isReturnToAnotherLocaton) {

      if (!this.pickUpFromHubValidForm.isEnableSearchReturnLocation) {
        if (!this.pickUpFromHubValidForm.returnLocationID) {
          this.alertService.danger('Please select return location.');
        }
      }

      if (this.pickUpFromHubValidForm.isEnableSearchReturnLocation) {
        if (!this.pickUpFromHubValidForm.returnLocation) {
          this.alertService.danger('Please enter return city.');
        }
      }

      if (!this.pickUpFromHubValidForm.returnDate) {
        this.alertService.danger('Please select return date.');
        return false;
      }

      if (!this.pickUpFromHubValidForm.returnTime) {
        this.alertService.danger('Please select return time.');
        return false;
      }
    }

    return true;

  }

  onSubmitSearch() {
    if (this.validateForm()) {
      const vehicleType = this.vehiclesTypes.find((x) => x.id === Number(this.pickUpFromHubValidForm.vehicleType));

      let returnLocation = null;
      if (this.pickUpFromHubValidForm.pickVehicleMyself) {
        returnLocation = {
          date: this.pickUpFromHubValidForm.returnDate,
          time: this.pickUpFromHubValidForm.returnTime,
        };
      }

      if (this.pickUpFromHubValidForm.isReturnToFirstPickUp) {
        returnLocation = {
          placeId: this.pickUpFromHubValidForm.pickUpLocationID ?
          this.parkOnlylocation.find((x) => x.id === parseInt(this.pickUpFromHubValidForm.pickUpLocationID)).placeID : this.pickUpFromHubValidForm.pickUpLocation.place_id,
          locationId: this.pickUpFromHubValidForm.pickUpLocationID ? parseInt(this.pickUpFromHubValidForm.pickUpLocationID) : 0,
          name: this.pickUpFromHubValidForm.pickUpLocationID ?
          this.parkOnlylocation.find((x) => x.id === parseInt(this.pickUpFromHubValidForm.pickUpLocationID)).locationName : this.pickUpFromHubValidForm.pickUpLocation.name,
          date: this.pickUpFromHubValidForm.returnDate,
          time: this.pickUpFromHubValidForm.returnTime,
        };
      }
      if (this.pickUpFromHubValidForm.isReturnToAnotherLocaton) {
        returnLocation = {
          placeId: this.pickUpFromHubValidForm.returnLocationID ?
          this.parkOnlylocation.find((x) => x.id === parseInt(this.pickUpFromHubValidForm.returnLocationID)).placeID : this.pickUpFromHubValidForm.returnLocation.place_id,
          locationId: this.pickUpFromHubValidForm.returnLocationID ? parseInt(this.pickUpFromHubValidForm.returnLocationID) : 0,
          name: this.pickUpFromHubValidForm.returnLocationID ?
          this.parkOnlylocation.find((x) => x.id === parseInt(this.pickUpFromHubValidForm.returnLocationID)).locationName : this.pickUpFromHubValidForm.returnLocation.name,
          date: this.pickUpFromHubValidForm.returnDate,
          time: this.pickUpFromHubValidForm.returnTime,
        };
      }

      const search = {
        formType: 1,
        vehicleType,
        pickUp: {
          placeId: this.pickUpFromHubValidForm.pickUpLocationID ?
          this.parkOnlylocation.find((x) => x.id === parseInt(this.pickUpFromHubValidForm.pickUpLocationID)).placeID : this.pickUpFromHubValidForm.pickUpLocation.place_id,
          locationId: this.pickUpFromHubValidForm.pickUpLocationID ? parseInt(this.pickUpFromHubValidForm.pickUpLocationID) : 0,
          name: this.pickUpFromHubValidForm.pickUpLocationID ?
          this.parkOnlylocation.find((x) => x.id ===  parseInt(this.pickUpFromHubValidForm.pickUpLocationID)).locationName : this.pickUpFromHubValidForm.pickUpLocation.name,
          date: this.pickUpFromHubValidForm.pickUpDate,
          time: this.pickUpFromHubValidForm.pickUpTime,
        },
        dropOff: {
          placeId: this.pickUpFromHubValidForm.dropOffLocation ?
          this.parkOnlylocation.find((x) => x.id === parseInt(this.pickUpFromHubValidForm.dropOffLocation)).placeID : this.pickUpFromHubValidForm.dropOffAddress.place_id,
          locationId: this.pickUpFromHubValidForm.dropOffLocation ? parseInt(this.pickUpFromHubValidForm.dropOffLocation) : 0,
          name: this.pickUpFromHubValidForm.dropOffLocation ?
          this.parkOnlylocation.find((x) => x.id === parseInt(this.pickUpFromHubValidForm.dropOffLocation)).locationName : this.pickUpFromHubValidForm.dropOffAddress.name,
        },
        return: returnLocation,
        isSelfPark: false,
        pickVehicleMyself: this.pickUpFromHubValidForm.pickVehicleMyself,
        isReturnToFirstPickUp: this.pickUpFromHubValidForm.isReturnToFirstPickUp,
        isReturnToAnotherLocaton: this.pickUpFromHubValidForm.isReturnToAnotherLocaton
      };



      this._service.searchData = search;
      this._route.navigate(['/pick-and-park/search-results']);
    }
  }

  private initFormElements(): void {
    this.pickUpFromHubValidForm = {
      isEnableSearchPickUpLocation: true,
      isEnableSearchDropOffLocation: false,
      pickVehicleMyself: false,
      isReturnToFirstPickUp: false,
      isReturnToAnotherLocaton: false,
      isEnableSearchReturnLocation: true,
      pickUpLocationID: '',
      dropOffLocation: '',
      pickUpDate: '',
      pickUpTime: '',
      returnLocationID: '',
      returnDate: '',
      returnTime: '',
      vehicleType:  '',
    };
  }


}
