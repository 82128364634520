
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { SearchRequest } from '../../../models/pick-park/result/result-request.model';
import { GenericService } from '../../generic-service/generic.service';
import { RoutingConstants } from 'src/app/app.routing.contents';
import { SearchResponse } from 'src/app/models/pick-park/result/result-response.model';
import { BookingResponse } from 'src/app/models/pick-park/booking/pickparkbookingresponse.model';
import { BookingRequest } from 'src/app/models/pick-park/booking/pickparkbookingrequest.model';

@Injectable({
  providedIn: 'root'
})
export class ResultService {
  sharedData: any;
  location: any;
  searchData: any;
  selectedOption: any;

  constructor(private genericService: GenericService) { }

  searchService(request) {
    const serviceUrl = RoutingConstants.pickPark.search;
    return this.genericService.postService(request, serviceUrl, true);
  }

  bookngService(request: BookingRequest): Observable<BookingResponse>{
    const serviceUrl = RoutingConstants.pickPark.bookings;
    return this.genericService.postService(request, serviceUrl, true);
  }

  public setSelectedCar(selectedItem: any) {
    localStorage.setItem('SelectedItem', JSON.stringify(selectedItem));

  }

  public getSelectedCar(): any {
    const selectedItem = localStorage.getItem('SelectedItem');
    return (selectedItem) ? JSON.parse(selectedItem) : null;
  }


}
