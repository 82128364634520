<app-self-drive-nav *ngIf="!(location == '/error' || location == '/coming-soon'
    || location == '/sign-in'
    || location == '/sign-up'
    ||location == '/forgot-password')">
</app-self-drive-nav>

<div class="page-title-area page-title-bg3">
    <div class="container">
        <div class="page-title-content">
            <h2>Hekayat Guidance</h2>
        </div>
    </div>
</div>

<div class="container">
    <div class="row row-margin-top justify-content-center">

        <div class="col-md-12 col-lg-12 col-sm-12">
            <h2 class="hekaya-title text-center">Oman <span class="hekayat-color">Accommodation Guidance</span></h2>
            <p class="text-center">There are a variety of places to stay in Oman that range from luxury properties to
                family-run guesthouses, camps, heritage Inn hosts, and hotels in restored historic buildings.</p>
            <p class="text-center">As you are looking for new and unique experience while taking our Hekayat Product, we are recommending self-camp and to combine it with other staying experience in between ( every 2-3 days, as per your convenience and budget ). We
                provide you with best staying options in Oman taking in consideration the
                experience </p>
            <br><br><br>
        </div>
    </div>
</div>

<section class="listing-area  pb-50" id="accommodation_Guidance">
    <div class="container">
        <div class="listing-slides owl-carousel owl-theme text-center">
            <div class="single-listing-box">
                <a  class="listing-image">
                    <img src="../../../../assets/img/self-drive/oman-guidence/gidance3/G3HeritageInnHost.jpg" class="card-img-top" style="height: 250px;" alt="image" loading="lazy">
                </a>

                <div class="listing-content">
                    <div class="content">
                        <h3><a >Heritage Inn Haosts</a></h3>
                    </div>
                </div>
            </div>
            <div class="single-listing-box">
                <a  class="listing-image">
                    <img src="../../../../assets/img/self-drive/oman-guidence/gidance3/G3GreenGuesthouses.jpg" class="card-img-top" style="height: 250px;" alt="image" loading="lazy">
                </a>

                <div class="listing-content">
                    <div class="content">
                        <h3><a >Green Guesthouses</a></h3>
                    </div>
                </div>
            </div>
            <div class="single-listing-box">
                <a  class="listing-image">
                    <img src="../../../../assets/img/self-drive/oman-guidence/gidance3/G3BedouinCamp.jpg" class="card-img-top" style="height: 250px;" alt="image" loading="lazy">
                </a>

                <div class="listing-content">
                    <div class="content">
                        <h3><a >Bedouin Camps</a></h3>
                    </div>
                </div>
            </div>
            <div class="single-listing-box">
                <a  class="listing-image">
                    <img src="../../../../assets/img/self-drive/oman-guidence/gidance3/G3HotelsatMountains.jpg" class="card-img-top" style="height: 250px;" alt="image" loading="lazy">
                </a>

                <div class="listing-content">
                    <div class="content">
                        <h3><a >Hotels at Mountains</a></h3>
                    </div>
                </div>
            </div>
            <div class="single-listing-box">
                <a  class="listing-image">
                    <img src="../../../../assets/img/self-drive/oman-guidence/gidance3/HotelsFacingBeachSeaView.jpg" class="card-img-top" style="height: 250px;" alt="image" loading="lazy">
                </a>

                <div class="listing-content">
                    <div class="content">
                        <h3><a >Hotels Facing Beach/Sea View</a></h3>
                    </div>
                </div>
            </div>
        </div>

    </div>
</section>

<section>
    <div class="text-center">
      <button class="btn text-center explore-button btn-md"
          style="margin-top: 50px;margin-bottom: 30px;" (click)="onaccommodationGuidance(1)" >Read More</button>
    </div>
</section>

<div class="hekaya-container">
    <div class="row top-row-margin">
        <div class="col-md-12 col-lg-12 col-sm-12">
            <header class="hero2">
                <div class="center-content1">
                    <h1 style="color: #fff;">Experience hospitality with its finest </h1>

                </div>
                <div class="center-content2">
                </div>
            </header>
        </div>
    </div>
</div>



<div class="container-fluid mt-5">
    <div class="row top-row-margin">
        <div class="col-md-12 col-lg-12 col-sm-12">
            <header class="hero">
                <div class="center-content">
                    <h2 class="hekaya-title2">General Guidance Tips<span class="hekayat-color"></span></h2> <br>
                    <p class="text-inside">Omani people are known for their generous hospitality and warm nature, and the country’s nationals live in perfect harmony with
                        other cultures and nationalities from all around the world. However, as in any country, visitors are kindly requested to adhere to a
                        number of rules to ensure this mutual respect remains:</p>

                </div>

            </header>
        </div>
    </div>
</div>

<div class="container-fluid mb-100">
    <div class="row row-top-margin3">
        <div class="col-md-7 col-lg-7 col-sm-12">
            <div class="row">
                <div class="col-md-1 col-lg-1 col-sm-12"></div>
                <div class="col-md-12 col-lg-12 col-sm-12">
                    <ul class="features-list box-list text-justify">
                        <li>Dressing conservatively is appreciated. Clothing that covers
                            knees and shoulders are required for men and women.</li>

                        <li>Basic rules of courtesy impose on people to respect Oman’s
                            calm and tranquillity. Please Keep noise to minimum.</li>

                        <li>Ask for permission before photographing people or entering
                            private premises as it is considered intrusive and inappropriate.</li>

                        <li>Help keep Oman litter-free. Make sure litter goes in its designated
                            locations.</li>

                        <li>Ensure your guide has valid tour guide license issued by the
                            Ministry of Tourism.</li>

                        <li>Village Visits: Practices that threaten wildlife and biodiversity
                            are strictly prohibited. Picking fruits and vegetables is considered
                            inappropriate. Keep in mind it is a source of income for villagers.</li>

                        <li>Do not contaminate any water source. Villagers depend on
                            traditional water systems for drinking and agriculture.</li>

                        <li>Keep to designated paths when exploring villages and surrounding
                            areas.</li>

                        <li>Make sure you park your vehicle in the designaated areas to
                            ensure villagers are not inconvenienced.</li>

                        <li>Always camp on high ground. Avoid valleys which are susceptible
                            to flash floods.</li>

                    </ul>
                </div>
            </div>
        </div>
        <div class="col-md-5 col-lg-5 col-sm-12">
            <img src="../../../../assets/img/self-drive/oman-guidence/gidance3/G3BottomImage1.jpg" class="img-fluid img-radius" alt="Image Canot load" style="height: 100%;" loading="lazy"/>
        </div>
    </div>
    <div class="row row-top-margin3 mb-5">
        <div class="col-md-5 col-lg-5 col-sm-12">
            <img src="../../../../assets/img/self-drive/oman-guidence/gidance3/G3BottomImage2.jpg" class="img-fluid img-radius" alt="Image Canot load" style="height: 100%;" loading="lazy"/>
        </div>
        <div class="col-md-7 col-lg-7 col-sm-12">
            <div class="row">
                <div class="col-md-1 col-lg-1 col-sm-12"></div>
                <div class="col-md-12 col-lg-12 col-sm-12">
                    <ul class="features-list box-list text-justify">
                        <li>Desert Safari: Vehicles must be equipped with GPS trackers and
                            safety equipment.</li>

                        <li>It is advised to travel only with a qualified tour guide and in groups
                            with more than one vehicle.</li>

                        <li>Using 4WD: ensure you are aware and understand safe driving of
                            4WD vehicles and how safely Low/High Gear Transition is used
                            and when it is required to use it. We recommend to read more
                            and to contact us; if need assistance.</li>

                        <li>Off Road Travel: Use only 4WD vehicles for off-road travel. Avoid
                            travelling off-road alone, and ensure your spare tyres are in place.</li>

                        <li>Ensure you are carrying plentiful supplies of food, water and fuel.
                            Notify others of your plans and expected time of return.</li>

                        <li>Cruising: Use only licensed tourism boats to ensure compliance
                            with safety requirements. Each passenger should be equipped
                            with a top-quality life vest.</li>

                        <li>Trekking: Be aware of the length and difficulty of a trail before
                            setting off.</li>

                        <li>Ensure you have sturdy shoes and adequate supplies of food and
                            water Please do not trek alone.</li>

                        <li>Keep away from valleys and beaches prone to flash flooding as
                            they might endanger your safety.</li>

                        <li>Oman overall is a safe country, feel free to do self-camp. There
                            is no rule in Oman which prevent camping at the public remote
                            areas. However, try to do so at safe location a way from residence
                            areas and villages.</li>

                    </ul>
                </div>
            </div>
        </div>

    </div>
</div>

<div class="container mt-5">
    <div class="row">
        <div class="col-lg-12 d-flex justify-content-center">
            <p>Now you are ready for your Hekayat Experience. Book now and live the real adventure. </p>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12 d-flex justify-content-center mt-4 mb-5">
            <button class="btn text-center explore-button btn-md" routerLink="/selfdrive">Book Now</button>
        </div>
    </div>
</div>



<app-self-drive-footer *ngIf="!(location == '/error' || location == '/coming-soon' || location == '/sign-in'
    || location == '/sign-up'
    ||location == '/forgot-password')">
</app-self-drive-footer>
