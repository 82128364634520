<app-self-drive-nav *ngIf="!(location == '/error' || location == '/coming-soon' 
    || location == '/sign-in' 
    || location == '/sign-up'
    ||location == '/forgot-password')">
</app-self-drive-nav>

<div class="page-title-area page-title-bg3">
    <div class="container">
        <div class="page-title-content">
            <h2>Hekayat Equipment’s</h2>
        </div>
    </div>
</div>


<div class="container" style="margin-top: 70px;">
    <div class="row">
        <div class="col-md-12 text-center">
            <div class="tab faq-accordion-tab">
                <ul class="tabs d-flex flex-wrap justify-content-center">


                    <li><a href="#"> <span>Vehicle Camp Equipment’s</span></a></li>
        
                    <li><a href="#"> <span>Personal Camp Equipment’s</span></a></li>
        
                    <li><a href="#"> <span>Other Equipment’s List</span></a></li>
                    
                </ul>

                <div class="tab-content">

                    <div class="tabs-item">
                        <div class="container text-center mt-5 mb-5">
                            <p>One Set of below equipment’s are by default included in your Hekayat per rented vehicle. This is most of the camping equipment will you need in your
                                trip for the entire group share (2-4 persons).</p>
                        </div>
                        <div class="col-12 mt-3">
                            <div class="card">
                                <div class="card-horizontal">
                                    <div class="col-3" style="padding:0;">
                                        <img class="gear-img" src="../../../../assets/img/self-drive/equipments/VCE-settingShelter.jpg"  alt="Image cannot display">
                                    </div>
                                    <div class="card-body">
                                        <h4 class="card-title">Setting Shelter</h4>
                                        <p class="card-text">Shelter can accommodate 3 – 5 Persons use for outdoor setting to hide
                                            from direct sunlight. Easy to install and repacked</p>   
                                    </div>
                                </div>
                               
                            </div>
                        </div>

                        <div class="col-12 mt-3">
                            <div class="card">
                                <div class="card-horizontal">
                                    <div class="col-3" style="padding:0;">
                                        <img class="gear-img" src="../../../../assets/img/self-drive/equipments/VCE-PowerBank.jpg"  alt="Image cannot display">
                                    </div>
                                    <div class="card-body">
                                        <h4 class="card-title">Power Bank</h4>
                                        <p class="card-text">Anker 20000 MAh power bank along with charging
                                            wires (android and iPhone mobiles). </p>
                                        <p class="card-text">Can charge phones 5 – 7 Times. </p>                                            
                                    </div>
                                </div>
                               
                            </div>
                        </div>

                        <div class="col-12 mt-3">
                            <div class="card">
                                <div class="card-horizontal">
                                    <div class="col-3" style="padding:0;">
                                        <img class="gear-img" src="../../../../assets/img/self-drive/equipments/VCE-LightingSystem.jpg"  alt="Image cannot display">
                                    </div>
                                    <div class="card-body">
                                        <h4 class="card-title">Lighting System</h4>
                                        <p class="card-text">Outdoor Fishing Rod LED Lighting System (500 W). operated by Car Battery for lightening wide area during camping and outdoor BBQ’s. Lighting
                                            intensity is adjustable.</p>
                                        <p class="card-text">Example: Jabel Akhdar road, Wadi Al jizi.</p>    
                                    </div>
                                </div>
                               
                            </div>
                        </div>

                        <div class="col-12 mt-3">
                            <div class="card">
                                <div class="card-horizontal">
                                    <div class="col-3" style="padding:0;">
                                        <img class="gear-img" src="../../../../assets/img/self-drive/equipments/VCE-FoldedDiningTable.jpg"  alt="Image cannot display">
                                    </div>
                                    <div class="card-body">
                                        <h4 class="card-title">Folded Dinning Table and Chairs</h4>
                                        <p class="card-text">Folded outdoor dining setting area for family. Can accommodate </p>
                                        <p class="card-text">2 – 4 Persons.</p>   
                                    </div>
                                </div>
                               
                            </div>
                        </div>

                        <div class="col-12 mt-3">
                            <div class="card">
                                <div class="card-horizontal">
                                    <div class="col-3" style="padding:0;">
                                        <img class="gear-img" src="../../../../assets/img/self-drive/equipments/VCE-Water.jpg"  alt="Image cannot display">
                                    </div>
                                    <div class="card-body">
                                        <h4 class="card-title">Water</h4>
                                        <p class="card-text">4L water Jugs for portable water to use for the cooking and washing purpose.</p> 
                                    </div>
                                </div>
                               
                            </div>
                        </div>

                        <div class="col-12 mt-3">
                            <div class="card">
                                <div class="card-horizontal">
                                    <div class="col-3" style="padding:0;">
                                        <img class="gear-img" src="../../../../assets/img/self-drive/equipments/VCE-Cooler.jpg"  alt="Image cannot display">
                                    </div>
                                    <div class="card-body">
                                        <h4 class="card-title">Cooler</h4>
                                        <p class="card-text">Cooler ( 20 L and above )</p>   
                                    </div>
                                </div>
                               
                            </div>
                        </div>

                        <div class="col-12 mt-3">
                            <div class="card">
                                <div class="card-horizontal">
                                    <div class="col-3" style="padding:0;">
                                        <img class="gear-img" src="../../../../assets/img/self-drive/equipments/VCE-BBQKit.jpg"  alt="Image cannot display">
                                    </div>
                                    <div class="card-body">
                                        <h4 class="card-title">BBQ Kit</h4>
                                        <p class="card-text">Including: Grill Stove, BBQ net, Grill tool sets, Safety glass, Head torch light.</p>
                                        <p class="card-text">1 Full Kit (suitable for 2 – 5 Persons)</p>    
                                    </div>
                                </div>
                               
                            </div>
                        </div>

                        <div class="col-12 mt-3">
                            <div class="card">
                                <div class="card-horizontal">
                                    <div class="col-3" style="padding:0;">
                                        <img class="gear-img" src="../../../../assets/img/self-drive/equipments/VCE-IceSubstitute.jpg"  alt="Image cannot display">
                                    </div>
                                    <div class="card-body">
                                        <h4 class="card-title">Ice Substitutes</h4>
                                        <p class="card-text">3 Set, Each 1.5 ponds and above</p>  
                                    </div>
                                </div>
                               
                            </div>
                        </div>

                        <div class="col-12 mt-3">
                            <div class="card">
                                <div class="card-horizontal">
                                    <div class="col-3" style="padding:0;">
                                        <img class="gear-img" src="../../../../assets/img/self-drive/equipments/MedicalKit.jpg"  alt="Image cannot display">
                                    </div>
                                    <div class="card-body">
                                        <h4 class="card-title">First Aid Box </h4>
                                        <p class="card-text">First Aid box for emergencies Class B with all essentials. </p>
                                        <!-- <p class="card-text">1 Full Kit (suitable for 2 – 5 Persons)</p>     -->
                                    </div>
                                </div>
                               
                            </div>
                        </div>

                        <div class="col-12 mt-3">
                            <div class="card">
                                <div class="card-horizontal">
                                    <div class="col-3" style="padding:0;">
                                        <img class="gear-img" src="../../../../assets/img/self-drive/equipments/VCE-CookingKit.jpg"  alt="Image cannot display">
                                    </div>
                                    <div class="card-body">
                                        <h4 class="card-title">Cooking Kit</h4>
                                        <p class="card-text">Firestarter, stove with
                                            cooking gas (2 L), cook pots, cutting board, eating utensils, cooking utensils, knife, plates/bowls, mugs/
                                            cups, water bottles, trash bags, frying pans. Most Spices Gars, washing kit</p>
                                        <p class="card-text">1 Full Kit (suitable for 2 – 5 persons )</p>    
                                    </div>
                                </div>
                               
                            </div>
                        </div>

                        
                    </div>

                    <div class="tabs-item">
                        <div class="container text-center mt-5 mb-5">
                            <p>Below equipment’s are included in your Hekayat per person. This is most of
                                the personal camping equipment will you need in your trip for personal use
                                only. If you are 2 you will get 2 set’s, if your 3, you will get 3 sets and so on.</p>
                        </div>
                        <div class="col-12 mt-3">
                            <div class="card">
                                <div class="card-horizontal">
                                    <div class="col-3" style="padding:0;">
                                        <img class="gear-img" src="../../../../assets/img/self-drive/equipments/SleepTent(PCE).jpg"  alt="Image cannot display">
                                    </div>
                                    <div class="card-body">
                                        <h4 class="card-title">Sleeping Tent</h4>
                                        <p class="card-text">Freestanding Polyester Personal easy folded sleeping tent with 1 Door and 3 - 4 Fiberglass poles.
                                            Can accommodate 2 Persons.</p>   
                                    </div>
                                </div>
                               
                            </div>
                        </div>

                        <div class="col-12 mt-3">
                            <div class="card">
                                <div class="card-horizontal">
                                    <div class="col-3" style="padding:0;">
                                        <img class="gear-img" src="../../../../assets/img/self-drive/equipments/SettingChair.jpg"  alt="Image cannot display">
                                    </div>
                                    <div class="card-body">
                                        <h4 class="card-title">Setting Chair</h4>
                                        <p class="card-text">Folded personal setting chair with flex fabric seating materials and
                                            cupholder comes with package bag. </p>                                            
                                    </div>
                                </div>
                               
                            </div>
                        </div>

                        <div class="col-12 mt-3">
                            <div class="card">
                                <div class="card-horizontal">
                                    <div class="col-3" style="padding:0;">
                                        <img class="gear-img" src="../../../../assets/img/self-drive/equipments/SleepingBed(PCE).jpg"  alt="Image cannot display">
                                    </div>
                                    <div class="card-body">
                                        <h4 class="card-title">Sleeping Bed</h4>
                                        <p class="card-text">1-Person soft stretch polyester bad quickly self-inflates from small
                                            packed size to plush, full inflation; included open allows for easy topoff to get maximum firmness.</p>   
                                    </div>
                                </div>
                               
                            </div>
                        </div>

                        <div class="col-12 mt-3">
                            <div class="card">
                                <div class="card-horizontal">
                                    <div class="col-3" style="padding:0;">
                                        <img class="gear-img" src="../../../../assets/img/self-drive/equipments/SleepingBag(PCE).jpg"  alt="Image cannot display">
                                    </div>
                                    <div class="card-body">
                                        <h4 class="card-title">Sleeping Bag</h4>
                                        <p class="card-text">Polyester sleep bag prevents cold
                                            and stabilizes the insulation with 2-Way main zips including a storage
                                            bag. Bag can be fully use as a quilt or unzip the bottom for ventilation.</p> 
                                    </div>
                                </div>
                               
                            </div>
                        </div>

                        <div class="col-12 mt-3">
                            <div class="card">
                                <div class="card-horizontal">
                                    <div class="col-3" style="padding:0;">
                                        <img class="gear-img" src="../../../../assets/img/self-drive/equipments/SleepingPillow(PCE).jpg"  alt="Image cannot display">
                                    </div>
                                    <div class="card-body">
                                        <h4 class="card-title">Sleeping Pillow </h4>
                                        <p class="card-text">Polyester pillow, Inflatable air core surrounded by
                                            soft polyester fill provides an adjustable level of support and comfort.</p> 
                                    </div>
                                </div>
                               
                            </div>
                        </div>

                        <div class="col-12 mt-3">
                            <div class="card">
                                <div class="card-horizontal">
                                    <div class="col-3" style="padding:0;">
                                        <img class="gear-img" src="../../../../assets/img/self-drive/equipments/Personal Amenities Kit.jpg"  alt="Image cannot display">
                                    </div>
                                    <div class="card-body">
                                        <h4 class="card-title">Personal Amenities Kit</h4>
                                        <p >2 Soaps, 2 Dental Cleaning Kit, Wipes, Body lotion,
                                            Sun screen , Mosquito repellent.</p>
                                    </div>
                                </div>
                               
                            </div>
                        </div>

                        <div class="col-12 mt-3">
                            <div class="card">
                                <div class="card-horizontal">
                                    <div class="col-3" style="padding:0;">
                                        <img class="gear-img" src="../../../../assets/img/self-drive/equipments/Torch(PCE).jpg"  alt="Image cannot display">
                                    </div>
                                    <div class="card-body">
                                        <h4 class="card-title">Torch</h4>
                                        <p class="card-text">Torch with 2 Set of batteries included.</p>    
                                    </div>
                                </div>
                               
                            </div>
                        </div>

                        <div class="col-12 mt-3">
                            <div class="card">
                                <div class="card-horizontal">
                                    <div class="col-3" style="padding:0;">
                                        <img class="gear-img" src="../../../../assets/img/self-drive/equipments/Lantern(PCE).jpg"  alt="Image cannot display">
                                    </div>
                                    <div class="card-body">
                                        <h4 class="card-title">Lantern </h4>
                                        <p class="card-text">Indoor or outdoor use Lantern. Brightness adjustment with easy-to-use dimmer switch.
                                            Candle Flicker mode is perfect for use in a tent. Powered by chargeable or alkaline batteries (Included). </p>
                                        <!-- <p class="card-text">1 Full Kit (suitable for 2 – 5 Persons)</p>     -->
                                    </div>
                                </div>
                               
                            </div>
                        </div>


                        
                        
                    </div>


                    <div class="tabs-item">
                        <div class="container text-center mt-5 mb-5">
                            <p>Below equipment’s are not included in your Hekayat. This is other available
                                equipment’s on request as per your choose. Please check rental rate for each.
                                </p>
                        </div>
                        <div class="col-12 mt-3">
                            <div class="card">
                                <div class="card-horizontal">
                                    <div class="col-3" style="padding:0;">
                                        <img class="gear-img" src="../../../../assets/img/self-drive/equipments/OEL-ExtraFoldedDining.jpg"  alt="Image cannot display">
                                    </div>
                                    <div class="card-body">
                                        <h4 class="card-title">Extra Folded Dinning Table and Chairs</h4>
                                        <p class="card-text">Folded outdoor dining setting area for family.
                                            Can accommodate 2 – 4 Persons.</p>   
                                    </div>
                                </div>
                               
                            </div>
                        </div>

                        <div class="col-12 mt-3">
                            <div class="card">
                                <div class="card-horizontal">
                                    <div class="col-3" style="padding:0;">
                                        <img class="gear-img" src="../../../../assets/img/self-drive/equipments/VCE-settingShelter.jpg"  alt="Image cannot display">
                                    </div>
                                    <div class="card-body">
                                        <h4 class="card-title">Large Setting Tent</h4>
                                        <p class="card-text">Shelter can accommodate up to 6 - 8 Persons use for
                                            outdoor setting to hide from direct Sunlight. Easy to install and repacked. </p>                                            
                                    </div>
                                </div>
                               
                            </div>
                        </div>

                        <div class="col-12 mt-3">
                            <div class="card">
                                <div class="card-horizontal">
                                    <div class="col-3" style="padding:0;">
                                        <img class="gear-img" src="../../../../assets/img/self-drive/equipments/OEL-EspressoTeaKit.jpg"  alt="Image cannot display">
                                    </div>
                                    <div class="card-body">
                                        <h4 class="card-title">Espresso Coffee & Tea Kit</h4>
                                        <p class="card-text">2 - 4 Persons stainless steel Coffee espresso
                                            kettle and coffee/tea hot brew maker with fresh local roasted coffee 250 G included and 2 - 4 Set of cubes.</p>  
                                        <p>Best coffee/tea makers for camping.</p> 
                                    </div>
                                </div>
                               
                            </div>
                        </div>

                        <div class="col-12 mt-3">
                            <div class="card">
                                <div class="card-horizontal">
                                    <div class="col-3" style="padding:0;">
                                        <img class="gear-img" src="../../../../assets/img/self-drive/equipments/full2.jpg"  alt="Image cannot display">
                                    </div>
                                    <div class="card-body">
                                        <h4 class="card-title">Hiking Gear Kit</h4>
                                        <p class="card-text">1-Person hiking gear set including 2 Hiking
                                            sticks, back bag, compass, emergency shelter,
                                            first aid small box, whistle, Water Bottle, small
                                            torch, wet tissue pack, small repair kit.</p> 
                                    </div>
                                </div>
                               
                            </div>
                        </div>

                        <div class="col-12 mt-3">
                            <div class="card">
                                <div class="card-horizontal">
                                    <div class="col-3" style="padding:0;">
                                        <img class="gear-img" src="../../../../assets/img/self-drive/equipments/OEL-ToiletShowerKit.jpg"  alt="Image cannot display">
                                    </div>
                                    <div class="card-body">
                                        <h4 class="card-title">Toilet/Shower Kit</h4>
                                        <p class="card-text">Shower kit including shower water pressure pump with
                                            small tank can be operated by the vehicle power, 1- Person
                                            shower privacy tent made from polyester easy to be folded
                                            with mesh for good ventilation come with storage bag,
                                            1 Small toilet chair</p> 
                                    </div>
                                </div>
                               
                            </div>
                        </div>

                        <div class="col-12 mt-3">
                            <div class="card">
                                <div class="card-horizontal">
                                    <div class="col-3" style="padding:0;">
                                        <img class="gear-img" src="../../../../assets/img/self-drive/equipments/Snorkeling Kit.jpg" alt="Image cannot display">
                                    </div>
                                    <div class="card-body">
                                        <h4 class="card-title">Snorkeling Kit</h4>
                                        <p class="card-text">1- Person high quality snorkeling kit includes mask, snorkel
                                            and 1 Set of fins.</p> 
                                    </div>
                                </div>
                               
                            </div>
                        </div>

                        <div class="col-12 mt-3">
                            <div class="card">
                                <div class="card-horizontal">
                                    <div class="col-3" style="padding:0;">
                                        <img class="gear-img" src="../../../../assets/img/self-drive/equipments/full.jpg"  alt="Image cannot display">
                                    </div>
                                    <div class="card-body">
                                        <h4 class="card-title">Ground Tent and Camping equipment for one person</h4>
                                        <p class="card-text">1 tent size 2 people, 1 sleeping bags, 1 mattress, 1 folding picnic chairs, 1 picnic stove, 1 cool box capacity 20 litre & a picnic light.</p>                                            
                                    </div>
                                </div>
                               
                            </div>
                        </div>

                        <div class="col-12 mt-3">
                            <div class="card">
                                <div class="card-horizontal">
                                    <div class="col-3" style="padding:0;">
                                        <img class="gear-img" src="../../../../assets/img/self-drive/equipments/full.jpg"  alt="Image cannot display">
                                    </div>
                                    <div class="card-body">
                                        <h4 class="card-title">Ground Tent and Camping equipment for two people</h4>
                                        <p class="card-text">1 tent size 2 people, 2 sleeping bags, 2 mattresses, 2 folding picnic chairs, 1 picnic stove,1 cool box capacity 20 litre & a picnic light.</p>                                            
                                    </div>
                                </div>
                               
                            </div>
                        </div>

                        <div class="col-12 mt-3">
                            <div class="card">
                                <div class="card-horizontal">
                                    <div class="col-3" style="padding:0;">
                                        <img class="gear-img" src="../../../../assets/img/self-drive/equipments/full.jpg"  alt="Image cannot display">
                                    </div>
                                    <div class="card-body">
                                        <h4 class="card-title">Ground Tent and Camping equipment for three people</h4>
                                        <p class="card-text">2 Tents each is size 2 people, 3 sleeping bags, 3 mattresses, 3 folding picnic chairs, 1 picnic stove 1 cool box capacity 20 litre & 2 picnic light.</p>                                            
                                    </div>
                                </div>
                               
                            </div>
                        </div>

                        <div class="col-12 mt-3">
                            <div class="card">
                                <div class="card-horizontal">
                                    <div class="col-3" style="padding:0;">
                                        <img class="gear-img" src="../../../../assets/img/self-drive/equipments/full.jpg"  alt="Image cannot display">
                                    </div>
                                    <div class="card-body">
                                        <h4 class="card-title">Ground Tent and Camping equipment for four people</h4>
                                        <p class="card-text">2 Tents each is size 2 people, 4 sleeping bags, 4 mattresses, 4 folding picnic chairs, 1 picnic stove, 1 cool box capacity 20 litres & 2 picnic lights.</p>                                            
                                    </div>
                                </div>
                               
                            </div>
                        </div>

                        <div class="col-12 mt-3">
                            <div class="card">
                                <div class="card-horizontal">
                                    <div class="col-3" style="padding:0;">
                                        <img class="gear-img" src="../../../../assets/img/self-drive/equipments/full.jpg"  alt="Image cannot display">
                                    </div>
                                    <div class="card-body">
                                        <h4 class="card-title">Ground Tent and Camping equipment for five people</h4>
                                        <p class="card-text">3 Tents each is size 2 people, 5 sleeping bags, 5 mattresses, 5 folding picnic chairs, 2 picnic stove, 2 cool box capacity 20 litres & 3 picnic lights.</p>                                            
                                    </div>
                                </div>
                               
                            </div>
                        </div>

                        <div class="col-12 mt-3">
                            <div class="card">
                                <div class="card-horizontal">
                                    <div class="col-3" style="padding:0;">
                                        <img class="gear-img" src="../../../../assets/img/self-drive/equipments/full.jpg"  alt="Image cannot display">
                                    </div>
                                    <div class="card-body">
                                        <h4 class="card-title">Ground Tent and Camping equipment for six people</h4>
                                        <p class="card-text">3 Tents each is size 2 people, 6 sleeping bags, 6 mattresses, 6 folding picnic chairs, 2 picnic stove, 2 cool box capacity 20 litres & 3 picnic lights.</p>                                            
                                    </div>
                                </div>
                               
                            </div>
                        </div>


                        <div class="col-12 mt-3">
                            <div class="card">
                                <div class="card-horizontal">
                                    <div class="col-3" style="padding:0;">
                                        <img class="gear-img" src="../../../../assets/img/self-drive/equipments/full.jpg"  alt="Image cannot display">
                                    </div>
                                    <div class="card-body">
                                        <h4 class="card-title">Ground Tent and Camping equipment for seven people</h4>
                                        <p class="card-text">4 Tents each is size 2 people, 7 sleeping bags, 7 mattresses, 7 folding picnic chairs, 2 picnic stove, 2 cool box capacity 20 litres & 4 picnic lights.</p>                                            
                                    </div>
                                </div>
                               
                            </div>
                        </div>

                        <div class="col-12 mt-3">
                            <div class="card">
                                <div class="card-horizontal">
                                    <div class="col-3" style="padding:0;">
                                        <img class="gear-img" src="../../../../assets/img/self-drive/equipments/full.jpg"  alt="Image cannot display">
                                    </div>
                                    <div class="card-body">
                                        <h4 class="card-title">Ground Tent and Camping equipment for eight people</h4>
                                        <p class="card-text">4 Tents each is size 2 people, 8 sleeping bags, 8 mattresses, 8 folding picnic chairs, 2 picnic stove, 2 cool box capacity 20 litres & 4 picnic lights.</p>                                            
                                    </div>
                                </div>
                               
                            </div>
                        </div>

                        <div class="col-12 mt-3">
                            <div class="card">
                                <div class="card-horizontal">
                                    <div class="col-3" style="padding:0;">
                                        <img class="gear-img" src="../../../../assets/img/self-drive/equipments/full.jpg"  alt="Image cannot display">
                                    </div>
                                    <div class="card-body">
                                        <h4 class="card-title">Ground Tent and Camping equipment for nine people</h4>
                                        <p class="card-text">5 Tents each is size 2 people, 9 sleeping bags, 9 mattresses, 9 folding picnic chairs, 3 picnic stove, 3 cool box capacity 20 litres & 5 picnic lights.</p>                                            
                                    </div>
                                </div>
                               
                            </div>
                        </div>

                        <div class="col-12 mt-3">
                            <div class="card">
                                <div class="card-horizontal">
                                    <div class="col-3" style="padding:0;">
                                        <img class="gear-img" src="../../../../assets/img/self-drive/equipments/full.jpg"  alt="Image cannot display">
                                    </div>
                                    <div class="card-body">
                                        <h4 class="card-title">Ground Tent and Camping equipment for ten people</h4>
                                        <p class="card-text">5 Tents each is size 2 people, 10 sleeping bags, 10 mattresses, 10 folding picnic chairs, 3 picnic stove, 3 cool box capacity 20 litres & 5 picnic lights.</p>                                            
                                    </div>
                                </div>
                               
                            </div>
                        </div>

                        <div class="col-12 mt-3">
                            <div class="card">
                                <div class="card-horizontal">
                                    <div class="col-3" style="padding:0;">
                                        <img class="gear-img ml-0" style="height: 75%;margin-top: 50px;" src="../../../../assets/img/self-drive/equipments/duster_rooftop.jpg"  alt="Image cannot display">
                                    </div>
                                    <div class="card-body">
                                        <h4 class="card-title">Rooftop Tent (Small Size)</h4>
                                        <p class="card-text">Roof top tent + all equipment + FREE Awning (40 Rials for tent installation + rent rate 10 Rials/Day). Minimum 4 days rent
                                            Side opening roof top tent 1.2 m wide x 1.9 m long. Suitable for Two Adults 
                                        </p>
                                        <p style="text-align: left;">Coming with the roof top tent those items:</p>      
                                        <ul style="padding: 15px 22px;text-align: justify;">
                                            <li style=" list-style: outside;color: #555;">Roof top tent + Sleeping Bags + Silk Sleeping Bag Liners (if requested).</li>
                                            <li style=" list-style: outside;color: #555;">Camping Mat + Rechargeable LED Light. + Cool Box + Water Jerry Can + Chairs + Table </li>
                                            <li style=" list-style: outside;color: #555;">Frying pan + Saucepan + Kettle + Knives & forks + Plates & Bowls & Mugs + Chopping board + Wash Bowl + useful kitchen utensils</li>
                                            <li style=" list-style: outside;color: #555;">Pressurized hot water Shower + Shovel + Gas Stove with Gas Bottle</li>
                                        </ul>                                      
                                    </div>
                                </div>
                               
                            </div>
                        </div>

                        <div class="col-12 mt-3">
                            <div class="card">
                                <div class="card-horizontal">
                                    <div class="col-3" style="padding:0;">
                                        <img class="gear-img ml-0" style="height: 75%;margin-top: 50px;" src="../../../../assets/img/self-drive/equipments/pajero-rooftop.jpg"  alt="Image cannot display">
                                    </div>
                                    <div class="card-body">
                                        <h4 class="card-title">Rooftop Tent (Medium Size)</h4>
                                        <p class="card-text">Roof top tent + all equipment + FREE Awning (50 Rials for tent installation + rent rate 12.5 Rials/Day) Minimum 4 days rent
                                            Side opening roof top tent 1.4 m wide x 2.1 m long. Suitable for Two Adults 1 child up to around 12 years.                                             
                                        </p>
                                        <p style="text-align: left;">Coming with the roof top tent those items:</p>      
                                        <ul style="padding: 15px 22px;text-align: justify;">
                                            <li style=" list-style: outside;color: #555;">Roof top tent + Sleeping Bags.</li>
                                            <li style=" list-style: outside;color: #555;">Camping Mat + Rechargeable LED Light. + Cool Box + Water Jerry Can + Chairs + Table.</li>
                                            <li style=" list-style: outside;color: #555;">Frying pan + Saucepan + Kettle + Knives & forks + Plates & Bowls & Mugs + Chopping board + Wash Bowl + useful kitchen utensils.</li>
                                            <li style=" list-style: outside;color: #555;">Pressurized hot water Shower + Shovel + Gas Stove with Gas Bottle.</li>
                                        </ul>                                      
                                    </div>
                                </div>
                               
                            </div>
                        </div>

                        <div class="col-12 mt-3">
                            <div class="card">
                                <div class="card-horizontal">
                                    <div class="col-3" style="padding:0;">
                                        <img class="gear-img ml-0" style="height: 75%;margin-top: 50px;" src="../../../../assets/img/self-drive/equipments/landCruiser-rooftop.png"  alt="Image cannot display">
                                    </div>
                                    <div class="card-body">
                                        <h4 class="card-title">Rooftop Tent (Large Size)</h4>
                                        <p class="card-text">Roof top tent + all equipment + FREE Awning (60 Rials for tent installation + rent rate 15 Rials/Day) Minimum 4 days rent
                                            Hard shell side opening roof top tent 1.9 m wide x 2.1 m long. Suitable for Two Adults + 2 Children up to around 12 years.                                                                                         
                                        </p>
                                        <p style="text-align: left;">Coming with the roof top tent those items:</p>      
                                        <ul style="padding: 15px 22px;text-align: justify;">
                                            <li style=" list-style: outside;color: #555;">Roof top tent + Sleeping Bags.</li>
                                            <li style=" list-style: outside;color: #555;">Camping Mat + Rechargeable LED Light. + Cool Box + Water Jerry Can + Chairs + Table.</li>
                                            <li style=" list-style: outside;color: #555;">Frying pan + Saucepan + Kettle + Knives & forks + Plates & Bowls & Mugs + Chopping board + Wash Bowl + useful kitchen utensils.</li>
                                            <li style=" list-style: outside;color: #555;">Pressurized hot water Shower + Shovel + Gas Stove with Gas Bottle.</li>
                                        </ul>                                      
                                    </div>
                                </div>
                               
                            </div>
                        </div>
                        
                    </div>

                    


                </div>


            </div>
        </div>
    </div>
</div>


<section>
    <div class="d-flex justify-content-center">
      <button class="btn text-center explore-button btn-md"
          style="margin-top: 50px;margin-bottom: 60px;" (click)="onClick()" routerLink="">Go Back</button>
    </div>
</section>



<app-self-drive-footer *ngIf="!(location == '/error' || location == '/coming-soon' || location == '/sign-in'
    || location == '/sign-up'
    ||location == '/forgot-password')">
</app-self-drive-footer>