import { Component, OnInit } from '@angular/core';
import * as $ from "jquery";
import { Options } from 'ng5-slider';
@Component({
  selector: 'app-fleet',
  templateUrl: './fleet.component.html',
  styleUrls: ['./fleet.component.scss']
})
export class FleetComponent implements OnInit {
seats_icon:string="../../assets/img/seats.png";
door_icon:string="../../assets/img/door.png";
trans_icon:string="../../assets/img/transmission.png";
bag_icon:string="../../assets/img/bag.png";
settings_icon="../../assets/img/settings.png";
ac_icon:string="../../assets/img/ac.png";
not_found:string="../../assets/img/not_found.png";
  value: number = 1040;
  highValue: number = 3000;
  options: Options = {
    floor: 0,
    ceil: 6000
  };

  
  value2: number = 1;
  highValue2: number = 3;
  options2: Options = {
    floor: 1,
    ceil: 5
  };

  constructor() {
    
   }

  ngOnInit(): void {
  }

  offer:string="../../assets/img/offer.png"
   filter_show1()
{
    if($(".sidebox").hasClass("sidebox1"))
     {
       $(".sidebox").removeClass("sidebox1"); 
       $(".sidebox").addClass("sidebox_responsive");
      
     }
     else
     {
      $(".sidebox").removeClass("sidebox_responsive"); 
      $(".sidebox").addClass("sidebox1");
     
     }
}


filter_close()
{
  
}

}
 

