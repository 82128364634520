<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>About HEKAYA</h3>

                    <div class="about-the-store text-justify">
                        <p>Hekaya founded, aiming to enhance the tourism services in Oman and abroad.</p>
                    </div>
                    <div class="logo text-center">
                        <a>
                            <img src="../../../../../assets/img/self-drive/main_2.png" alt="logo" class="hekaya-logo" loading="lazy">
                        </a>
                    </div>

                    <div class="about-the-store text-justify">
                        <p class="credit">We Accept all Major Debit & Credit Cards</p>
                    </div>

                    <div class="logo">
                        <img src="../../../../../assets/img/card.png" alt=""  class="credit-img" loading="lazy">
                    </div>

                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget pl-4">
                    <h3>Quick Links</h3>

                    <ul class="quick-links">
                        <li><a routerLink="/self-drive/selfdrive-AboutUs">About Us</a></li>
                        <li><a routerLink="/self-drive/selfdrive-Services">Service</a></li>
                        <li><a routerLink="/self-drive/selfdrive-ContactUS">Contact Us</a></li>
                        <li><a routerLink="/self-drive/selfdrive-Login">Login</a></li>



                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Customer Support</h3>

                    <ul class="customer-support">
                        <li><a routerLink="/self-drive/selfdrive-MyProfile">My Account</a></li>
                        <li><a routerLink="/self-drive/selfdriveSignUp">Register</a></li>
                        <li><a routerLink="/self-drive/selfdrive-FAQ">FAQ's</a></li>
                      <!--   <li><a routerLink="/contact">Help & Support</a></li> -->
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <div class="about-the-store">
                        <h3>Contact Us</h3>

                        <ul class="footer-contact-info">
                            <li><i class='bx bx-map'></i> <a target="_blank">Post Box 211, PC 111
                                Sultanate of Oman, Muscat, Al Seeb, Al Hill South, Al Barakat Street</a></li>
                            <!-- <li><i class='bx bx-phone-call'></i> <a href="tel:+968 95682966">+968 95682966</a></li> -->
                            <li><i class='bx bx-envelope'></i> <a href="info@hekaya.co">info@hekaya.co</a></li>
                        </ul>
                    </div>
                    <ul class="social-link">
                        <li><a href="https://www.facebook.com/Hekayaco-110105420624431/?modal=admin_todo_tour" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="https://twitter.com/Hekaya_Holidays" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="https://www.instagram.com/hekaya.co/" class="d-block" rel="https://www.instagram.com/" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="footer-bottom-area">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <p>Copyright <i class='bx bx-copyright'></i>{{currentYear}} <a href="#" target="_blank">HEKAYA</a> Designed By <a href="https://www.blueleaftechit.com/" target="_blank">Blueleaf</a> | All rights reserved.</p>
                </div>

                <div class="col-lg-6 col-md-6">
                    <ul>
                        <li><a routerLink="/self-drive/Web-Use-Terms">Web Use Terms</a></li>
                        <li><a routerLink="/self-drive/selfdrive-PrivacyPolicy">Privacy Policy</a></li>
                        <li><a routerLink="/self-drive/selfdrive-TermsAndConditions">Services Terms</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</footer>

<div class="go-top"><i class='bx bx-chevron-up'></i></div>

<div class="modal right fade shoppingCartModal" id="shoppingCartModal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true"><i class='bx bx-x'></i></span>
            </button>

            <div class="modal-body">
                <h3>My Cart (3)</h3>

                <div class="products-cart-content">
                    <div class="products-cart">
                        <div class="products-image">
                            <a routerLink="/"><img src="assets/img/products/img1.jpg" alt="image" loading="lazy"></a>
                        </div>

                        <div class="products-content">
                            <h3><a routerLink="/">Ham Salad</a></h3>
                            <span>Quantity: 01</span>
                            <div class="products-price">
                                $20
                            </div>
                            <a routerLink="/" class="remove-btn"><i class='bx bx-trash'></i></a>
                        </div>
                    </div>

                    <div class="products-cart">
                        <div class="products-image">
                            <a routerLink="#"><img src="assets/img/products/img2.jpg" alt="image" loading="lazy"></a>
                        </div>

                        <div class="products-content">
                            <h3><a routerLink="#">Fresh Cappuccino</a></h3>
                            <span>Quantity: 02</span>
                            <div class="products-price">
                                $25
                            </div>
                            <a routerLink="#" class="remove-btn"><i class='bx bx-trash'></i></a>
                        </div>
                    </div>

                    <div class="products-cart">
                        <div class="products-image">
                            <a routerLink="#"><img src="assets/img/products/img3.jpg" alt="image" loading="lazy"></a>
                        </div>

                        <div class="products-content">
                            <h3><a routerLink="#">Honey Cake</a></h3>
                            <span>Quantity: 01</span>
                            <div class="products-price">
                                $11
                            </div>
                            <a routerLink="#" class="remove-btn"><i class='bx bx-trash'></i></a>
                        </div>
                    </div>
                </div>

                <div class="products-cart-subtotal">
                    <span>Subtotal</span>

                    <span class="subtotal">$524.00</span>
                </div>

                <div class="products-cart-btn">
                    <a routerLink="/cart" class="default-btn">View Bag & Checkout</a>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="search-overlay">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-layer"></div>

            <div class="search-overlay-close">
                <span class="search-overlay-close-line"></span>
                <span class="search-overlay-close-line"></span>
            </div>

            <div class="search-overlay-form">
                <form>
                    <input type="text" class="input-search" placeholder="Search here...">
                    <button type="submit"><i class='bx bx-search-alt'></i></button>
                </form>
            </div>
        </div>
    </div>
</div>

