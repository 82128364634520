<div class="search_box">
    <div class="tab-content">
        <div role="tabpanel" aria-labelledby="pills-home-tab" class="tab-pane fade show active">
            <div class="row">
                <div class="col-md-6" style="text-align: left;">
                </div>
                <div class="col-md-6">

                </div>
            </div>
            <form (submit)="onSubmitSearch()">

                <div class="row align-items-center pb-3">
                    <div class="col-md-6 text-left " >
                        <div class="form-check d-none pb-3 pb-md-0">
                            <input type="checkbox" class="form-check-input returncar" name="search-Location" [(ngModel)]="pickUpValidForm.isEnableSearchPickUpLocation">
                            <label class="form-check-label text-brown" name="returncar" for="rentACar_ReturnLocation">Search For Pick Up Locations</label>
                        </div>
                    </div>

                    <div class="col-md-6 text-left " >
                        <!-- <div class="form-check pb-3 pb-md-0">
                            <input type="checkbox" class="form-check-input returncar" name="search-Location" [(ngModel)]="pickUpValidForm.isEnableSearchDropOffLocation">
                            <label class="form-check-label text-brown" name="returncar" for="rentACar_ReturnLocation">Search For Drop Off Locations</label>
                        </div> -->

                        <label class="form-check text-brown  pb-3 pb-md-0">
                            Search For Drop Off Locations
                           <input type="checkbox" checked="checked" class="returncar"  name="search-Location" [(ngModel)]="pickUpValidForm.isEnableSearchDropOffLocation">
                           <span class="checkmark"></span>
                      </label>
                    </div>
                </div>


                <div class="row align-items-center">
                    <!-- <div class="col-md-6" [hidden]="pickUpValidForm.isEnableSearchPickUpLocation">
                        <div class="form-group select">
                            <select class="form-control" name="pick-up-from-hub" [(ngModel)]="pickUpValidForm.pickUpFromHub"  placeholder="Select pick location from our main hub">
                                <option value="">Select Pickup Hub Location</option>
                                <option *ngFor="let location of parkOnlylocation" value="{{location.id}}">
                                    {{location.locationName}} ({{location.locationCode}})
                                </option>
                            </select>
                        </div>
                    </div> -->

                    <div class="col-md-6" >
                        <div class="form-group transparent-field autotxt">
                            <ngx-autocomplete [searchKeyword]="'name'" [inputId]="'searchEmployee'" [placeholderValue]="'Search Pick Up City'" [entries]="pickUpCities" autocomplete="off" class="form-control m-0 pt-0 bg-transparent border-0 transparent-field" (keyup)="onEnterLocation($event,1)"
                            [(ngModel)]="pickUpValidForm.pickUpAddress" name="pick-up-Address" (selectedValue)="onSelectItem($event,1)">
                            </ngx-autocomplete>
                        </div>
                    </div>

                    <div class="col-md-6" [hidden]="pickUpValidForm.isEnableSearchDropOffLocation">
                        <div class="form-group select" >
                            <select class="form-control" name="drop-off-to-hub" [(ngModel)]="pickUpValidForm.dropOffToHub"  placeholder="Select drop off location to our main hub" (change)="filterdReturnHubLocation()">
                                <option value="">Select Drop Off Hub Location</option>
                                <option *ngFor="let location of parkOnlylocation" value="{{location.id}}">
                                    {{location.locationName}} ({{location.locationCode}})
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="col-md-6" [hidden]="!pickUpValidForm.isEnableSearchDropOffLocation">
                        <div class="form-group transparent-field autotxt">
                            <ngx-autocomplete [searchKeyword]="'name'" [inputId]="'searchEmployee'" [placeholderValue]="'Search Drop Off City'" [entries]="dropOffCities" autocomplete="off" class="form-control m-0 pt-0 bg-transparent border-0 transparent-field" (keyup)="onEnterLocation($event,2)"
                            [(ngModel)]="pickUpValidForm.dropOffAddress" name="drop-off-address" (selectedValue)="onSelectItem($event,2)">
                            </ngx-autocomplete>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="form-group select">
                            <select name="Vehicle Type" placeholder="Select Vehicle Type" [(ngModel)]="pickUpValidForm.vehicleTypeId" class="form-control">
                                <option value="">Vehicle Type</option>
                                <option *ngFor="let vehicle of vehiclesTypes" value="{{vehicle.id}}">
                                    {{vehicle.typeName}} 
                                </option>

                            </select>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <input type="text" readonly="true" class="form-control" name="pick-up-date" 
                            [minDate]="minDatePickUp" [maxDate]="maxDatePickUp" placeholder="Pickup date" autocomplete="off"
                            #dp="bsDatepicker" bsDatepicker [(ngModel)]="pickUpValidForm.pickUpdate" (ngModelChange)=dateSelect($event)>
                        </div>
                    </div>
                    <div class="col-md-4 text-left text-md-right">
                        <div class="form-group select">
                            <select class="form-control" name="pick-up-time" [(ngModel)]="pickUpValidForm.pickUptime" name="pick-up-time" (change)="getReturnTime()">
                                <option value="">Pick Up Time</option>
                                <option *ngFor="let time of pickUpTime" value="{{time}}">
                                    {{time}}
                                </option>
                            </select>
                        </div>
                    </div>

                </div>
                <div class="row align-items-center pb-3" [hidden]="pickUpValidForm.isReturnToAnotherLocaton">
                    <div class="col-auto text-left " >
                        <!-- <div class="form-check pb-3 pb-md-0">
                            <input type="checkbox" class="form-check-input returncar" [(ngModel)]="pickUpValidForm.isReturnToFirstPickUp" name="is-Return-To-First-Pick-Up">
                            <label class="form-check-label text-brown" name="returncar" for="rentACar_ReturnLocation">I would like my vehicle to be returned to the same point of first pick up</label>
                        </div> -->

                        <label class="form-check text-brown  pb-3 pb-md-0">
                            I would like my vehicle to be returned to the same point of first pick up
                           <input type="checkbox" checked="checked" class="returncar"  [(ngModel)]="pickUpValidForm.isReturnToFirstPickUp" name="is-Return-To-First-Pick-Up">
                           <span class="checkmark"></span>
                      </label>
                    </div>
                </div>

                <div class="row align-items-center pb-3" [hidden]="!pickUpValidForm.isReturnToFirstPickUp">
                    <div class="col-md-6">
                        <div class="form-group">
                            <input type="text" class="form-control" name="return-up-date" [minDate]="minDateReturn" [maxDate]="maxDateReturn" autocomplete="off"
                             [(ngModel)]="pickUpValidForm.returnDate" placeholder="Return date" #dp="bsDatepicker" bsDatepicker (ngModelChange)=getReturnTime()>
                        </div>
                    </div>
                    <div class="col-md-6 text-left text-md-right">
                        <div class="form-group select">
                            <select class="form-control" name="return-up-time" [(ngModel)]="pickUpValidForm.returnTime">
                                <option value="">Return Time</option>
                                <option *ngFor="let time of returnTime" value="{{time}}">
                                    {{time}}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="row align-items-center pb-3" [hidden]="pickUpValidForm.isReturnToFirstPickUp">
                    <div class="col-auto text-left " >
                        <!-- <div class="form-check pb-3 pb-md-0">
                            <input type="checkbox" class="form-check-input returncar" [(ngModel)]="pickUpValidForm.isReturnToAnotherLocaton" name="is-Return-To-First-Pick-Up">
                            <label class="form-check-label text-brown" name="returncar" for="rentACar_ReturnLocation">I would like my vehicle to be returned to another location</label>
                        </div> -->

                        <label class="form-check text-brown  pb-3 pb-md-0">
                            I would like my vehicle to be returned to another location
                           <input type="checkbox" checked="checked" class="returncar"  [(ngModel)]="pickUpValidForm.isReturnToAnotherLocaton" name="is-Return-To-First-Pick-Up">
                           <span class="checkmark"></span>
                      </label>
                    </div>
                </div>

                <div class="row align-items-center pb-3" [hidden]="!pickUpValidForm.isReturnToAnotherLocaton">
                    <div class="col-md-12 text-left " >
                        <div class="form-check d-none pb-3 pb-md-0">
                            <input type="checkbox" class="form-check-input returncar" name="search-Location" [(ngModel)]="pickUpValidForm.isEnableSearchReturnLocation">
                            <label class="form-check-label text-brown" name="returncar" for="rentACar_ReturnLocation">Search For Return Locations</label>
                        </div>
                    </div>
                    <!-- <div class="col-md-6" [hidden]="pickUpValidForm.isEnableSearchReturnLocation">
                        <div class="form-group select">
                            <select class="form-control" name="return-hub-location" [(ngModel)]="pickUpValidForm.returnHubLocation"  placeholder="Select return drop Location from our main hub" >
                                <option value="">Select Return Hub Location</option>
                                <option *ngFor="let location of returnHubLocation" value="{{location.id}}">
                                    {{location.locationName}} ({{location.locationCode}})
                                </option>
                            </select>
                        </div>
                    </div> -->

                    <div class="col-md-6">
                        <div class="form-group transparent-field autotxt">
                            <ngx-autocomplete [searchKeyword]="'name'" [inputId]="'searchEmployee'" [placeholderValue]="'Search Return Drop City'" [entries]="dropOffCities" autocomplete="off" class="form-control m-0 pt-0 bg-transparent border-0 transparent-field" (keyup)="onEnterLocation($event,2)"
                                [(ngModel)]="pickUpValidForm.returnAddress" name="return-address" (selectedValue)="onSelectItem($event,3)">
                            </ngx-autocomplete>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <input type="text" class="form-control" name="return-up-date" [minDate]="minDateReturn" [maxDate]="maxDateReturn" autocomplete="off"
                            [(ngModel)]="pickUpValidForm.returnDate" placeholder="Return date" #dp="bsDatepicker" bsDatepicker (ngModelChange)=getReturnTime()>
                        </div>
                    </div>
                    <div class="col-md-6 text-left text-md-right">
                        <div class="form-group select">
                            <select class="form-control" name="return-up-time" [(ngModel)]="pickUpValidForm.returnTime">
                                <option value="">Return Time</option>
                                <option *ngFor="let time of returnTime" value="{{time}}">
                                    {{time}}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="row align-items-center">
                    <div class="col-md-8">
                        <select class="form-control" name="transfer-purpose" [(ngModel)]="pickUpValidForm.transferPurpose"  placeholder="Select Transfer Purpose" >
                            <option value="">Select Transfer Purpose</option>
                            <option *ngFor="let purpose of transferPurpose" value="{{purpose}}">
                                {{purpose}}
                            </option>
                        </select>
                    </div>
                </div>
                
                <div class="text-right pr-0 mt-4">
                    <div class="form-check">
                        <button type="submit" class="btn btn-warning">Search</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
