<app-self-drive-nav *ngIf="!(location == '/error' || location == '/coming-soon'
    || location == '/sign-in'
    || location == '/sign-up'
    ||location == '/forgot-password')">
</app-self-drive-nav>

<div class="page-title-area page-title-bg3">
    <div class="container">
        <div class="page-title-content">
            <h2>Hekayat Guidence</h2>
        </div>
    </div>
</div>

<div class="container mt-5">
    <div class="row row-margin-top justify-content-center">

        <div class="col-md-12 col-lg-12 col-sm-12">
            <h2 class="hekaya-title text-center">Oman <span class="hekayat-color">Accommodation Guidance</span></h2>
            <p class="text-center">There are a variety of places to stay in Oman that range from luxury properties to
                family-run guesthouses, camps, heritage Inn hosts, and hotels in restored historic buildings</p>
            <br><br><br>
        </div>
    </div>
</div>

<div class="container">
    <div class="row">
        <div class="col-12 mt-3">
            <div class="card">
                <div class="card-horizontal">
                    <div class="col-lg-4 col-md-4 col-sm-4" style="padding:0;">
                        <img class="" src="../../../../assets/img/self-drive/oman-guidence/gidance3/G3HeritageInnHost.jpg" style="height: auto;border-radius: 0px 70px 1px 70px;" alt="Image cannot display">
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 right-border text-justify">
                        <h4 class="card-title">Heritage Houses</h4>
                        <p class="card-text">Historical and Traditional Experience. This is old
                            clay houses mostly located at old towns/villages and been restored and turned to heritage hosts</p>
                        <p class="card-text-example mt-2"><span class="hekayat-example-color">Example:</span> Heritage Inn Host in Nizwa and Al Hamra Wilayat’s</p>
                    </div>
                    <div class="col-lg-2 col-md-2 col-sm-2">
                        <div class="price_list">
                            <div style="text-align: center;margin-top: 99px;">
                                <h5><small>OMR</small>
                                    30 – 35  </h5>

                                   <span class="normal_price_list"></span>

                                    <small>Start from / Room</small>
                                <br>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div class="col-12 mt-3">
            <div class="card">
                <div class="card-horizontal">
                    <div class="col-lg-4 col-md-4 col-sm-4" style="padding:0;">
                        <img class="" src="../../../../assets/img/self-drive/oman-guidence/gidance3/G3GreenGuesthouses.jpg" style="height: 100%;border-radius: 0px 70px 1px 70px;" alt="Image cannot display">
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 right-border text-justify">
                        <h4 class="card-title">Green Guesthouses</h4>
                        <p class="card-text">This is date palm gardens or other cultivated ground which been repaired and turned to
                            green guest house where the rooms located in the middle with garden. Different gardens offer different type of facilities</p>
                        <p class="card-text mt-2">Green Guesthouses are now common in Oman and become extremely popular by locals and outside visitors.</p>
                        <p class="card-text-example mt-2"><span class="hekayat-example-color">Example:</span> Hekaya Park Inn Nizwa. Al Faisal in Muscat and etc</p>
                        <p class="card-text-example mt-2"><span class="hekayat-example-color">Note:</span> Range upon location and facilities provided Mostly hired per garden not per room</p>
                    </div>
                    <div class="col-lg-2 col-md-2 col-sm-2">
                        <div class="price_list">
                            <div style="text-align: center;margin-top: 70px;">
                                <h5><small>OMR</small>
                                    60 – 200  </h5>

                                   <span class="normal_price_list"></span>

                                    <small>Start from /  per garden per night</small>
                                <br>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div class="col-12 mt-3">
            <div class="card">
                <div class="card-horizontal">
                    <div class="col-lg-4 col-md-4 col-sm-4" style="padding:0;">
                        <img class="" src="../../../../assets/img/self-drive/oman-guidence/gidance3/G3BedouinCamp.jpg" style="height: auto;border-radius: 0px 70px 1px 70px;" alt="Image cannot display">
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 right-border text-justify">
                        <h4 class="card-title">Bedouin Camps</h4>
                        <p class="card-text">Traditional Experience. This is representing Bedouin tent or structured camps at Sandy dunes spots</p>
                        <p class="card-text-example mt-2"><span class="hekayat-example-color">Example:</span> Camps at Bediyia (Al Sharquia Sands), Al Wasil and Wadi Al Abyad</p>
                        <p class="card-text-example mt-2"><span class="hekayat-example-color">Note:</span> Range upon location and facilities provided</p>
                    </div>
                    <div class="col-lg-2 col-md-2 col-sm-2">
                        <div class="price_list">
                            <div style="text-align: center;margin-top: 75px;">
                                <h5><small>OMR</small>
                                    20 – 90  </h5>

                                   <span class="normal_price_list"></span>


                                <br>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div class="col-12 mt-3">
            <div class="card">
                <div class="card-horizontal">
                    <div class="col-lg-4 col-md-4 col-sm-4" style="padding:0;">
                        <img class="" src="../../../../assets/img/self-drive/oman-guidence/gidance3/G3HotelsatMountains.jpg" style="height: auto;border-radius: 0px 70px 1px 70px;" alt="Image cannot display">
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 right-border text-justify">
                        <h4 class="card-title">Hotels at Mountains</h4>
                        <p class="card-text">Ranged from Luxury properties to standard
                            one. This is hotels/resorts located at the peak top of mountains</p>
                        <p class="card-text-example mt-2"><span class="hekayat-example-color">Example:</span> Properties located in Jabal Akhdar, Jabal Shams,
                            Shorfet Alalamin, Wakan villages and etc</p>
                        <p class="card-text-example mt-2"><span class="hekayat-example-color">Note:</span> Range upon no. of stars, location and facilities provided</p>
                    </div>
                    <div class="col-lg-2 col-md-2 col-sm-2">
                        <div class="price_list">
                            <div style="text-align: center;margin-top: 70px;">
                                <h5><small>OMR</small>
                                    50 – 200  </h5>

                                   <span class="normal_price_list"></span>

                                    <small>Start from / Room</small>
                                <br>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div class="col-12 mt-3">
            <div class="card">
                <div class="card-horizontal">
                    <div class="col-lg-4 col-md-4 col-sm-4" style="padding:0;">
                        <img class="" src="../../../../assets/img/self-drive/oman-guidence/gidance3/HotelsFacingBeachSeaView.jpg" style="height: auto;border-radius: 0px 70px 1px 70px;" alt="Image cannot display">
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 right-border text-justify">
                        <h4 class="card-title">Hotels Facing Beach/Sea View</h4>
                        <p class="card-text">Ranged from Luxury properties to standard one. This is hotels/resorts located at beach or at
                            sea front view</p>
                        <p class="card-text-example mt-2"><span class="hekayat-example-color">Example:</span> Properties located in Muscat Al Qurum or
                            Bandar Al Jasa. In Al Masana’a, Barka, Sohar, Sur, Doqum, Salalah</p>
                        <p class="card-text-example mt-2"><span class="hekayat-example-color">Note:</span> Range upon no. of stars, location and facilities provided</p>
                    </div>
                    <div class="col-lg-2 col-md-2 col-sm-2">
                        <div class="price_list">
                            <div style="text-align: center;margin-top: 80px;">
                                <h5><small>OMR</small>
                                    50 – 200  </h5>

                                   <span class="normal_price_list"></span>

                                    <small>Start from / Room</small>
                                <br>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

<div class="container mt-5">
    
    <div class="row justify-content-center">
        <button class="btn text-center explore-button btn-md" (click)="onClick()">Go Back</button>
    </div>
</div>

<app-self-drive-footer *ngIf="!(location == '/error' || location == '/coming-soon' || location == '/sign-in'
    || location == '/sign-up'
    ||location == '/forgot-password')">
</app-self-drive-footer>
