import { AlertService } from 'ngx-alerts';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, EmailValidator } from '@angular/forms';
import { NavigationCancel, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { UtilityService } from 'src/app/services/utility-service/utility.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  forgotFrom: FormGroup;
  //get f() { return this.forgotFrom.controls };
  isSubmitted: boolean;
  isLoginAction: boolean = false;
  location: any;
  routerSubscription: any;

  constructor(private formBuilder: FormBuilder
    , private _router: Router
    , private _utilityService: UtilityService
    , private _authService: AuthenticationService
    , private alertService: AlertService) { }

  ngOnInit(): void {
    this._utilityService.getAppSettings().subscribe(appSettings => {
      if (appSettings) {
        if (!appSettings.isAdmin) {
          this._router.navigate(['/']);
        }
      }
    });
    this.initForm();
    this.recallJsFuntions();
  }

  private initForm() {
    this.forgotFrom = this.formBuilder.group({
      emailAddress: ['', [Validators.required,Validators.email]]
    });
  }


  public onSend() {
    this.isSubmitted = true;
    
    //if (this.forgotFrom.invalid) { return; }
    this.isLoginAction = true;
    const emailAddress = this.forgotFrom.controls.emailAddress.value;

    
    this.forgotFrom.controls.emailAddress.setValue("");

    this._authService.forgotPassword(emailAddress, environment.agencyCode).subscribe(isSend => {
      this.isLoginAction = false;
      if (isSend) {
        this.alertService.success('Verification link successfully sent to your email address.Please click link to reset your password');
      } else {
        this.alertService.warning('Email address does not registered.');
      }
    }, error => {
      this.isLoginAction = false;
      this.alertService.warning('Can not sent password. Please try later.');
    });

  }

  recallJsFuntions() {
    this.routerSubscription = this._router.events
        .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
        .subscribe(event => {
            $.getScript('../assets/js/main.js');
            this.location = this._router.url;
            if (!(event instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0);
        });
}

}
