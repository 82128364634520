import { LocationStrategy, PathLocationStrategy, ViewportScroller } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, NavigationCancel } from '@angular/router';
import Splide from '@splidejs/splide';
import { filter } from 'rxjs/operators';
import { CommonService } from 'src/app/services/common/common.service';

@Component({
  selector: 'app-hekayat-categories',
  templateUrl: './hekayat-categories.component.html',
  styleUrls: ['./hekayat-categories.component.scss'],
  providers: [
    Location, {
        provide: LocationStrategy,
        useClass: PathLocationStrategy
    }
]
})
export class HekayatCategoriesComponent implements OnInit {

  location: any;
  routerSubscription: any;
  selectedSection: any;

  currentYear: number = new Date(). getFullYear();

  constructor(private _router: Router,
    private commonService: CommonService
    , private scroller: ViewportScroller) { }


  ngOnInit(): void {

    this.selectedSection = this.commonService.getSelectedCar();
    this.sectionCheck();


    this.recallJsFuntions();
  }

  sectionCheck(){
    if(this.selectedSection === 1){
      this.scroller.scrollToAnchor("drivingCategory");
    } else if(this.selectedSection === 2){
      this.scroller.scrollToAnchor("habitatCategory");
    } else if(this.selectedSection === 3){
      this.scroller.scrollToAnchor("gearSection");
    }
  }

  ondrivingCategory(id){
    let sectionID = id;
    this.commonService.setSelectedCar(sectionID);
    this._router.navigate(['/driving-category']);
  }

  onHabitatClick(id){
    let sectionID = id;
    this.commonService.setSelectedCar(sectionID);
    this._router.navigate(['/habitat-category']);
  }

  onClick(id){
    let sectionID = id;
    this.commonService.setSelectedCar(sectionID);
    this._router.navigate(['/Hekayat-Camping-Gear']);
  }

  recallJsFuntions() {
    this.routerSubscription = this._router.events
        .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
        .subscribe(event => {
            $.getScript('../assets/js/main.js');
            this.location = this._router.url;
            if (!(event instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0);
        });
}




}
