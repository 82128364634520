<app-self-drive-nav *ngIf="!(location == '/error' || location == '/coming-soon' 
    || location == '/sign-in' 
    || location == '/sign-up'
    ||location == '/forgot-password')">
</app-self-drive-nav>


<div class="page-title-area page-title-bg3">
    <div class="hekaya-container">
        <div class="page-title-content">
            <h2>Oman’s Weather </h2>
           
        </div>
    </div>
</div>
  


<div class="hekaya-container">
    <div class="row top-row-margin">
      <div class="col-md-12 col-lg-12 col-sm-12">
        <h2 class="hekaya-title">
          <span class="title-color">Oman's </span> <span class="titleweather">Weather</span>
        </h2>
        <p class="first-line">
          Oman is a country of a moderate climate in winter and hot in the summer. Generally, Oman is a hot country due
          to the fact that it crosses the tropic of cancer.
        </p>
        <p class="first-line">
          The temperature varies from one region to another in Oman due to the diversity of its geological terrains and
          the fact that it overlooks 3 seas and the Indian Ocean. From September to March, the temperature drops
          significantly, making the mountainous and desert areas chilly, while it increases from April towards August,
          leaving coastal areas hot and damp and the internal areas hot and dry. However, Oman's summer isn't so hot
          for some areas, such as Dhofar Governorate that witness a wonderful weather due to the monsoon season which
          is locally called "Al Khareef".
        </p>

        <p class="first-line">
          In this exceptional weather, the tourism season reaches the peak, and festivals and many activities are held
          in Dhofar in celebration of the refreshing monsoon. Some of the other regions in Oman enjoy a lovely weather
          in the summer as well, especially the mountains and valleys near Muscat and Interior of Oman, that are
          constant destinations for visitors and tourists.Below table and map represent division of areas in the
          Sultanate upon change in the average temperature throughout the year. Respectively below graphs represent
          Average High/Low Monthly Temperature for different areas which are divided as per location and habitat wises.
        </p>
      </div>
    </div>
  </div>

  

  <div class="hekaya-container">
    <div class="row ">
      <div class="col-md-4 col-lg-4 col-sm-12 text-center row-margin">
        <img src="../../../../assets/img/self-drive/weather/1.png" class="img-fluid" loading="lazy" />
        <h2 class="icon-head">NCA</h2>
        <h2 class="icon-sub-head">North Costal Area</h2>
      </div>
      <div class="col-md-4 col-lg-4 col-sm-12 text-center row-margin ">
        <img src="../../../../assets/img/self-drive/weather/2.png" class="img-fluid"  loading="lazy"/>
        <h2 class="icon-head ino">INO</h2>
        <h2 class="icon-sub-head ino">Interior Of Oman</h2>
      </div>
      <div class="col-md-4 col-lg-4 col-sm-12 text-center row-margin">
        <img src="../../../../assets/img/self-drive/weather/3.png" class="img-fluid" loading="lazy" />
        <h2 class="icon-head nmp">NMP</h2>
        <h2 class="icon-sub-head nmp">North Mountain Peaks</h2>
      </div>
    </div>

    <div class="row row-margin">
      <div class="col-md-4 col-lg-4 col-sm-12 text-center row-margin">
        <img src="../../../../assets/img/self-drive/weather/4.png" class="img-fluid" loading="lazy"/>
        <h2 class="icon-head dnd">DND</h2>
        <h2 class="icon-sub-head dnd">Desert Sandy Dunes Area</h2>
      </div>
      <div class="col-md-4 col-lg-4 col-sm-12 text-center row-margin">
        <img src="../../../../assets/img/self-drive/weather/5.png" class="img-fluid" loading="lazy"/>
        <h2 class="icon-head smz">SMZ</h2>
        <h2 class="icon-sub-head smz">South Monsoon Zone</h2>
      </div>
      <div class="col-md-4 col-lg-4 col-sm-12 text-center row-margin">
        <img src="../../../../assets/img/self-drive/weather/6.png" class="img-fluid" loading="lazy" />
        <h2 class="icon-head omez">OMEZ</h2>
        <h2 class="icon-sub-head omez">Other Mosoon Effected Zone</h2>
      </div>
    </div>
  </div>

  

  <div class="hekaya-container">
    <div class="row row-pad">
      <div class="col-md-9 col-lg-12 col-sm-12 text-center row-margin ">
        <img src="../../../../assets/img/self-drive/weather/WeatherPageMapFullImage.jpg"  class="map-height w-100 img-fluid" loading="lazy"/>
      </div>
    </div>
  </div>

  <div class="hekaya-container">
    <div class="row row-margin">
      <div class="col-md-12 col-lg-12 col-sm-12">
        <header class="hero2">
          <div class="center-content">
            <h1>Oman's Weather</h1>
          
        
          </div>
          <div class="center-content2">
            
          </div>
        </header>
      </div>
    </div>
  </div>

<div class="hekaya-container">
  <div class="row">
    <div class="col-md-4 col-lg-4 col-sm-12 grid-3p row-margin">
        <img src="../../../../assets/img/self-drive/weather/NorthCostArea.jpg" class="img-fluid grid-3 w-100" loading="lazy"/>
        <p><b style="color: red;">North Cost Area :</b> This area is the coastal zone extended from Wilayat Sur toward north to Shinas Wilayat and costal of Musandam Governorate.</p>
    </div>
    <div class="col-md-4 col-lg-4 col-sm-12 grid-3p row-margin">
      <img src="../../../../assets/img/self-drive/weather/InteriorofOman.jpg" class="img-fluid grid-3 w-100" loading="lazy"/>
        <p><b style="color: #254090">Interior of Oman:</b> This is representing interior cities/towns and what in between at the north of Oman except mountain areas.</p>
    </div>
    <div class="col-md-4 col-lg-4 col-sm-12  grid-3p row-margin">
      <img src="../../../../assets/img/self-drive/weather/NorthMountainPeaks.jpg" class="img-fluid grid-3 w-100" loading="lazy"/>
      <p><b style="color: #be9b71;">North Mountain Peaks :</b> This area representing peaks of mountain at the north of Oman Such as Jabal Al Akhadar, Jabal Shams, Hatt Village peaks, Warkan villages and other around peaks.</p>
    </div>
  </div>
  <div class="row">
    <div class="col-md-4 col-lg-4 col-sm-12 grid-3p row-margin">
        <img src="../../../../assets/img/self-drive/weather/DesertSandyDunesArea.jpg" class="img-fluid grid-3 w-100" loading="lazy"/>
        <p><b style="color: #ffd900;">Desert Sandy Dunes Area :</b> This is representing sandy dunes spots at entire Oman such as Al Sharqiyah Sandy Dunes, Sandy Dunes Around Ibri Wilayat, Third Quarter Sandy Dunes and others.</p>
    </div>
    <div class="col-md-4 col-lg-4 col-sm-12 grid-3p row-margin">
      <img src="../../../../assets/img/self-drive/weather/SouthMonsoonZone.jpg" class="img-fluid grid-3 w-100" loading="lazy"/>
        <p><b style="color: #41ad49;">South Monsoon Zone :</b> This representing area which is directly affected by the mon-soon season (Al Khareef) at  the south of Oman, which is area from Wilayat Rakuit, Salalah and Murbat Wilayat's.</p>
    </div>
    <div class="col-md-4 col-lg-4 col-sm-12  grid-3p row-margin">
      <img src="../../../../assets/img/self-drive/weather/OtherMonsoonaffectedArea.jpg" class="img-fluid grid-3 w-100" loading="lazy"/>
      <p><b style="color: #cd8112;">Other Monsoon affected Area :</b> This representing area which is in-directly affected by the mon-soon season(Al Khareef) which is the costal areas from Al Shi-waimia along the cost to Ras Al Hadd and Masirah Island.</p>
    </div>
  </div>
</div>



<header class="hero-last">
  <div class="center-content">
   

  </div>
  <div class="center-content2">
   
  </div>
</header>

<app-self-drive-footer *ngIf="!(location == '/error' || location == '/coming-soon' || location == '/sign-in'
    || location == '/sign-up'
    ||location == '/forgot-password')">
</app-self-drive-footer>
