<app-header *ngIf="!(location == '/error' || location == '/coming-soon'
    || location == '/sign-in'
    || location == '/sign-up'
    ||location == '/forgot-password')">
</app-header>

<!-- <div class="hdgap"></div>

<section class="w-100">
    <div class="container">



       
        <div id="carouselExampleControls" class="carousel slide mnslider" data-ride="carousel">
            <div class="carousel-inner">
                <div class="carousel-item active d-flex align-items-stretch">

                   
                 <div class="w-50 bgsetup">
                    <img src="../../../../assets/img/mob.jpg" class="dmob"/>
                    <div class="slider_txt">
                        <h2>Introducing self drive for better oman experience</h2>
                        <span>Rent a car along with camping equipment and explore oman</span>
                        <a href="https://car-rental.hekaya.co/self-drive" target="_blank">Take a tour</a>
                    </div>
                
                </div>
                 <div class="w-50 videosetup">
                    <video width="100%" height="auto"  [muted]="'muted'" autoplay loop style="display: block;">
                        <source src="../../../../assets/video/slide.mp4" type="video/mp4">
                        Your browser does not support the video tag.
                      </video>
            
                 </div>
                
                </div>
               
            </div>
           
        </div>



    </div>

    

</section>
<div class="main-categories-box">
    <ul>
       

        <li>
            
            <a href="https://car-rental.hekaya.co/pick-up-and-drop-off" target="_blank">
                <span class="icon">
                    <img src="https://hekaya.s3.ap-south-1.amazonaws.com/carRental/icon2.png" alt="Car Icon- Pick&Drop" >
                </span> Pick & Drop

            </a>
        </li>

        <li>
            
            <a href="https://car-rental.hekaya.co/self-drive" target="_blank">
                <span class="icon">
                    <img src="https://hekaya.s3.ap-south-1.amazonaws.com/carRental/icon4.png" alt="Car Icon- Self Drive">
                </span> Self Drive
            </a>
        </li>

        <li>
            <a href="https://hekaya.co/default.aspx" target="_blank">
                <span class="icon">
                    <img src="https://hekaya.s3.ap-south-1.amazonaws.com/carRental/icon3.png" alt="Car Icon- Packages">
                </span> Packages
            </a>
        </li>
        <li>
            <a href="https://visa.hekaya.co/" target="_blank">
                <span class="icon">
                    <img src="https://hekaya.s3.ap-south-1.amazonaws.com/carRental/icon5.png" alt="Visa Icon">
                </span> Visa
            </a>
        </li>


    </ul>
</div> -->
<app-home1></app-home1>




<section class="w-100 mt-5">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <h1 class="hd_main">
                    Welcome to <br />
                    <span style="color: #a98e5f;">HEKAYA</span> Car Rental<br />
                    <span class="">in <i>Oman</i></span>
                </h1>
                <p class="newstyl">
                    Welcome to Hekaya Car Rental in Oman, your gateway to extraordinary journeys. Experience seamless and reliable car rental services that will elevate your exploration of this beautiful country.

                </p>
            </div>
            <div class="col-lg-6 d-flex align-items-end">
                <img class="img-fluid" src="https://hekaya.s3.ap-south-1.amazonaws.com/carRental/car-sample.png" alt=" White car available for Car rentals in Oman">

            </div>
        </div>
    </div>
</section>


<!-- <section class="w-100">
    <div class="container">
        <div class="row">
            <div class="col-md-3 col-sm-6" data-toggle="modal" data-target="#exampleModalsearch">
                <div class="w-100 d-flex flex-wrap justify-content-center box_car">
                    <div class="home-addon-image  mt-4"><i class="bx bxs-map ic_siz1" aria-hidden="true" ></i></div>
                    <span class="w-100 mt-3 d-block text-center hd_blk" >Pick destination</span>
                    <p class="w-100 text-center mb-4">Select a destination you want to go</p>
                </div>
            </div>

            <div class="col-md-3 col-sm-6" data-toggle="modal" data-target="#exampleModalsearch">
                <div class="w-100 d-flex flex-wrap justify-content-center box_car">
                    <div class="home-addon-image  mt-4"><i class="bx bxs-calendar ic_siz1" aria-hidden="true" ></i></div>
                    <span class="w-100 mt-3 d-block text-center hd_blk">Select Term</span>
                    <p class="w-100 text-center mb-4">Select expected staring date of your journey.</p>
                </div>
            </div>

            <div class="col-md-3 col-sm-6" data-toggle="modal" data-target="#exampleModalsearch">
                <div class="w-100 d-flex flex-wrap justify-content-center box_car">
                    <div class="home-addon-image mt-4"><i class="bx bxs-car ic_siz1" aria-hidden="true" ></i>
                    </div>
                    <span class="w-100 mt-3 d-block text-center hd_blk">Choose a Car</span>
                    <p class="w-100 text-center mb-4">Choose a car that suits your travel.</p>
                </div>
            </div>

            <div class="col-md-3 col-sm-6">
                <div class="w-100 d-flex flex-wrap justify-content-center box_car" data-toggle="modal" data-target="#exampleModalsearch">
                    <div class="home-addon-image d-flex justify-content-center align-items-center mt-4">
                        <img class="ic_siz1 me-0" src="https://hekaya.s3.ap-south-1.amazonaws.com/carRental/smile.png" width=50 alt="smiley" >
                    </div> <span class="w-100 mt-3 d-block text-center hd_blk">Enjoy The Ride</span>
                    <p class="w-100 text-center mb-4">Enjoy your trip with your family and friends.</p>
                </div>
            </div>
        </div>

    </div>
</section>






<section class="w-100 mt-5">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
<div class="w-100 brdrds">
    <iframe class="d-block" width="100%" height="315" src="https://www.youtube.com/embed/h_XlEIqxqgA?si=QdPMO_xQKrRXE3Ew&amp;controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
</div>

                </div>
                <div class="col-lg-6 d-flex flex-wrap align-content-center">

                    <h2 class="hd_think mb-3">Thinking of Renting a Car in Oman?</h2>
                    <p class="newstyl">
                       
Thinking about a long weekend getaway, a 2- week trip, extended travel, or something in between? Depending on where you live and the amount of time you have to travel, certain destinations will make more sense than others.
    
                    </p>
                </div>
            </div>   </div>
</section>




<section class="w-100 mt-5">
    <div class="container">

        <div class="row">
            <div class="col-12">
                <div class="w-100 radius_20">
                    <img class="w-100" src="https://hekaya.s3.ap-south-1.amazonaws.com/carRental/home2.jpg" alt="Three Girls Proudly Holding a Flag">
                </div>
            </div>
        </div>

    </div>
</section>

<section class="w-100 mt-5">
    <div class="container">
        <div class="row">
            <div class="col-12" >
                <h2 class="w-100 text-center head2">Our Fleets</h2>
                <p class="w-100 text-center">
                    Welcome to our exceptional fleet of vehicles, proudly offered by our premier car rental company in Oman. Our diverse and meticulously maintained collection features an extensive range of cars to suit your every need, ensuring a smooth and enjoyable journey.
                </p>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 col-lg-3"data-toggle="modal" data-target="#exampleModalsearch">
                <div class="w-100 d-flex align-items-center box_car">
                    <div><img src="https://hekaya.s3.ap-south-1.amazonaws.com/carRental/car_box1.jpg" alt="White car available for Car rentals in Oman-Toyota Corola" class="img-fluid"></div>
                    <div>Toyota Corolla</div>
                </div>
            </div>

            <div class="col-md-6 col-lg-3" data-toggle="modal" data-target="#exampleModalsearch">
                <div class="w-100 d-flex align-items-center box_car">
                    <div><img src="https://hekaya.s3.ap-south-1.amazonaws.com/carRental/car_box2.jpg" alt="Silver car available for Car rentals in Oman -Nissan Sunny" class="img-fluid"></div>
                    <div>Nissan Sunny</div>
                </div>
            </div>

            <div class="col-md-6 col-lg-3" data-toggle="modal" data-target="#exampleModalsearch">
                <div class="w-100 d-flex align-items-center box_car">
                    <div><img src="https://hekaya.s3.ap-south-1.amazonaws.com/carRental/car_box3.jpg" alt="Black car available for Car rentals in Oman-Land Cruiser" class="img-fluid"></div>
                    <div>Land Cruiser</div>
                </div>
            </div>

            <div class="col-md-6 col-lg-3" data-toggle="modal" data-target="#exampleModalsearch">
                <div class="w-100 d-flex align-items-center box_car">
                    <div><img src="https://hekaya.s3.ap-south-1.amazonaws.com/carRental/car_box4.jpg" alt="Red car available for Car rentals in Oman- Toyota Altis" class="img-fluid" ></div>
                    <div>Toyota Altis</div>
                </div>
            </div>

            <div class="col-md-6 col-lg-3" data-toggle="modal" data-target="#exampleModalsearch">
                <div class="w-100 d-flex align-items-center box_car">
                    <div><img src="https://hekaya.s3.ap-south-1.amazonaws.com/carRental/car_box5.jpg" alt="Golden car available for Car rentals in Oman- Duster" class="img-fluid" ></div>
                    <div>Duster</div>
                </div>
            </div>

            <div class="col-md-6 col-lg-3" data-toggle="modal" data-target="#exampleModalsearch">
                <div class="w-100 d-flex align-items-center box_car">
                    <div><img src="https://hekaya.s3.ap-south-1.amazonaws.com/carRental/car_box6.jpg" alt="Silver car available for Car rentals in Oman- Elantra" class="img-fluid" ></div>
                    <div>Elantra</div>
                </div>
            </div>

            <div class="col-md-6 col-lg-3" data-toggle="modal" data-target="#exampleModalsearch">
                <div class="w-100 d-flex align-items-center box_car">
                    <div><img src="https://hekaya.s3.ap-south-1.amazonaws.com/carRental/car_box7.jpg" alt="White car available for Car rentals in Oman- Mazda" class="img-fluid"></div>
                    <div>Mazda</div>
                </div>
            </div>

            <div class="col-md-6 col-lg-3" data-toggle="modal" data-target="#exampleModalsearch">
                <div class="w-100 d-flex align-items-center box_car">
                    <div><img src="https://hekaya.s3.ap-south-1.amazonaws.com/carRental/car_box8.jpg" alt="White car available for Car rentals in Oman- Kicks" class="img-fluid"></div>
                    <div>Kicks</div>
                </div>
            </div>


        </div>

    </div>
</section>

<section class="w-100 mt-5">
    <div class="container">

        <div class="row">
            <div class="col-12">
                <div class="w-100 radius_20">
                    <img class="w-100" src="https://hekaya.s3.ap-south-1.amazonaws.com/carRental/home3.jpg" alt="Rent a Red Car in Muscat">
                </div>
            </div>
        </div>

    </div>
</section> -->
<app-home2></app-home2>
<section class="w-100 mt-5">
    <div class="container">
        <div class="bg_blk radius_20 p-5">
            <div class="row">

                <div class="col-md-6">
                    <div class="w-100 scrollonviewmore">
                        <h2>Top-Notch Car Rental in Muscat </h2>
                        <p class="text-white">We are dedicated to provide our customers with top-notch car rental in Muscat and excellent
                            customer service. Our ﬂeet of vehicles includes a wide range of options to ﬁt every need,
                            from compact cars for city driving to SUVs for off-road adventures.</p>
                        <p class="text-white">Oman is a beautiful country with a lot to offer tourists, and rent a car in Oman is a great
                            way to explore all that it has to offer. Whether you're interested in history, nature, or
                            just want to experience the beauty of Oman, renting a car is the perfect way to see all the
                            country has to offer.</p>
                        <p class="text-white" *ngIf="viewMore">Hekaya is the best car rental company in Oman, offering competitive pricing
                            and transparent policies, with no hidden fees or surprises. Our easy online booking system
                            makes it simple to reserve your rental car in just a few clicks, and our knowledgeable staff
                            are available to answer any questions you may have.</p>
                        <p class="text-white" *ngIf="viewMore">Whether you are visiting Oman for business or pleasure, we are here to help
                            you get around and explore everything this beautiful country has to offer.
                            Trust us to provide you with a safe and reliable car rental in Muscat that
                            will make your trip a success.
                        </p>
                        <h2 *ngIf="viewMore">Rent a Car in Oman, Muscat</h2>
                        <p class="text-white" *ngIf="viewMore">When you're planning to rent a car in Oman, Muscat is one of the best places to start. With many car rental companies operating in the city, you can easily ﬁnd a vehicle that suits your needs and preferences. </p>
                        <p class="text-white" *ngIf="viewMore">To rent a car in Muscat, you must be at least 21 years old and hold a valid driving license from your home country or an International Driving Permit (IDP). You'll also need to provide a passport or national ID card as proof of identity and a credit card for the security deposit.</p>
                        <h2 *ngIf="viewMore">Top Tips for Car Rental in Oman, Muscat</h2>
                        <p class="text-white" *ngIf="viewMore">Before <a href="https://car-rental.hekaya.co/blog/2023/06/08/renting-a-car-in-oman-budget-friendly-options-for-travelers/ " target="_blank">renting a car in Oman,</a> it's essential to consider some factors that can affect your rental experience. Here are some top tips to keep in mind when renting a car in Muscat.</p>
                        <p class="text-white" *ngIf="viewMore">●    Choose a reputable car rental company in Muscat that offers competitive prices, quality vehicles, and excellent customer service. Reading reviews and comparing prices between companies can help you make an informed decision.</p>
                        <p class="text-white" *ngIf="viewMore">●	Check the car rental requirements, including age restrictions, driver's license requirements, insurance coverage, and payment options. Some car rental companies may require additional documentation or charge extra fees for certain services, so it's important to understand the terms and conditions before renting.</p>
                        <p class="text-white" *ngIf="viewMore">●	Choose a car that suits your needs and preferences, such as a compact car for city driving or an SUV for off-road adventures. Consider the number of passengers and luggage you'll be carrying and the type of terrain you'll be driving on.</p>
                        <p class="text-white" *ngIf="viewMore">●	Understand the trafﬁc rules and regulations in Oman, such as driving on the
                            right-hand side, wearing seatbelts, and avoiding using mobile phones while driving. Familiarizing yourself with local driving laws can help you avoid ﬁnes and accidents.
                            </p>
                        <p class="text-white" *ngIf="viewMore">●	Inspect the car thoroughly before renting it, checking for any damages, scratches, or dents, and take photos as evidence. Make sure to report any existing damage to the rental company to avoid being held responsible for it later.</p>
                        <p class="text-white" *ngIf="viewMore">●	Drive safely and responsibly, respecting trafﬁc laws, speed limits, and other drivers on the road. Avoid reckless driving or dangerous maneuvers that can put yourself and others at risk.</p>
                        <p class="text-white" *ngIf="viewMore">●	Return the car on time and in the same condition as when you rented it to avoid any penalties or extra charges. Make sure to ﬁll up the gas tank and clean the car before returning it, as some rental companies may charge additional fees for these services.</p>
                        <h2 *ngIf="viewMore">Discover Muscat at Your Own Pace with a Rental Car</h2>
                        <p class="text-white" *ngIf="viewMore">Are you planning a trip to Muscat and looking for the freedom to explore the city on your
                            own terms? Look no further! Our expert car rental services in Muscat offer you the
                            perfect opportunity to discover this beautiful city at your own pace. No matter if you're
                            traveling alone, a couple seeking adventure , or with your whole family on vacation, our
                            expert car rental choices can meet your needs. We make sure that every visitor has a
                            smooth experience by having handy meeting spots and a fleet of well-kept cars. Rent a car
                            in Muscat today and unlock the true essence of this enchanting city. You have the freedom
                            to go where you want, find secret gems, and make experiences that will last a lifetime.
                             </p>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="w-100 scrollonviewmore">
                        <h2>Best Car Rental Company in Oman </h2>
                        <p class="text-white">Oman is an incredible country with a wide range of sights and activities for the adventurous
                            traveler. You can expect to ﬁnd quiet beaches, immense deserts, and lonely mountains. Rent a
                            Car in Muscat is the most convenient method to explore most of it at your own pace.</p>
                        <p class="text-white">Our dedicated staff is committed to giving outstanding customer service and making sure your
                            rental experience is trouble-free. You can easily and comfortably explore the city and its
                            surroundings with the help of Hekaya Rent a car in Muscat.</p>
                        <p class="text-white" *ngIf="viewMore">Hekaya was established with the goal of improving tourism services in Oman
                            and overseas. We provide rent a car options in Oman that offers reasonable rental rates and
                            an enjoyable, stress-free customer experience at most popular tourist destinations in Oman.
                            We are the best car rental company in Oman, offering affordable rates for renting a car.
                        </p>
                        <p class="text-white" *ngIf="viewMore">With a great selection of premium and family vehicles, Hekaya Rent a Car in
                            Oman is the ideal choice for exploring the local area or going on international travel and
                            it is one of the cheapest car rentals in muscat.</p>
                        <p class="text-white" *ngIf="viewMore">We will take all necessary steps to ensure a comfortable rental experience
                            and a seamless rental procedure for renting a car in Muscat. In terms of renting a car in
                            Muscat, we place a high priority on exceeding your expectations. </p>
                        <h2 *ngIf="viewMore">Beneﬁts of Renting a Car in Muscat</h2>
                        <p class="text-white" *ngIf="viewMore">Renting a car in Muscat provides you with the convenience and ﬂexibility to explore the city and surrounding areas at your own pace. You can travel comfortably to your desired destinations without having to rely on public transportation or taxis, which can be expensive and inconvenient. You can also avoid the hassle of waiting for buses or taxis, especially during peak hours or busy times.</p>
                        <p class="text-white" *ngIf="viewMore">Car rental in Muscat is an affordable option compared to other forms of transportation, and you can choose from a variety of vehicles that suit your needs and preferences. From compact cars to luxury SUVs, you can choose a vehicle that ﬁts your budget and style. You can also rent a car for a short period or a longer duration, depending on your travel plans.</p>
                        <h2 *ngIf="viewMore">Affordable and Reliable <a href="https://car-rental.hekaya.co/salalah" target="_blank">Car Rental Salalah</a></h2>
                        <p class="text-white" *ngIf="viewMore">Are you going to Salalah or visiting the beautiful Oman? Our budget-friendly car rental
                            services in Salalah provide you with affordable and reliable transportation options. With
                            our top-notch car rental in Oman, you can see the stunning beauty of Salalah and its
                            surroundings. We know how important it is to have a smooth trip, so our fleet is made up of
                            well-kept cars that will keep you safe and comfortable on the road. We have car rental
                            options for everyone, whether you're a local looking for an easy way to get around or a
                            tourist wanting to see Oman's sights. You don't have to worry about plans or public
                            transportation, so you can go wherever you want. You can enjoy Salalah the way you want
                            to by booking your budget-friendly rental car in Muscat with us today.</p>
                    </div>
                </div>

                <div class="col-12 d-flex justify-content-end mt-3"><span (click)="viewMore = !viewMore"
                        *ngIf="!viewMore"><b> View More</b></span></div>
                <div class="col-12 d-flex justify-content-end mt-3"><span (click)="viewMore = !viewMore"
                        *ngIf="viewMore"><b> View Less</b></span></div>
            </div>
        </div>
    </div>
</section>


<section class="w-100 mt-5">
    <div class="container">
        <div class="row">
            <div class="col-md-4">
                <h2 class="faq_hd">Frequently Asked
                    <br /> Questions
                </h2>
                <span>Can't find what you're looking for?<br />Visit our <a 
                        class="text-decoration-underline font-weight-bold" routerLink="/contact" target="_blank">Help centre</a></span>
            </div>
            <div class="col-md-8">
                <div class="accordion faqstyle faqopen" id="accordionExample">
                    <div class="card">
                        <div class="card-header" id="headingOne">
                            <h2 class="mb-0">
                                <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse"
                                    data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">Which is
                                    the most cost-effective month for renting a car in Oman?
                                </button>
                            </h2>
                        </div>

                        <div id="collapseOne" class="collapse show" aria-labelledby="headingOne"
                            data-parent="#accordionExample">
                            <div class="card-body">
                                <p>The least expensive month to rent a car in Oman is June, while the most expensive
                                    month is December. In reality, the cost to Rent a Car in Oman is about 22% less in
                                    June than the other months. When traveling to Oman, it is usually less expensive to
                                    <a href="https://car-rental.hekaya.co/salalah" target="_blank">rent a car in Salalah,</a>
                                     where daily rates are typically around 4% less than the
                                    national average.</p>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" id="headingTwo">
                            <h2 class="mb-0">
                                <button class="btn btn-link btn-block text-left collapsed" type="button"
                                    data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false"
                                    aria-controls="collapseTwo">Is it a good idea to rent a car in Muscat?
                                </button>
                            </h2>
                        </div>
                        <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo"
                            data-parent="#accordionExample">
                            <div class="card-body">
                                <p>
                                    Muscat can be explored in luxury and at a reasonable cost by rent a car in Muscat.
                                    If you're traveling on a limited budget, this is the best way to experience the
                                    city. For large groups, it is also very practical because everyone can enjoy
                                    themselves in one vehicle rather than having to take various taxis to get from one
                                    location to another. Renting a car allows for signiﬁcant time savings, and what
                                    could be better than driving the capital city's coastal roads.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" id="headingThree">
                            <h2 class="mb-0">
                                <button class="btn btn-link btn-block text-left collapsed" type="button"
                                    data-toggle="collapse" data-target="#collapseThree" aria-expanded="false"
                                    aria-controls="collapseThree">What is the eligible age to rent a car in Salalah?
                                </button>
                            </h2>
                        </div>
                        <div id="collapseThree" class="collapse" aria-labelledby="headingThree"
                            data-parent="#accordionExample">
                            <div class="card-body">
                                <p>In Oman, 18 is the minimum limit to drive. However, most companies demand that
                                    drivers be at least 25 years old and have had their licenses for at least a year in
                                    order to <a href="https://car-rental.hekaya.co/salalah" target="_blank">rent a car in Salalah.</a> Some car rental companies in Salalah might let
                                    people between the ages of 21 and 24 rent a vehicle, but they may charge a lot of
                                    money if an accident occurs. All drivers must show a current driver's license, an ID
                                    that serves as identiﬁcation, and a debit card to complete the transaction for car
                                    rental in Salalah.</p>
                            </div>
                        </div>
                    </div>

                    <div class="card" *ngIf="faqViewMore">
                        <div class="card-header" id="headingfour">
                            <h2 class="mb-0">
                                <button class="btn btn-link btn-block text-left collapsed" type="button"
                                    data-toggle="collapse" data-target="#collapsefour" aria-expanded="false"
                                    aria-controls="collapsefour">Why do people rent cars?
                                </button>
                            </h2>
                        </div>
                        <div id="collapsefour" class="collapse" aria-labelledby="headingfour"
                            data-parent="#accordionExample">
                            <div class="card-body">
                                <p>Oman is a large nation with breathtaking natural features like remote deserts,
                                    sparkling wadis, and craggy coasts that extend for miles in every direction. Due to
                                    the lack of public transportation and the high expense of taking a taxi everywhere,
                                    car rental in Muscat makes sense. Oman usually has excellent roads, which makes
                                    driving simple and convenient. However, it's a good idea to familiarize yourself
                                    with local customs and trafﬁc laws before you go for a car rental in oman. </p>
                            </div>
                        </div>
                    </div>
                    <div class="card" *ngIf="faqViewMore">
                        <div class="card-header" id="headingfour">
                            <h2 class="mb-0">
                                <button class="btn btn-link btn-block text-left collapsed" type="button"
                                    data-toggle="collapse" data-target="#collapsefive" aria-expanded="false"
                                    aria-controls="collapsefive">What types of documents are required for renting?
                                </button>
                            </h2>
                        </div>
                        <div id="collapsefive" class="collapse" aria-labelledby="headingfour"
                            data-parent="#accordionExample">
                            <div class="card-body"> 
                                <p>To rent a car in Muscat, you'll need a valid driving license from your home country or an <a href="https://internationaldrivingpermit.org/what-is-an-idp/" target="_blank">International Driving Permit (IDP)</a>, a passport or national ID card as proof of identity, and a credit card for the security deposit. The driving license must be valid for the entire duration of the rental period. Some car rental companies may also require additional documents such as proof of insurance or a return airline ticket. </p>
                            </div>
                        </div>
                    </div>
                    <div class="card" *ngIf="faqViewMore">
                        <div class="card-header" id="headingfour">
                            <h2 class="mb-0">
                                <button class="btn btn-link btn-block text-left collapsed" type="button"
                                    data-toggle="collapse" data-target="#collapsesix" aria-expanded="false"
                                    aria-controls="collapsesix">What types of driving licenses are accepted for renting a car in Oman?
                                </button>
                            </h2>
                        </div>
                        <div id="collapsesix" class="collapse" aria-labelledby="headingfour"
                            data-parent="#accordionExample">
                            <div class="card-body">
                                <p>Oman accepts driving licenses from certain countries, including the United States, the United Kingdom, Canada, Australia, and many European countries. However, it's best to
 
                                    check with your car rental company for speciﬁc requirements as some companies may have different policies regarding acceptable driving licenses.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="card" *ngIf="faqViewMore">
                        <div class="card-header" id="headingfour">
                            <h2 class="mb-0">
                                <button class="btn btn-link btn-block text-left collapsed" type="button"
                                    data-toggle="collapse" data-target="#collapseseven" aria-expanded="false"
                                    aria-controls="collapseseven">What is the process of car rental in Muscat?
                                </button>
                            </h2>
                        </div>
                        <div id="collapseseven" class="collapse" aria-labelledby="headingfour"
                            data-parent="#accordionExample">
                            <div class="card-body">
                                <p>To rent a car in Muscat, you can either book online or visit a car rental company in person. You'll need to provide your driving license, passport or national ID card as proof of identity, and a credit card for the security deposit. The car rental company will then provide you with a rental agreement that outlines the terms and conditions of the rental, including the rental period, rental rate, and any additional fees or charges.</p>
                                <p>Before signing the rental agreement, make sure to read it carefully and ask any questions you may have. After signing the rental agreement and paying the rental fee, you'll be given the keys to the rental car. It's important to inspect the car thoroughly for any damages, scratches, or dents before driving off the rental lot. If you notice any damages, inform the rental company immediately and take photos as evidence. When returning the car, make sure to return it on time and in the same condition as when you rented it to avoid any penalties or extra charges.</p>
                            </div>
                        </div>
                    </div>
                    <div class="card" *ngIf="faqViewMore">
                        <div class="card-header" id="headingfour">
                            <h2 class="mb-0">
                                <button class="btn btn-link btn-block text-left collapsed" type="button"
                                    data-toggle="collapse" data-target="#collapse8" aria-expanded="false"
                                    aria-controls="collapse8">Which car rental agency will pick you up at the airport?
                                </button>
                            </h2>
                        </div>
                        <div id="collapse8" class="collapse" aria-labelledby="headingfour"
                            data-parent="#accordionExample">
                            <div class="card-body">
                                <p>Hekaya stands out as the premier car rental agency in Muscat, offering the convenience
                                    of airport pick-up services. Their commitment to customer satisfaction and a diverse
                                    fleet of vehicles make them the top choice for travelers seeking a hassle-free car rental
                                    experience upon landing in Muscat.</p>
                            </div>
                        </div>
                    </div>
                    <div class="card" *ngIf="faqViewMore">
                        <div class="card-header" id="headingfour">
                            <h2 class="mb-0">
                                <button class="btn btn-link btn-block text-left collapsed" type="button"
                                    data-toggle="collapse" data-target="#collapse9" aria-expanded="false"
                                    aria-controls="collapse9">What are the basic requirements for renting a car in Muscat?
                                </button>
                            </h2>
                        </div>
                        <div id="collapse9" class="collapse" aria-labelledby="headingfour"
                            data-parent="#accordionExample">
                            <div class="card-body">
                                <p>Renting a car in Muscat is straightforward with Hekaya. Basic requirements include a
                                    valid driver's license, proof of identity (usually a passport), and a minimum age of 21
                                    years. Standard procedures involve completing rental agreements and providing a
                                    security deposit, ensuring a smooth and reliable rental process.</p>
                            </div>
                        </div>
                    </div>
                    <div class="card" *ngIf="faqViewMore">
                        <div class="card-header" id="headingfour">
                            <h2 class="mb-0">
                                <button class="btn btn-link btn-block text-left collapsed" type="button"
                                    data-toggle="collapse" data-target="#collapse10" aria-expanded="false"
                                    aria-controls="collapse10">Can we rent a car to Oman from Dubai?
                                </button>
                            </h2>
                        </div>
                        <div id="collapse10" class="collapse" aria-labelledby="headingfour"
                            data-parent="#accordionExample">
                            <div class="card-body">
                                <p>Hekaya makes it possible to rent a car in Oman from Dubai, offering the flexibility and
                                    convenience travelers often seek. With their services, you can seamlessly transition
                                    between these two neighboring countries, enhancing your travel experience.</p>
                            </div>
                        </div>
                    </div>
                    <div class="card" *ngIf="faqViewMore">
                        <div class="card-header" id="headingfour">
                            <h2 class="mb-0">
                                <button class="btn btn-link btn-block text-left collapsed" type="button"
                                    data-toggle="collapse" data-target="#collapse11" aria-expanded="false"
                                    aria-controls="collapse11">Is it possible to rent a car for one- way travel within Oman?
                                </button>
                            </h2>
                        </div>
                        <div id="collapse11" class="collapse" aria-labelledby="headingfour"
                            data-parent="#accordionExample">
                            <div class="card-body">
                                <p>Hekaya's one-way car rental options within Oman provide incredible flexibility. Whether
                                    you're planning a scenic road trip or simply need to return your rental car to a different
                                    location within Oman, Hekaya has you covered, allowing you to tailor your journey to
                                    your preferences.</p>
                            </div>
                        </div>
                    </div>
                    <div class="card" *ngIf="faqViewMore">
                        <div class="card-header" id="headingfour">
                            <h2 class="mb-0">
                                <button class="btn btn-link btn-block text-left collapsed" type="button"
                                    data-toggle="collapse" data-target="#collapse12" aria-expanded="false"
                                    aria-controls="collapse12">Can I cross the border into other countries with a rented car from Oman?
                                </button>
                            </h2>
                        </div>
                        <div id="collapse12" class="collapse" aria-labelledby="headingfour"
                            data-parent="#accordionExample">
                            <div class="card-body">
                                <p>Hekaya's car rental services extend beyond Oman's borders. You can confidently cross
                                    into neighboring countries with a rented car from Hekaya. This option opens up exciting
                                    opportunities for cross-border adventures while enjoying the comfort and convenience
                                    of your rental vehicle.</p>
                            </div>
                        </div>
                    </div>
                    <div class="card" *ngIf="faqViewMore">
                        <div class="card-header" id="headingfour">
                            <h2 class="mb-0">
                                <button class="btn btn-link btn-block text-left collapsed" type="button"
                                    data-toggle="collapse" data-target="#collapse13" aria-expanded="false"
                                    aria-controls="collapse13">Is it possible to rent a car in Oman without a credit card?

                                </button>
                            </h2>
                        </div>
                        <div id="collapse13" class="collapse" aria-labelledby="headingfour"
                            data-parent="#accordionExample">
                            <div class="card-body">
                                <p>Hekaya goes the extra mile in terms of convenience by accepting debit cards, in addition
                                    to credit cards, for car rentals in Oman. This policy accommodates a broader range of
                                    customers, ensuring that anyone can access their reliable rental services without the
                                    need for a credit card.</p>
                            </div>
                        </div>
                    </div>
                </div>



                <div class="w-100 d-flex justify-content-end mt-3"><span class="viewbtn" (click)="faqViewMore = !faqViewMore"
                    *ngIf="!faqViewMore"><b> View More</b></span></div>
                <div class="w-100 d-flex justify-content-end mt-3"><span class="viewbtn" (click)="faqViewMore = !faqViewMore"
                    *ngIf="faqViewMore"><b> View Less</b></span></div>
          
            </div>
        </div>
    </div>
</section>


<!-- <app-feedback></app-feedback> -->


<app-footer *ngIf="!(location == '/error' || location == '/coming-soon' || location == '/sign-in'
     || location == '/sign-up'
     ||location == '/forgot-password')">
</app-footer>
