<app-self-drive-nav *ngIf="!(location == '/error' || location == '/coming-soon' 
    || location == '/sign-in' 
    || location == '/sign-up'
    ||location == '/forgot-password')">
</app-self-drive-nav>


<div class="page-title-area page-title-bg3">
    <div class="hekaya-container">
        <div class="page-title-content">
            <h2>Plan Your Trip</h2>
        </div>
    </div>
</div>





<div class="container-fluid">
    <div class="row top-row-margin">
        <div class="col-md-12 col-lg-12s col-sm-12 first-col-bg">
            <div class="container-fluid">
                <div class="page-content text-center">
                    <p>Plan your Oman Road Trip with self-camping and hotel stay combination, as you wish so that
                        you see all the beauty the Sultanate has to offer. Hekayat make is easier to discover Oman,
                        just in your way. Below is your Hekayat quick navigation guide to start planning and define
                        your route plan and so on:
                    </p>
                </div>
            </div>
        </div>
        
    </div>
</div>



<div class="hekaya-container">
    <div class="row top-row-margin">
        <div class="col-md-12 col-lg-7 col-sm-12">
            <div class="container">
                <div class="page-content1 text-justtify">
                    <h4 class="hekayat-titl">STEP 1: <span class="hekayat-color"> ESSENTIALS</span></h4>
                    <p>• Decide Your group numbers .</p>
                    <p>• Approximate Budget .</p>
                    <p>• Things you like to see and experience in Oman .</p>
                    <br>
                    <h4 class="hekayat-titl">STEP 2: <span class="hekayat-color"> DECIDE WHEN</span></h4>
                    <p>• Decide best time to visit Oman.</p>
                    <p>• Weather.</p>
                    <p>• Plan your trip to be within same period.</p>
                </div>
            </div>
        </div>
        <div class="col-md-12 col-lg-5 col-sm-12">
            <div class="single-destinations-box">
                <img src="../../../../assets/img/self-drive/about-oman/service-img2.png" alt="image" class="img-box-left" loading="lazy">
            </div>
        </div>
    </div>
</div>


<div class="hekaya-container">
    <div class="row top-row-margin">
        <div class="col-md-12 col-lg-5 col-sm-12">
            <div class="single-destinations-box">
                <img src="../../../../assets/img/self-drive/about-oman/service-img3.png" alt="image" class="img-box-right" loading="lazy">
            </div>
        </div>
        <div class="col-md-12 col-lg-7 col-sm-12">
            <div class="container">
                <div class="page-content2 text-justify">
                    <h4 class="hekayat-titl">STEP 3: <span class="hekayat-color"> DECIDE WHERE TO GO</span></h4>
                    <p>• Check Hekayat Recommended Route Plans (there is much more routes).</p>
                    <p>• Check maps.</p>
                    <p>• Understand routes with all details (distance, attractions, habitats etc.) .</p>
                    <p>• Navigate on Oman map and read more about attractions .</p>
                    <p>• Decide your personal belong you will need in your trip and what to wear . </p>
                    <br>
                    <h4 class="hekayat-titl">STEP 4: <span class="hekayat-color">ACCOMODATIONS</span></h4>
                    <p>• Decide if welling to do self-camp for the entire trip or a part of it (Check Hekayat Tips on this).</p>
                    <p>• Book your accommodation, if want to be within your plan (we recommend
                        this to be every 2-3 days). Options are hostels, guesthouse, garden stay,
                        heritage Inn, or hotels .</p>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="hekaya-container">
    <div class="row top-row-margin">
        <div class="col-md-12 col-lg-7 col-sm-12">
            <div class="container">
                <div class="page-content1 text-justify">
                    <h4 class="hekayat-titl">STEP 5: <span class="hekayat-color"> HEKAYAT CATEGORIES</span></h4>
                    <p>• Check Hekayat Driving Categories .</p>
                    <p>• Check Habitats and Driving Skills required at each.</p>
                    <p>• Understand caution given at each area for your safety .</p>
                    <br>
                    <h4 class="hekayat-titl">STEP 6: <span class="hekayat-color"> EQUIPMENTS</span></h4>
                    <p>• Check all equipment’s that will be provided. </p>
                    <p>• Decide if more equipment’s you need for your convenience.</p>
                    <p>• Read remarks section and terms for your convenience.</p>
                </div>
            </div>
        </div>
        <div class="col-md-12 col-lg-5 col-sm-12">
            <div class="single-destinations-box">
                <img src="../../../../assets/img/self-drive/about-oman/service-img4.png" alt="image" class="img-box-left" loading="lazy">
            </div>
        </div>
    </div>

</div>


<div class="hekaya-container">
    <div class="row top-row-margin">
        <div class="col-md-12 col-lg-5 col-sm-12">
            <div class="single-destinations-box">
                <img src="../../../../assets/img/self-drive/about-oman/service-img7.png" alt="image" class="img-box-right" loading="lazy">
            </div>
        </div>
        <div class="col-md-12 col-lg-7 col-sm-12">
            <div class="container">
                <div class="page-content1 text-justify">
                    <h4 class="hekayat-titl">STEP 7: <span class="hekayat-color"> YOUR VEHICLE</span></h4>
                    <p>• Based on your decided route plan, equipment’s you need, habitat
                        and driving experience you would like to experience, choice your
                        vehicle category and brand .</p>
                    <p>• Read remarks section and terms for your convenience .</p>
                    <br>
                    <h4 class="hekayat-titl">STEP 8: <span class="hekayat-color"> SAFETY & SECURITY GUIDANCE</span></h4>
                    <p>• Read about safety and security tips we provide .</p>
                    <p>• Read and understand main traffic rules at Oman .</p>
                    <p>• Read about Other Guidance’s . </p>
                </div>
            </div>
        </div>
        
    </div>
</div>


<div class="hekaya-container">
    <div class="row top-row-margin">
        <div class="col-md-12 col-lg-7 col-sm-12">
            <div class="container">
                <div class="page-content3 text-center">
                    <h4 class="hekayat-titl text-center"><span class="hekayat-color"> GET READY</span></h4>
                    <p> You are ready to go .</p>
                    <p> Book your Hekayat Product now and live the experience.</p>
                        
                </div>
            </div>
        </div>
        <div class="col-md-12 col-lg-5 col-sm-12">
            <div class="single-destinations-box">
                <img src="../../../../assets/img/self-drive/about-oman/service-img5.png" alt="image" class="img-box-left" loading="lazy">
            </div>
        </div>
    </div>
</div>
 


<div class="container-fluid">
    <div class="row top-row-margin">
        <div class="col-md-12 col-lg-12 col-sm-12 container-pad">
            <header class="hero">
                <div class="center-content">
                    <h1 style="color:white;font-weight:800">Sultanate Of Oman</h1>
                    <h3 style="color:white;">"Simplicity is a Treasure of Luxury"</h3>
                </div>
                <div class="center-content2">
                  
                </div>
            </header>
        </div>
    </div>
</div>




<app-self-drive-footer *ngIf="!(location == '/error' || location == '/coming-soon' || location == '/sign-in'
    || location == '/sign-up'
    ||location == '/forgot-password')">
</app-self-drive-footer>
