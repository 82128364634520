<app-header *ngIf="!(location == '/error' || location == '/coming-soon'
    || location == '/sign-in'
    || location == '/sign-up'
    ||location == '/forgot-password')">
</app-header>


<div class="page-title page-title-bg3">
    <div class="container">
        <div class="page-title-content">

        </div>
    </div>
</div>

<div class="hekaya-container">
    <div class="row row-top-margin m-0 ptb-100">
        <div class="col-md-12">
            <div class="tab faq-accordion-tab">
                <ul class="nav nav-tabs tabs justify-content-center" style="border-bottom: none;">
                    <li class="active"><a class="active" data-toggle="tab" href="#home">Using of the Website</a></li>
                    <li><a data-toggle="tab" href="#menu1">Purchasing of Services</a></li>
                    <li><a data-toggle="tab" href="#menu2">Payment and Policies</a></li>
                </ul>
            </div>
            

            <div class="tab-content">
                <div id="home" class="tab-pane fade show active">
                    <div class="container">
                        <div class="row">
                            <div class="col-12">
                                <section class="faq-area ">
                                    <div class="container">
                                        <div class="page-heading-content" style="padding-bottom: 6%;">
                                            <h2>Using of the Website</h2>
                                        </div>
                                        <div class="tab faq-accordion-tab">
                                            
    
                                            <div class="tab-content">
                                                <div class="tabs-item">
                                                    <div class="faq-accordion" id="accordionEx" role="tablist"
                                                        aria-multiselectable="true">
                                                        <div class="card">
                                                            <div class="card-header" role="tab" id="headingOne">
                                                                <a data-toggle="collapse" data-parent="#accordionEx"
                                                                    href="#collapseOne" aria-expanded="false"
                                                                    aria-controls="collapseOne">How can I use this website?
                                                                    <i class='bx bx-chevron-down'></i></a>
                                                            </div>
                                                            <div id="collapseOne" class="collapse " role="tabpanel"
                                                                data-parent="#accordionEx">
                                                                <div class="card-body">
                                                                    <p>This website can be used to rental a car within the
                                                                        Sultanate of Oman and other
                                                                        tourism services as showed in the ‘’Our Services’’
                                                                        Section.</p>
                                                                    <p>Through our website, you can get to know our products
                                                                        and services, their
                                                                        availability and how to purchase them. We do not
                                                                        guarantee the reliability of the
                                                                        website content or that it is free of viruses. By
                                                                        using the website, the user
                                                                        acknowledges his eligibility and overall knowledge
                                                                        of the terms and conditions of
                                                                        using the website and all of our policies and in the
                                                                        event of purchase, the user
                                                                        acknowledges his eligibility to contract and agree
                                                                        to all terms and provisions of what
                                                                        was purchased.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="card">
                                                            <div class="card-header" role="tab" id="headingTwo">
                                                                <a class="collapsed" data-toggle="collapse"
                                                                    data-parent="#accordionEx" href="#collapseTwo"
                                                                    aria-expanded="false" aria-controls="collapseTwo">What
                                                                    are the prohibitions when using the website? <i
                                                                        class='bx bx-chevron-down'></i></a>
                                                            </div>
                                                            <div id="collapseTwo" class="collapse" role="tabpanel"
                                                                data-parent="#accordionEx">
                                                                <div class="card-body">
                                                                    <p>The user is prohibited from copying the contents of
                                                                        the website and using it for any
                                                                        commercial purposes, as well as selling and
                                                                        reselling the products and services
                                                                        provided on the website. All rights to the content,
                                                                        services and products are reserved
                                                                        to Hekaya Holidays Company.</p>
    
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="card">
                                                            <div class="card-header" role="tab" id="headingThree">
                                                                <a class="collapsed" data-toggle="collapse"
                                                                    data-parent="#accordionEx" href="#collapseThree"
                                                                    aria-expanded="false" aria-controls="collapseThree">What
                                                                    are Hekaya Holidays Company products? <i
                                                                        class='bx bx-chevron-down'></i></a>
                                                            </div>
                                                            <div id="collapseThree" class="collapse" role="tabpanel"
                                                                data-parent="#accordionEx">
                                                                <div class="card-body">
                                                                    <p>Hekaya Holidays Company is specialized in providing
                                                                        tourism and holiday’s service
                                                                        in Oman and abroad such as follows:</p>
                                                                    <ul>
                                                                        <li>International tickets with all international
                                                                            airlines</li>
                                                                        <li>Hotels, chalets, and resorts bookings</li>
                                                                        <li>All sorts of hotels around the world, including
                                                                            individual and group bookings</li>
                                                                        <li>Transport, entertainment and cultural tours
                                                                            bookings as per the client preference or as
                                                                            scheduled by the tourism company</li>
                                                                        <li>All sorts of public transport (buses, trains,
                                                                            and ferries)</li>
                                                                        <li>Yachts and marine tours bookings</li>
                                                                        <li>Car rental</li>
                                                                        <li>Planning different types of travel packages and
                                                                            travel scehdules for families, honeymooners, and
                                                                            the youth</li>
                                                                        <li>Tourism attraction entry tickets to some
                                                                            countries as available</li>
                                                                        <li>Organizing group travels and adventures</li>
                                                                        <li>Travel insurance</li>
                                                                        <li>Completing process for some countries</li>
                                                                        <li>Different travel consultancies</li>
                                                                    </ul>
                                                                    <p>Some mentioned services are available in our main
                                                                        website <a href=" www.hekaya.co">www.hekaya.co</a>
                                                                        and
                                                                        others might not be available in the website right
                                                                        now. You can contact us directly
                                                                        through <a href="">customerservices@hekaya.co</a>
                                                                        where Hekaya team will provide you with
                                                                        services you want.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="card">
                                                            <div class="card-header" role="tab" id="headingFour">
                                                                <a class="collapsed" data-toggle="collapse"
                                                                    data-parent="#accordionEx" href="#collapseFour"
                                                                    aria-expanded="false" aria-controls="collapseFour">What
                                                                    is the difference between the website usage terms and
                                                                    conditions, and
                                                                    the Car Rental terms and conditions? <i
                                                                        class='bx bx-chevron-down'></i></a>
                                                            </div>
                                                            <div id="collapseFour" class="collapse" role="tabpanel"
                                                                data-parent="#accordionEx">
                                                                <div class="card-body">
                                                                    <p>The website terms and conditions are about the way
                                                                        you use the website, our
                                                                        privacy policy, payment, compatibility and others
                                                                        (please read more in the website
                                                                        terms and conditions section). The Car Rental terms
                                                                        and conditions are related to
                                                                        Car Rental services and they reflect the service
                                                                        suppliers and providers policies and
                                                                        the clients has to be acknowledged before purchase
                                                                        (please read more in the Car
                                                                        Rental Terms at the purchase page).</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="card">
                                                            <div class="card-header" role="tab" id="headingFive">
                                                                <a class="collapsed" data-toggle="collapse"
                                                                    data-parent="#accordionEx" href="#collapseFive"
                                                                    aria-expanded="false" aria-controls="collapseFive">What
                                                                    happens when any misuse of the website or any fraud
                                                                    occurs? <i class='bx bx-chevron-down'></i></a>
                                                            </div>
                                                            <div id="collapseFive" class="collapse" role="tabpanel"
                                                                data-parent="#accordionEx">
                                                                <div class="card-body">
                                                                    <p>In case of using the website against our usage policy
                                                                        and other policies or in case of
                                                                        suspecting a fraud, the website team and management
                                                                        or their representatives has
                                                                        the right to investigate the matter and take the
                                                                        necessary legal measures and refer
                                                                        the matter to the competent legal authorities in the
                                                                        Sultanate of Oman.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="card">
                                                            <div class="card-header" role="tab" id="headingSix">
                                                                <a class="collapsed" data-toggle="collapse"
                                                                    data-parent="#accordionEx" href="#collapseSix"
                                                                    aria-expanded="false" aria-controls="collapseSix">How
                                                                    can I contact Hekaya team? <i
                                                                        class='bx bx-chevron-down'></i></a>
                                                            </div>
                                                            <div id="collapseSix" class="collapse " role="tabpanel"
                                                                data-parent="#accordionEx">
                                                                <div class="card-body">
                                                                    <p>We are happy to get in touch with you. You can
                                                                        contact us through the website by
                                                                        writing to us in the contact us section, or you can
                                                                        reach us through our email
                                                                        <a href="">customerservices@hekaya.co.</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="menu1" class="tab-pane fade">
                    <div class="container">
                        <div class="row">
                            <div class="col-12">
                                <section class="faq-area pb-100">
                                    <div class="container">
                                        <div class="page-heading-content" style="padding-bottom: 6%;padding-top: 2%;">
                                            <h2>Purchasing of Services</h2>
                                        </div>
                                        <div class="tab faq-accordion-tab">
                                           
    
                                            <div class="tab-content">
                                                <div class="tabs-item">
                                                    <div class="faq-accordion" id="accordionEx" role="tablist"
                                                        aria-multiselectable="true">
                                                        <div class="card">
                                                            <div class="card-header" role="tab" id="headingOne">
                                                                <a data-toggle="collapse" data-parent="#accordionEx"
                                                                    href="#collapseSeven" aria-expanded="false"
                                                                    aria-controls="collapseSeven">How is purchase
                                                                    confirmation done on the website? <i
                                                                        class='bx bx-chevron-down'></i></a>
                                                            </div>
                                                            <div id="collapseSeven" class="collapse " role="tabpanel"
                                                                data-parent="#accordionEx">
                                                                <div class="card-body">
                                                                    <p>When purchasing any product, the client will receive
                                                                        an initial confirmation after
                                                                        making the purchase through their profile and email.
                                                                        This process is done after the
                                                                        supplier confirms the bookings definitively, to
                                                                        ensure our client’s peace of mind.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="card">
                                                            <div class="card-header" role="tab" id="headingTwo">
                                                                <a class="collapsed" data-toggle="collapse"
                                                                    data-parent="#accordionEx" href="#collapseEight"
                                                                    aria-expanded="false" aria-controls="collapseEight">What
                                                                    happens when I do not receive a confirmation on any of
                                                                    the services I
                                                                    purchased? <i class='bx bx-chevron-down'></i></a>
                                                            </div>
                                                            <div id="collapseEight" class="collapse" role="tabpanel"
                                                                data-parent="#accordionEx">
                                                                <div class="card-body">
                                                                    <p>After making your purchase, the bookings team will
                                                                        confirm the final booking by
                                                                        directly contacting the supplier. When none of the
                                                                        services you purchased in
                                                                        available, you will be notified, and a similar
                                                                        option will be sent to you. If you agree,
                                                                        you will receive a confirmation through a phone call
                                                                        or via the registered email.</p>
    
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="card">
                                                            <div class="card-header" role="tab" id="headingThree">
                                                                <a class="collapsed" data-toggle="collapse"
                                                                    data-parent="#accordionEx" href="#collapseNine"
                                                                    aria-expanded="false" aria-controls="collapseNine">How
                                                                    can I receive the service I purchased through the
                                                                    website? <i class='bx bx-chevron-down'></i></a>
                                                            </div>
                                                            <div id="collapseNine" class="collapse" role="tabpanel"
                                                                data-parent="#accordionEx">
                                                                <div class="card-body">
                                                                    <p>After purchasing any product, the user will receive
                                                                        the confirmation through the
                                                                        user’s account and the registered email. The
                                                                        confirmation coupon will be issued
                                                                        electronically which has all the booking
                                                                        confirmations and the client can use as a
                                                                        reference.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="menu2" class="tab-pane fade">
                    <div class="container">
                        <div class="row">
                            <div class="col-12">
                                <section class="faq-area pb-100">
                                    <div class="container">
                                        <div class="page-heading-content" style="padding-bottom: 6%;padding-top: 2%;">
                                            <h2>Payment and Policies</h2>
                                        </div>
                                        <div class="tab faq-accordion-tab">
                                           
    
                                            <div class="tab-content">
                                                <div class="tabs-item">
                                                    <div class="faq-accordion" id="accordionEx" role="tablist"
                                                        aria-multiselectable="true">
                                                        <div class="card">
                                                            <div class="card-header" role="tab" id="headingOne">
                                                                <a data-toggle="collapse" data-parent="#accordionEx"
                                                                    href="#collapseTen" aria-expanded="false"
                                                                    aria-controls="collapseTen">What is the difference
                                                                    between change, cancellation and no-show policies? <i
                                                                        class='bx bx-chevron-down'></i></a>
                                                            </div>
                                                            <div id="collapseTen" class="collapse " role="tabpanel"
                                                                data-parent="#accordionEx">
                                                                <div class="card-body">
                                                                    <p>The change policy determines the client’s ability to
                                                                        change the date of getting the
                                                                        service that has been purchased and the change fees
                                                                        if available, according to the
                                                                        type of the service. The cancellation policy
                                                                        determines the client’s ability to cancel a
                                                                        tourism service or not and the sum of the refund.
                                                                        The no-show policy determines the
                                                                        refunded sums or no refund- in case the client does
                                                                        not show up during the period of
                                                                        providing the service. Each tourism service has a
                                                                        different change, cancellation and
                                                                        not-showing-up policy.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="card">
                                                            <div class="card-header" role="tab" id="headingTwo">
                                                                <a class="collapsed" data-toggle="collapse"
                                                                    data-parent="#accordionEx" href="#collapseEleven"
                                                                    aria-expanded="false" aria-controls="collapseEleven">How
                                                                    can I pay? <i class='bx bx-chevron-down'></i></a>
                                                            </div>
                                                            <div id="collapseEleven" class="collapse" role="tabpanel"
                                                                data-parent="#accordionEx">
                                                                <div class="card-body">
                                                                    <p>The client can use their visa card or Master card for
                                                                        each purchase; therefore, it is
                                                                        necessary for the client to have one, and to copy
                                                                        the needed information in the given
                                                                        spaces in the website. The client also must make
                                                                        sure that he is using the card
                                                                        securely. In Hekaya website, we aim to keep all
                                                                        personal information private.</p>
    
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="card">
                                                            <div class="card-header" role="tab" id="headingThree">
                                                                <a class="collapsed" data-toggle="collapse"
                                                                    data-parent="#accordionEx" href="#collapseTwelve"
                                                                    aria-expanded="false" aria-controls="collapseTwelve">How
                                                                    can I cancel and get refunded? <i
                                                                        class='bx bx-chevron-down'></i></a>
                                                            </div>
                                                            <div id="collapseTwelve" class="collapse" role="tabpanel"
                                                                data-parent="#accordionEx">
                                                                <div class="card-body">
                                                                    <p>Cancellation policies differ for each tourism
                                                                        service, and usually those policies reflect
                                                                        the supplier’s policies. The client must know the
                                                                        cancellation policy of the service
                                                                        before purchasing it and is usually stated in the
                                                                        cancellation policies section. In case
                                                                        the purchased service can be cancelled as due to the
                                                                        cancellation request date, the
                                                                        client must call the customer service and express
                                                                        their desire to cancel the service,
                                                                        as well as writing an email to the company. The
                                                                        request will be investigated,
                                                                        confirmed and a cancellation sum will be withdrawn
                                                                        in case there were no
                                                                        management fees (5%) of the purchase coupon or a
                                                                        minimum of 5 Omani Rials. The
                                                                        rest of the sum will be refunded to the same visa or
                                                                        debit card used within 14 days.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="card">
                                                            <div class="card-header" role="tab" id="headingFour">
                                                                <a class="collapsed" data-toggle="collapse"
                                                                    data-parent="#accordionEx" href="#collapseThirteen"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseThirteen">How can I make sure my
                                                                    personal information is private in the website? <i
                                                                        class='bx bx-chevron-down'></i></a>
                                                            </div>
                                                            <div id="collapseThirteen" class="collapse" role="tabpanel"
                                                                data-parent="#accordionEx">
                                                                <div class="card-body">
                                                                    <p>The privacy and confidentiality of personal
                                                                        information is our utmost priority in
                                                                        Hekaya Holidays, and we appreciate your trust.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>









<!-- <app-partner></app-partner> -->


<app-footer *ngIf="!(location == '/error' || location == '/coming-soon' || location == '/sign-in'
    || location == '/sign-up'
    ||location == '/forgot-password')">
</app-footer>