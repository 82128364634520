export class Location {
    id: number;
    locationName: string;
    locationCode: string;
    locationType: number;
    latitude: number;
    longitude: number;
    placeID: string;
    regionName: string;
    region_id: number;
    typeName: string;
}