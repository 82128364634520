import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertService } from 'ngx-alerts';
import { Prediction } from 'src/app/models/location/googl-location.model';
import { vehicleType } from 'src/app/models/vehicle-type/vehicleType.model';
import { LocationService } from 'src/app/services/location/location.service';
import { PickAndDropService } from '../../../services/pick-and-drop.service';

@Component({
  selector: 'app-req-one-way-transfer',
  templateUrl: './req-one-way-transfer.component.html',
  styleUrls: ['./req-one-way-transfer.component.scss']
})
export class ReqOneWayTransferComponent implements OnInit {

  public minDatePickUp: Date = new Date();
  public maxDatePickUp: Date;
  public minDateReturn: Date;
  public maxDateReturn: Date;
  public pickUpFromHubValidForm: any;
  public pickUpCities: Prediction[] = [];
  public dropOffCities: Prediction[] = [];
  public pickUpTime = [];
  public returnTime = [];
  public dropOffHubLocation: any;

  @Input() public times: Array<string>;
  @Input() public vehiclesTypes: Array<vehicleType>;
  @Input() public parkOnlylocation: any;

  public transferPurpose = [
    'I am traveling via airport/port and need my vehicle to be transferred',
    'Vehicle transfer (just need my vehicle to be transferred)',
    'Vehicle for regular service at Agency/Workshop',
    // 'Vehicle for major service at Agency/Workshop',
    // 'Vehicle for regular maintenance at Agency/Workshop',
    // 'Vehicle for tire change at Agency/Worksop',
    'Vehicle for usual cleaning',
    'Vehicle for advance cleaning or polish',
    'Vehicle for accessories installation'
  ]

  
  //#endregion

  constructor(
    private alertService: AlertService
    , private _route: Router
    , private formBuilder: FormBuilder
    , private datepipe: DatePipe
    , private _locationService: LocationService
    , private _service: PickAndDropService
  ) {
    this.minDatePickUp = new Date((new Date()).getTime() + (1 * 24 * 60 * 60 * 1000));
    this.minDateReturn = new Date((new Date()).getTime() + (1 * 24 * 60 * 60 * 1000));
   }

  ngOnInit(): void {
    this.initFormElements();
  }

  filteredDropOffHubLocation(){
    this.dropOffHubLocation = this.parkOnlylocation.filter(x => x.id !== parseInt(this.pickUpFromHubValidForm.pickUpFromHub));
  }

  checkTime(i) {
    return (i < 10) ? "0" + i : i;
  }

  startTime() {
    let currentDate = new Date((new Date()).getTime() + (1 * 24 * 60 * 60 * 1000)).getDate();
    if( new Date(this.pickUpFromHubValidForm.pickUpDate).getDate() === currentDate){
      var today = new Date(),
        h = this.checkTime(today.getHours()),
        m = this.checkTime(today.getMinutes())
        this.generateTime(h,m);
    } else {
      this.pickUpTime = this.times;
    }
  }

  generateTime(h,m){
    for (let i = h; i < 24; i++) {
      if (i < 10) {
        this.pickUpTime.push('0' + i + ':00');
        this.pickUpTime.push('0' + i + ':30');
      } else {
        this.pickUpTime.push(i + ':00');
        this.pickUpTime.push(i + ':30');
      }
    }
  }

  getReturnTime(){
    this.returnTime = [];
    let pickUpTime = this.pickUpFromHubValidForm.pickUpTime;
    pickUpTime = pickUpTime.split(':');
    let h = this.checkTime(parseInt(pickUpTime[0]) + 2)
    let m = this.checkTime(parseInt(pickUpTime[1]))
    this.generateReturnTime(h,m)
  }

  generateReturnTime(h,m){
    for (let i = h; i < 24; i++) {
      if (i < 10) {
        this.returnTime.push('0' + i + ':00');
        this.returnTime.push('0' + i + ':30');
      } else {
        this.returnTime.push(i + ':00');
        this.returnTime.push(i + ':30');
      }
    }
  }

  public dateSelect(event: Date) {
    this.minDateReturn = event;
    this.startTime();
  }

  public onEnterLocation(event, type: number) {
    this.pickUpCities = [];
    this.dropOffCities = [];
    if (event?.target?.value) {
      const value = event.target.value;
      if (value?.length > 2) {
        this._locationService.googleLocation(value).subscribe(result => {
          if (result?.data?.predictions?.length > 0) {
            if (type === 1) {
              this.pickUpCities = result.data.predictions;
            } else if (type === 2) {
              this.dropOffCities = result.data.predictions;
            }
          }
        });
      }
    }

  }

  public onSelectItem(event: any, type: number) {
    if (event) {
      if (type === 1) {
        this.pickUpFromHubValidForm.pickUpAddress = event;
      } else if (type === 2) {
        this.pickUpFromHubValidForm.dropOffAddress = event;
      }
    }

  }


  // Search form submittion function
  public onSubmitSearch() {
    if (this.validateForm()) {
      const vehicleType = this.vehiclesTypes.find(x => x.id === Number(this.pickUpFromHubValidForm.vehicleType));

      const search = {
        formType: 1,
        pickUp: {
          placeId: this.pickUpFromHubValidForm.pickUpFromHub? 
                  this.parkOnlylocation.find(x => x.id === parseInt(this.pickUpFromHubValidForm.pickUpFromHub)).placeID :
                  this.pickUpFromHubValidForm.pickUpAddress.place_id,
          locationId: this.pickUpFromHubValidForm.pickUpFromHub? parseInt(this.pickUpFromHubValidForm.pickUpFromHub) : 0,
          name: this.pickUpFromHubValidForm.pickUpFromHub ? 
                this.parkOnlylocation.find(x => x.id === parseInt(this.pickUpFromHubValidForm.pickUpFromHub)).locationName : 
                this.pickUpFromHubValidForm.pickUpAddress.name,
          date: this.pickUpFromHubValidForm.pickUpDate,
          time: this.pickUpFromHubValidForm.pickUpTime,
        },
        dropOff: {
          placeId: this.pickUpFromHubValidForm.dropOffToHub ? 
                    this.parkOnlylocation.find(x => x.id === parseInt(this.pickUpFromHubValidForm.dropOffToHub)).placeID :
                    this.pickUpFromHubValidForm.dropOffAddress.place_id,
          locationId: this.pickUpFromHubValidForm.dropOffToHub ? parseInt(this.pickUpFromHubValidForm.dropOffToHub) : 0,
          name: this.pickUpFromHubValidForm.dropOffToHub ? 
                this.parkOnlylocation.find(x => x.id === parseInt(this.pickUpFromHubValidForm.dropOffToHub)).locationName : 
                this.pickUpFromHubValidForm.dropOffAddress.name,
        },
        isReturnSameLocation: false,
        vehicleType: {
          code: vehicleType.typeCode,
          id: vehicleType.id
        },
        transferPurpose: this.pickUpFromHubValidForm.transferPurpose
      }
      this._service.searchData = search;
      this._route.navigate(['/pick-up-and-drop-off/search-results']);
    }
  }

  private validateForm() {
    // Validating if pick up hub locations are select by isEnableSearchPickUpLocation is false
    if(!this.pickUpFromHubValidForm.isEnableSearchPickUpLocation){
      if (!this.pickUpFromHubValidForm.pickUpFromHub) {
        this.alertService.danger('Please select pick-up hub location.');
        return false;
      }
    }

    // Validating if pick up search locations are select by isEnableSearchPickUpLocation is true
    if(this.pickUpFromHubValidForm.isEnableSearchPickUpLocation){
      if (!this.pickUpFromHubValidForm.pickUpAddress) {
        this.alertService.danger('Please enter pick-up city.');
        return false;
      }
    }

    // Validating if drop off hub locations are select by isEnableSearchDropOffLocation is false
    if(!this.pickUpFromHubValidForm.isEnableSearchDropOffLocation){
      if (!this.pickUpFromHubValidForm.dropOffToHub) {
        this.alertService.danger('Please select drop-off hub location.');
        return false;
      }
    }

    // Validating if drop off search locations are select by isEnableSearchDropOffLocation is true
    if(this.pickUpFromHubValidForm.isEnableSearchDropOffLocation){
      if (!this.pickUpFromHubValidForm.dropOffAddress) {
        this.alertService.danger('Please enter drop-off city.');
        return false;
      }
    }

    if (!this.pickUpFromHubValidForm.vehicleType) {
        this.alertService.danger('Please select vehicle type.');
        return false;
    }

    if (!this.pickUpFromHubValidForm.pickUpDate) {
      this.alertService.danger('Please select pick-up date.');
      return false;
    }

    if (!this.pickUpFromHubValidForm.pickUpTime) {
      this.alertService.danger('Please select pick-up time.');
      return false;
    }

    if (!this.pickUpFromHubValidForm.transferPurpose) {
      this.alertService.danger('Please select purpose of your transfer.');
      return false;
    }

    return true;
    
  }

  private initFormElements(): void {
    this.pickUpFromHubValidForm = {
      isEnableSearchPickUpLocation: true,
      isEnableSearchDropOffLocation: false,
      pickUpFromHub: '',
      pickUpAddress: '',
      dropOffToHub: '',
      dropOffAddress: '',
      vehicleType:  '',
      pickUpDate: '',
      pickUpTime: '',
      transferPurpose:'',
    }
  }


}
