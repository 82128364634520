
import { GenericService } from './../generic-service/generic.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RoutingConstants } from 'src/app/app.routing.contents';
import { UserUpdate } from 'src/app/models/update/update/update.model';



@Injectable({
  providedIn: 'root'
})
export class MyProfileService {
 
  constructor( private _genericService:GenericService ,) { }

  update(request:any): Observable<any> {
    let serviceUrl = RoutingConstants.authentication.update;
    return this._genericService.postService(request, serviceUrl, true);
  }

  resetPassword(currentPassword: any, newPassword: any): Observable<any> {
    console.log(currentPassword);
    console.log(newPassword);
    let serviceUrl = RoutingConstants.authentication.resetPassword + 
    '?currentPassword=' + currentPassword +
    '&newPassword=' + newPassword;
    return this._genericService.getService(serviceUrl, true);
  }
 
}
