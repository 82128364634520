import { Component, OnInit, Renderer2 } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {

  constructor(private meta: Meta
    ,private renderer: Renderer2) { }

  ngOnInit(): void {
    // this.meta.addTag({ 
    //   name: 'canonical', 
    //   href: 'https://car-rental.hekaya.co/checkout' 
    // });
    const canonicalLink = this.renderer.createElement('link');
    this.renderer.setAttribute(canonicalLink, 'rel', 'canonical');
    this.renderer.setAttribute(canonicalLink, 'href', 'https://car-rental.hekaya.co/checkout');
    this.renderer.appendChild(document.head, canonicalLink);
    
  }

}
