import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { UtilityService } from 'src/app/services/utility-service/utility.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-self-drive-nav',
  templateUrl: './self-drive-nav.component.html',
  styleUrls: ['./self-drive-nav.component.scss']
})
export class SelfDriveNavComponent implements OnInit {

  public appSettings: any;
  collapsed = true;

  constructor(private _utilityService: UtilityService
    , private _authService: AuthenticationService) { }

  ngOnInit(): void {
    this._utilityService.getAppSettings().subscribe(appSettings => {
      if (appSettings) {
        this.appSettings = appSettings;
      }
    })
  }

  onSignOut() {
    this._authService.signIn(environment.agencyCode, environment.emailAddress, environment.password).subscribe(response => {
      if (response) {
        this._utilityService.setAppSettings(response);
        this._utilityService.saveWebSettings(response.accessToken);
      }
    }, error => {

    });
  }


}
