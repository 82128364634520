<div class="search_box">
    <div class="tab-content">
        <div role="tabpanel" aria-labelledby="pills-home-tab" class="tab-pane fade show active">
            <form (submit)="onSubmitSearch()">

                <!-- <div class="row align-items-center pb-3">
                    <div class="col-md-6 text-left " >
                        <div class="form-check pb-3 pb-md-0">
                            <input type="checkbox" class="form-check-input returncar" name="search-Location" [(ngModel)]="pickUpFromHubValidForm.isEnableSearchPickUpLocation">
                            <label class="form-check-label text-brown" name="returncar" for="rentACar_ReturnLocation">Search For Pick Up Locations</label>
                        </div>
                    </div>
                </div> -->

                <div class="row align-items-center">
                    <!-- <div class="col-md-6" [hidden]="pickUpFromHubValidForm.isEnableSearchPickUpLocation">
                        <div class="form-group select">
                            <select class="form-control" name="pickUp-Location" [(ngModel)]="pickUpFromHubValidForm.pickUpLocationID"  placeholder="Select pick location from our main hub" >
                                <option value="">Select pick location from our main hub</option>
                                <option *ngFor="let location of parkOnlylocation" value="{{location.id}}">
                                    {{location.locationName}} ({{location.locationCode}})
                                </option>
                            </select>
                        </div>
                    </div> -->

                    <div class="col-md-6" [hidden]="!pickUpFromHubValidForm.isEnableSearchPickUpLocation">
                        <div class="form-group transparent-field autotxt">
                            <ngx-autocomplete [searchKeyword]="'name'" [inputId]="'searchEmployee'" [placeholderValue]="'Search pick up location '" 
                                [entries]="pickUpCities" autocomplete="off" class="form-control m-0 pt-0 bg-transparent border-0 transparent-field" 
                                [(ngModel)]="pickUpFromHubValidForm.pickUpAddress" (keyup)="onEnterLocation($event,1)" name="pick-up-location" (selectedValue)="onSelectItem($event,1)">
                            </ngx-autocomplete>
                        </div>
                    </div>

                    <div class="col-md-6" >
                        <div class="form-group select" >
                            <select class="form-control" name="dropOff-Location" [(ngModel)]="pickUpFromHubValidForm.dropOffLocation"  placeholder="Select drop off park hub" (change)="filteredReturnlocation()">
                                <option value="">Select drop off park hub</option>
                                <option *ngFor="let location of parkOnlylocation" value="{{location.id}}">
                                    {{location.locationName}} ({{location.locationCode}})
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="form-group select">
                            <select name="Vehicle Type" [(ngModel)]="pickUpFromHubValidForm.vehicleType" placeholder="Select Vehicle Type"  class="form-control">
                                <option value="">Vehicle Type</option>
                                <option *ngFor="let vehicle of vehiclesTypes" value="{{vehicle.id}}">
                                    {{vehicle.typeName}} 
                                </option>

                            </select>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <input type="text" readonly="true" class="form-control" name="pick-up-date"
                             [(ngModel)]="pickUpFromHubValidForm.pickUpDate"
                             [minDate]="minDatePickUp" [maxDate]="maxDatePickUp" placeholder="Pickup date" 
                             #dp="bsDatepicker" bsDatepicker (ngModelChange)=dateSelect($event)>
                        </div>
                    </div>
                    <div class="col-md-4 text-left text-md-right">
                        <div class="form-group select">
                            <select class="form-control" name="pick-up-time" [(ngModel)]="pickUpFromHubValidForm.pickUpTime" (change)="getReturnTime()">
                                <option value="">Pick Up Time</option>
                                <option *ngFor="let time of pickUpTime" value="{{time}}">
                                    {{time}}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="row align-items-center pb-3" [hidden]="pickUpFromHubValidForm.isReturnToAnotherLocaton || pickUpFromHubValidForm.isReturnToFirstPickUp">
                    <div class="col-auto text-left " >
                        <!-- <div class="form-check pb-3 pb-md-0">
                            <input type="checkbox" class="form-check-input returncar" name="pick-vehicle-myself" [(ngModel)]="pickUpFromHubValidForm.pickVehicleMyself">
                            <label class="form-check-label text-brown" name="returncar" for="rentACar_ReturnLocation">Myself will pick up my vehicle from park hub after parking days</label>
                        </div> -->

                        <label class="form-check text-brown  pb-3 pb-md-0">
                            Myself will pick up my vehicle from park hub after parking days
                           <input type="checkbox" checked="checked" class="returncar"  name="pick-vehicle-myself" [(ngModel)]="pickUpFromHubValidForm.pickVehicleMyself">
                           <span class="checkmark"></span>
                      </label>
                    </div>
                </div>

                <div class="row align-items-center pb-3" [hidden]="!pickUpFromHubValidForm.pickVehicleMyself">
                    <div class="col-md-6">
                        <div class="form-group">
                            <input type="text" class="form-control" name="return-up-date" [minDate]="minDateReturn" [maxDate]="maxDateReturn" autocomplete="off"
                            [(ngModel)]="pickUpFromHubValidForm.returnDate" placeholder="Return date" #dp="bsDatepicker" bsDatepicker (ngModelChange)="getNoOfDays()">
                        </div>
                    </div>
                    <div class="col-md-6 text-left text-md-right">
                        <div class="form-group select">
                            <select class="form-control" name="return-up-time" [(ngModel)]="pickUpFromHubValidForm.returnTime">
                                <option value="">Return Time</option>
                                <option *ngFor="let time of returnTime" value="{{time}}">
                                    {{time}}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="row align-items-center pb-3" [hidden]="pickUpFromHubValidForm.isReturnToAnotherLocaton || pickUpFromHubValidForm.pickVehicleMyself">
                    <div class="col-auto text-left " >
                        <!-- <div class="form-check pb-3 pb-md-0">
                            <input type="checkbox" class="form-check-input returncar" [(ngModel)]="pickUpFromHubValidForm.isReturnToFirstPickUp" name="is-Return-To-First-Pick-Up">
                            <label class="form-check-label text-brown" name="returncar" for="rentACar_ReturnLocation">I would like my vehicle to be returned to the same point of first pick up after parking days</label>
                        </div> -->

                        <label class="form-check text-brown  pb-3 pb-md-0">
                            I would like my vehicle to be returned to the same point of first pick up after parking days
                           <input type="checkbox" checked="checked" class="returncar"  [(ngModel)]="pickUpFromHubValidForm.isReturnToFirstPickUp" name="is-Return-To-First-Pick-Up">
                           <span class="checkmark"></span>
                      </label>
                    </div>
                </div>

                <div class="row align-items-center pb-3" [hidden]="!pickUpFromHubValidForm.isReturnToFirstPickUp">
                    <div class="col-md-6">
                        <div class="form-group">
                            <input type="text" class="form-control" name="return-up-date" [minDate]="minDateReturn" [maxDate]="maxDateReturn" autocomplete="off"
                            [(ngModel)]="pickUpFromHubValidForm.returnDate" placeholder="Return date" #dp="bsDatepicker" bsDatepicker (ngModelChange)="getNoOfDays()">
                        </div>
                    </div>
                    <div class="col-md-6 text-left text-md-right">
                        <div class="form-group select">
                            <select class="form-control" name="return-up-time" [(ngModel)]="pickUpFromHubValidForm.returnTime">
                                <option value="">Return Time</option>
                                <option *ngFor="let time of returnTime" value="{{time}}">
                                    {{time}}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="row align-items-center pb-3" [hidden]="pickUpFromHubValidForm.isReturnToFirstPickUp || pickUpFromHubValidForm.pickVehicleMyself">
                    <div class="col-auto text-left " >
                        <!-- <div class="form-check pb-3 pb-md-0">
                            <input type="checkbox" class="form-check-input returncar" [(ngModel)]="pickUpFromHubValidForm.isReturnToAnotherLocaton" name="is-Return-To-First-Pick-Up">
                            <label class="form-check-label text-brown" name="returncar" for="rentACar_ReturnLocation">I would like my vehicle to be returned to another location after parking days</label>
                        </div> -->

                        <label class="form-check text-brown  pb-3 pb-md-0">
                            I would like my vehicle to be returned to another location after parking days
                           <input type="checkbox" checked="checked" class="returncar"  [(ngModel)]="pickUpFromHubValidForm.isReturnToAnotherLocaton" name="is-Return-To-First-Pick-Up">
                           <span class="checkmark"></span>
                      </label>
                    </div>
                </div>

                <div class="row align-items-center pb-3" [hidden]="!pickUpFromHubValidForm.isReturnToAnotherLocaton">
                    <!-- <div class="col-md-12 text-left " >
                        <div class="form-check pb-3 pb-md-0">
                            <input type="checkbox" class="form-check-input returncar" name="search-Location" [(ngModel)]="pickUpFromHubValidForm.isEnableSearchReturnLocation">
                            <label class="form-check-label text-brown" name="returncar" for="rentACar_ReturnLocation">Search For Return Locations</label>
                        </div>
                    </div> -->
                    <!-- <div class="col-md-6" [hidden]="pickUpFromHubValidForm.isEnableSearchReturnLocation">
                        <div class="form-group select">
                            <select class="form-control" name="return-location" [(ngModel)]="pickUpFromHubValidForm.returnLocationID" placeholder="Select return drop Location from our main hub" >
                                <option value="">Select return drop Location from our main hub</option>
                                <option *ngFor="let location of returnHubLocation" value="{{location.id}}">
                                    {{location.locationName}} ({{location.locationCode}})
                                </option>
                            </select>
                        </div>
                    </div> -->

                    <div class="col-md-6" [hidden]="!pickUpFromHubValidForm.isEnableSearchReturnLocation">
                        <div class="form-group transparent-field autotxt">
                            <ngx-autocomplete [searchKeyword]="'name'" [inputId]="'searchEmployee'" [placeholderValue]="'Search Return Drop City'" [entries]="dropOffCities" autocomplete="off" class="form-control m-0 pt-0 bg-transparent border-0 transparent-field" (keyup)="onEnterLocation($event,2)"
                                [(ngModel)]="pickUpFromHubValidForm.returnLocation" name="drop-off-location" (selectedValue)="onSelectItem($event,2)">
                            </ngx-autocomplete>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <input type="text" class="form-control" name="return-up-date" [minDate]="minDateReturn" [maxDate]="maxDateReturn" autocomplete="off"
                            [(ngModel)]="pickUpFromHubValidForm.returnDate" placeholder="Return date" #dp="bsDatepicker" bsDatepicker (ngModelChange)="getNoOfDays()">
                        </div>
                    </div>
                    <div class="col-md-6 text-left text-md-right">
                        <div class="form-group select">
                            <select class="form-control" name="return-up-time" [(ngModel)]="pickUpFromHubValidForm.returnTime">
                                <option value="">Return Time</option>
                                <option *ngFor="let time of returnTime" value="{{time}}">
                                    {{time}}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="row align-items-center pb-3" *ngIf="!pickUpFromHubValidForm.isReturnToAnotherLocaton && pickUpFromHubValidForm?.returnDate">
                    <div class="col-auto text-left">
                        <div class="form-check pb-3 pb-md-0">
                            <label class="form-check-label text-brown" name="returncar" for="rentACar_ReturnLocation">Parking calculated for {{noOfDays}} days and Vehicle should be received on or before {{pickUpFromHubValidForm?.returnDate | date: 'dd/MM/yyyy'}} at {{pickUpFromHubValidForm?.returnTime}}</label>
                        </div>
                    </div>
                </div>

                <div class="row align-items-center pb-3" *ngIf="pickUpFromHubValidForm.isReturnToAnotherLocaton && pickUpFromHubValidForm?.returnDate">
                    <div class="col-auto text-left">
                        <div class="form-check pb-3 pb-md-0">
                            <label class="form-check-label text-brown" name="returncar" for="rentACar_ReturnLocation">Parking calculated for {{noOfDays}} days</label>
                        </div>
                    </div>
                </div>

                <div class="text-right pr-0 mt-4">
                    <div class="form-check">
                        <button type="submit" class="btn btn-warning">Search</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
