import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AlertService } from 'ngx-alerts';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { LocationService } from 'src/app/services/location/location.service';
import { UtilityService } from 'src/app/services/utility-service/utility.service';
import { CarRentalService } from 'src/app/services/car-rental-service/car-rental.service';
import { NeedCarDeliveryComponent } from '../need-car-delivery/need-car-delivery.component';


@Component({
  selector: 'app-search-car',
  templateUrl: './search-car.component.html',
  styleUrls: ['./search-car.component.scss']
})
export class SearchCarComponent implements OnInit {

  carRentalPickUpLocationForm: FormGroup;
  isSubmitepickUpFromLocation: boolean;
  showRentACarPickUpFromLocation: boolean;
  locations: Array<Location>;
  minDateCollection: Date;
  minDateDelivery: Date;
  filteredSelectedTime: string[];
  maxDatePickUp: Date;
  times: Array<string>;
  maxDateReturn: Date;
  monthDateDelivery: Date;
  maxDate: Date;
  monthDateCollection: Date;
  searchCount: number; 
  searchResults: any;
  currentDate: Date;
  @ViewChild('searchCarsPopUp') public searchCarsPopUp: ModalDirective;
  selectedLocation: any;
  returnlocation:Array<Location>;
  carDeliveryOpen:boolean = false;
  val: any;
  val1: any;
  constructor(private formBuilder: FormBuilder
    , private _router: Router
    , private locationService: LocationService
    , private _utilityService: UtilityService
    , private alertService: AlertService
    , public _datepipe: DatePipe
    , private carRentalService:CarRentalService
    , private activateRoute:ActivatedRoute) {

    this.isSubmitepickUpFromLocation = false;
    this.showRentACarPickUpFromLocation = false;
    this.locations = new Array<Location>();
    this.minDateDelivery = new Date();
    this.minDateCollection = new Date();
    this.times = new Array<string>();
    this.monthDateDelivery = new Date();
    this.monthDateCollection = new Date();
    this.searchCount = 0;
   

  

  }

  ngOnInit(): void {
    const currentUrl =this._router.url;
    if (currentUrl ==='/'||currentUrl ==='/home'){
      // Clear the localStorage
      localStorage.removeItem('carRentalFormValues');
      
    }

    this.initCarRentalPickUpLocationForms();
    this.getTimes();
  
    this._utilityService.getAppSettings().subscribe(appSettings => {
      if (appSettings) {
        this.getLocations();
      }
    });
    const storedFormValues = localStorage.getItem('carRentalFormValues');

  if (storedFormValues) {
    // Parse the stored values and set them to the form
    const parsedFormValues = JSON.parse(storedFormValues, (key, value) => {
      if (key === 'pickUpDate' || key === 'retureDate') {
        return new Date(value);
      }
      return value;
    });

    this.carRentalPickUpLocationForm.patchValue(parsedFormValues);
    console.log(this.carRentalPickUpLocationForm.value.retureDate);
    this.val=this.carRentalPickUpLocationForm.value.pickUpDate;
    this.val1=this.carRentalPickUpLocationForm.value.retureDate;
    this.dateSelect(this.carRentalPickUpLocationForm.value.pickUpDate);
  }
  else {
    // If no stored form values found, reset the form to its initial state
    this.carRentalPickUpLocationForm.reset();
    this.carRentalPickUpLocationForm.patchValue({
      pickUpLocation: '',
      pickUpTime: '10:00',
      retureTime:'10:00',
      isAgree18Plus: true
    
    });
  }
  this._router.events.subscribe((event) => {
    if (event instanceof NavigationEnd) {
      const targetRoute = '/home';
      const currentUrl =this._router.url;

      // Check if the current URL or the target route is 'home'
      if (currentUrl ==='/home' || this._router.isActive(targetRoute, true)) {
        // Clear the localStorage
        localStorage.removeItem('carRentalFormValues');
       
        
      }
    }
  });

  
}

monthlyFunction(){
  const currentUrl =this._router.url;
  if (currentUrl !=='/'){
    console.log('currenturl',currentUrl);
    this.carRentalPickUpLocationForm.controls.retureDate.setValue(this.val1);
    this.carRentalPickUpLocationForm.controls.pickUpDate.setValue(this.val);
    this.setReturnDate(this.val);
}}
dailyFunction(){
  const currentUrl =this._router.url;
  if (currentUrl !=='/'){
    console.log('currenturl',currentUrl);
    this.carRentalPickUpLocationForm.controls.retureDate.setValue(this.val1);
    this.carRentalPickUpLocationForm.controls.pickUpDate.setValue(this.val);
    this.dateSelect(this.val);
}}
// toggleCarDelivery() {
//   this.carDeliveryOpen = true;
// }
public removeform()
{
  localStorage.removeItem('carRentalFormValues');
}

  public openPopUp() {
    if (this.searchCarsPopUp) {
      this.searchCarsPopUp.show();
    }
  }

  public setReturnDate(event: Date) {
    const selectedDate: Date = new Date(event);
    const today: Date = new Date();
    today.setHours(0, 0, 0, 0);
  
    if (selectedDate < today) {
      // If the selected date is in the past, reset the pickUpDate control value to today's date
      this.carRentalPickUpLocationForm.controls.retureDate.setValue(today);
    }
    const eventDate=new Date(event);
    const minSelectableDate: Date = new Date(selectedDate.getTime() + 30 * 24 * 60 * 60 * 1000);
    const currentDate: Date = new Date();
    this.monthDateCollection = currentDate > minSelectableDate ? currentDate : minSelectableDate;
    this.carRentalPickUpLocationForm.patchValue({
      retureDate:this.monthDateCollection,
    });
    // this.carRentalPickUpLocationForm.controls.retureDate.setValue( minSelectableDate);
    
  }


  private getTimes(): void {
    for (let i = 0; i < 24; i++) {
      if (i < 10) {
        this.times.push('0' + i + ':00');
        this.times.push('0' + i + ':30');
      } else {
        this.times.push(i + ':00');
        this.times.push(i + ':30');
      }
    }
  }

  public getcarRentalFilterdTime() {
    this.filteredSelectedTime = this.times.filter(time => time >= this.carRentalPickUpLocationForm.controls.pickUpTime.value);
  }

  public dateSelect(event:Date) {
    const selectedDate: Date = new Date(event);
    const today: Date = new Date();
    today.setHours(0, 0, 0, 0);
  
    if (selectedDate < today) {
      // If the selected date is in the past, reset the pickUpDate control value to today's date
      this.carRentalPickUpLocationForm.controls.pickUpDate.setValue(today);
    }
    const minSelectableDate: Date = new Date(selectedDate.getTime() + 2 * 24 * 60 * 60 * 1000);
const currentDate: Date = new Date();
this.minDateCollection = currentDate > minSelectableDate ? currentDate : minSelectableDate;
this.carRentalPickUpLocationForm.controls.retureDate.setValue(this.minDateCollection);


  
  }
 

  private getLocations(): void {

    this.locationService.locationByBookingModule(1).subscribe(locations => {
      this.locations = locations;
    })
  }

  //CAR RENTAL PICKUP FORM
  private initCarRentalPickUpLocationForms(): void {
    this.carRentalPickUpLocationForm = this.formBuilder.group({
      pickUpLocation: ['', Validators.required],
      isEnableReturnDifferentLocation: [false],
      returnLocation: [''],
      pickUpDate: ['', Validators.required],
      pickUpTime: ['', Validators.required],
      retureDate: ['', Validators.required],
      retureTime: ['', Validators.required],
      isAgree18Plus: [true]
    });
  }

  //CAR RENTAL RETURN DIFFERENT LOCATION
  public onChangeReturnDifferentLocation() {
    const isReturnDifferentLocation = this.carRentalPickUpLocationForm.controls.isEnableReturnDifferentLocation.value;
    if (isReturnDifferentLocation) {
      this.carRentalPickUpLocationForm.controls.returnLocation.setValidators([Validators.required]);
    } else {
      this.carRentalPickUpLocationForm.controls.returnLocation.setValidators([]);
      this.carRentalPickUpLocationForm.controls.returnLocation.setValue('');
    }
  }

  public onSubmitCarRentalPickUpLocation(): void {

    this.isSubmitepickUpFromLocation = true;


    if (this.carRentalPickUpLocationForm.invalid) {
      if (!this.carRentalPickUpLocationForm.controls.pickUpLocation.value) {
        this.alertService.danger('Please select Pick Up Location')
      } else if (!this.carRentalPickUpLocationForm.controls.pickUpDate.value) {
        this.alertService.danger('Please select Pick Up Date ')
      } else if (!this.carRentalPickUpLocationForm.controls.pickUpTime.value) {
        this.alertService.danger('Please select Pick Up Time ')
      } else if (!this.carRentalPickUpLocationForm.controls.retureDate.value) {
        this.alertService.danger('Please select Return Date ')
      } else if (!this.carRentalPickUpLocationForm.controls.retureTime.value) {
        this.alertService.danger('Please select Return Time ')
      } else if (!this.carRentalPickUpLocationForm.controls.isAgree18Plus.value) {
        this.alertService.danger('Please select if you are 18 Plus ')
      }
    }
    else {
      if (!this.carRentalPickUpLocationForm.controls.isAgree18Plus.value) {
        // Show an alert if the checkbox is unticked
        this.alertService.warning('You must be 18 years or older to rent a car.');
        return; // Stop form submission
      }

      const pickUp: number = parseInt(this.carRentalPickUpLocationForm.controls.pickUpLocation.value);
      let dropOff: number = 0;
      if (this.carRentalPickUpLocationForm.controls.isEnableReturnDifferentLocation.value) {
        dropOff = parseInt(this.carRentalPickUpLocationForm.controls.returnLocation.value);
      } else {
        dropOff = parseInt(this.carRentalPickUpLocationForm.controls.pickUpLocation.value);
      }
      const pickUpDate = this.carRentalPickUpLocationForm.controls.pickUpDate.value;
      const pickUpTime = this.carRentalPickUpLocationForm.controls.pickUpTime.value;
      const retureDate = this.carRentalPickUpLocationForm.controls.retureDate.value;
      const retureTime = this.carRentalPickUpLocationForm.controls.retureTime.value;
     const formattedPickUpDate = this._datepipe.transform(pickUpDate, 'dd-MMM-yyyy');
  const formattedRetureDate = this._datepipe.transform(retureDate, 'dd-MMM-yyyy');

      let inputData = `/${pickUp}-${dropOff},${this._datepipe.transform(pickUpDate, 'dd-MMM-yyyy')},${pickUpTime},${this._datepipe.transform(retureDate, 'dd-MMM-yyyy')},${retureTime}`;
      this._router.navigate(['/car-rental/search/pick-up' + inputData]);
      // window.location.reload();

      this.carRentalService.formValues=this.carRentalPickUpLocationForm;
      const isNavigatedFromAnotherPage = this.activateRoute.snapshot.queryParams['fromAnotherPage'];

      if (isNavigatedFromAnotherPage) {
        
       
        this.search(inputData);
        
       
      } else {
        const targetRoute = '/car-rental/search/pick-up' + inputData;
        const currentUrl = this._router.url;
        
        // Check if the first three segments of the current URL and target route are identical
        const currentUrlSegments = currentUrl.split('/').slice(0, 3).join('/');
        const targetUrlSegments = targetRoute.split('/').slice(0, 3).join('/');
        
        if (currentUrlSegments === targetUrlSegments) {
          const queryParams = {
            pickUpLocation: pickUp,
            pickUpDate: formattedPickUpDate,
            pickUpTime: pickUpTime,
            retureDate: formattedRetureDate,
            retureTime: retureTime
          };
          
          const formValues = this.carRentalPickUpLocationForm.getRawValue();
          formValues.pickUpDate = this._datepipe.transform(formValues.pickUpDate, 'yyyy-MM-dd');
          formValues.retureDate = this._datepipe.transform(formValues.retureDate, 'yyyy-MM-dd');
          localStorage.setItem('carRentalFormValues', JSON.stringify(formValues));
      
          // this._router.navigate(['/car-rental/search/pick-up'+inputData]).then(() => {
          //   // Reload the page
          //   location.reload();
          // });
          this._router.navigateByUrl('/refresh', { skipLocationChange: true }).then(() => {
            this._router.navigate(['/car-rental/search/pick-up'+inputData]);
          });
        } else {
          // Navigate to the target route
          const formValues = this.carRentalPickUpLocationForm.getRawValue();
          formValues.pickUpDate = this._datepipe.transform(formValues.pickUpDate, 'yyyy-MM-dd');
          formValues.retureDate = this._datepipe.transform(formValues.retureDate, 'yyyy-MM-dd');
          localStorage.setItem('carRentalFormValues', JSON.stringify(formValues));
      
          this._router.navigateByUrl(targetRoute);
        }
      }
       
    }
 
    
 
 
  }
   public onPickupLocationChange(event: any) {
    const selectedLocationId = event.target.value;
   
    
    if (selectedLocationId) {
      this.SelectedLocationName(selectedLocationId);
    }
  }
  SelectedLocationName(location){
    var i;
  
    for(i=0;i<=this.locations.length-1;i++){
      console.log(this.locations[i]);
       console.log(this.locations[i]["id"]);
      if(this.locations[i]["id"]==location){
       this.selectedLocation=location;
      }
    }
   console.log("selectedloc",this.selectedLocation);
  }
  private search(inputData: string):void {
    this.carRentalService.searchCars(inputData).subscribe((results) => {
      this.searchResults = results;
      this._router.navigate(['/searchResults']);
      
     
     
      // Perform any additional operations with the search results
    });
  }
  get f() { return this.carRentalPickUpLocationForm.controls };


}
function openPopUp(): () => void {
  throw new Error('Function not implemented.');
}

