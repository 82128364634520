<div class="error-404-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="notfound">
                    <div class="notfound-bg">
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    <h1>Oops!</h1>
                    <h3>Error 404 : Page Not Found</h3>
                    <p>The page you are looking for might have been removed had its name changed or is temporarily unavailable.</p>
                    <a routerLink="/" class="default-btn">Back Home</a>
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer *ngIf="!(location == '/error' || location == '/coming-soon' || location == '/sign-in'
    || location == '/sign-up'
    ||location == '/forgot-password')">
    </app-footer>