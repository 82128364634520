<app-pick-drop-header *ngIf="!(location == '/error' || location == '/coming-soon' 
    || location == '/sign-in' 
    || location == '/sign-up'
    ||location == '/forgot-password')">
</app-pick-drop-header>
<div *ngIf="isModalShown" [config]="{ show: true }" bsModal #autoShownModal="bs-modal"
     class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-auto-name">
  <div class="modal-dialog modal-xl" style="border-radius:50px; margin-top:60px;height:90% !important;width:150% !important;box-shadow: rgba(255, 255, 255, 0.5);" >
    <div class="modal-content" style="height:90% !important;width:100% !important;">
      <div class="modal-header" style="background-color: #ae8f47 !important;"><img src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/99/OOjs_UI_icon_alert-yellow.svg/1200px-OOjs_UI_icon_alert-yellow.svg.png" style="height:50px;width:50px;">
        <h4 id="dialog-auto-name" class="modal-title pull-left" style="color:white ;flex: 1; display: flex; justify-content: center; align-items: center; font-family:'janna';font-weight:bolder;padding-top: 50px;"></h4>
        <button type="button"  class="close"   data-dismiss="modal" (click)="autoShownModal.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <!-- <div class="modal-body" style="background-color:black; flex: 1; display: flex; justify-content: center; align-items: center;">
        <p style="color:white !important; font-size: larger;font-family:'janna';font-size:larger;"><strong>Page Under Maintenance</strong></p>
      </div> -->
      <div class="modal-body" style="background-color:white; flex: 1; display: flex; justify-content: center; align-items: center;text-align: center;">
        <p style="color:black !important; font-size: larger;font-family:'janna';font-size:xx-large;align-items: ce;"><strong>Page Under Maintenance and please write to us for quotation info@hekaya.co</strong></p>
      </div>
      
    </div>
  </div>
</div>
<div class="main-banner banner-bg1 ">
    <div class="container">
        <div class="main-banner-content">

            <ul class="nav nav-tabs row no-gutters" id="myTab" role="tablist">
                <!-- Tab Head -->
                <!-- Rent a car-->
                <li class="nav-item col first_tab">
                    <a class="nav-link active" id="rentACar-tab" data-toggle="tab" href="#rentACar_TabContent" role="tab" aria-controls="home" aria-selected="true">
                        <span class="icon_size">Pick And Drop</span>
                        <br>
                        <small>(vehicle transfer service from location to another)</small>
                    </a>
                </li>
                <!-- Rent a car-->

                <!--Monthly Rentals-->
                <li class="nav-item col last-tab">
                    <a class="nav-link" id="profile-tab" data-toggle="tab" href="#MonthlyRentals" role="tab" aria-controls="profile" aria-selected="false">
                        <span class="icon_size">Pick And Park</span>
                        <br>
                        <small>(vehicle transfer service from location to park area)</small>
                    </a>
                </li>
                <!--Monthly Rentals-->
               
                <!-- // Tab Head-->
            </ul>

            <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active" id="rentACar_TabContent" role="tabpanel" aria-labelledby="home-tab">
                    <div class="search_box">
                        <ul class="nav mb-4 nav-pills sub-tabs mb-3" id="pills-tab" role="tablist">
                            <!-- Pick up from hub-->
                            <li class="nav-item ">
                                <a class="nav-link nav-pills active" id="pills-home-tab" data-toggle="pill" href="#PickUpFromHub_TabContent" role="tab" aria-controls="pills-home" aria-selected="true">Request for one way transfer</a>
                            </li>
                            <!-- Pick up from hub-->

                            <!-- Need Car Delivery-->
                            <li class="nav-item ">
                                <a class="nav-link nav-pills " id="pills-profile-tab" data-toggle="pill" href="#PickUpFromLocation_TabContent" role="tab" aria-controls="pills-profile" aria-selected="false">Request for transfer on return base</a>
                            </li>
                            <!-- Need Car Delivery-->
                        </ul>
                        <div class="tab-content" id="pills-tabContent">

                            <div class="tab-pane fade show active" id="PickUpFromHub_TabContent" role="tabpanel" aria-labelledby="home-tab">
                                <app-req-one-way-transfer [times]="times" [vehiclesTypes]="vehiclesType" [parkOnlylocation]="pickAndDroplocations"></app-req-one-way-transfer>
                            </div>

                            <div class="tab-pane fade" id="PickUpFromLocation_TabContent" role="tabpanel" aria-labelledby="home-tab">
                                <app-req-transfer-on-return [times]="times" [vehiclesTypes]="vehiclesType" [parkOnlylocation]="pickAndDroplocations"></app-req-transfer-on-return>
                            </div>
    
                        </div>
                    </div>
                </div>
                

                <!-- Monthly rentals -->

                <div class="tab-pane fade" id="MonthlyRentals" role="tabpanel" aria-labelledby="home-tab">
                    <div class="search_box">

                        <ul class="nav mb-4 nav-pills sub-tabs mb-3" id="pills-tab" role="tablist">
                            <!-- Pick up from hub-->
                            <li class="nav-item ">
                                <a class="nav-link nav-pills active" id="pills-home-tab" data-toggle="pill" href="#PickparkFromLocation_TabContent" role="tab" aria-controls="pills-home" aria-selected="true">Request for pick up from location and park</a>
                            </li>
                            <!-- Pick up from hub-->

                            <!-- Need Car Delivery-->
                            <li class="nav-item ">
                                <a class="nav-link nav-pills " id="pills-profile-tab" data-toggle="pill" href="#SelfparkatHub_TabContent" role="tab" aria-controls="pills-profile" aria-selected="false">Request for self-park at hub</a>
                            </li>
                            <!-- Need Car Delivery-->
                        </ul>

                        <div class="tab-content">
                            <div class="tab-pane fade show active" id="PickparkFromLocation_TabContent" role="tabpanel" aria-labelledby="home-tab">
                                <app-pick-up-location-search-form [times]="times" [vehiclesTypes]="vehiclesType" [parkOnlylocation]="pickAndParklocations"></app-pick-up-location-search-form>
                            </div>

                            <div class="tab-pane fade" id="SelfparkatHub_TabContent" role="tabpanel" aria-labelledby="home-tab">
                                <app-self-park-at-hub-search-form [times]="times" [vehiclesTypes]="vehiclesType" [parkOnlylocation]="pickAndParklocations"></app-self-park-at-hub-search-form>
                            </div>

                        </div>
                    </div>
                </div>

                <!-- Monthly rentals ending -->

                

                <!--// Rent a Car -->

            </div>
        </div>
    </div>

    
</div>

<div class="main-categories-box">
    <ul>
        <li>
            <a [href]="carRental">
                <span class="icon">
                    <img src="../../../../../assets/img/icon1.png" alt="" loading="lazy">
                </span> Rent a Car
            </a>
        </li>

        <li>
            <a [href]="pickPark">
                <span class="icon">
                    <img src="../../../../../assets/img/icon2.png" alt="" loading="lazy">
                </span> Pick & Drop

            </a>
        </li>

        <li>
            <a [href]="selfDrive">  
                <span class="icon">
                    <img src="../../../../../assets/img/icon4.png" alt="" loading="lazy">
                </span> Self Drive
            </a>
        </li>

        <li>
            <a href="https://hekaya.co/default.aspx">
                <span class="icon">
                    <img src="../../../../../assets/img/icon3.png" alt="" loading="lazy">
                </span> Packages
            </a>
        </li>


    </ul>
</div>




<div class="container-fluid " style="padding-bottom: 6%;">
    <div class="row row-top-margin1">
        <div class="col-md-12 col-lg-12 col-sm-12 text-center">
            <h3 class="hekayat-titl">Get Your Vehicle Transferred <span class="color-hekayat">Or Park With Peace Of Mind</span></h3>
            <p class="hekayat-titl-p ">This is short transfer and park services to your vehicle
                 at chosen destinations in the Sultanate of Oman
            </p>
        </div>
    </div>
</div>

<div class="container-fluid container2">
    <div class="row top-row-margin">
        <div class="col-md-12 col-lg-12 col-sm-12">
            <header class="hero">
                <div class="center-content">
                    <div class="row">
                        <div class="col-md-6 col-lg-6 col-sm-12">
                            <img src="../../../../../assets/img/pick-drop/firstImageSmall.jpg" class="img-fluid img-border" loading="lazy" />
                        </div>
                        <div class="col-md-6 col-lg-6 col-sm-12 center-text">
                            <h2 class="hekayat-titl points-title" style="color: #fff;">Park Service</h2>
                            <p class="hekayat-titl-p" style="color: #fff;">Drive your vehicle to our secured and sheltered <br> hubs for
                                your peace of mind.
                            </p>
                        </div>
                    </div>
                </div>
            </header>
        </div>
    </div>
    <div class="row">
        <div class="col-md-4 col-lg-4 col-sm-12 padding-first">
            <h2 class="hekayat-titl points-titl">Pick up and Park service</h2>
                            <p class="hekayat-titl-p points-para">Let our well-trained driver to pick up your vehicle from your chosen location and park it at our nearest parking hub
                            </p>
        </div>
        <div class="col-md-8 col-lg-8 col-sm-12 text-right right-col-pad">
            <img src="../../../../../assets/img/pick-drop/secondImage.jpg" class="img-fluid" loading="lazy"/>
        </div>
    </div>
</div>

<div class="container-fluid">
    <div class="row bg-color">
        <div class="col-md-12 col-lg-8 col-sm-12 sec-padding">
                <img src="../../../../../assets/img/pick-drop/arabImage.jpg" class="img-fluid width-hundred" loading="lazy"/>
        </div>
        <div class="col-lg-4 col-md-12 col-sm-12 sec-padding2">
            <div class="white-box">
                <h2 class="hekayat-titl text-center drop-off-service">Pick up and drop off service</h2>
                            <p class="hekayat-titl-p text-justify">Let our well-trained driver to pick up your vehicle from your chosen location and drop off it at other destination. 
                            </p>
            </div>
        </div>
    </div>

    <div class="row bg-color">
        <div class="col-lg-4 col-md-12 col-sm-12 sec-padding3">
            <div class="white-box2">
                <h2 class="hekayat-titl text-center add-on-Services">Add on Services</h2>
                            <p class="hekayat-titl-p text-justify">Multi of other add on services can be provided to your vehicle at our park hubs or during the transfer service 
                            </p>
            </div>
        </div>
        <div class="col-md-12 col-lg-8 col-sm-12 sec-padding">
                <img src="../../../../../assets/img/pick-drop/img4.jpg" class="img-fluid width-hundred" loading="lazy"/>
        </div>
       
    </div>
</div>


<div class="container-fluid">
    <div class="row row-top-margin">
        <div class="col-md-5 col-lg-5 col-sm-12">
            <img src="../../../../../assets/img/pick-drop/Reasonsforusingpick.jpg" class="img-fluid width-hundred img-5-border" loading="lazy"/>
        </div>
        <div class="col-md-7 col-lg-7 col-sm-12">
            <div class="row responsive-space">
                <div class="col-md-12 col-lg-12 col-sm-12">
                    <h2 class="hekayat-titl responsive-heading">Reasons for using pick up 
                        and <span class="color-hekayat">drop off or parking services</span></h2>
                        <div class="row sub-responsive-row">
                            <div class="col-md-6 col-lg-6 col-sm-12">
                                <h3 class="sub-head">Moving</h3>    
                                <p class="text-justify sub-para">You are moving from one place to another in your state and have more vehicles than available drivers. Transfer one or more autos to your new home while you focus on getting your stuff and your family safely to the destination.</p>
                                <br>
                                <h3 class="sub-head">Security</h3>    
                                <p class="text-justify sub-para">Welling to drive somewhere and having concern about where to park you vehicle safely.</p>
                            </div>
                            <div class="col-md-6 col-lg-6 col-sm-12 save-margin">
                                <h3 class="sub-head">Saving</h3>    
                                <p class="text-justify sub-para">Your vehicle regular service or maintenance time is coming and you don't have the time to deliver it to the dealer or not want to call a friend or ride taxi for your return home trip till vehicle is ready.</p>
                                <br>
                                <h3 class="sub-head">Exchanging</h3>    
                                <p class="text-justify sub-para">You plan to give or trade a vehicle to someone in your state, or the reverse. With car transfer services, you don't have to leave home to make the exchange, saving you a lot of unnecessary time and travel.</p>
                            </div>
                        </div> 
                        <div class="row">
                            <div class="col-md-12 col-sm-12 col-lg-12 save-margin">
                                <h3 class="sub-head">Travelling</h3>    
                                <p class="text-justify sub-para">You are welling to travel by air or sea and welling to drive your own vehicle but having concern about parking your vehicle at the destination as for high parking fees, your vehicle security, un-availability of car parks or other reasons. </p>
                            </div>
                        </div>
                </div>
            </div>
        </div>
    </div>
</div>



<div class="container-fluid pt-5">
    <div class="row">
        <div class="col-md-12 col-lg-12-col-sm-12 banner-home" style="background-size:cover !important;">
            <div class="row">
                <div class="col-md-6 col-lg-6 col-sm-6 text-left">
                    <h1 class="banner-text">Visit <br>Oman</h1>
                </div>
                <div class="col-md-6 col-lg-6 col-sm-6 text-right">
                    <h1 class="banner-text">Explore <br>Oman</h1>
                </div>
            </div>
        </div>
    </div>
</div>







<app-pick-drop-footer *ngIf="!(location == '/error' || location == '/coming-soon' || location == '/sign-in'
     || location == '/sign-up'
     ||location == '/forgot-password')">
</app-pick-drop-footer>

