import { Observable } from 'rxjs';
import { GenericService } from './../generic-service/generic.service';
import { Injectable } from '@angular/core';
import { ContactUs } from 'src/app/models/contact-Us/contact-us.model';
import { RoutingConstants } from 'src/app/app.routing.contents';

@Injectable({
  providedIn: 'root'
})
export class ContactUsService {

  constructor(private genericService: GenericService) { }

  contactService(request: ContactUs) : Observable<any> {
    const serviceUrl = RoutingConstants.contactUs;
    return this.genericService.postService(request, serviceUrl, true);
  }
}
