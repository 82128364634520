import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { TermsAndConditionComponent } from './components/pages/terms-and-condition/terms-and-condition.component';
import { HekayaRouteMapComponent } from './components/pages/hekaya-route-map/hekaya-route-map.component';
import { VehicleCategoryComponent } from './components/pages/vehicle-category/vehicle-category.component';
import { HekayatGuidenceComponent } from './components/pages/hekayat-guidence/hekayat-guidence.component';
import { CampingGearComponent } from './components/pages/camping-gear/camping-gear.component';
import { HabitatCategoryComponent } from './components/pages/habitat-category/habitat-category.component';
import { HekayatCategoriesComponent } from './components/pages/hekayat-categories/hekayat-categories.component';
import { HekayaRouteComponent } from './components/pages/hekaya-route/hekaya-route.component';
import { OmanClimateComponent } from './components/pages/oman-climate/oman-climate.component';
import { SelfCampComponent } from './components/pages/self-camp/self-camp.component';
import { SelfDriveExploreComponent } from './components/pages/self-drive/self-drive.component';
import { PlanninYourRoadTripComponent } from './components/pages/plannin-your-road-trip/plannin-your-road-trip.component';
import { WhyHekayatComponent } from './components/pages/why-hekayat/why-hekayat.component';
import { WhatIsHekayatComponent } from './components/pages/what-is-hekayat/what-is-hekayat.component';
import { ServicesComponent } from './components/pages/services/services.component';
import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule ,PreloadAllModules} from '@angular/router';
import { AboutComponent } from './components/pages/about/about.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { CheckoutComponent } from './components/pages/checkout/checkout.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';
import { LoginComponent } from './components/pages/login/login.component';
import { RegisterComponent } from './components/pages/register/register.component';
import { FleetComponent } from './components/pages/fleet/fleet.component';
import { BookingComponent } from './components/pages/booking/booking.component';
import { NewbookingComponent } from './components/pages/newbooking/newbooking.component';
import { HomeComponent } from './components/views/home/home.component';
import { SignInComponent } from './components/views/sign-in/sign-in.component';
import { SignUpComponent } from './components/views/sign-up/sign-up.component';
import { ForgotPasswordComponent } from './components/views/forgot-password/forgot-password.component';
import { PersonalInfoComponent } from './components/pages/personal-info/personal-info.component';
import { PaymentComponent } from './components/pages/payment/payment.component';
import { PaymentFailureComponent } from './components/views/payment-failure/payment-failure.component';
import { HekayatGuidenceTwoComponent } from './components/pages/hekayat-guidence-two/hekayat-guidence-two.component';
import { HekayatGuidenceThreeInnerComponent } from './components/pages/hekayat-guidence-three-inner/hekayat-guidence-three-inner.component';
import { HekayatGuidenceThreeComponent } from './components/pages/hekayat-guidence-three/hekayat-guidence-three.component';
import { MapDetailPageComponent } from './components/pages/map-detail-page/map-detail-page.component'
import { WebUseTermsComponent } from './components/pages/web-use-terms/web-use-terms.component';
import { SelfdriveWebUseTermsComponent } from './components/pages/selfdrive-web-use-terms/selfdrive-web-use-terms.component';
import { DefaultHomeComponent } from './components/views/default-home/default-home.component';
import { SelfdriveHomeComponent } from './components/views/selfdrive-home/selfdrive-home.component';
import { ResetPasswordComponent } from './components/views/reset-password/reset-password/reset-password.component';
import { HomeNizwaComponent } from './components/views/home-nizwa/home-nizwa.component';
import { Home1Component } from './components/views/home1/home1.component';
import { Home2Component } from './components/views/home2/home2.component';
import { HomeSalalahComponent } from './components/views/home-salalah/home-salalah.component';
import { HomeSoharComponent } from './components/views/home-sohar/home-sohar.component';
import { HomeJebelAkhdarComponent } from './components/views/home-jebel-akhdar/home-jebel-akhdar.component';




const routes: Routes = [
	// { path: '', component: DefaultHomeComponent },
	{ path: '', component: HomeComponent},
	{ path: 'home', component: HomeComponent},
	{ path: 'home1', component:Home1Component},
	{ path: 'home2', component: Home2Component},
	{ path: 'nizwa', component:HomeNizwaComponent},
	{ path: 'salalah', component:HomeSalalahComponent},
	{ path: 'sohar', component:HomeSoharComponent},
	{ path: 'jebel-akhdar', component:HomeJebelAkhdarComponent},
	{ path: 'selfdrive', component: SelfdriveHomeComponent },
	{ path: 'car-rental', loadChildren: () => import('./components/views/car-rental/car-rental.module').then(x => x.CarRentalModule) },
	{ path: 'sign-in', component: SignInComponent },
	{ path: 'sign-up', component: SignUpComponent },
	{ path: 'forgot-password', component: ForgotPasswordComponent },
	{ path: 'reset-password', component: ResetPasswordComponent},
	{ path: 'my-account', loadChildren: () => import('./components/views/my-account/my-account.module').then(x => x.MyAccountModule) },
	{ path: 'pick-up-and-drop-off', loadChildren: () => import('./components/views/pick-drop/pick-drop.module').then(x => x.PickDropModule) },
	{ path: 'pick-and-park', loadChildren: () => import('./components/views/pick-park/pick-park.module').then(x => x.PickParkModule) },
	{ path: 'self-drive', loadChildren: () => import('./components/views/self-drive/self-drive.module').then(x => x.SelfDriveModule) },
	{ path: 'payment-falure/:moduleTypeId/:refNumber', component: PaymentFailureComponent },
	{ path: 'hekaya-services', component: ServicesComponent },
	{ path: 'about', component: AboutComponent },
	{ path: 'checkout', component: CheckoutComponent },
	{ path: 'faqs', component: FaqComponent },
	{ path: 'error', component: ErrorComponent },
	{ path: 'coming-soon', component: ComingSoonComponent },
	{ path: 'login', component: LoginComponent },
	{ path: 'register', component: SignUpComponent },
	{ path: 'contact', component: ContactComponent },
	{ path: 'fleet', component: FleetComponent },
	{ path: 'booking', component: BookingComponent },
	{ path: 'newbooking', component: NewbookingComponent },
	{ path: 'personal', component: PersonalInfoComponent },
	{ path: 'payments', component: PaymentComponent },
	{ path: 'what-is-hekayat', component: WhatIsHekayatComponent },
	{ path: 'why-hekayat', component: WhyHekayatComponent },
	{ path: 'Plannin-your-Road-Trip', component: PlanninYourRoadTripComponent },
	{ path: 'SelfDriveHekaya', component: SelfDriveExploreComponent },
	{ path: 'SelfCamp', component: SelfCampComponent },
	{ path: 'Oman-weather', component: OmanClimateComponent },
	{ path: 'Hekaya-Route-plan', component: HekayaRouteComponent },
	{ path: 'Driving-Category', component: HekayatCategoriesComponent },
	{ path: 'Habitat-Category', component: HabitatCategoryComponent },
	{ path: 'Vehicle-category', component: VehicleCategoryComponent },
	{ path: 'Hekayat-Camping-Gear', component: CampingGearComponent },
	{ path: 'Hekayat-Guidence', component: HekayatGuidenceComponent },
	{ path: 'Hekayat-route-map', component: HekayaRouteMapComponent },
	{ path: 'Terms-and-Condition', component: TermsAndConditionComponent },
	{ path: 'Privacy-Policy', component: PrivacyPolicyComponent },
	{ path: 'Hekayat-Guidence-Two', component: HekayatGuidenceTwoComponent },
	{ path: 'Hekayat-Accommodation' , component: HekayatGuidenceThreeComponent },
	{ path: 'Hekayat-Accommodation-Detail' , component: HekayatGuidenceThreeInnerComponent },
	{ path: 'Map-Detail-Page' , component: MapDetailPageComponent},
	{ path: 'Web-Use-Terms' , component: WebUseTermsComponent},
	{ path: 'Selfdrive-Web-Use-Terms' , component: SelfdriveWebUseTermsComponent},
	{ path: 'forgot-password/#/#', redirectTo: '', pathMatch: 'full' },
	{ path: 'pick-up-and-drop-off/Web-Use-Terms/#/#', redirectTo:'' ,pathMatch: 'full'},
	{ path: 'pick-up-and-drop-off/Web-Use-Terms/#', redirectTo:'' ,pathMatch: 'full'},
	{ path: 'pick-up-and-drop-off/#/#/#', redirectTo:'' ,pathMatch: 'full'},
	{ path: 'pick-up-and-drop-off/#', redirectTo:'' ,pathMatch: 'full'},
	{ path: 'pick-up-and-drop-off/#/#', redirectTo:'' ,pathMatch: 'full'},
	{ path: 'Terms-and-Condition/#', redirectTo:'' ,pathMatch: 'full'},
	{ path: 'Terms-and-Condition/#/#', redirectTo:'' ,pathMatch: 'full'},
	{ path: 'www.hekaya.co/#/#/#', redirectTo: '', pathMatch: 'full' },
	{ path: 'blog/comments/feed', redirectTo: '', pathMatch: 'full' },
	{ path: 'blog/feed', redirectTo: '', pathMatch: 'full' },
	{ path: '{search_term_string}/#', redirectTo: '', pathMatch: 'full' },
	{ path: '{search_term_string}', redirectTo: '', pathMatch: 'full' },
	{ path: 'selfdrive/#', redirectTo: '', pathMatch: 'full' },
	{ path: '?s={search_term_string}', redirectTo: '', pathMatch: 'full' },
	{ path: '?s={search_term_string}', redirectTo: '', pathMatch: 'full' },
	{ path: '?s=%7Bsearch_term_string%7D', redirectTo: '', pathMatch: 'full' },
	{ path: 'www.hekaya.co/#/#', redirectTo: '', pathMatch: 'full' },
	{ path: 'www.hekaya.co/#', redirectTo: '', pathMatch: 'full' },
	{ path: 'hekaya-services/#', redirectTo: 'hekaya-services', pathMatch: 'full' },
	{ path: 'hekaya-services/#/#', redirectTo: 'hekaya-services', pathMatch: 'full' },
	{ path: 'sign-in/#', redirectTo: '', pathMatch: 'full' },
	{ path: 'forgot-password/#', redirectTo: '', pathMatch: 'full' },
	{ path: 'about/#', redirectTo: 'about', pathMatch: 'full' },
	{ path: 'Driving-Category/#', redirectTo: '', pathMatch: 'full' },
	{ path: 'Visa@hekaya.co/#', redirectTo: '', pathMatch: 'full' },
	{ path: 'pick-up-and-drop-off/Privacy-Policy/#/#', redirectTo: '', pathMatch: 'full' },
	{ path: 'self-drive/Web-Use-Terms/#', redirectTo: '', pathMatch: 'full' },
	{ path: 'car-rental.hekaya.co/about', redirectTo: '', pathMatch: 'full' },
	{ path: 'pick-up-and-drop-off/contact-us/#', redirectTo: '', pathMatch: 'full' },
	{ path: '#', redirectTo: '', pathMatch: 'full' },
	{ path: 'self-drive/selfdrive-PrivacyPolicy/#/#', redirectTo: '', pathMatch: 'full' },
	{ path: 'self-drive/selfdrive-PrivacyPolicy/#', redirectTo: '', pathMatch: 'full' },
	{ path: 'www.hekaya.co/#/#/#', redirectTo: '', pathMatch: 'full' },
	// { path: 'robots.txt', redirectTo: '', pathMatch: 'full' },
	{ path: 'blog/2023/03/22/first-post', component: ErrorComponent },
	{ path: 'blog/2023/03/22/first-post/', component: ErrorComponent },
	{ path: '#/#', redirectTo: '', pathMatch: 'full' },
	{ path: '**', component: ErrorComponent },


	
];

@NgModule({
	imports: [RouterModule.forRoot(routes,{preloadingStrategy: PreloadAllModules })],
	exports: [RouterModule]
})
export class AppRoutingModule {}
