import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertService } from 'ngx-alerts';
import { Prediction } from 'src/app/models/location/googl-location.model';
import { vehicleType } from 'src/app/models/vehicle-type/vehicleType.model';
import { LocationService } from 'src/app/services/location/location.service';
import { PickAndDropService } from '../../../services/pick-and-drop.service';

@Component({
  selector: 'app-req-transfer-on-return',
  templateUrl: './req-transfer-on-return.component.html',
  styleUrls: ['./req-transfer-on-return.component.scss']
})
export class ReqTransferOnReturnComponent implements OnInit {

  public minDatePickUp: Date = new Date();
  public maxDatePickUp: Date;
  public minDateReturn: Date;
  public maxDateReturn: Date;
  public pickUpValidForm: any;
  public pickUpCities: Prediction[] = [];
  public dropOffCities: Prediction[] = [];
  public pickUpTime = [];
  public returnTime = [];
  public dropOffHubLocation: any;
  public returnHubLocation: any;

  @Input() public times: Array<string>;
  @Input() public vehiclesTypes: Array<vehicleType>;
  @Input() public parkOnlylocation: any;

  public transferPurpose = [
      "I am traveling via airport/port and need my vehicle to be transferred",
      "Vehicle transfer (just need my vehicle to be transferred)",
      "Vehicle for regular service at Agency/Workshop",
    //   "Vehicle for major service at Agency/Workshop",
    //   "Vehicle for regular maintenance at Agency/Workshop",
    //   "Vehicle for tire change at Agency/Worksop",
      "Vehicle for usual cleaning",
      "Vehicle for advance cleaning or polish",
      "Vehicle for accessories installation",
  ];
  //#endregion

  constructor(
      private alertService: AlertService,
      private _route: Router,
      private formBuilder: FormBuilder,
      private datepipe: DatePipe,
      private _locationService: LocationService,
      private _service: PickAndDropService
  ) {
    this.minDatePickUp = new Date((new Date()).getTime() + (1 * 24 * 60 * 60 * 1000));
    this.minDateReturn = new Date((new Date()).getTime() + (1 * 24 * 60 * 60 * 1000));
  }

  ngOnInit(): void {
      this.initFormElements();
      
  }

  filteredDropOffLocation(){
    this.dropOffHubLocation = this.parkOnlylocation.filter(x => x.id !== parseInt(this.pickUpValidForm.pickUpFromHub));
  }

  filterdReturnHubLocation(){
    this.returnHubLocation = this.parkOnlylocation.filter(x => x.id !== parseInt(this.pickUpValidForm.dropOffToHub));
  }

  checkTime(i) {
    return (i < 10) ? "0" + i : i;
  }

  startTime() {
    let currentDate = new Date((new Date()).getTime() + (1 * 24 * 60 * 60 * 1000)).getDate();
    if( new Date(this.pickUpValidForm.pickUpdate).getDate() === currentDate){
      var today = new Date(),
        h = this.checkTime(today.getHours()),
        m = this.checkTime(today.getMinutes())
        this.generateTime(h,m);
    } else {
      this.pickUpTime = this.times;
    }
  }

  generateTime(h,m){
    for (let i = h; i < 24; i++) {
      if (i < 10) {
        this.pickUpTime.push('0' + i + ':00');
        this.pickUpTime.push('0' + i + ':30');
      } else {
        this.pickUpTime.push(i + ':00');
        this.pickUpTime.push(i + ':30');
      }
    }
  }

  getReturnTime(){
    this.returnTime = [];
    if( new Date(this.pickUpValidForm.returnDate).getDate() === new Date(this.pickUpValidForm.pickUpdate).getDate()){
        this.returnTime = this.pickUpTime.filter(x => x > this.pickUpValidForm.pickUptime);
        // let pickUpTime = this.pickUpValidForm.pickUpTime;
        // pickUpTime = pickUpTime.split(':');
        // let h = this.checkTime(parseInt(pickUpTime[0]) + 2)
        // let m = this.checkTime(parseInt(pickUpTime[1]))
        // this.generateReturnTime(h,m)
    } else {
        this.returnTime = this.times;
    }
    
  }

  generateReturnTime(h,m){
    for (let i = h; i < 24; i++) {
      if (i < 10) {
        this.returnTime.push('0' + i + ':00');
        this.returnTime.push('0' + i + ':30');
      } else {
        this.returnTime.push(i + ':00');
        this.returnTime.push(i + ':30');
      }
    }
  }

  //#region Public Variable
  public onSubmitSearch() {
      if (this.validateForm()) {
          const vehicleType = this.vehiclesTypes.find((x) => x.id === Number(this.pickUpValidForm.vehicleTypeId));

          let isReturnSelected: boolean = false;
          if (this.pickUpValidForm.isReturnToFirstPickUp || this.pickUpValidForm.isReturnToAnotherLocaton) {
              isReturnSelected = true;
          }

          let returnLocation = null;
          if (this.pickUpValidForm.isReturnToFirstPickUp) {
              returnLocation = {
                  placeId: this.pickUpValidForm.pickUpFromHub? 
                  this.parkOnlylocation.find((x) =>x.id === parseInt(this.pickUpValidForm.pickUpFromHub)).placeID : this.pickUpValidForm.pickUpAddress.place_id,
                  locationId: this.pickUpValidForm.pickUpFromHub ? parseInt(this.pickUpValidForm.pickUpFromHub): 0,
                  name: this.pickUpValidForm.pickUpFromHub ? 
                  this.parkOnlylocation.find((x) =>x.id === parseInt(this.pickUpValidForm.pickUpFromHub)).locationName : this.pickUpValidForm.pickUpAddress.name,
                  date: this.pickUpValidForm.returnDate,
                  time: this.pickUpValidForm.returnTime,
              };
          }

          if (this.pickUpValidForm.isReturnToAnotherLocaton) {
              returnLocation = {
                  placeId: this.pickUpValidForm.returnHubLocation ? 
                  this.parkOnlylocation.find((x) => x.id === parseInt(this.pickUpValidForm.returnHubLocation)).placeID : this.pickUpValidForm.returnAddress.place_id,
                  locationId: this.pickUpValidForm.returnHubLocation ? parseInt(this.pickUpValidForm.returnHubLocation) : 0,
                  name: this.pickUpValidForm.returnHubLocation ? 
                  this.parkOnlylocation.find((x) => x.id === parseInt(this.pickUpValidForm.returnHubLocation)).locationName : this.pickUpValidForm.returnAddress.name,
                  date: this.pickUpValidForm.returnDate,
                  time: this.pickUpValidForm.returnTime,
              };
          }

          const search = {
              formType: 2,
              pickUp: {
                  placeId: this.pickUpValidForm.pickUpFromHub ? 
                  this.parkOnlylocation.find((x) =>x.id === parseInt(this.pickUpValidForm.pickUpFromHub)).placeID : this.pickUpValidForm.pickUpAddress.place_id,
                  locationId: this.pickUpValidForm.pickUpFromHub ? parseInt(this.pickUpValidForm.pickUpFromHub) : 0,
                  name: this.pickUpValidForm.pickUpFromHub ? 
                  this.parkOnlylocation.find((x) => x.id ===  parseInt(this.pickUpValidForm.pickUpFromHub)).locationName : this.pickUpValidForm.pickUpAddress.name,
                  date: this.pickUpValidForm.pickUpdate,
                  time: this.pickUpValidForm.pickUptime,
              },
              dropOff: {
                  placeId: this.pickUpValidForm.dropOffToHub ? 
                  this.parkOnlylocation.find((x) => x.id === parseInt(this.pickUpValidForm.dropOffToHub)).placeID : this.pickUpValidForm.dropOffAddress.place_id,
                  locationId: this.pickUpValidForm.dropOffToHub ? parseInt(this.pickUpValidForm.dropOffToHub) : 0,
                  name: this.pickUpValidForm.dropOffToHub ? 
                  this.parkOnlylocation.find((x) => x.id === parseInt(this.pickUpValidForm.dropOffToHub)).locationName : this.pickUpValidForm.dropOffAddress.name,
              },
              isReturnSameLocation: isReturnSelected,
              return: returnLocation,
              vehicleType: {
                  code: vehicleType.typeCode,
                  id: vehicleType.id,
              },
              transferPurpose: this.pickUpValidForm.transferPurpose,
          };

          this._service.searchData = search;
          this._route.navigate(["/pick-up-and-drop-off/search-results"]);
      }
  }

  public dateSelect(event: Date) {
      this.minDateReturn = event;
      this.startTime();
  }

  public onEnterLocation(event, type: number) {
      this.pickUpCities = [];
      this.dropOffCities = [];
      if (event?.target?.value) {
          const value = event.target.value;
          if (value?.length > 2) {
              this._locationService
                  .googleLocation(value)
                  .subscribe((result) => {
                      if (result?.data?.predictions?.length > 0) {
                          if (type === 1) {
                              this.pickUpCities = result.data.predictions;
                          } else if (type === 2) {
                              this.dropOffCities = result.data.predictions;
                          }
                      }
                  });
          }
      }
  }

  public onSelectItem(event: any, type: number) {
      if (event) {
          if (type === 1) {
              this.pickUpValidForm.pickUpAddress = event;
          } else if (type === 2) {
              this.pickUpValidForm.dropOffAddress = event;
          } else if (type === 3) {
              this.pickUpValidForm.returnAddress = event;
          }
      }
  }

  //#endregion

  //#region Private Methods

  private validateForm() {
      // Validating if pick up hub location is selected
    //   if (!this.pickUpValidForm.isEnableSearchPickUpLocation) {
    //       if (!this.pickUpValidForm.pickUpFromHub) {
    //           this.alertService.danger("Please select pick-up hub location.");
    //           return false;
    //       }
    //   }

      // Validating if pick up search locations is selected
      if (this.pickUpValidForm.isEnableSearchPickUpLocation) {
          if (!this.pickUpValidForm.pickUpAddress) {
              this.alertService.danger("Please enter pick-up city.");
              return false;
          }
      }

      // Validating if drop off hub location is selected
      if (!this.pickUpValidForm.isEnableSearchDropOffLocation) {
          if (!this.pickUpValidForm.dropOffToHub) {
              this.alertService.danger(
                  "Please select drop-off hub location."
              );
              return false;
          }
      }

      // Validating if drop off search location is selected
      if (this.pickUpValidForm.isEnableSearchDropOffLocation) {
          if (!this.pickUpValidForm.dropOffAddress) {
              this.alertService.danger("Please enter drop-off city.");
              return false;
          }
      }

      if (!this.pickUpValidForm.isReturnToFirstPickUp && !this.pickUpValidForm.isReturnToAnotherLocaton) {
        this.alertService.danger("Please select one option.");
        return false;
      }

      // Validating if return to same location is selected
      if (this.pickUpValidForm.isReturnToFirstPickUp) {
          if (!this.pickUpValidForm.returnDate) {
              this.alertService.danger("Please select return date.");
              return false;
          }

          if (!this.pickUpValidForm.returnTime) {
              this.alertService.danger("Please select return time.");
              return false;
          }
      }

      // Validating if return to another location is selected
      if (this.pickUpValidForm.isReturnToAnotherLocaton) {
          // Validating if return hub location is selected
        //   if (!this.pickUpValidForm.isEnableSearchReturnLocation) {
        //       if (!this.pickUpValidForm.returnHubLocation) {
        //           this.alertService.danger("Please select return hub location.");
        //           return false;
        //       }
        //   }

          // Validating if return search location is selected
          if (this.pickUpValidForm.isEnableSearchReturnLocation) {
              if (!this.pickUpValidForm.returnAddress) {
                  this.alertService.danger("Please enter return city.");
                  return false;
              }
          }

          if (!this.pickUpValidForm.returnDate) {
              this.alertService.danger("Please select return date.");
              return false;
          }

          if (!this.pickUpValidForm.returnTime) {
              this.alertService.danger("Please select return time.");
              return false;
          }
      }

      if (!this.pickUpValidForm.vehicleTypeId) {
          this.alertService.danger("Please select vehicle type.");
          return false;
      }

      if (!this.pickUpValidForm.pickUpdate) {
          this.alertService.danger("Please select pick-up date.");
          return false;
      }

      if (!this.pickUpValidForm.pickUptime) {
          this.alertService.danger("Please select pick-up time.");
          return false;
      }

      if (!this.pickUpValidForm.transferPurpose) {
          this.alertService.danger("Please select purpose of your transfer.");
          return false;
      }

      return true;
  }

  private initFormElements(): void {
      this.pickUpValidForm = {
          isEnableSearchPickUpLocation: false,
          isEnableSearchDropOffLocation: false,
          isReturnToFirstPickUp: false,
          isReturnToAnotherLocaton: false,
          isEnableSearchReturnLocation: false,
          pickUpFromHub: "",
          pickUpAddress: "",
          dropOffToHub: "",
          dropOffAddress: "",
          vehicleTypeId: "",
          pickUpdate: "",
          pickUptime: "",
          returnHubLocation: "",
          returnAddress: "",
          returnDate: "",
          returnTime: "",
          transferPurpose: "",
      };
  }
}

