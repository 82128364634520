import { AlertService } from 'ngx-alerts';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router, NavigationEnd, NavigationCancel } from '@angular/router';
import { filter } from 'rxjs/operators';
import { ContactUs } from 'src/app/models/contact-Us/contact-us.model';
import { ContactUsService } from 'src/app/services/contact-Us/contact-us.service';
import { Meta } from '@angular/platform-browser';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
  providers: [
    Location, {
        provide: LocationStrategy,
        useClass: PathLocationStrategy
    }
]
})
export class ContactComponent implements OnInit {

  location: any;
  routerSubscription: any;
  contactFrom: FormGroup

  constructor(private _router: Router
    , private formBuilder: FormBuilder
    , private contactUsService: ContactUsService
    , private alertService: AlertService
    , private meta: Meta
    , private titleService: Title
    ,private renderer: Renderer2) { }

  ngOnInit(): void {
    this.titleService.setTitle('Tourism Services in Oman, Muscat | Contact Us | Hekaya');

    // this.meta.addTag({ 
    //   name: '', 
    //   href: 'https://car-rental.hekaya.co/contact' 
    // });
    const canonicalLink = this.renderer.createElement('link');
    this.renderer.setAttribute(canonicalLink, 'rel', 'canonical');
    this.renderer.setAttribute(canonicalLink, 'href', 'https://car-rental.hekaya.co/contact');
    this.renderer.appendChild(document.head, canonicalLink);

    this.meta.addTag({ 
      name: 'description', 
      content: "Looking to explore Oman with our tourism services in Oman, Muscat? We offer a wide range of options to suit every traveler's needs. Contact us now."
    });

    this.meta.addTag({ 
      property: 'og:title', 
      content: 'Tourism Services in Oman, Muscat | Call now | Hekaya' 
    });

    this.meta.addTag({ 
      property: 'og:description', 
      content: "Looking to explore Oman with our tourism services in Oman, Muscat? We offer a wide range of options to suit every traveler's needs. Contact us now." 
    });

    this.meta.addTag({ 
      property: 'og:url', 
      content: 'https://car-rental.hekaya.co/contact'
    });

    this.meta.addTag({ 
      property: 'og:type', 
      content: 'website' 
    });

    this.meta.addTag({ 
      name: 'og:image', 
      content: 'assets/img/categories/contact-img.jpg'
    });

    this.initContactUsForm();
    this.recallJsFuntions();
  }

  private initContactUsForm(){
    this.contactFrom = this.formBuilder.group({
      name: ['',Validators.required],
      email: ['',Validators.required],
      phoneNumber: ['',Validators.required],
      message: ['',Validators.required]
    });
  }

  onSend(){

    if(this.contactFrom.invalid){
      if(!this.contactFrom.controls.name.value){
        this.alertService.warning('Please fill in your name');
      } else if(!this.contactFrom.controls.email.value){
        this.alertService.warning('Please fill in your email address');
      } else if(!this.contactFrom.controls.phoneNumber.value){
        this.alertService.warning('Please fill in your Contact Number');
      } else if(!this.contactFrom.controls.message.value){
        this.alertService.warning('Please provide your message');
      }
    } else{
      let details: ContactUs = {
        type: "Request",
        service: "ContactUs",
        content: {
            serviceType: "ContactUs",
            contact: {
                Name: this.contactFrom.controls.name.value,
                Email: this.contactFrom.controls.email.value,
                Phone: this.contactFrom.controls.phoneNumber.value,
                Message: this.contactFrom.controls.message.value
            }
        }
    }



    this.contactUsService.contactService(details).subscribe( response =>{

      if(response){
        this.alertService.success('Your message has been sent successfully');
      } else {
        this.alertService.warning('Something went wrong. Please try again later');
      }
    });

    }
    this.contactFrom.reset();

  }

  recallJsFuntions() {
    this.routerSubscription = this._router.events
        .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
        .subscribe(event => {
            $.getScript('../assets/js/main.js');
            this.location = this._router.url;
            if (!(event instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0);
        });
}

}
