import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RoutingConstants } from 'src/app/app.routing.contents';
import { GenericService } from 'src/app/services/generic-service/generic.service';
import { EquipmentsCategory } from '../../models/gear-rental/equipments/equipments.model';
import { GearBookingResponse } from '../../models/gear/search/bookings/gear-bookig-response.model';
import { GearBookingRequest } from '../../models/gear/search/bookings/gear-booking-request.model';
import { SearchRequest } from '../../models/gear/search/gear-search-request.model';
import { SearchResponse } from '../../models/gear/search/gear-search-response.model';

@Injectable({
  providedIn: 'root'
})
export class GearRentalServiceService {

  constructor(private genericService: GenericService) { }
  gearNeedDeliverySearchCriteria: any;

  getAll(): Observable<Array<EquipmentsCategory>> {
    const serviceUrl = RoutingConstants.gearRental.equipmentsCategory;
    return this.genericService.getService(serviceUrl, true);
  }

  search(request : SearchRequest) : Observable<SearchResponse>{
    const serviceUrl = RoutingConstants.gearRental.search;
    return this.genericService.postService(request, serviceUrl, true);
  }

  public setSelectedCar(selectedItem: any) {
    localStorage.setItem('SelectedItem', JSON.stringify(selectedItem));
    
  }

  public getSelectedCar(): any {
    const selectedItem = localStorage.getItem('SelectedItem');
    return (selectedItem) ? JSON.parse(selectedItem) : null;
  }

  gearBookingService(request: GearBookingRequest): Observable<GearBookingResponse> {
    const serviceUrl = RoutingConstants.gearRental.booking;
    return this.genericService.postService(request, serviceUrl, true);
  }

  getbookingRef(request: any) : Observable<any>{
    const serviceUrl = RoutingConstants.gearRental.bookingByRef;
    return this.genericService.postService(request, serviceUrl, true);
  }
}
