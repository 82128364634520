<div class="main-banner-content">
    <ul class="nav nav-tabs row no-gutters" id="myTab" role="tablist">
        <!-- Tab Head -->
        <!-- Rent a car-->
        <li class="nav-item first_tab" (click)="dailyFunction()">
            <a class="nav-link active" id="rentACar-tab" data-toggle="tab" href="#rentACar_TabContent" role="tab"
                aria-controls="home" aria-selected="true">
                <span class="icon_size">Rent A Car</span>

                <small>(Cars Available For Daily/Weekly)</small>
            </a>
        </li>
        <!-- Rent a car-->

        <!--Monthly Rentals-->
        <li class="nav-item last-tab" (click)="monthlyFunction()">
            <a class="nav-link" id="profile-tab" data-toggle="tab" href="#MonthlyRentals" role="tab"
                aria-controls="profile" aria-selected="false">
                <span class="icon_size">Monthly Rentals</span>

                <small>(Cars Available On Monthly Basis)</small>
            </a>
        </li>
        <!--Monthly Rentals-->
        <!--Lease Enquiry-->
        <li class="nav-item  last_tab">
            <a class="nav-link" id="contact-tab" data-toggle="tab" href="#lease_enquiry" role="tab"
                aria-controls="contact" aria-selected="false">
                <span class="icon_size">Lease Enquiry</span>

                <small>(Cars Available For More Than 2 Months)</small>
            </a>
        </li>
        <!--Lease Enquiry-->
        <!-- // Tab Head-->
    </ul>
    <div class="tab-content" id="myTabContent">
        <!-- Rent a Car -->
        <div class="tab-pane fade show active" id="rentACar_TabContent" role="tabpanel" aria-labelledby="home-tab">
            <div class="search_box">
                <ul class="nav mb-4 nav-pills sub-tabs mb-3" id="pills-tab" role="tablist">
                    <!-- Pick up Location-->
                    <li class="nav-item ">
                        <a class="nav-link nav-pills active" id="pills-home-tab" data-toggle="pill"
                            href="#rentACar_PickUpLocation_TabContent" role="tab" aria-controls="pills-home"
                            aria-selected="true">PICK-UP FROM LOCATION</a>
                    </li>
                    <!-- Pick up Location-->
                    <!-- Need Car Delivery-->
                    <li class="nav-item ">
                        <a class="nav-link nav-pills " id="pills-profile-tab" data-toggle="pill" href="#pills-profile"
                            role="tab" aria-controls="pills-profile" aria-selected="false">NEED A CAR DELIVERY</a>
                    </li>
                    <!-- Need Car Delivery-->
                </ul>
                <div class="tab-content" id="pills-tabContent">
                    <!-- Pick up Location-->
                    <div class="tab-pane fade show active" id="rentACar_PickUpLocation_TabContent" role="tabpanel"
                        aria-labelledby="pills-home-tab">
                        <div class="row">
                            <div class="col-md-6" style="text-align:left;"></div>
                            <div class="col-md-6"></div>
                        </div>
                        <form [formGroup]="carRentalPickUpLocationForm" (submit)="onSubmitCarRentalPickUpLocation()">
                                <div class="row align-items-center">
                                    <div class="col-md-6">
                                      <div class="form-group select">
                                        <select class="form-control" name="Pickup Location" id="rent_a_Car_Pickup_Location"
                                          (focus)="showRentACarPickUpFromLocation=true" placeholder="Pick up Location..." formControlName="pickUpLocation" (change)="onPickupLocationChange($event)" >
                                          <option value="" [hidden]="carRentalPickUpLocationForm.controls.pickUpLocation.value">Select Pickup Location
                                          </option>
                                          <option *ngFor="let location of locations" value="{{location.id}}">{{location.locationName}}
                                            ({{location.locationCode}})
                                          </option>
                                        </select>
                                      </div>
                                    </div>
                                  
                                <div class="col-md-6 text-left text-md-right">
                                    <div class="form-check pb-3 pb-md-0">
                                        <input type="checkbox" class="form-check-input returncar"
                                            id="rentACar_ReturnLocation"
                                            formControlName="isEnableReturnDifferentLocation"
                                            (change)="onChangeReturnDifferentLocation()">
                                        <label class="form-check-label text-brown" name="returncar"
                                            for="rentACar_ReturnLocation">Return car to different
                                            location </label>
                                    </div>

                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12" [hidden]="!f.isEnableReturnDifferentLocation.value">
                                    <div class="form-group select">
                                        <select name="Delivery Location" class="form-control"
                                            placeholder="Return Location..." formControlName="returnLocation">
                                            <option value="">Select Return Location</option>
                                            <ng-container *ngFor="let location of locations" >
                                                <option *ngIf="selectedLocation!=location.id"  value="{{location.id}}" style="white-space: nowrap;">
                                                {{location.locationName.trim()}} ({{location.locationCode}})
                                            </option></ng-container>
                                        </select>
                                    </div>
                                </div>
                                <div [hidden]="!showRentACarPickUpFromLocation" class="more_box col-md-12">
                                    <div class="w-100 more_control">
                                        <div class="row">
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <input type="text" class="form-control" placeholder="Pickup date"
                                                        [minDate]="minDateDelivery" [maxDate]="maxDatePickUp"
                                                        #dp="bsDatepicker" bsDatepicker formControlName="pickUpDate"
                                                        [readonly]="true" placement="top" (ngModelChange)=dateSelect($event)
                                                    
>
                                                </div>
                                            </div>
                                            <div class="col-md-2">                                              
                                                <div class="form-group select">
                                                   
                                                        <select class="form-control" formControlName="pickUpTime"
                                                        >
                                                        <option value="" [hidden]="carRentalPickUpLocationForm.controls.pickUpTime.value">Time
                                                        </option>
                                                        <option *ngFor="let time of times" value="{{time}}">
                                                            {{time}}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">                                                    
                                                    <input type="text" class="form-control" placeholder="Return Date"
                                                        [minDate]="minDateCollection" [maxDate]="maxDateReturn"
                                                        #dp="bsDatepicker" bsDatepicker formControlName="retureDate"
                                                        [readonly]="true"  placement="top">
                                                </div>
                                            </div>
                                            <div class="col-md-2">                                               
                                                <div class="form-group select">
                                                    <select class="form-control" formControlName="retureTime">
                                                        <option value="" [hidden]="carRentalPickUpLocationForm.controls.retureTime.value">Time
                                                        </option>
                                                        
                                                            <option *ngFor="let time of times"
                                                            value="{{time}}">
                                                            {{time}}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="row align-items-center more_control align0items-center">
                                                <div class="col-md-6 driver_check">
                                                    <div class="form-check">
                                                        <input id="driver18Plus" type="checkbox"
                                                            class="form-check-input" id="exampleCheck1"
                                                            formControlName="isAgree18Plus">
                                                        <label for="driver18Plus" class="form-check-label"
                                                            name="returncar" id="returncar" for="exampleCheck1">Driver
                                                            age
                                                            18+</label>
                                                    </div>
                                                </div>
                                                <div class="col-md-6 text-right pr-0">
                                                    <div class="form-check">
                                                        <input type="submit" value="Search" class="btn btn-warning">

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="link_but">
                                    <a (click)="showRentACarPickUpFromLocation=!showRentACarPickUpFromLocation"
                                        style="cursor: pointer;">
                                        {{showRentACarPickUpFromLocation?'Hide':'Show'}} </a>
                                </div>

                            </div>
                        </form>
                    </div>
                    <!-- Pick up Location-->
                    <!--Need Car Delivery-->
                    <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                        <app-need-car-delivery [times]="times"></app-need-car-delivery>
                    </div>
                </div>
            </div>
        </div>
        <!-- Monthly rentals -->

        <div class="tab-pane fade" id="MonthlyRentals" role="tabpanel" aria-labelledby="home-tab">
            <div class="search_box">
                <ul class="nav mb-4 nav-pills sub-tabs mb-3" id="pills-tab" role="tablist">
                    <!-- Pick up Location-->
                    <li class="nav-item ">
                        <a class="nav-link nav-pills active" id="pills-home-tab" data-toggle="pill"
                            href="#rentACar_PickUpLocation_TabContent" role="tab" aria-controls="pills-home"
                            aria-selected="true">PICK-UP FROM LOCATION
                            <small>(Monthly Rentals)</small></a>
                    </li>
                    <!-- Pick up Location-->
                    <!-- Need Car Delivery-->
                    <li class="nav-item d-none">
                        <a class="nav-link nav-pills " id="pills-profile-tab"  data-toggle="pill" href="#pills-profile"
                            role="tab" aria-controls="pills-profile" aria-selected="false">NEED A CAR DELIVERY</a>
                    </li>
                    <!-- Need Car Delivery-->
                </ul>
                <div class="tab-content" id="pills-tabContent">
                    <!-- Pick up Location-->
                    <div class="tab-pane fade show active" id="rentACar_PickUpLocation_TabContent" role="tabpanel"
                        aria-labelledby="pills-home-tab">
                        <div class="row">
                            <div class="col-md-6" style="text-align:left;"></div>
                            <div class="col-md-6"></div>

                        </div>
                        <form [formGroup]="carRentalPickUpLocationForm" (submit)="onSubmitCarRentalPickUpLocation()">
                            <div class="row align-items-center ">

                                <div class="col-md-6">
                                    <div class="form-group select">
                                        <select class="form-control" name="Pickup Location"
                                            id="rent_a_Car_Pickup_Location"
                                            (focus)="showRentACarPickUpFromLocation=true"
                                            placeholder="Pick up Location..." formControlName="pickUpLocation">
                                            <option value="">Select Pickup Location</option>
                                            <option *ngFor="let location of locations" value="{{location.id}}">
                                                {{location.locationName}} ({{location.locationCode}})
                                            </option>
                                        </select>

                                    </div>


                                </div>
                                <div class="col-md-6 text-left text-md-right">
                                    <div class="form-check pb-3 pb-md-0">
                                        <input type="checkbox" class="form-check-input returncar"
                                            id="rentACar_ReturnLocation"
                                            formControlName="isEnableReturnDifferentLocation"
                                            (change)="onChangeReturnDifferentLocation()">
                                        <label class="form-check-label text-brown" name="returncar"
                                            for="rentACar_ReturnLocation">Return car to different
                                            location </label>
                                    </div>

                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12" [hidden]="!f.isEnableReturnDifferentLocation.value">
                                    <div class="form-group select">
                                        <select name="Delivery Location" class="form-control"
                                            placeholder="Return Location..." formControlName="returnLocation">
                                            <option value="">Select Return Location</option>
                                            <option *ngFor="let location of locations" value="{{location.id}}">
                                                {{location.locationName}} ({{location.locationCode}})
                                            </option>
                                        </select>
                                    </div>

                                </div>
                                <div [hidden]="!showRentACarPickUpFromLocation" class="col-md-12 more_box">
                                    <div class="w-100 more_control">
                                        <div class="row">
                                            <div class="col-md-4">
                                                <div class="form-group" >
                                                    <input type="text" class="form-control" placeholder="Pickup date"
                                                        [minDate]="monthDateDelivery" [maxDate]="maxDate"
                                                        #dp="bsDatepicker" bsDatepicker formControlName="pickUpDate"
                                                        [readonly]="true" placement="top" (ngModelChange)=setReturnDate($event)>
                                                </div>
                                            </div>
                                            <div class="col-md-2">                                           
                                                <div class="form-group select">
                                                    <select class="form-control" formControlName="pickUpTime"
                                                        (change)="getcarRentalFilterdTime()">
                                                        <option value="">Time</option>
                                                        <option *ngFor="let time of times" value="{{time}}">
                                                            {{time}}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">                                                    
                                                    <input type="text" class="form-control" placeholder="Return Date"
                                                        [minDate]="monthDateCollection" [maxDate]="maxDate"
                                                        #dp="bsDatepicker" placement="top" bsDatepicker formControlName="retureDate"
                                                        [readonly]="true">
                                                </div>
                                            </div>
                                            <div class="col-md-2">                                            
                                                <div class="form-group select">
                                                    <select class="form-control" formControlName="retureTime">
                                                        <option value="">Time</option>
                                                        <option *ngFor="let time of times"
                                                            value="{{time}}">
                                                            {{time}}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="row align-items-center more_control align0items-center">
                                                <div class="col-md-6 driver_check">
                                                    <div class="form-check">
                                                        <input id="driver18Plus" type="checkbox"
                                                            class="form-check-input" id="exampleCheck1"
                                                            formControlName="isAgree18Plus">
                                                        <label for="driver18Plus" class="form-check-label"
                                                            name="returncar" id="returncar" for="exampleCheck1">Driver
                                                            age
                                                            18+</label>
                                                    </div>
                                                </div>
                                                <div class="col-md-6 text-right pr-0">
                                                    <div class="form-check">
                                                        <input type="submit" class="btn btn-warning">

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="link_but">
                                    <a (click)="showRentACarPickUpFromLocation=!showRentACarPickUpFromLocation;monthlyFunction()"
                                        style="cursor: pointer;">
                                        {{showRentACarPickUpFromLocation?'Hide':'Show'}} </a>
                                </div>

                            </div>
                        </form>
                    </div>
                    <!-- Pick up Location-->
                    <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                        <div class="row">
                          
                            <div class="col-md-6">
                                <div class="form-group select">
                                    <select class="form-control">
                                        <option selected> SELECT DELIVERY CITY</option>
                                        <option>City 1</option>
                                        <option>City 1</option>
                                        <option>City 1</option>
                                        <option>City 1</option>
                                    </select>
                                </div>
                            </div>
                           
                            <div class="col-md-6">
                                <div class="form-group">

                                    <input data-toggle="collapse" href="#collapseExample" type="text"
                                        class="form-control " placeholder="DELIVERY ADDRESS">
                                </div>

                            </div>
                        </div>
                        <div class="row row_mar">
                            <div class="col-md-12 left_text">
                                <div class="form-check">
                                    <input type="checkbox" class="form-check-input col_adress  col_add " id="col_add">
                                    <label class="form-check-label" name="col_adress" id="col_adress"
                                        for="exampleCheck1">CHANGE COLLECTION ADDRESS</label>
                                </div>
                                <div class="c hide_control" style="padding-top:10px">
                                    <div class="row">                                    
                                        <div class="col-md-6">
                                            <div class="form-group select">
                                                <select class="form-control">
                                                    <option selected> COLLECTION CITY</option>
                                                    <option>City 1</option>
                                                    <option>City 1</option>
                                                    <option>City 1</option>
                                                    <option>City 1</option>
                                                </select>
                                            </div>
                                        </div>                                       
                                        <div class="col-md-6">
                                            <div class="form-group">

                                                <input type="text" class="form-control"
                                                    placeholder="COLLECTION ADDRESS">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row" style="padding-top:10px">
                                    </div>
                                </div>
                            </div>
                            <div class="collapse more_box" id="collapseExample">
                                <div class="card card-body more_control">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <div class="form-group">                                              
                                                <input type="text" class="form-control" placeholder="PICK UP DATE"
                                                    onfocus="(this.type='date')">
                                            </div>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-group select">                                                
                                                <select class="list form-control">
                                                    <option>00:30</option>
                                                    <option>01:00</option>
                                                    <option>01:30</option>
                                                    <option>02:00</option>
                                                    <option>02:30</option>
                                                    <option>03:00</option>
                                                    <option>03:30</option>
                                                    <option>04:00</option>
                                                    <option>04:30</option>
                                                    <option>05:00</option>
                                                    <option>05:30</option>
                                                    <option>06:00</option>
                                                    <option>06:30</option>
                                                    <option>07:00</option>
                                                    <option>07:30</option>
                                                    <option>08:00</option>

                                                    <option>08:30</option>
                                                    <option>09:00</option>
                                                    <option>09:30</option>
                                                    <option>10:00</option>
                                                    <option>10:30</option>
                                                    <option>11:00</option>
                                                    <option>11:30</option>
                                                    <option>12:00</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <input type="text" class="form-control" placeholder="RETURN DATE"
                                                    onfocus="(this.type='date')">
                                            </div>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-group select">
                                                <select class="list form-control">
                                                    <option>00:30</option>
                                                    <option>01:00</option>
                                                    <option>01:30</option>
                                                    <option>02:00</option>
                                                    <option>02:30</option>
                                                    <option>03:00</option>
                                                    <option>03:30</option>
                                                    <option>04:00</option>
                                                    <option>04:30</option>
                                                    <option>05:00</option>
                                                    <option>05:30</option>
                                                    <option>06:00</option>
                                                    <option>06:30</option>
                                                    <option>07:00</option>
                                                    <option>07:30</option>
                                                    <option>08:00</option>

                                                    <option>08:30</option>
                                                    <option>09:00</option>
                                                    <option>09:30</option>
                                                    <option>10:00</option>
                                                    <option>10:30</option>
                                                    <option>11:00</option>
                                                    <option>11:30</option>
                                                    <option>12:00</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="row align-items-center more_control align-items-center">
                                            <div class="col-md-6 driver_check">
                                                <div class="form-check">
                                                    <input type="checkbox" class="form-check-input" id="exampleCheck1">
                                                    <label class="form-check-label" name="returncar" id="returncar"
                                                        for="exampleCheck1">Driver age
                                                        18+</label>
                                                </div>
                                            </div>
                                            <div class="col-md-6  text-right pr-0">
                                                <div class="form-check">
                                                    <button routerLink="/fleet" class="btn btn-warning">SEARCH</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div class="link_but">

                                <a class="more_but" data-toggle="collapse" href="#collapseExample" role="button"
                                    aria-expanded="false" aria-controls="collapseExample">
                                    Show
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Monthly rentals ending -->

        <!-- Lease enquiry -->
        <div class="tab-pane fade" id="lease_enquiry" role="tabpanel" aria-labelledby="home-tab">
            <div class="search_box">
                <ul class="nav mb-4 nav-pills sub-tabs mb-3" role="tablist">
                    <li class="nav-item ">
                        <a class="nav-link nav-pills active" data-toggle="pill" role="tab" aria-controls="pills-home"
                            aria-selected="true">Lease Enquiry</a>
                    </li>
                    <li class="nav-item d-none">
                        <a class="nav-link nav-pills " data-toggle="pill" role="tab" aria-controls="pills-profile"
                            aria-selected="false"></a>
                    </li>
                </ul>
                <div class="tab-content">
                    <div class="tab-pane fade show active" role="tabpanel" aria-labelledby="pills-home-tab">
                        <div class="row">
                            <div class="col-md-6" style="text-align:left;"></div>
                            <div class="col-md-6"></div>
                        </div>
                        <app-lease-enquiry></app-lease-enquiry>
                    </div>
                </div>
            </div>
        </div>
        <!-- Lease enquiry -->
        <!--// Rent a Car -->
    </div>
</div>