<app-self-drive-nav *ngIf="!(location == '/error' || location == '/coming-soon' 
    || location == '/sign-in' 
    || location == '/sign-up'
    ||location == '/forgot-password')">
</app-self-drive-nav>

<div class="page-title-area page-title-bg3">
    <div class="container">
        <div class="page-title-content">
            <h2>Hekayat Guidance</h2>
        </div>
    </div>
</div>


<div class="container-fluid mt-5">
    <div class="row row-margin-top justify-content-center">
        <div class="col-md-8 col-lg-8 col-sm-12 ">
            <h2 class="text-center">Self-Driving <span class="hekayat-color">Guidance at Oman</span></h2>
            <p class="text-center">Self-driving in Oman is safe and interesting. Please consider below main
                points for your peace of mind</p>
        </div>
    </div>
</div>

<div class="container-fluid">
    <div class="row row-top-margin">
        <div class="col-md-12 col-lg-5 col-sm-12 text-center">
            <img src="../../../../assets/img/self-drive/oman-guidence/MainTrafficRulesinOman.jpg" class="img-fluid img-radius" alt="Image Canot load" loading="lazy"/>
        </div>
        <div class="col-md-12 col-lg-7 col-sm-12 right-padding">
            <h2 class="hekaya-title">Main Traffic <span class="hekayat-color">Rules in Oman</span></h2>

            <ul class="features-list box-list text-justify">
                <li>Driving is on the right side of the road.</li>
                <li>Speed limits in Oman are enforced. There are many radars plus cars start beeping
                    over 120 km/h. Speed limits are clearly indicated at all roads. Most main roads between cities is
                    120 Km/h. However, always pay attention to the signages.
                </li>
                <li>Be respectful in any case, to not make any gesture even if the other driver is behaving dangerously.</li>
                <li>Zero-tolerance policy for driving under the influence of alcohol/drugs.</li>
                <li>Do not use your cell phone while driving- on the-spot fines apply.</li>
                <li>In case of accident, leave your car where it is and call the Police on 9999.</li>
            </ul>

        </div>
    </div>
</div>

<div class="container-fluid">
    <div class="row row-top-margin row-bg row-4-padding">
        <div class="col-md-12 col-lg-7 col-sm-12 ">
            <h2 class="hekaya-title">Road and Traffic <span class="hekayat-color">Signs in Oman</span></h2>

            <ul class="features-list box-list text-justify">
                <li>Most cities are connected with lighted highways.</li>
                <li>Streets are paved and in reasonably good conditions in cities.</li>
                <li>Points of interest and towns are clearly indicated on road signs. Cities are on blue signs while points of interests are on brown signs.</li>
                <li>The Traffic signs use Latin alphabet, so you can easily find your way.</li>
                <li>Speed is measured in km/h, and signs are according to the ISO (European) system.</li>
                <li>The numbers are in dual language with Arabic numbers at the top within the same sign.</li>
                <li>Speed reducers (Bumps) are highly use in Oman to control speed in towns and main roads, before crossing, turns, wadi riverbeds etc.
                    They are bright yellow. Don't forget to slow down.
                </li>
            </ul>

        </div>
        <div class="col-md-12 col-lg-5 col-sm-12 text-center">
            <img src="../../../../assets/img/self-drive/oman-guidence/RoadandTrafficSignsinOman.jpg" class="img-fluid img-radius" alt="Image Canot load" loading="lazy" />
        </div>
    </div>
</div>


<div class="container-fluid">
    <div class="row row-top-margin">
        <div class="col-md-12 col-sm-12 text-center">
            <img src="../../../../assets/img/self-drive/oman-guidence/GuidanceCentreBanner.jpg" class="img-fluid img-radius" alt="Image Canot load" loading="lazy" />
        </div>
    </div>
</div>

<div class="container-fluid">
    <div class="row row-top-margin row-bg row-4-padding">
        <div class="col-md-12 col-lg-7 col-sm-12">
            <h2 class="hekaya-title">Stopped by the Royal <br><span class="hekayat-color">Oman Police</span></h2>
            

            <ul class="features-list box-list text-justify">
                <li>There are a frequent checkpoints by the Royal Omani Police. Just present your passport, driver license and insurance documents. Be respectfull and
                    all is fine.
                </li>
                <li>They may check what you have in the car as it is not allowed to carry alcohol without a specific license.</li>
            </ul>

           

        </div>
        <div class="col-md-12 col-lg-5 col-sm-12 text-center">
            <img src="../../../../assets/img/self-drive/oman-guidence/StoppedBYRoyal.jpg" class="img-fluid img-radius" alt="Image Canot load" loading="lazy" />
        </div>
    </div>
</div>


<div class="container-fluid">
    <div class="row row-5-padding">
        <div class="col-md-5 col-lg-5 col-sm-12">
            <img src="../../../../assets/img/self-drive/oman-guidence/PotentialRisksatMainRoad.jpg" class="img-fluid img-radius" alt="Image Canot load" loading="lazy" />
        </div>
        <div class="col-md-7 col-lg-7 col-sm-12">
            <h2 class="hekaya-title"><img src="../../../../assets/img/self-drive/caution.png" alt="" style="max-width: 50px;max-height: 50px;" loading="lazy">Potential Risks at <span class="hekayat-color">Main Road</span></h2>
            <div class="row">
                <div class="col-md-6 col-lg-6 col-sm-12">
                    <h3 class="sub-head">Animals</h3>
                    <p class="points-text p-margin">Wild animals and other pets such as donkeys, goats and camels
                        are wildly can be seen at main roads area, between and within cities and towns, and at some
                        habitats and sometimes they are habitats and sometimes they are crossing unexpectedly. Pay
                        full attention and always slow down till passing through.</p>
                </div>
                <div class="col-md-6 col-lg-6 col-sm-12">
                    <h3 class="sub-head">Wadi Crossing</h3>
                    <p class="points-text p-margin">There are many wadies in Oman crossing main roads between cities
                        or within the cities and towns. Most of the time there is no problem, but in case of heavy
                        rain, use the red pole (after the red sign) to evaluate the depth of water and never cross
                        when level is reaching the red zone of the pole.</p>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 col-sm-12 col-lg-12 mt-4">
                    <h3 class="sub-head">Long Straight Roads</h3>
                    <p class="points-text p-margin">Driving at long straight roads between cities has high potential to make loose focus or
                        sleepiness. We are highly recommended to make sure planning frequent stop, have plenty of Water and food.</p>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="container-fluid">
    <div class="row top-row-margin">
        <div class="col-md-12 col-lg-12 col-sm-12">
            <header class="hero">
                <div class="center-content">
                    <h2 class="hekaya-title2">Road and Traffic Sign in Oman<span class="hekayat-color"></span></h2> <br>
                    <p class="text-inside">Hekayat Route Plans provides the most popular tourism routes in the Sultanate with its most attractive sites.  The suitable vehicle (4WD or Non 4WD), habitat expected and potential risks for each route is provided for your convenience.</p>
                    <p class="text-inside">Generally, 4WD Vehicle category is more convenient option to experience more habitats and have more route options and things to see. Hence, go for 4WD, if welling to see and experience more . You will need 4WD vehicle if you want to do the below:</p>
                    
                </div>

            </header>
        </div>
    </div>
</div>

<div class="container-fluid">
    <div class="row row-top-margin3">
        <div class="col-md-7 col-lg-7 col-sm-12">
            <div class="row">
                <div class="col-md-1 col-lg-1 col-sm-12"></div>
                <div class="col-md-5 col-lg-5 col-sm-12">
                    <ul class="features-list box-list text-justify">
                        <li>To go long off road or advance off-roading.</li>
                        <li>To get to the some paved mountain such as Al Jabal Al Akhdar. 4WD is mandatory as road is too steep.</li>
                        <li>Jebel Shams (strongly recommended to have a 4WD but not mandatory).</li>
                    </ul>
                </div>
                <div class="col-md-6 col-lg-6 col-sm-12">
                    <ul class="features-list box-list text-justify">
                        <li>To get inside the dunes of Wahiba Sands and other dunes.</li>
                        <li>Drive through Wadies such as Al Khoud Wadi, Wadi Nakhr, and Wadi Al Arabyieen and others which is not paved.</li>
                        <li>Drive through beaches and wetlands.</li>
                        <li>Drive at not paved mountain tracks such as the road connect Rustaq with Al Hamra
                            passing though Bilad Sayt Village and Salmah Plateau.
                        </li>
                    </ul>
                   
    
                </div>
            </div>
            <div class="row">
                <div class="col-md-1 col-lg-1 col-sm-12"></div>
                <div class="col-md-11 col-sm-12 col-lg-11 mt-4">
                    <p class="points-text">
                        Using 4WD Vehicle at different habitats may require different minor skills and techniques. Ensure you are aware and understand safe driving of 4WD vehicles and how safely Low/High Gear Transition is used and when it is required to use it.
                    </p>
                    <p class="points-text">
                        We recommend to read more and to connect us if need assistance. Below is Classic rules of off-road driving,
                    </p>
                    <div class="row">
                        <div class="col-md-6">
                            <ul class="features-list box-list text-justify">
                                <li>Don’t go unless you know how to drive 4WD.</li>
                                <li>Check your vehicle before starting off-roading.</li>
                            </ul>
                           
                        </div>

                        <div class="col-md-6">
                            <ul class="features-list box-list text-justify">
                                <li>Adapt the tyre pressure (at the desert entrance there are many shops where you can get help).</li>
                                <li>Always bring food and water with you.</li>
                            </ul>
                          
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-5 col-lg-5 col-sm-12">
            <img src="../../../../assets/img/self-drive/oman-guidence/RoadandTrafficSigninOman22.jpg" class="img-fluid img-radius" alt="Image Canot load" style="height: 100%;" loading="lazy"/>
        </div>
    </div>
</div>

<div class="container mt-5">
    <div class="row">
        <div class="col-lg-12 d-flex justify-content-center">
            <p>Now, you are ready for self-drive experince in Oman but you will need to know more about <span class="hekayat-color">self-camp</span> guidance in Oman</p>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12 d-flex justify-content-center">
            <button class="btn text-center explore-button btn-md" routerLink="/Hekayat-Guidence-Two">Read More</button>
        </div>
    </div>
</div>

<div class="container-fluid last-banner">
    <div class="row">
        <div class="col-md-12 col-lg-12 col-sm-12">
            <img src="../../../../assets/img/self-drive/oman-guidence/LastBanner.jpg" class="img-fluid w-100" alt="Image Canot load" loading="lazy" />
        </div>
    </div>
</div> 

<app-self-drive-footer *ngIf="!(location == '/error' || location == '/coming-soon' || location == '/sign-in'
    || location == '/sign-up'
    ||location == '/forgot-password')">
    </app-self-drive-footer>