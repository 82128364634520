<app-self-drive-nav *ngIf="!(location == '/error' || location == '/coming-soon'
    || location == '/sign-in'
    || location == '/sign-up'
    ||location == '/forgot-password')">
</app-self-drive-nav>

<div class="page-title-area page-title-bg3">
    <div class="hekaya-container">
        <div class="page-title-content">
            <h2>Hekayat Route Plan</h2>
            <!-- <h4></h4> -->
        </div>
    </div>
</div>

<div class="container mt-5">
    <div class="row row-margin-top justify-content-center">

        <div class="col-md-12 col-lg-12 col-sm-12">
            <h2 class="hekaya-title text-center">Hekayat <span class="hekayat-color">Rout Plans</span></h2>
            <p class="text-center">At Hekayat, we have chosen the most common routes which
                shall maximize your experience. Choose you route plan from the below or combine two or more routes
                together.
                Ensure to understand how to navigate and understand the requirements for your safety and convenience.
            </p>
            <br>
        </div>
    </div>
</div>

<div class="container mt-1">
    <div class="row row-margin-top justify-content-center">
        <div class="col-md-12 col-lg-12 col-sm-12">
            <h2 class="hekaya-title text-center">Rout Plans <span class="hekayat-color">Navigation Key</span></h2>
        </div>
    </div>
    <div class="row row-margin-top justify-content-center mt-1">
        <div class="col-md-12 col-lg-3 col-sm-12 text-center">
            <img src="../../../../assets/img/self-drive/hekayat-route/calander.svg" class="w-50" alt="" loading="lazy">
        </div>
        <div class="col-md-12 col-lg-3 col-sm-12 text-center">
            <img src="../../../../assets/img/self-drive/hekayat-route/CAR.svg" class="w-50" alt="" loading="lazy">
        </div>
        <div class="col-md-12 col-lg-3 col-sm-12 text-center">
            <img src="../../../../assets/img/self-drive/hekayat-route/DRIVING.svg" class="w-50" alt="" loading="lazy">
        </div>
        <div class="col-md-12 col-lg-3 col-sm-12 text-center">
            <img src="../../../../assets/img/self-drive/hekayat-route/HABITAT.svg" class="w-50" alt="" loading="lazy">
        </div>
    </div>
</div>

<div class="container mt-5">
    <div class="row row-margin-top justify-content-center">
        <div class="col-md-12 col-lg-12 col-sm-12">
            <h2 class="hekaya-title text-center">Main <span class="hekayat-color">Route</span></h2>
        </div>
    </div>
    <div class="row row-margin-top justify-content-center">
        <div class="col-md-12 col-lg-4 col-sm-12 text-center">
            <img src="../../../../assets/img/self-drive/hekayat-route/marker1.png" class="w-16" alt="" loading="lazy">
        </div>
        <div class="col-md-12 col-lg-8 col-sm-12 text-justify">
            <p class="pt-3">This is your main route and main cities to visit during this
                route plan.</p>
        </div>
    </div>
</div>

<div class="container mt-5">
    <div class="row row-margin-top justify-content-center">
        <div class="col-md-12 col-lg-12 col-sm-12">
            <h2 class="hekaya-title text-center">Optional <span class="hekayat-color">Route</span></h2>
        </div>
    </div>
    <div class="row row-margin-top justify-content-center">
        <div class="col-md-12 col-lg-4 col-sm-12 text-center">
            <img src="../../../../assets/img/self-drive/hekayat-route/marker2.png" class="mr-3 " alt="" loading="lazy">
            <img src="../../../../assets/img/self-drive/hekayat-route/marker3.png" alt="" loading="lazy">
        </div>
        <div class="col-md-12 col-lg-8 col-sm-12 text-justify">
            <p class="pt-3">This is your optional cities/towns you may visit during
                this route plan.</p>
        </div>
    </div>
</div>







<div class="container mt-5 mb-5">
    <div class="row row_top mt-5">
        <div class="col-lg-12">
            <div class="row">
               
                <div class="col-lg-4">
                    <div class="dropdown" data-control="checkbox-dropdown">
                        <label class="dropdown-label">Driving Category</label>

                        <div class="dropdown-list" style="z-index: 1;">
                            <label class="dropdown-option" *ngFor="let type of drivingType">
                                <input type="checkbox" [(ngModel)]="type.isSelected" (change)="onFilter()">
                                {{type.name}}
                              </label>
                        </div>
                      </div>
                </div>
                <div class="col-lg-4">
                    <div class="dropdown" data-control="checkbox-dropdown">
                        <label class="dropdown-label">Vehicle Category</label>

                        <div class="dropdown-list" style="z-index: 1;">
                          <label class="dropdown-option" *ngFor="let type of vehicleTypes">
                            <input type="checkbox" [(ngModel)]="type.isSelected" (change)="onFilter()">
                            {{type.name}}
                          </label>
                        </div>
                      </div>
                </div>

                <div class="col-lg-4">
                    <div class="dropdown" data-control="checkbox-dropdown">
                        <label class="dropdown-label">Habitat Category</label>

                        <div class="dropdown-list" style="z-index: 1;">
                            <label class="dropdown-option" *ngFor="let type of habitatType">
                                <input type="checkbox" [(ngModel)]="type.isSelected" (change)="onFilter()">
                                {{type.name}}
                              </label>
                        </div>
                      </div>
                </div>
            </div>




        </div>
    </div>

    <div class="row mt-5" *ngFor="let map of filteredMap">
        <img class="d-block w-100" [src]="map.image" alt="">
    </div>
</div>

<app-self-drive-footer *ngIf="!(location == '/error' || location == '/coming-soon' || location == '/sign-in'
    || location == '/sign-up'
    ||location == '/forgot-password')">
</app-self-drive-footer>
