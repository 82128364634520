<app-header *ngIf="!(location == '/error' || location == '/coming-soon'
    || location == '/sign-in'
    || location == '/sign-up'
    ||location == '/forgot-password')">
</app-header>


<section class="about-area pt-4">
    <div class="container new_container">
        <div class="about-inner-area">
            <div class="row">
                <div class="col-12">
                      
                      <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                          <li class="breadcrumb-item"><a href="#">Home</a></li>
                          <li class="breadcrumb-item active" aria-current="page">Privacy Policy </li>
                        </ol>
                      </nav>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-8 pr-lg-5">
                    <div class="w-100 mb-5">
                        <h4 class="suhd text-left">Legal Terms</h4>
                        <h2 class="hdfont text-left">Privacy Policy</h2>
                    </div>

                    <div class="about-text text-justify">
                        <h3 class="mt-0">Table of Content:</h3>
        
                        <ol>
                            <li><a (click)="scrollToSection('Collect')">Collect customer information</a></li>
                            <li><a (click)="scrollToSection('Other')">Protecting private information</a></li>
                            <li><a (click)="scrollToSection('Misuse')">Misuse or unauthorized use of personal information</a></li>
                            <li><a (click)="scrollToSection('Change')">Change, cancel, no show, and refund</a></li>
                            <li><a (click)="scrollToSection('reservations')">Re-make reservations and upgrade requests</a></li>
                            <li><a (click)="scrollToSection('Governing')">Governing Law Policy and Dispute Resolution</a></li>
                        </ol>
                    </div>

                    <div class="about-text" id="Collect">
                        <h3>Collect customer information</h3>
                        <ul>
                            <li>
                                <p>Through dealing with Hekaya website, the customer's private information
                                    is obtained and may be used in statistics operations and studying customer
                                    preferences to improve the different tourism services and forecasts of
                                    market requirements and customer preferences and to improve the travel
                                    experience through the Hekaya website.
                                </p>
                            </li>
                            <li>
                                <p>As sometimes, according to the nature of the service being imported or
                                    according to the supplier’s policies, suppliers are requested to share
                                    customer data or copies of identity documents for purposes related to
                                    providing the service and proving the customer’s identity.
                                </p>
                            </li>
                        </ul>
    
                    </div>
                    <div class="about-text" id="Other">
                        <h3>Protecting private information</h3>
                                <p>
                                    The Hekaya website ensures that every customer’s personal information is
                                    protected and that it will not be used or accessed without the customer’s
                                    consent.
                                </p>
                    </div>
                    <div class="about-text" id="Misuse">
                        <h3>Misuse or unauthorized use of personal information</h3>
                            <ul>
                                <li>
                                    <p>
                                        Hekaya website does not bear responsibility for misusing the customer’s
                                        personal information or any other procedures through external ebsites that
                                        have been linked or directed to the Hekaya website. The Hekaya website
                                        bears all the losses that may result from that after investigating it and
                                        verifying its issuance by the Hekaya team and the customer is compensated
                                        with what it is commensurate with the documented loss and the nature of
                                        the service purchased.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Any violation of this policy may result in the cancellation of the purchase
                                        and confiscation of any money paid.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        We also advise customers to refer to the privacy policies of other external
                                        websites that they accessed before using the various websites.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Change, cancellation, no-show, and upgrade policies
                                    </p>
                                </li>
                            </ul>
                    </div>
                    <div class="about-text" id="Change">
                        <h3>Change, cancel, no show, and refund</h3>
                                <p>
                                    When the customer wants to change or cancel the reservation or promotion, it
                                    is advised to contact the customer care service as soon as possible on the
                                    number, then write to <a href=""><u>customerservice@hekaya.co</u></a>.
                                    </p>
                                    <p>
                                    Writing must be done via the registered email when requesting the service.
                                    A representative of Hekaya team will respond within 24 working hours to
                                    requests to change reservations or promotions and may take 48 working hours
                                    for cancelling requests and the customer may incur a service tax of 3% of the
                                    value of the cost of the change, in addition to the change or promotion fees
                                    depending on the provider providing the service.
                                    </p>
                                    <p>
                                    The nature of the product and its cancellation and refund policy will be taken
                                    into consideration. Please refer to the terms and conditions of each purchase
                                    to avoid incurring any cancellation or no-show fees as necessary.
                                    </p>
                                    <p>
                                    If the specified Hekaya service is refundable by the supplier, it will be
                                    processed and debited to the customer’s credit or debit card account within
                                    24 days. The administrative fee for the website of Hekaya, which is 3% of the
                                    total purchase price, in addition to any amount stipulated in the terms and
                                    conditions of purchase shall be deducted from the refunded amount. Then the
                                    money is returned to the customer within 7 working days.
                                </p>
                    </div>
                    <div class="about-text" id="reservations">
                        <h3>Re-make reservations and upgrade requests</h3>
                                <p>
                                    For requests to re-make a reservation, change, cancel, or upgrade, please
                                    contact Hekaya team at the number
                                    or and write to us at <a href=""><u>customerservice@hekaya.co</u></a>.
                                    </p>
                                    <p>
                                        Writing must be done via the registered email when requesting the service.
                                        A representative of Hekaya team will respond within 24 working hours to
                                        requests to change reservations or promotions and may take 48 working hours
                                        for cancelling requests and the customer may incur a service tax of 3% of the
                                        value of the cost of the change, in addition to the change or promotion fees
                                        depending on the provider providing the service.
                                    </p>
                    </div>
                    <div class="about-text" id="Governing">
                        <h3>Governing Law Policy and Dispute Resolution</h3>
                                <p>
                                    These terms and conditions are subject to the laws of the government of the
                                    Sultanate of Oman, which may be represented in the laws of the General
                                    Authority of Civil Aviation and the General Authority for Consumer Protection
                                    and the laws of the Ministry of Commerce and Industry and the Ministry of
                                    Tourism and others; any dispute not settled amicably between the parties is
                                    subject to the exclusive jurisdiction of the courts of the Sultanate.
                                </p>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="w-100 stikside">
                    <div class="w-100 textrightcond">
    
                        
                        <h2>Want to get help with something?</h2>
                        <ng-container *ngIf="isLoggedIn; else notLoggedIn">
                          <button class="btn btn-warning w-100" routerLink="/contact">Contact us</button>
                        </ng-container>
                        <ng-template #notLoggedIn>
                          <p>Log in to help with your reservations, account, and more.</p>
                          <button class="btn btn-warning w-100" routerLink="/sign-in">Log in or sign up</button>
                        </ng-template>
                    </div>
                    
                    <div class="w-100 textrightcond">
    
                        <h2>Related articles:</h2>
                        <div class="w-100 mb-1"><a routerLink="/Web-Use-Terms">Web Use Terms</a></div>
                        <div class="w-100 mb-1"><a routerLink="/Terms-and-Condition">Car Rental Terms</a></div>
                       
                    </div>
                </div> 
                 </div>
            </div>
        </div>
    </div>
</section>

<app-footer *ngIf="!(location == '/error' || location == '/coming-soon' || location == '/sign-in'
    || location == '/sign-up'
    ||location == '/forgot-password')">
    </app-footer>