import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, NavigationCancel } from '@angular/router';
import { filter } from 'rxjs/operators';
import Splide from '@splidejs/splide';

@Component({
  selector: 'app-vehicle-category',
  templateUrl: './vehicle-category.component.html',
  styleUrls: ['./vehicle-category.component.scss'],
  providers: [
    Location, {
        provide: LocationStrategy,
        useClass: PathLocationStrategy
    }
]
})
export class VehicleCategoryComponent implements OnInit {

  location: any;
  routerSubscription: any;
  currentYear: number = new Date(). getFullYear();

  constructor(private _router: Router) { }

  ngOnInit(): void {
    new Splide( '#splide', {
      perPage : 3,
      lazyLoad: 'nearby',
      arrows     : true,
      breakpoints: {
        600: {
            height: '25rem',
            perPage : 1
        }

    }
    } ).mount();

    this.recallJsFuntions();
  }


  recallJsFuntions() {
    this.routerSubscription = this._router.events
        .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
        .subscribe(event => {
            $.getScript('../assets/js/main.js');
            this.location = this._router.url;
            if (!(event instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0);
        });
}

}
