import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RoutingConstants } from 'src/app/app.routing.contents';
import { VehicleCategory } from 'src/app/components/views/self-drive/models/vehicle-category/vehicle-category.model';
import { GenericService } from '../../generic-service/generic.service';

@Injectable({
  providedIn: 'root'
})
export class VehicleCategoryService {

  constructor(private genericService: GenericService) { }

  getAll(): Observable<Array<VehicleCategory>> {
    const serviceUrl = RoutingConstants.selfDrive.vehicleCategory;
    return this.genericService.getService(serviceUrl, true);
  }
}
