<form [formGroup]="leasevalidform" (submit)="isSubmiteleaseEnquiry()">
    <div class="row align-items-center ">

        <div class="col-md-6">
            <div class="form-group">
                <input type="text" class="form-control" placeholder="Name"
                    formControlName="name">
            </div>
        </div>
        <div class="col-md-6 text-left text-md-right">
            <div class="form-group pb-3 pb-md-0">
                <input type="tel" class="form-control" placeholder="Place"
                    formControlName="place">
            </div>

        </div>
    </div>
    <div class="row align-items-center ">

        <div class="col-md-6">
            <div class="form-group">
                <input type="text" class="form-control" placeholder="From date"
                    [minDate]="minDate" [maxDate]="maxDate" #dp="bsDatepicker"
                    bsDatepicker placement="top" formControlName="fromDate" [readonly]="true">
            </div>
        </div>
        <div class="col-md-6 text-left text-md-right">
            <div class="form-group pb-3 pb-md-0">
                <input type="text" class="form-control" placeholder="To date"
                    [minDate]="minDate" [maxDate]="maxDate" #dp="bsDatepicker"
                    bsDatepicker placement="top" formControlName="toDate" [readonly]="true">
            </div>

        </div>
    </div>
    <div class="row align-items-center ">

        <div class="col-md-6">
            <div class="form-group">
                <input type="email" class="form-control" name="Email"
                    placeholder="Email" formControlName="email">
            </div>
        </div>
        <div class="col-md-6 text-left text-md-right">
            <div class="form-group pb-3 pb-md-0">
                <input type="tel" class="form-control" name="Phone"
                    placeholder="Phone" formControlName="phone">
                <div *ngIf="f2.phone.touched">
                    <span *ngIf="f2.phone.errors.minlength"
                        style="color: red;">Phone number must be atleast 8
                        characters</span>
                </div>
            </div>

        </div>
    </div>
    <div class="text-right pr-0">
        <div class="form-check">
            <button type="submit" class="btn btn-warning">SEND</button>
        </div>
    </div>
</form>