<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>About HEKAYA</h3>

                    <div class="about-the-store">
                        <a href="#"><img src="https://hekaya.s3.ap-south-1.amazonaws.com/carRental/logo-footer.png" style="width: 40px;padding: 4px  0px;" alt="hekaya-logo" loading="lazy"></a>
                        <p class="mt-2">Hekaya founded, aiming to enhance the tourism services in Oman and abroad.</p>

                       
                    </div>

            

                    <div class="about-the-store text-justify mt-2">
                        <p class="credit">We Accept all Major Debit & Credit Cards</p>
                    </div>

                    <div class="logo">
                        <img src="https://hekaya.s3.ap-south-1.amazonaws.com/carRental/payment.png" alt="mastercard&visacard" class="credit-img" loading="lazy">
                    </div>

                
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget pl-4">
                    <h3>Quick Links</h3>

                    <ul class="quick-links">
                        <li><a routerLink="/about">About Us</a></li>
                        <li><a routerLink="/hekaya-services">Service</a></li>
                        <li><a routerLink="/contact">Contact Us</a></li>
                        <li><a routerLink="/login">Login</a></li>



                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Customer Support</h3>

                    <ul class="quick-links">
                        <li><a (click)="myAccount()">My Account</a></li>
                        <li><a routerLink="/sign-up">Register</a></li>
                        <li><a routerLink="/faqs">FAQ's</a></li>
                        <li><a routerLink="/sitemap">Sitemap</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <div class="about-the-store">
                        <h3>Contact Us</h3>

                        <ul class="quick-links">
                            <li class="d-flex align-items-center"><i class='bx bx-envelope mr-3'></i>  <a href="info@hekaya.co">info@hekaya.co</a></li>
                            <!-- <li a (click)="onShowGoogleMap()">Location</li> -->
                            <li class="d-flex align-items-center" data-toggle="modal" data-target="#googleMapPopup">
                                <i class='bx bx-map mr-3'></i> 
                                <a target="_blank">Location</a>
                              </li>

                              <li class="d-flex align-items-center mt-3">
                                <a href="https://www.instagram.com/hekaya.co/" target="_blank" class="mr-3 mt-1"><i class="bi bi-instagram"></i></a>
                                <a href="https://twitter.com/Hekaya_Holidays" target="_blank"  class="mr-3"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-twitter-x" viewBox="0 0 16 16">
                                    <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865l8.875 11.633Z"/>
                                  </svg></a>
                                <a href="https://www.facebook.com/people/Hekayaco/100064112176959/?modal=admin_todo_tour" target="_blank">
                                    
                                    <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M17 2H14C12.6739 2 11.4021 2.52678 10.4645 3.46447C9.52678 4.40215 9 5.67392 9 7V10H6V14H9V22H13V14H16L17 10H13V7C13 6.73478 13.1054 6.48043 13.2929 6.29289C13.4804 6.10536 13.7348 6 14 6H17V2Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                        
                                </a>
                              </li>
                            <li>
                                <strong class="w-100 mb-3  d-block pt-4">Route Car Rental</strong>
                                <div class="w-100 mb-3 d-block"><img src="https://hekaya.s3.ap-south-1.amazonaws.com/carRental/Route%20Logo.jpg" alt="Route Logo" style="max-width:100px ;"></div> 
                            </li>
                        </ul>
                    </div>
                   
                </div>
            </div>
        </div>
        

        <div class="footer-bottom-area">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <p>Copyright <i class='bx bx-copyright'></i>{{currentYear}} <a href="#" target="_blank">HEKAYA</a> Designed By <a href="https://www.blueleaftechit.com/" target="_blank">Blueleaf</a> | All rights reserved.</p>
                </div>

                <div class="col-lg-6 col-md-6">
                    <ul>
                        <li><a routerLink="/Web-Use-Terms">Web Use Terms</a></li>
                        <li><a routerLink="/Privacy-Policy">Privacy Policy</a></li>
                        <li><a routerLink="/Terms-and-Condition">Car Rental Terms</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</footer>
<a href="https://wa.me/96890996418" target="_blank" class="whatsapp_fx"><img src="../../../../assets/img/whatsapp.png" alt=""></a>
   
<!-- <div class="connects" id="chats">
    <div class="connectname">Connect Us <i class='bx bx-chevron-up'></i></div>
    
    <div class="iconslefts">
        <a href="https://wa.me/96890996418" target="_blank" class="whatsapp_fx"><img src="../../../../assets/img/logos_whatsapp-icon.svg" alt=""></a>
    
    </div>
    </div> -->


<div class="go-top"><i class='bx bx-chevron-up'></i></div>

<div class="modal right fade shoppingCartModal" id="shoppingCartModal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true"><i class='bx bx-x'></i></span>
            </button>

            <div class="modal-body">
                <h3>My Cart (3)</h3>

                <div class="products-cart-content">
                    <div class="products-cart">
                        <div class="products-image">
                            <a routerLink="/"><img src="assets/img/products/img1.jpg" alt="image" loading="lazy"></a>
                        </div>

                        <div class="products-content">
                            <h3><a routerLink="/">Ham Salad</a></h3>
                            <span>Quantity: 01</span>
                            <div class="products-price">
                                $20
                            </div>
                            <a routerLink="/" class="remove-btn"><i class='bx bx-trash'></i></a>
                        </div>
                    </div>

                    <div class="products-cart">
                        <div class="products-image">
                            <a routerLink="#"><img src="assets/img/products/img2.jpg" alt="image" loading="lazy"></a>
                        </div>

                        <div class="products-content">
                            <h3><a routerLink="#">Fresh Cappuccino</a></h3>
                            <span>Quantity: 02</span>
                            <div class="products-price">
                                $25
                            </div>
                            <a routerLink="#" class="remove-btn"><i class='bx bx-trash'></i></a>
                        </div>
                    </div>

                    <div class="products-cart">
                        <div class="products-image">
                            <a routerLink="#"><img src="assets/img/products/img3.jpg" alt="image" loading="lazy"></a>
                        </div>

                        <div class="products-content">
                            <h3><a routerLink="#">Honey Cake</a></h3>
                            <span>Quantity: 01</span>
                            <div class="products-price">
                                $11
                            </div>
                            <a routerLink="#" class="remove-btn"><i class='bx bx-trash'></i></a>
                        </div>
                    </div>
                </div>

                <div class="products-cart-subtotal">
                    <span>Subtotal</span>

                    <span class="subtotal">$524.00</span>
                </div>

                <div class="products-cart-btn">
                    <a routerLink="/cart" class="default-btn">View Bag & Checkout</a>
                </div>
            </div>
        </div>
    </div>
</div>
<div bsModal #googleMapPopup="bs-modal" class="modal fade rds" id="googleMapPopup" tabindex="-1" role="dialog"
    aria-labelledby="googleMapPopup" aria-hidden="true">
    <div class="modal-dialog modal-xl  modal-dialog-centered">
        <div class="modal-content lg-radius">
            <div class="modal-header close-ico">

                <button type="button" class="close bgSolid" data-dismiss="modal" style="display:block;" (click)="googleMapPopup.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
           
            <div class="modal-body pd-0">
                <div class="embed-responsive embed-responsive-16by9">
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d47548.75570105729!2d57.78136845058644!3d23.315808273797607!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e8e6d721c04ded7%3A0xde70c01c0cd80024!2sHekaya%20Holidays%20SPC!5e0!3m2!1sen!2som!4v1697031684150!5m2!1sen!2som" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
</div>
                <!-- <div id="map" style="height: 600px;"></div> -->
            </div>
        </div>
    </div>
</div>


<div class="search-overlay">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-layer"></div>

            <div class="search-overlay-close">
                <span class="search-overlay-close-line"></span>
                <span class="search-overlay-close-line"></span>
            </div>

            <div class="search-overlay-form">
                <form>
                    <input type="text" class="input-search" placeholder="Search here...">
                    <button type="submit"><i class='bx bx-search-alt'></i></button>
                </form>
            </div>
        </div>
    </div>
</div>

