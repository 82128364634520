<div class="page-title-area page-title-bg3">
    <div class="container">
        <div class="page-title-content">
            <h2>Hekayat Rout Plans </h2>
        </div>
    </div>
</div>

<section class="about-area ">
    <div class="container">

        <div class="about-inner-area">
            <div class="row">
                <div class="">
                    <div class="about-text">
                        <p>At Hekayat, we have chosen the most common routes which shall maximize your experience. Choose you route plan from the below or combine two or more routes together. Ensure to understand how to navigate and understand the requirements for your safety and convenience.At Hekayat, we have chosen the most common routes which shall maximize your experience. Choose you route plan from the below or combine two or more routes together. Ensure to understand how to navigate and understand the requirements for your safety and convenience.</p>
                        <br>
                        <hr style="background: #e3b566;">
                        <div class="container sml-box" >
                            <p class="para-style">Route Plan Navigation Key</p>
                        </div>
                    </div>
                </div>               
            </div>
        </div>

        <div class="row align-items-center" style="margin-top: 30px;margin-left: 35px;">
            <div class="col-md-3">
                <img src="../../../../assets/img/self-drive/key1.png" alt="" loading="lazy">
            </div>
            <div class="col-md-3">
                <img src="../../../../assets/img/self-drive/key2.png" alt="" loading="lazy">
            </div>
            <div class="col-md-3">
                <img src="../../../../assets/img/self-drive/key3.png" alt="" loading="lazy">
            </div>
            <div class="col-md-3">
                <img src="../../../../assets/img/self-drive/key4.png" alt="" loading="lazy">
            </div>
        </div>

        <hr style="background: #767678;">
        
        <div class="row align-items-center" style="margin-top: 30px;">
           <div class="col-md-3" style="margin-left: 118px;">
            <img src="../../../../assets/img/self-drive/marker.png" alt="" loading="lazy">
           </div>
           <div class="col-md-5">
            <div class="container" style="background-color: #f3f0e4;width: 111px;
            margin-left: 0px;">
                <p><span style="color: #565662;">Main Route</span></p>
            </div>
            <p>This is your main route and main cities to visit during this route plan  </p>
            </div>
        </div>

        <hr style="background: #767678;">
        
        <div class="row align-items-center" style="margin-top: 30px;">
           <div class="col-md-3" style="margin-left: 118px;">
            <img src="../../../../assets/img/self-drive/marker2.png" alt="" loading="lazy">
           </div>
           <div class="col-md-5">
            <div class="container" style="background-color: #f3f0e4;width: 136px;
            margin-left: 0px;">
                <p><span style="color: #565662;">Optional Route</span></p>
            </div>
            <p>This is your optional cities/towns you may visit during this route plan </p>
            </div>
        </div>

        <hr style="background: #767678;">
        
        
    </div>
</section>

<section class="about-area ">
    <div class="container">
        <div class="about-inner-area">
            <div class="row">
                <div class="">
                    <div class="about-text">
                        <div class="container">
                            <img src="../../../../assets/img/self-drive/map-head1.png" alt="" loading="lazy">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row align-items-center" style="margin-top: 30px;">
            <div class="card mb-3" style="height: 300px;width: 100%;" >
                <div class="row g-0">
                  <div class="col-md-5">
                    <img src="../../../../assets/img/self-drive/maps/map1/map1.png" alt="" style="height: 298px;" loading="lazy">
                  </div>
                  <div class="col-md-5" style="margin-left: -30px;">
                    <div class="card-body">  
                        <div class="row">
                            <div class="col-sm-2">
                                <img src="../../../../assets/img/self-drive/maps/map1/key1.png" alt="" loading="lazy">
                            </div>
                            <div class="col-sm-2">
                              <img src="../../../../assets/img/self-drive/maps/map1/key2.png" alt="" loading="lazy">
                            </div>
                            <div class="col-sm-2">
                              <img src="../../../../assets/img/self-drive/maps/map1/key3.png" alt="" loading="lazy">
                            </div>
                            <div class="col-sm-2">
                              <img src="../../../../assets/img/self-drive/maps/map1/key4.png" alt="" loading="lazy">
                            </div>
                        </div>
                        <div class="row">
                            <div class="container sm-card">
                                <h6 style="color: #c49a6c;padding-top: 6px;">Optional Routes</h6>
                                <div class="col-sm">
                                    <ol style="color: #c49a6c;">
                                        <li>Fanja Town</li>
                                        <li>Samail</li>
                                        <li>Izki</li>
                                        <li>Burkat Al Mouj</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
        </div>

    </div>
</section>


<section class="about-area ">
    <div class="container">
        <div class="about-inner-area">
            <div class="row">
                <div class="">
                    <div class="about-text">
                        <div class="container">
                            <img src="../../../../assets/img/self-drive/map-head2.png" alt="" loading="lazy">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row align-items-center" style="margin-top: 30px;">
            <div class="card mb-3" style="height: 300px;width: 100%;" >
                <div class="row g-0">
                  <div class="col-md-5">
                    <img src="../../../../assets/img/self-drive/maps/map2/map2.png" alt="" style="height: 298px;">
                  </div>
                  <div class="col-md-5" style="margin-left: -30px;">
                    <div class="card-body">  
                        <div class="row">
                            <div class="col-sm-2">
                                <img src="../../../../assets/img/self-drive/maps/map2/key1.png" alt="" loading="lazy">
                            </div>
                            <div class="col-sm-2">
                              <img src="../../../../assets/img/self-drive/maps/map2/key2.png" alt="" loading="lazy">
                            </div>
                            <div class="col-sm-2">
                              <img src="../../../../assets/img/self-drive/maps/map2/key3.png" alt="" loading="lazy">
                            </div>
                            <div class="col-sm-2">
                              <img src="../../../../assets/img/self-drive/maps/map2/key4.png" alt="" loading="lazy">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md">
                                <div class="container sm-card">
                                    <h6 style="color: #c49a6c;padding-top: 6px;">Optional Routes</h6>
                                    <div class="col-sm">
                                        <ol style="color: #c49a6c;">
                                            <li>Burkat Al Maouz</li>
                                            <li>Tanuf</li>
                                            <li>Al Hamra</li>
                                            <li>Izki</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md" style="margin-left: -100px;">
                                <div class="container sm-card">
                                    <h6 style="color: #c49a6c;padding-top: 6px;">Optional Routes</h6>
                                    <div class="col-sm">
                                        <ol style="color: #c49a6c;" start="5">
                                            <li>Samail</li>
                                            <li>Fanja Town</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
        </div>

    </div>
</section>

<section class="about-area ">
    <div class="container">
        <div class="about-inner-area">
            <div class="row">
                <div class="">
                    <div class="about-text">
                        <div class="container">
                            <img src="../../../../assets/img/self-drive/map-head3.png" alt="" loading="lazy">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row align-items-center" style="margin-top: 30px;">
            <div class="card mb-3" style="height: 434px;width: 100%;" >
                <div class="row g-0">
                  <div class="col-md-5">
                    <img src="../../../../assets/img/self-drive/maps" alt="" style="height: 298px;" loading="lazy">
                  </div>
                  <div class="col-md-5" style="margin-left: -30px;">
                    <div class="card-width">  
                        <div class="row">
                            <div class="col-sm-2">
                                <img src="../../../../assets/img/self-drive/maps/map3/key1.png" alt="" loading="lazy">
                            </div>
                            <div class="col-sm-2">
                              <img src="../../../../assets/img/self-drive/maps/map3/key2.png" alt="" loading="lazy">
                            </div>
                            <div class="col-sm-2">
                              <img src="../../../../assets/img/self-drive/maps/map3/key3.png" alt="" loading="lazy">
                            </div>
                            <div class="col-sm-2">
                              <img src="../../../../assets/img/self-drive/maps/map3/key4.png" alt="" loading="lazy">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md" style="margin-left: 40px;">
                                <div class="container map-card">
                                    <h6 style="color: #c49a6c;padding-top: 6px;">Optional Routes</h6>
                                    <div class="col-sm">
                                        <ol style="color: #c49a6c;">
                                            <li>Fanja Town</li>
                                            <li>Samail</li>
                                            <li>Izki</li>
                                            <li>Burkat Al Maouz</li>
                                            
                                        </ol>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md" >
                                <div class="container map-card">
                                    <h6 style="color: #c49a6c;padding-top: 6px;">Optional Routes</h6>
                                    <div class="col-sm">
                                        <ol style="color: #c49a6c;" start="5">
                                            <li>Ibra</li>
                                            <li>Al Sharqia Sands</li>
                                            <li>Wadi Bani Khalid</li>
                                            <li>Qalhat</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md" style="margin-left: 40px;">
                                <div class="container map-card">
                                    <h6 style="color: #c49a6c;padding-top: 6px;">Optional Routes</h6>
                                    <div class="col-sm">
                                        <ol style="color: #c49a6c;" start="9">
                                            <li>Quriyat</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
        </div>

    </div>
</section>


<section class="about-area ">
    <div class="container">
        <div class="about-inner-area">
            <div class="row">
                <div class="">
                    <div class="about-text">
                        <div class="container">
                            <img src="../../../../assets/img/self-drive/map-head4.png" alt="" loading="lazy">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row align-items-center" style="margin-top: 30px;">
            <div class="card mb-3" style="height: 300px;width: 100%;" >
                <div class="row g-0">
                  <div class="col-md-5">
                    <img src="../../../../assets/img/self-drive/maps/map4/map4.png" alt="" style="height: 298px; width: 100%;">
                  </div>
                  <div class="col-md-5" style="margin-left: -30px;">
                    <div class="card-body">  
                        <div class="row">
                            <div class="col-sm-2">
                                <img src="../../../../assets/img/self-drive/maps/map4/key1.png" alt="" loading="lazy"> 
                            </div>
                            <div class="col-sm-2">
                              <img src="../../../../assets/img/self-drive/maps/map4/key2.png" alt="" loading="lazy">
                            </div>
                            <div class="col-sm-2"> 
                              <img src="../../../../assets/img/self-drive/maps/map4/key3.png" alt="" loading="lazy">
                            </div>
                            <div class="col-sm-2">
                              <img src="../../../../assets/img/self-drive/maps/map4/key4.png" alt="" loading="lazy">
                            </div>
                        </div>
                        <div class="row">
                            <div class="container sm-card">
                                <h6 style="color: #c49a6c;padding-top: 6px;">Optional Routes</h6>
                                <div class="col-sm">
                                    <ol style="color: #c49a6c;">
                                        <li>Barka</li>
                                        <li>Al Awaib</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
        </div>

    </div>
</section>

<section class="about-area ">
    <div class="container">
        <div class="about-inner-area">
            <div class="row">
                <div class="">
                    <div class="about-text">
                        <div class="container">
                            <img src="../../../../assets/img/self-drive/map-head5.png" alt="" loading="lazy">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row align-items-center" style="margin-top: 30px;">
            <div class="card mb-3" style="height: 300px;width: 100%;" >
                <div class="row g-0">
                  <div class="col-md-5">
                    <img src="../../../../assets/img/self-drive/maps/map5/map5.png" alt="" style="height: 298px; width: 100%;">
                  </div>
                  <div class="col-md-5" style="margin-left: -30px;">
                    <div class="card-body">  
                        <div class="row">
                            <div class="col-sm-2">
                                <img src="../../../../assets/img/self-drive/maps/map5/key1.png" alt="" loading="lazy">
                            </div>
                            <div class="col-sm-2">
                              <img src="../../../../assets/img/self-drive/maps/map5/key2.png" alt="" loading="lazy">
                            </div>
                            <div class="col-sm-2">
                              <img src="../../../../assets/img/self-drive/maps/map5/key3.png" alt="" loading="lazy">
                            </div>
                            <div class="col-sm-2">
                              <img src="../../../../assets/img/self-drive/maps/map5/key4.png" alt="" loading="lazy">
                            </div>
                        </div>
                        <div class="row">
                            <div class="container sm-card">
                                <h6 style="color: #c49a6c;padding-top: 6px;">Optional Routes</h6>
                                <div class="col-sm">
                                    <ol style="color: #c49a6c;">
                                        <li>Barka</li>
                                        <li>Al Awaib</li>
                                        <ol style="color: #c49a6c;padding-left: 1px;" start="2">
                                            <li>Nakel</li>
                                        </ol>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
        </div>

    </div>
</section>

<section class="about-area ">
    <div class="container">
        <div class="about-inner-area">
            <div class="row">
                <div class="">
                    <div class="about-text">
                        <div class="container">
                            <img src="../../../../assets/img/self-drive/map-head6.png" alt="" loading="lazy">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row align-items-center" style="margin-top: 30px;">
            <div class="card mb-3" style="height: 300px;width: 100%;" >
                <div class="row g-0">
                  <div class="col-md-5">
                    <img src="../../../../assets/img/self-drive/maps/map6/map6.png" alt="" style="height: 298px; width: 100%;">
                  </div>
                  <div class="col-md-5" style="margin-left: -30px;">
                    <div class="card-body">  
                        <div class="row">
                            <div class="col-sm-2">
                                <img src="../../../../assets/img/self-drive/maps/map6/key1.png" alt="" loading="lazy">
                            </div>
                            <div class="col-sm-2">
                              <img src="../../../../assets/img/self-drive/maps/map6/key2.png" alt="" loading="lazy">
                            </div>
                            <div class="col-sm-2">
                              <img src="../../../../assets/img/self-drive/maps/map6/key3.png" alt="" loading="lazy">
                            </div>
                            <div class="col-sm-2">
                              <img src="../../../../assets/img/self-drive/maps/map6/key4.png" alt="" loading="lazy">
                            </div>
                        </div>
                        <div class="row">
                            <div class="container sm-card">
                                <h6 style="color: #c49a6c;padding-top: 6px;">Optional Routes</h6>
                                <div class="col-sm">
                                    <ol style="color: #c49a6c;">
                                        <li>Barka</li>
                                        <li>Al Awaib</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
        </div>

    </div>
</section>

<section class="about-area ">
    <div class="container">
        <div class="about-inner-area">
            <div class="row">
                <div class="">
                    <div class="about-text">
                        <div class="container">
                            <img src="../../../../assets/img/self-drive/map-head7.png" alt="" loading="lazy">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row align-items-center" style="margin-top: 30px;">
            <div class="card mb-3" style="height: 300px;width: 100%;" >
                <div class="row g-0">
                  <div class="col-md-5">
                    <img src="../../../../assets/img/self-drive/maps" alt="" style="height: 298px; width: 100%;" loading="lazy">
                  </div>
                  <div class="col-md-5" style="margin-left: -30px;">
                    <div class="card-body">  
                        <div class="row">
                            <div class="col-sm-2">
                                <img src="../../../../assets/img/self-drive/maps/map7/key1.png" alt="" loading="lazy">
                            </div>
                            <div class="col-sm-2">
                              <img src="../../../../assets/img/self-drive/maps/map7/key2.png" alt="" loading="lazy">
                            </div>
                            <div class="col-sm-2">
                              <img src="../../../../assets/img/self-drive/maps/map7/key3.png" alt="" loading="lazy">
                            </div>
                            <div class="col-sm-2">
                              <img src="../../../../assets/img/self-drive/maps/map7/key4.png" alt="" loading="lazy">
                            </div>
                        </div>
                        <div class="row">
                            <div class="container sm-card">
                                <h6 style="color: #c49a6c;padding-top: 6px;">Optional Routes</h6>
                                <div class="col-sm">
                                    <ol style="color: #c49a6c;">
                                        <li>Fanja Town</li>
                                        <li>Samail</li>
                                        <li>Izki</li>
                                        <li>Ibra</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
        </div>

    </div>
</section>

<section class="about-area ">
    <div class="container">
        <div class="about-inner-area">
            <div class="row">
                <div class="">
                    <div class="about-text">
                        <div class="container">
                            <img src="../../../../assets/img/self-drive/map-head8.png" alt="" loading="lazy">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row align-items-center" style="margin-top: 30px;">
            <div class="card mb-3" style="height: 300px;width: 100%;" >
                <div class="row g-0">
                  <div class="col-md-5">
                    <img src="../../../../assets/img/self-drive/maps" alt="" style="height: 298px; width: 100%;">
                  </div>
                  <div class="col-md-5" style="margin-left: -30px;">
                    <div class="card-body">  
                        <div class="row">
                            <div class="col-sm-2">
                                <img src="../../../../assets/img/self-drive/maps/map8/key1.png" alt="" loading="lazy">
                            </div>
                            <div class="col-sm-2">
                              <img src="../../../../assets/img/self-drive/maps/map8/key2.png" alt="" loading="lazy">
                            </div>
                            <div class="col-sm-2">
                              <img src="../../../../assets/img/self-drive/maps/map8/key3.png" alt="" loading="lazy">
                            </div>
                            <div class="col-sm-2">
                              <img src="../../../../assets/img/self-drive/maps/map8/key4.png" alt="" loading="lazy">
                            </div>
                        </div>
                        <div class="row">
                            <div class="container sm-card">
                                <h6 style="color: #c49a6c;padding-top: 6px;">Optional Routes</h6>
                                <div class="col-sm">
                                    <ol style="color: #c49a6c;">
                                        <li>Fanja Town</li>
                                        <li>Samail</li>
                                        <li>Izki</li>
                                    </ol>
                                </div>
                            </div>
                            <div class="container sm-card">
                                <h6 style="color: #c49a6c;padding-top: 6px;">Optional Routes</h6>
                                <div class="col-sm">
                                    <ol style="color: #c49a6c;" start="4">
                                        <li>Ibra</li>
                                        <li>Qalhat</li>
                                        <li>Quriyat</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
        </div>

    </div>
</section>


<section class="about-area ">
    <div class="container">
        <div class="about-inner-area">
            <div class="row">
                <div class="">
                    <div class="about-text">
                        <div class="container">
                            <img src="../../../../assets/img/self-drive/map-head9.png" alt="" loading="lazy">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row align-items-center" style="margin-top: 30px;">
            <div class="card mb-3" style="height: 434px;width: 100%;" >
                <div class="row g-0">
                  <div class="col-md-5">
                    <img src="../../../../assets/img/self-drive/maps/map9/map9.png" alt="" style="height: 100%;" loading="lazy">
                  </div>
                  <div class="col-md-5" style="margin-left: -30px;">
                    <div class="card-width">  
                        <div class="row" style="margin-left: 9px;">
                            <div class="col-sm-2">
                                <img src="../../../../assets/img/self-drive/maps/map9/key1.png" alt="" loading="lazy">
                            </div>
                            <div class="col-sm-2">
                              <img src="../../../../assets/img/self-drive/maps/map9/key2.png" alt="" loading="lazy">
                            </div>
                            <div class="col-sm-2">
                              <img src="../../../../assets/img/self-drive/maps/map9/key3.png" alt="" loading="lazy">
                            </div>
                            <div class="col-sm-2">
                              <img src="../../../../assets/img/self-drive/maps/map9/key4.png" alt="" loading="lazy">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md" style="margin-left: 40px;">
                                <div class="container map-card">
                                    <h6 style="color: #c49a6c;padding-top: 6px;">Optional Routes</h6>
                                    <div class="col-sm">
                                        <ol style="color: #c49a6c;">
                                            <li>Al Hamra</li>
                                            <li>Tanuf</li>
                                            <li>Burkat Al Maouz</li>
                                            <li>Sinaw</li>
                                            
                                        </ol>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md" >
                                <div class="container map-card">
                                    <h6 style="color: #c49a6c;padding-top: 6px;">Optional Routes</h6>
                                    <div class="col-sm">
                                        <ol style="color: #c49a6c;" start="5">
                                            <li>Ibra</li>
                                            <li>Wadi Bani Khalid</li>
                                            <li>Al Sharqia Sand</li>
                                            <li>Rass Al Hadd</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md" style="margin-left: 40px;">
                                <div class="container map-card">
                                    <h6 style="color: #c49a6c;padding-top: 6px;">Optional Routes</h6>
                                    <div class="col-sm">
                                        <ol style="color: #c49a6c;" start="9">
                                            <li>Sur</li>
                                            <li>Qalhat</li>
                                            <li>Quriyat</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
        </div>

    </div>
</section>

<section class="about-area ">
    <div class="container">
        <div class="about-inner-area">
            <div class="row">
                <div class="">
                    <div class="about-text">
                        <div class="container">
                            <img src="../../../../assets/img/self-drive/map-head10.png" alt="" loading="lazy">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row align-items-center" style="margin-top: 30px;">
            <div class="card mb-3" style="height: 434px;width: 100%;" >
                <div class="row g-0">
                  <div class="col-md-5">
                    <img src="../../../../assets/img/self-drive/maps/map10/map10.png" alt="" style="height: 100%;" loading="lazy">
                  </div>
                  <div class="col-md-5" style="margin-left: -30px;">
                    <div class="card-width">  
                        <div class="row" style="margin-left: 9px;">
                            <div class="col-sm-2">
                                <img src="../../../../assets/img/self-drive/maps/map10/key1.png" alt="" style="height: 111px;" loading="lazy">
                            </div>
                            <div class="col-sm-2">
                              <img src="../../../../assets/img/self-drive/maps/map10/key2.png" alt="" loading="lazy">
                            </div>
                            <div class="col-sm-2">
                              <img src="../../../../assets/img/self-drive/maps/map10/key3.png" alt="" loading="lazy">
                            </div>
                            <div class="col-sm-2">
                              <img src="../../../../assets/img/self-drive/maps/map10/key4.png" alt="" loading="lazy">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md" style="margin-left: 40px;">
                                <div class="container map-card">
                                    <h6 style="color: #c49a6c;padding-top: 6px;">Optional Routes</h6>
                                    <div class="col-sm">
                                        <ol style="color: #c49a6c;">
                                            <li>Al Hamra</li>
                                            <li>Tanuf</li>
                                            <li>Burkat Al Maouz</li>
                                            
                                            
                                        </ol>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md" >
                                <div class="container map-card">
                                    <h6 style="color: #c49a6c;padding-top: 6px;">Optional Routes</h6>
                                    <div class="col-sm">
                                        <ol style="color: #c49a6c;" start="4">
                                            <li>Sinaw</li>
                                            <li>Ibra</li>
                                            <li>Wadi Bani Khalid</li>
                                            
                                            
                                        </ol>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md" style="margin-left: 40px;">
                                <div class="container map-card">
                                    <h6 style="color: #c49a6c;padding-top: 6px;">Optional Routes</h6>
                                    <div class="col-sm">
                                        <ol style="color: #c49a6c;" start="7">
                                            <li>Rass Al Hadd</li>
                                            <li>Qalhat</li>
                                            <li>Quriyat</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
        </div>

    </div>
</section>

<section class="about-area ">
    <div class="container">
        <div class="about-inner-area">
            <div class="row">
                <div class="">
                    <div class="about-text">
                        <div class="container">
                            <img src="../../../../assets/img/self-drive/map-head11.png" alt="" loading="lazy">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row align-items-center" style="margin-top: 30px;">
            <div class="card mb-3" style="height: 434px;width: 100%;" >
                <div class="row g-0">
                  <div class="col-md-5">
                    <img src="../../../../assets/img/self-drive/maps" alt="" style="height: 100%;" loading="lazy">
                  </div>
                  <div class="col-md-5" style="margin-left: -30px;">
                    <div class="card-width">  
                        <div class="row" style="margin-left: 9px;">
                            <div class="col-sm-2">
                                <img src="../../../../assets/img/self-drive/maps/map11/key1.png" alt="" style="height: 111px;" loading="lazy">
                            </div>
                            <div class="col-sm-2">
                              <img src="../../../../assets/img/self-drive/maps/map11/key2.png" alt="" loading="lazy">
                            </div>
                            <div class="col-sm-2">
                              <img src="../../../../assets/img/self-drive/maps/map11/key3.png" alt="" loading="lazy">
                            </div>
                            <div class="col-sm-2">
                              <img src="../../../../assets/img/self-drive/maps/map11/key4.png" alt="" loading="lazy">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md" style="margin-left: 40px;">
                                <div class="container map-card">
                                    <h6 style="color: #c49a6c;padding-top: 6px;">Optional Routes</h6>
                                    <div class="col-sm">
                                        <ol style="color: #c49a6c;">
                                            <li>Al Hamra</li>
                                            <li>Tanuf</li>
                                            <li>Burkat Al Maouz</li>
                                            <li>Sinaw</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md" >
                                <div class="container map-card">
                                    <h6 style="color: #c49a6c;padding-top: 6px;">Optional Routes</h6>
                                    <div class="col-sm">
                                        <ol style="color: #c49a6c;" start="5">
                                            <li>Ibra</li>
                                            <li>Wadi Bani Khalid</li>
                                            <li>Al Ashkharah</li>
                                            <li>Rass Al Hadd</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md" style="margin-left: 40px;">
                                <div class="container map-card">
                                    <h6 style="color: #c49a6c;padding-top: 6px;">Optional Routes</h6>
                                    <div class="col-sm">
                                        <ol style="color: #c49a6c;" start="9">
                                            
                                            <li>Qalhat</li>
                                            <li>Quriyat</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
        </div>

    </div>
</section>

<section class="about-area ">
    <div class="container">
        <div class="about-inner-area">
            <div class="row">
                <div class="">
                    <div class="about-text">
                        <div class="container">
                            <img src="../../../../assets/img/self-drive/map-head12.png" alt="" loading="lazy">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row align-items-center" style="margin-top: 30px;">
            <div class="card mb-3" style="height: 300px;width: 100%;" >
                <div class="row g-0">
                  <div class="col-md-5">
                    <img src="../../../../assets/img/self-drive/maps/map12/map12.png" alt="" style="height: 298px; width: 100%;" loading="lazy">
                  </div>
                  <div class="col-md-5" style="margin-left: -30px;">
                    <div class="card-body">  
                        <div class="row">
                            <div class="col-sm-2">
                                <img src="../../../../assets/img/self-drive/maps/map12/key1.png" alt="" loading="lazy">
                            </div>
                            <div class="col-sm-2">
                              <img src="../../../../assets/img/self-drive/maps/map12/key2.png" alt="" loading="lazy">
                            </div>
                            <div class="col-sm-2">
                              <img src="../../../../assets/img/self-drive/maps/map12/key3.png" alt="" loading="lazy">
                            </div>
                            <div class="col-sm-2">
                              <img src="../../../../assets/img/self-drive/maps/map12/key4.png" alt="" loading="lazy">
                            </div>
                        </div>
                        <div class="row">
                            <div class="container sm-card">
                                <h6 style="color: #c49a6c;padding-top: 6px;">Optional Routes</h6>
                                <div class="col-sm">
                                    <ol style="color: #c49a6c;">
                                        <li>Al Hamra</li>
                                        <li>Nakel</li>
                                        <li>Al Awabi</li>
                                        <li>Tanuf</li>
                                    </ol>
                                </div>
                            </div>
                            <div class="container sm-card">
                                <h6 style="color: #c49a6c;padding-top: 6px;">Optional Routes</h6>
                                <div class="col-sm">
                                    <ol style="color: #c49a6c;" start="4">
                                        <li>Burkat Al Mouz</li>
                                        <li>Izki</li>
                                        <li>Samail</li>
                                        <li>Fanja Town</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
        </div>

    </div>
</section>


<section class="about-area ">
    <div class="container">
        <div class="about-inner-area">
            <div class="row">
                <div class="">
                    <div class="about-text">
                        <div class="container">
                            <img src="../../../../assets/img/self-drive/map-head13.png" alt="" loading="lazy">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row align-items-center" style="margin-top: 30px;">
            <div class="card mb-3" style="height: 434px;width: 100%;" >
                <div class="row g-0">
                  <div class="col-md-5">
                    <img src="../../../../assets/img/self-drive/maps/map13/map13.png" alt="" style="height: 100%;" loading="lazy">
                  </div>
                  <div class="col-md-5" style="margin-left: -30px;">
                    <div class="card-width">  
                        <div class="row" style="margin-left: 9px;">
                            <div class="col-sm-2">
                                <img src="../../../../assets/img/self-drive/maps/map13/key1.png" alt="" style="height: 111px;" loading="lazy">
                            </div>
                            <div class="col-sm-2">
                              <img src="../../../../assets/img/self-drive/maps/map13/key2.png" alt="" loading="lazy">
                            </div>
                            <div class="col-sm-2">
                              <img src="../../../../assets/img/self-drive/maps/map13/key3.png" alt="" loading="lazy">
                            </div>
                            <div class="col-sm-2">
                              <img src="../../../../assets/img/self-drive/maps/map13/key4.png" alt="" loading="lazy">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md" style="margin-left: 40px;">
                                <div class="container map-card">
                                    <h6 style="color: #c49a6c;padding-top: 6px;">Optional Routes</h6>
                                    <div class="col-sm">
                                        <ol style="color: #c49a6c;">
                                            <li>Nakel</li>
                                            <li>Wakan</li>
                                            <li>AL Awabi</li>
                                            <li>Al Hamra</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md" >
                                <div class="container map-card">
                                    <h6 style="color: #c49a6c;padding-top: 6px;">Optional Routes</h6>
                                    <div class="col-sm">
                                        <ol style="color: #c49a6c;" start="5">
                                            <li>Tanuf</li>
                                            <li>Burkat Al Mouz</li>
                                            <li>Sinaw</li>
                                            <li>Ibra</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md" style="margin-left: 40px;">
                                <div class="container map-card">
                                    <h6 style="color: #c49a6c;padding-top: 6px;">Optional Routes</h6>
                                    <div class="col-sm">
                                        <ol style="color: #c49a6c;" start="9">
                                            <li>Wadi Bani Khalid</li>
                                            <li>Raz Al Hadd</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md" style="margin-left: 6px;">
                                <div class="container map-card">
                                    <h6 style="color: #c49a6c;padding-top: 6px;">Optional Routes</h6>
                                    <div class="col-sm">
                                        <ol style="color: #c49a6c;" start="11">
                                            <li>Qalhat</li>
                                            <li>Quriyat</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
        </div>

    </div>
</section>

<section class="about-area ">
    <div class="container">
        <div class="about-inner-area">
            <div class="row">
                <div class="">
                    <div class="about-text">
                        <div class="container">
                            <img src="../../../../assets/img/self-drive/map-head15.png" alt="" loading="lazy">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row align-items-center" style="margin-top: 30px;">
            <div class="card mb-3" style="height: 434px;width: 100%;" >
                <div class="row g-0">
                  <div class="col-md-5">
                    <img src="" alt="" style="height: 100%;">
                  </div>
                  <div class="col-md-5" style="margin-left: -30px;">
                    <div class="card-width">  
                        <div class="row" style="margin-left: 9px;">
                            <div class="col-sm-2">
                                <img src="../../../../assets/img/self-drive/maps/map15/key1.png" alt="" style="height: 111px;" loading="lazy">
                            </div>
                            <div class="col-sm-2">
                              <img src="../../../../assets/img/self-drive/maps/map15/key2.png" alt="" loading="lazy"> 
                            </div>
                            <div class="col-sm-2">
                              <img src="../../../../assets/img/self-drive/maps/map15/key3.png" alt="" loading="lazy">
                            </div>
                            <div class="col-sm-2">
                              <img src="../../../../assets/img/self-drive/maps/map15/key4.png" alt="" loading="lazy">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md" style="margin-left: 40px;">
                                <div class="container map-card">
                                    <h6 style="color: #c49a6c;padding-top: 6px;">Optional Routes</h6>
                                    <div class="col-sm">
                                        <ol style="color: #c49a6c;">
                                            <li>Nakel</li>
                                            <li>Wakan</li>
                                            <li>AL Awabi</li>
                                            <li>Burkat Al Mouz</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md" >
                                <div class="container map-card">
                                    <h6 style="color: #c49a6c;padding-top: 6px;">Optional Routes</h6>
                                    <div class="col-sm">
                                        <ol style="color: #c49a6c;" start="5">
                                            <li>Al Hamra</li>
                                            <li>Tanuf</li>
                                            <li>Sinaw</li>
                                            <li>Ibra</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md" style="margin-left: 40px;">
                                <div class="container map-card">
                                    <h6 style="color: #c49a6c;padding-top: 6px;">Optional Routes</h6>
                                    <div class="col-sm">
                                        <ol style="color: #c49a6c;" start="9">
                                            <li>Wadi Bani Khalid</li>
                                            <li>Raz Al Hadd</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md" style="margin-left: 6px;">
                                <div class="container map-card">
                                    <h6 style="color: #c49a6c;padding-top: 6px;">Optional Routes</h6>
                                    <div class="col-sm">
                                        <ol style="color: #c49a6c;" start="11">
                                            <li>Qalhat</li>
                                            <li>Quriyat</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
        </div>

    </div>
</section>

<section class="about-area ">
    <div class="container">
        <div class="about-inner-area">
            <div class="row">
                <div class="">
                    <div class="about-text">
                        <div class="container">
                            <img src="../../../../assets/img/self-drive/map-head16.png" alt="" loading="lazy">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row align-items-center" style="margin-top: 30px;">
            <div class="card mb-3" style="height: 434px;width: 100%;" >
                <div class="row g-0">
                  <div class="col-md-5">
                    <img src="" alt="" style="height: 100%;">
                  </div>
                  <div class="col-md-5" style="margin-left: -30px;">
                    <div class="card-width">  
                        <div class="row" style="margin-left: 9px;">
                            <div class="col-sm-2">
                                <img src="../../../../assets/img/self-drive/maps/map16/key1.png" alt="" style="height: 111px;" loading="lazy">
                            </div>
                            <div class="col-sm-2">
                              <img src="../../../../assets/img/self-drive/maps/map16/key2.png" alt="" loading="lazy">
                            </div>
                            <div class="col-sm-2">
                              <img src="../../../../assets/img/self-drive/maps/map16/key3.png" alt="" loading="lazy">
                            </div>
                            <div class="col-sm-2">
                              <img src="../../../../assets/img/self-drive/maps/map16/key4.png" alt="" loading="lazy">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md" style="margin-left: 40px;">
                                <div class="container map-card">
                                    <h6 style="color: #c49a6c;padding-top: 6px;">Optional Routes</h6>
                                    <div class="col-sm">
                                        <ol style="color: #c49a6c;">
                                            <li>Wadi Al Jizi</li>
                                            <li>Dhank</li>
                                            <li>Wadi Al Ayn</li>
                                            <li>Al Hamra</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md" >
                                <div class="container map-card">
                                    <h6 style="color: #c49a6c;padding-top: 6px;">Optional Routes</h6>
                                    <div class="col-sm">
                                        <ol style="color: #c49a6c;" start="5">
                                            <li>Tanuf</li>
                                            <li>Izki</li>
                                            <li>Al Hawqayn</li>
                                            <li>Samail</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md" style="margin-left: 40px;">
                                <div class="container map-card">
                                    <h6 style="color: #c49a6c;padding-top: 6px;">Optional Routes</h6>
                                    <div class="col-sm">
                                        <ol style="color: #c49a6c;" start="9">
                                            <li>Fanja Town</li>
                                            <li>Barka </li>
                                            <li>Burkat Al Mouz</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
        </div>

    </div>
</section>

<section class="about-area ">
    <div class="container">
        <div class="about-inner-area">
            <div class="row">
                <div class="">
                    <div class="about-text">
                        <div class="container">
                            <img src="../../../../assets/img/self-drive/map-head17.png" alt="" loading="lazy">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row align-items-center" style="margin-top: 30px;">
            <div class="card mb-3" style="height: 434px;width: 100%;" >
                <div class="row g-0">
                  <div class="col-md-5">
                    <img src="" alt="" style="height: 100%;" loading="lazy">
                  </div>
                  <div class="col-md-5" style="margin-left: -30px;">
                    <div class="card-width">  
                        <div class="row" style="margin-left: 9px;">
                            <div class="col-sm-2">
                                <img src="../../../../assets/img/self-drive/maps/map17/key1.png" alt="" style="height: 111px;" loading="lazy">
                            </div>
                            <div class="col-sm-2">
                              <img src="../../../../assets/img/self-drive/maps/map17/key2.png" alt="" loading="lazy">
                            </div>
                            <div class="col-sm-2">
                              <img src="../../../../assets/img/self-drive/maps/map17/key3.png" alt="" loading="lazy">
                            </div>
                            <div class="col-sm-2">
                              <img src="../../../../assets/img/self-drive/maps/map17/key4.png" alt="" loading="lazy">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md" style="margin-left: 40px;">
                                <div class="container map-card">
                                    <h6 style="color: #c49a6c;padding-top: 6px;">Optional Routes</h6>
                                    <div class="col-sm">
                                        <ol style="color: #c49a6c;">
                                            <li>Nakel</li>
                                            <li>Al Awabi</li>
                                            <li>Al Hamra</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md" >
                                <div class="container map-card">
                                    <h6 style="color: #c49a6c;padding-top: 6px;">Optional Routes</h6>
                                    <div class="col-sm">
                                        <ol style="color: #c49a6c;" start="4">
                                            <li>Tanuf</li>
                                            <li>Sinaw</li>
                                            <li>Ibri</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md" style="margin-left: 40px;">
                                <div class="container map-card">
                                    <h6 style="color: #c49a6c;padding-top: 6px;">Optional Routes</h6>
                                    <div class="col-sm">
                                        <ol style="color: #c49a6c;" start="7">
                                            <li>Al Ashkhara</li>
                                            <li>Raz Al Hadd </li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md" >
                                <div class="container map-card">
                                    <h6 style="color: #c49a6c;padding-top: 6px;">Optional Routes</h6>
                                    <div class="col-sm">
                                        <ol style="color: #c49a6c;" start="9">
                                            <li>Qalhat</li>
                                            <li>Quriyat</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
        </div>

    </div>
</section>

<section class="about-area ">
    <div class="container">
        <div class="about-inner-area">
            <div class="row">
                <div class="">
                    <div class="about-text">
                        <div class="container">
                            <img src="../../../../assets/img/self-drive/map-head18.png" alt="" loading="lazy">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row align-items-center" style="margin-top: 30px;">
            <div class="card mb-3" style="height: 300px;width: 100%;" >
                <div class="row g-0">
                  <div class="col-md-5">
                    <img src="" alt="" style="height: 298px; width: 100%;" loading="lazy">
                  </div>
                  <div class="col-md-5" style="margin-left: -30px;">
                    <div class="card-body">  
                        <div class="row">
                            <div class="col-sm-2">
                                <img src="../../../../assets/img/self-drive/maps/map18/key1.png" alt="" loading="lazy">
                            </div>
                            <div class="col-sm-2">
                              <img src="../../../../assets/img/self-drive/maps/map18/key2.png" alt="" loading="lazy">
                            </div>
                            <div class="col-sm-2">
                              <img src="../../../../assets/img/self-drive/maps/map18/key3.png" alt="" loading="lazy">
                            </div>
                            <div class="col-sm-2">
                              <img src="../../../../assets/img/self-drive/maps/map18/key4.png" alt="" loading="lazy">
                            </div>
                        </div>
                        <div class="row">
                            <div class="container sm-card">
                                <h6 style="color: #c49a6c;padding-top: 6px;">Optional Routes</h6>
                                <div class="col-sm">
                                    <ol style="color: #c49a6c;">
                                        <li>Al Shwaymia</li>
                                        <li>Mahut Island</li>
                                        <li>Suqar Beach</li>
                                        <li>Kaloof</li>
                                    </ol>
                                </div>
                            </div>
                            <div class="container sm-card">
                                <h6 style="color: #c49a6c;padding-top: 6px;">Optional Routes</h6>
                                <div class="col-sm">
                                    <ol style="color: #c49a6c;" start="4">
                                        <li>Al Ashkharah</li>
                                        <li>Qalhat</li>
                                        <li>Quriyat</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
        </div>

    </div>
</section>

<section class="about-area ">
    <div class="container">
        <div class="about-inner-area">
            <div class="row">
                <div class="">
                    <div class="about-text">
                        <div class="container">
                            <img src="../../../../assets/img/self-drive/map-head19.png" alt="" loading="lazy">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row align-items-center" style="margin-top: 30px;">
            <div class="card mb-3" style="height: 300px;width: 100%;" >
                <div class="row g-0">
                  <div class="col-md-5">
                    <img src="" alt="" style="height: 298px; width: 100%;" loading="lazy">
                  </div>
                  <div class="col-md-5" style="margin-left: -30px;">
                    <div class="card-body">  
                        <div class="row">
                            <div class="col-sm-2">
                                <img src="../../../../assets/img/self-drive/maps/map19/key1.png" alt="" loading="lazy">
                            </div>
                            <div class="col-sm-2">
                              <img src="../../../../assets/img/self-drive/maps/map19/key2.png" alt="" loading="lazy">
                            </div>
                            <div class="col-sm-2">
                              <img src="../../../../assets/img/self-drive/maps/map19/key3.png" alt="" loading="lazy">
                            </div>
                            <div class="col-sm-2">
                              <img src="../../../../assets/img/self-drive/maps/map19/key4.png" alt="" loading="lazy">
                            </div>
                        </div>
                        <div class="row">
                            <div class="container sm-card">
                                <h6 style="color: #c49a6c;padding-top: 6px;">Optional Routes</h6>
                                <div class="col-sm">
                                    <ol style="color: #c49a6c;">
                                        <li>Barka</li>
                                        <li>Al Hawqayn</li>
                                        <li>AL Awabi</li>
                                    </ol>
                                </div>
                            </div>
                            <div class="container sm-card">
                                <h6 style="color: #c49a6c;padding-top: 6px;">Optional Routes</h6>
                                <div class="col-sm">
                                    <ol style="color: #c49a6c;" start="4">
                                        <li>Balad Sayt</li>
                                        <li>Tanuf</li>
                                        <li>Manah</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
        </div>

    </div>
</section>


