<app-header *ngIf="!(location == '/error' || location == '/coming-soon' 
    || location == '/sign-in' 
    || location == '/sign-up'
    ||location == '/forgot-password')">
</app-header>

<div class="page-title-area page-title-bg">
    <div class="container">
        <div class="page-title-content">
            <h2>Contact Us</h2>
        </div>
    </div>
</div>

<section class="contact-area ptb-100">
    <div class="container main-services">
        <div class="row">
            
            <div class="col-lg-7 col-md-12">
                <div class="contact-form">
                    <h3>Drop Us A Line</h3>
                    <p>We're happy to answer any questions you have or provide you with an estimate. Just send us a message in the form below with any questions you may have.</p>

                    <form id="contactForm" [formGroup]="contactFrom" (submit)="onSend()">
                        <div class="row">
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <label>Name <span>*</span></label>
                                    <input type="text" name="name" id="name" class="form-control" placeholder="Your name" formControlName="name">
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <label>Email <span>*</span></label>
                                    <input type="email" name="email" id="email" class="form-control" placeholder="Your email address" formControlName="email">
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <label>Phone Number <span>*</span></label>
                                    <input type="text" name="phone_number" id="phone_number" class="form-control" placeholder="Your phone number" formControlName="phoneNumber">
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <label>Your Message <span>*</span></label>
                                    <textarea name="message" id="message" cols="30" rows="5" class="form-control" placeholder="Write your message..." formControlName="message"></textarea>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12 pb-3">
                                <button type="submit" class="btn  explore-button btn-md">Send Message</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="col-md-4">
                <div class="box_style_1">
                    
                    <h4>
                        Address
                        <span><i class="icon-pin pull-right"></i></span></h4>
                    <p>
                        Sultanate of Oman, Muscat, Al Seeb, Al Hill South, Al Barakat Street,<br>
                        Post Box 211, PC 111
                    </p>
                    <hr>
                   
                    
                        <p>
                            Contact Us At
                        </p>
                        
                        <p><a href="#">info@hekaya.co</a>
                        </p>
                    
                    <hr>
                   
                </div>
                <div class="box_style_4">
                   

                </div>
            </div>

        </div>
    </div>
</section>




<app-footer *ngIf="!(location == '/error' || location == '/coming-soon' || location == '/sign-in'
    || location == '/sign-up'
    ||location == '/forgot-password')">
    </app-footer>