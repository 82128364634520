<app-header *ngIf="!(location == '/error' || location == '/coming-soon' 
    || location == '/sign-in' 
    || location == '/sign-up'
    ||location == '/forgot-password')">
</app-header>





<section class="login-area">
    <div class="row m-0">
        <div class="col-lg-6 col-md-12 p-0">
            <div class="login-image">
                
            </div>
        </div>

        <div class="col-lg-6 col-md-12 p-0">
            <div class="login-content">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="login-form">
                            <div class="logo">
                                <a routerLink="/"><img src="https://hekaya.s3.ap-south-1.amazonaws.com/carRental/logodark.png" alt="image" loading="lazy"></a>
                            </div>

                            <h3>Welcome Back</h3>
                            <p>New to Hekaya? <a routerLink="/sign-up">Sign up</a></p>

                            <form [formGroup]="loginFrom" >
                                <div class="form-group">
                                    <input type="email" placeholder="Your Email Address" class="form-control"
                                        formControlName="emailAddress">
                                </div>

                                <div class="form-group">
                                    <input type="password" placeholder="Your Password" class="form-control"
                                        formControlName="password">
                                </div>

                                <button  (click)="onSignIn()">Login</button>

                                <div class="forgot-password">
                                    <a routerLink="/forgot-password">Forgot Password?</a>
                                </div>

                                
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<app-footer *ngIf="!(location == '/error' || location == '/coming-soon' || location == '/sign-in'
     || location == '/sign-up'
     ||location == '/forgot-password')">
</app-footer>