import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LeaseAgreementService } from './../../../services/lease-agreement/lease-agreement.service';

import { PickUp } from './../../../models/car-rental/search/search-request.model';
import { Component, OnInit, NgModule, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, EmailValidator } from '@angular/forms';
import { NavigationCancel, NavigationEnd, Router } from '@angular/router';
import { DatePipe, LocationStrategy, PathLocationStrategy } from '@angular/common'

import { LocationService } from '../../../services/location/location.service';
import * as $ from "jquery";
import { Location } from '../../../models/location/location.model';
import { SearchRequest } from 'src/app/models/car-rental/search/search-request.model';
import { RequestType, ServiceRequestType, ServiceType } from 'src/app/models/service.constant';
import { CarRentalService } from 'src/app/services/car-rental-service/car-rental.service';
import { Variable } from '@angular/compiler/src/render3/r3_ast';
import { UtilityService } from 'src/app/services/utility-service/utility.service';
import { AlertService } from 'ngx-alerts';
import { LeaseEnquiry } from 'src/app/models/lease-enquiry/lease-enquiry.model';
import { LeaseEnquiryRequest } from 'src/app/models/car-rental/lease-enquiry/leaseEnquiry-request.model';
import { LeaseEnquiryResponse } from 'src/app/models/car-rental/lease-enquiry/leaseEnquiry-response.model';
import { SearchFilterPipe } from 'src/app/models/shared/filter-pipe';
import { ClickOutsideDirective } from 'src/app/models/shared/dropdown.directive';
import { LetterBoldPipe } from 'src/app/models/shared/letter-bold.pipe';
import { filter } from 'rxjs/operators';
import { Meta } from '@angular/platform-browser';
import { Title } from '@angular/platform-browser';
import { SearchCarComponent } from '../../shared/components/search-car/search-car.component';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [
    Location, {
        provide: LocationStrategy,
        useClass: PathLocationStrategy
    }
]

})


export class HomeComponent implements OnInit {
  @ViewChild(SearchCarComponent, { static: false })
  private searchCarComponent: SearchCarComponent;
  //#region  Variables
  minDatePickUp: Date;
  minDateReturn: Date;
  carRental = environment.carRental;
  pickPark = environment.pickAndPark;
  selfDrive = environment.selfDrive;
  leaseDetails = Object(LeaseEnquiry)
  showDropDown = false;
  viewMore = false;
  faqViewMore = false;
  location: any;
  routerSubscription: any;
  //#endregion

  constructor(private locationService: LocationService
    , private formBuilder: FormBuilder
    , private carRentalService: CarRentalService
    , private _router: Router
    , private datepipe: DatePipe
    , private _utilityService: UtilityService
    , private alertService: AlertService
    , private _leaseService: LeaseAgreementService
    , public _datepipe: DatePipe
    , public http: HttpClient
    , private meta: Meta
    , private titleService: Title
    ,private renderer: Renderer2) {

    this.leaseDetails = new Array<LeaseEnquiry>();    
  }

  ngOnInit(): void {
    
    // this.meta.addTag({ rel: 'canonical', href: 'https://car-rental.hekaya.co' });
    const canonicalLink = this.renderer.createElement('link');
    this.renderer.setAttribute(canonicalLink, 'rel', 'canonical');
    this.renderer.setAttribute(canonicalLink, 'href', 'https://car-rental.hekaya.co');
    this.renderer.appendChild(document.head, canonicalLink);
    this.titleService.setTitle('Car Rental in Muscat, Oman | Book Online Now | Hekaya');

    this.meta.addTag({ 
      name: 'description', 
      content: 'Looking for Car Rental in Muscat, Oman? Look no further than Hekaya as we are the leading car rental company providing budget-friendly cars. Book online now.' 
    });

    this.meta.addTag({ 
      property: 'og:title', 
      content: 'Car Rental in Muscat, Oman | Book Online Now | Hekaya' 
    });

    this.meta.addTag({ 
      property: 'og:description', 
      content: 'Looking for Car Rental in Muscat, Oman? Look no further than Hekaya as we are the leading car rental company providing budget-friendly cars. Book online now.' 
    });

    this.meta.addTag({ 
      property: 'og:url', 
      content: 'https://car-rental.hekaya.co' 
    });

    this.meta.addTag({ 
      property: 'og:type', 
      content: 'website' 
    });

    this.meta.addTag({ 
      name: 'og:image', 
      content: 'assets/img/car-rental/car-rental.jpg'
    });
   

    this.minDatePickUp = new Date();
    this.minDateReturn = new Date();
   
   
    this.recallJsFuntions();
    
  }


  //#region  Private Methods



  //NEED A CAR DELIVERY FORM

  recallJsFuntions() {
    this.routerSubscription = this._router.events
        .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
        .subscribe(event => {
            $.getScript('../assets/js/main.js');
            this.location = this._router.url;
            if (!(event instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0);
        });
}


  //#endregion

}
