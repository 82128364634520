<app-header-one></app-header-one>
<div class="menu_bar">
   
</div>



<div class="container-fluid">
    <div class="row row_top">
          <div class="col-md-3 col-sm-12">
                 <div class="sidebox">
                     <div class="side_header">booking details  </div>
                     <div class="side_container">
                        <div class="side_container_inner">
                            <h6>PICKUP</h6>
                            <i class='bx bxs-calendar side_icon'></i>
                        </div>
                        <div class="side_container_inner2">
                            <h6>Muscat International Airport,Muscat <br>
                                September 12, 2020 10:00</h6>
                            
                        </div>
                     </div>
                     <div class="side_container">
                        <div class="side_container_inner">
                            <h6>RETURN</h6>
                            <i class='bx bxs-calendar side_icon'></i>
                        </div>
                        <div class="side_container_inner2">
                            <h6>Muscat International Airport,Muscat <br>
                                September 12, 2020 10:00</h6>
                            
                        </div>
                     </div>
                     <div class="rental_length">RENTAL LENGTH</div>
                     <div>3 DAYS</div>
                     <div class="align_center">
                        <button  class=" button_margin btn btn-warning ">MODIFY SEARCH</button>
                     </div>
                 </div>
               
          </div>
          <div class="col-md-9 col-sm-12">
             <div class="fleet_box">
                <div class="row car_row">
                      <div class="col-md-4 col-sm-3 first_col right_border">
                       
                          <h6>NISSAN SUNNY </h6>
                          <span class="small_text"> (or similar)</span><br>
                          <img alt="Image canot Displayed" src="https://images-na.ssl-images-amazon.com/images/I/61UYdfUBAoL._SX425_.jpg" class="img-fluid img_width">    
                          <br> <span class="small_text"> Economy</span>
                      </div>
                     <div class="col-md-5 col-sm-5 right_border">
                         <div class="row">
                          <div class="third_col_pad  col-md-6  col-sm-6 ">
                              <p> <img [src]=seats_icon> 5 Seats  </p>
                              <p>  <img [src]=door_icon> 5 Door  </p>
                              <p> <img [src]=trans_icon>  Auto  </p>
                          
                          </div>
                          <div class=" third_col_pad right_border col-md-6 col-sm-6 ">
                              <p><img [src]=bag_icon>  2 Luggage(s)  </p>
                              <p><img [src]=settings_icon>  1.5 L Engine  </p>
                              <p> <img [src]=ac_icon>  AC </p>
                          </div>
                         </div>
                         <div class="row row_top_border">
                              <div class="third_col_pad  col-md-6  col-sm-6 ">
                                  <i class='bx bxs-map sub_icon'></i>
                                  <p class=" float_left">Current Location <br>Muscat International Airport</p>
                              </div>   
                              <div class="third_col_pad  col-md-6  col-sm-6 ">
                                  <i class='bx bxs-coin-stack sub_icon'></i>
                                  <p class=" float_left">Delivery Charge <br>00.20 OMR</p>
                              </div>     
                              

                         </div>
                     </div>

                      <div class=" third_col_pad col-md-3 col-sm-4 ">
                           <span class="total_charges">TOTAL CHARGES</span>
                           <h3>9.26 OMR</h3>
                           <div class="refundable">Refundable</div>
                           <button routerLink="/personal-info" class=" button_margin btn btn-warning btn-lg">Book Now</button>
                      </div>
                </div>
             </div>
<!-- tab section -->
             <div class="row row_top">
                    <div class="col-md-12">
                        <nav>
                            <div class="nav nav-tabs" id="nav-tab" role="tablist">
                              <a class="nav-item nav-link active tab_design" id="nav-home-tab" data-toggle="tab" href="#nav-home" role="tab" aria-controls="nav-home" aria-selected="true">Features</a>
                              <a class="nav-item nav-link tab_design" id="nav-profile-tab" data-toggle="tab" href="#nav-profile" role="tab" aria-controls="nav-profile" aria-selected="false">Terms & Conditions</a>
                              <a class="nav-item nav-link tab_design" id="nav-contact-tab" data-toggle="tab" href="#nav-contact" role="tab" aria-controls="nav-contact" aria-selected="false">Documents</a>
                            </div>
                          </nav>
                          <div class="tab-content nav_border" id="nav-tabContent">
                            <div class="tab-pane fade show active " id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                                <!-- features tab -->
                                        <div class="container">
                                            <div class="row feature_row_pad border_bottom">
                                                <div class="col-md-1">
                                                    <img [src]=gps_icon class="img-fluid" alt="GPS">
                                                </div>
                                                <div class="col-md-7">
                                                    <h6 class="head_weight" >GPS</h6> 
                                                    <span class="line_height">GPS Satellite System</span>
                                                </div>
                                                <div class="col-md-4">
                                                   <h6 class="float_left">OMR 03.00 / PER DAY</h6>     
                                                   <div class="container_frame float_cont">
                                                        <label class="container">
                                                            <input type="checkbox" checked="checked">
                                                            <span class="checkmark"></span>
                                                            
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>    
                                                    <!-- second row -->
                                                    <div class="row feature_row_pad border_bottom">
                                                        <div class="col-md-1">
                                                            <img [src]=driver_icon class="img-fluid" alt="GPS">
                                                        </div>
                                                        <div class="col-md-7">
                                                            <h6 class="head_weight">Additional Driver </h6> 
                                                            <span class="line_height">Share the driving on any journey and enjoy the peace of mind that someone else is insured to drive if needed.</span>
                                                        </div>
                                                        <div class="col-md-4">
                                                           <h6 class="float_left">OMR 03.00 / PER DAY</h6>     
                                                           <div class="container_frame float_cont">
                                                                <label class="container">
                                                                    <input type="checkbox" checked="checked">
                                                                    <span class="checkmark"></span>
                                                                    
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>    
                                                    <!-- third row -->
                                                    <div class="row feature_row_pad border_bottom">
                                                        <div class="col-md-1">
                                                            <img [src]=baby_icon class="img-fluid" alt="GPS">
                                                        </div>
                                                        <div class="col-md-7">
                                                            <h6 class="head_weight">Baby Safety Seat </h6> 
                                                            <span class="line_height">For children 1-4 years (13-25 kg, 20-50 lb) </span>
                                                        </div>
                                                        <div class="col-md-4">
                                                           <h6 class="float_left">OMR 03.00 / PER DAY</h6>     
                                                           <div class="container_frame float_cont">
                                                                <label class="container">
                                                                    <input type="checkbox" checked="checked">
                                                                    <span class="checkmark"></span>
                                                                    
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>    
                                                    <!-- forth row -->
                                                    <div class="row feature_row_pad border_bottom">
                                                        <div class="col-md-1">
                                                            <img [src]=col_icon class="img-fluid" alt="GPS">
                                                        </div>
                                                        <div class="col-md-7">
                                                            <h6 class="head_weight">Collision Damage Waiver (CDW) </h6> 
                                                            <span class="line_height">OMR: 0 deductible </span>
                                                        </div>
                                                        <div class="col-md-4">
                                                           <h6 class="float_left">OMR 03.00 / PER DAY</h6>     
                                                           <div class="container_frame float_cont">
                                                                <label class="container">
                                                                    <input type="checkbox" checked="checked">
                                                                    <span class="checkmark"></span>
                                                                    
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>    
                                                    <!-- fifith row -->
                                                    <div class="row feature_row_pad ">
                                                        <div class="col-md-1">
                                                            <img [src]=acc_icon class="img-fluid" alt="GPS">
                                                        </div>
                                                        <div class="col-md-7">
                                                            <h6 class="head_weight">Personal Accident Insurance </h6> 
                                                            <span class="line_height">Personal Accident Insurance Covers driver and passengers in case of serious personal injury. </span>
                                                        </div>
                                                        <div class="col-md-4">
                                                           <h6 class="float_left">OMR 03.00 / PER DAY</h6>     
                                                           <div class="container_frame float_cont">
                                                                <label class="container">
                                                                    <input type="checkbox" checked="checked">
                                                                    <span class="checkmark"></span>
                                                                    
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>    
                                                  
                                        </div>

                                <!-- features tab ends -->

                            </div>
                            <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">...</div>
                            <div class="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">...</div>
                          </div>
                    </div>
             </div>
             <div class="row  text_right">
                 <div class="total_pad col-md-12">
                    <h3 class="float_right total_pad">Total : OMR 09.20</h3>
                    <button routerLink="/personal-info" class=" button_margin btn btn-warning btn-lg">Book Now</button>
                          
                 </div>
             </div>

         </div>
  </div>
</div>