import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { UtilityService } from 'src/app/services/utility-service/utility.service';

declare const google: any;
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  public appSettings: any;
  hasInitMap = false;
  currentYear: number = new Date(). getFullYear();
  @ViewChild('googleMapPopup') public googleMapPopup: ModalDirective;
  map: any;
  constructor(private _utilityService: UtilityService
    , private router: Router) { }

  ngOnInit(): void {
  }

  myAccount(){
    this._utilityService.getAppSettings().subscribe(appSettings => {
      if (appSettings) {
        this.appSettings = appSettings;

        if(!appSettings?.isAdmin){
          this.router.navigate(['/my-account/profile']);
        } else {
          this.router.navigate(['/login']);
        }
      }
    });
  }
  onShowGoogleMap() {
    console.log('Mark');
    if (!this.hasInitMap) {
      this.initMap();
      // this.addMarkers();
      this.hasInitMap = true;
    }
    this.googleMapPopup.show();
  }
  initMap(): void {
    this.map = new google.maps.Map(document.getElementById('map'), {
      center: { lat: 23.321440001173656, lng: 57.962086724388875 },
      zoom:20 // Adjust the initial zoom level as needed
    });

    // Add a custom marker
    const icon = {
      url: 'https://cdn.pixabay.com/photo/2014/04/03/10/03/google-309740_960_720.png',
      scaledSize: new google.maps.Size(25,30), // Adjust the size as needed
      anchor: new google.maps.Point(20,-30)
    };

    const googleMarker = new google.maps.Marker({
      position: { lat: 23.321440001173656, lng: 57.962086724388875 },
      map: this.map,
      icon: icon
    });   
    const infoWindow = new google.maps.InfoWindow({
      content: `<div style="text-align: center;">
        <div>Company location</div>
        <div style="font-weight: bold;">Hekaya Holidays SPC</div>
    </div>
    `,
    });
 
    googleMarker.addListener('mouseover', () => {
      const iframeContent = `
      <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d47548.75570105729!2d57.78136845058644!3d23.315808273797607!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e8e6d721c04ded7%3A0xde70c01c0cd80024!2sHekaya%20Holidays%20SPC!5e0!3m2!1sen!2som!4v1697031684150!5m2!1sen!2som" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      `;
      infoWindow.setContent(iframeContent);
      infoWindow.open(this.map, googleMarker);
    });
    
  }


  

  // Other methods...
}

document.addEventListener('DOMContentLoaded', function() {
  const myElement = document.getElementById('chats');

  if (myElement) {
      myElement.addEventListener('click', function() {
          this.classList.toggle('highlight');
      });
  }
});