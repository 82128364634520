<app-self-drive-nav *ngIf="!(location == '/error' || location == '/coming-soon'
    || location == '/sign-in'
    || location == '/sign-up'
    ||location == '/forgot-password')">
</app-self-drive-nav>

<div class="page-title-area page-title-bg3">
    <div class="hekaya-container">
        <div class="page-title-content">
            <h2>WHY </h2>
            <h4>hekayat</h4>
        </div>
    </div>
</div>

<div class="container">
    <section class="listing-area extra-pt pb-100">
        <div class="container">
            <div class="section-title">
                <h2>Why Hekayat</h2>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-4 col-sm-6">
                <div class="single-categories-listing-item bg1">
                    <div class="icon">
                        <i class='bx bx-shopping-bag'></i>
                    </div>
                    <h3>1</h3>
                    <p>Make is much easier to discover Oman, just in your way.</p>
                    <!-- <a routerLink="/listing-two" class="learn-more-btn">Learn More <i class='bx bx-chevron-right'></i></a>
                    <a routerLink="listing-2.html" class="link-btn"></a> -->
                </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-6">
                <div class="single-categories-listing-item bg2">
                    <div class="icon">
                        <i class='bx bx-shopping-bag'></i>
                    </div>
                    <h3>2</h3>
                    <p>Plan your self-drive and self-camp safely and probably. </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-6">
                <div class="single-categories-listing-item bg3">
                    <div class="icon">
                        <i class='bx bx-shopping-bag'></i>
                    </div>
                    <h3>3</h3>
                    <p>Choose your needed vehicle.</p>                    
                </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-6">
                <div class="single-categories-listing-item bg4">
                    <div class="icon">
                        <i class='bx bx-shopping-bag'></i>
                    </div>
                    <h3>4</h3>
                    <p>Understand what to expect and skills required.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-6">
                <div class="single-categories-listing-item bg5">
                    <div class="icon">
                        <i class='bx bx-shopping-bag'></i>
                    </div>
                    <h3>5</h3>
                    <p>Find out all necessary guidance, maps and information at one window.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-6">
                <div class="single-categories-listing-item bg6">
                    <div class="icon">
                        <i class='bx bx-shopping-bag'></i>
                    </div>
                    <h3>6</h3>
                    <p>Get all necessary tools and equipment’s (No need to buy anything)</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-6">
                <div class="single-categories-listing-item bg7">
                    <div class="icon">
                        <i class='bx bx-shopping-bag'></i>
                    </div>
                    <h3>7</h3>
                    <p>Get other equipment’s you need to experience more. </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-6">
                <div class="single-categories-listing-item bg8">
                    <div class="icon">
                        <i class='bx bx-shopping-bag'></i>
                    </div>
                    <h3>8</h3>
                    <p>Ask for more and get the assistance you need</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-6">
                <div class="single-categories-listing-item bg9">
                    <div class="icon">
                        <i class='bx bx-shopping-bag'></i>
                    </div>
                    <h3>9</h3>
                    <p>Get 24/7 support</p>
                </div>
            </div>
        </div>
    </section>
</div>


<div class="hekaya-container pb-100">
    <section class="hekaya-banner-Hekayat ptb-100 jarallax" data-jarallax='{"speed": 0.3}'>
        <div class="container">
            <div class="hekaya-content">
                <h2>Enjoy the true <br> <span> Hekayat.</span></h2>
                <!-- <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn popup-youtube"><i class='bx bx-play'></i></a> -->
            </div>
        </div>
    </section>
</div>



<app-self-drive-footer *ngIf="!(location == '/error' || location == '/coming-soon' || location == '/sign-in'
    || location == '/sign-up'
    ||location == '/forgot-password')">
</app-self-drive-footer>
