import { Component, OnInit } from '@angular/core';
import { AlertService } from 'ngx-alerts';
import { MyProfileService } from './../../../../services/my-profile/my-profile.service';
import { FormBuilder, FormGroup, Validators, EmailValidator, FormControl } from '@angular/forms';
import { NavigationCancel, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { UtilityService } from 'src/app/services/utility-service/utility.service';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Validator,PatternValidator } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { AnyMxRecord } from 'dns';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  
  //get f() { return this.forgotFrom.controls };
  isSubmitted: boolean;
  isLoginAction: boolean = false;
  location: any;
  routerSubscription: any;
  resetPasswordForm: FormGroup;
  Email:string='';
  Password:string='';
  Time:string='';
  currentPassword:string=''
  isLinkExpired: boolean = false;
  
  myTime: Date;
  isExpired: boolean;
  constructor(private formBuilder: FormBuilder
    , private _router: Router
    , private _utilityService: UtilityService
    , private _authService: AuthenticationService
    , private alertService: AlertService
    , public userService:MyProfileService
    ,private _activatedRoute:ActivatedRoute
    ,private datePipe: DatePipe
    ) { }
     
  ngOnInit(): void {
    
  
    this._activatedRoute.queryParams.subscribe(params => {
      console.log(params);
      Object.keys(params).forEach(encodedKey => {
        const decodedKey = this.base64UrlDecode(encodedKey);
        const encodedValue = params[encodedKey];
        const decodedValue = this.base64UrlDecode(encodedValue.split('=')[0]);
        console.log(`Key: ${decodedKey}, Decoded Value: ${decodedValue}`);
      
  
        // console.log(decodedKey);
        const emailMatch = decodedKey.match(/email=([^&]+)/);
        const passwordMatch = decodedKey.match(/password=([^&]+)/);
        const timestampMatch = decodedKey.match(/timestamp=([^&]+)/);
        if (emailMatch) {
          let email = emailMatch[1];
          this.Email=email;
          console.log(this.Email);
          // Assign the email value to another variable or use it as needed
        }
        if (passwordMatch) {
          let password = passwordMatch[1];
          this.Password=password;
          console.log(this.Password);
          // Assign the password value to another variable or use it as needed
        }
        if (timestampMatch) {
          let timestamp = timestampMatch[1];
          this.Time=timestamp;
        
          console.log(this.Time);
          // Assign the timestamp value to another variable or use it as needed
        }
      });
    });
  const currentDate = new Date();
  const currentTime=this.datePipe.transform(currentDate, 'dd/MM/yyyy-HH:mm');
  console.log(currentTime);
  const [dateString, timeString]:any=currentTime.split('-');
  const currentTime1=new Date(` ${dateString} ${timeString}`)
  const dateTimeString = this.Time; // Replace with your actual date string
  const index = dateTimeString.indexOf(':');
  const dateString1= dateTimeString.substring(0, index).trim();
  const timeString1 = dateTimeString.substring(index + 1).trim();
  const myTime=new Date(` ${dateString1} ${timeString1}`);
  console.log(myTime);
  const currentTime1UTC = new Date(
    currentTime1.getUTCFullYear(),
    currentTime1.getUTCMonth(),
    currentTime1.getUTCDate(),
    currentTime1.getUTCHours(),
    currentTime1.getUTCMinutes(),
    currentTime1.getUTCSeconds()
  );
  console.log(currentTime1UTC);
  const timeDifferenceInMilliseconds = currentTime1UTC.getTime()-myTime.getTime();
  const timeDifferenceInMinutes = Math.floor(timeDifferenceInMilliseconds / (1000 * 60));
  console.log(timeDifferenceInMinutes);
  const isDateExceeded = currentTime1UTC.getDate() > myTime.getDate();
  const isTimeExceeded = timeDifferenceInMinutes >10;
  this.isExpired = isDateExceeded || isTimeExceeded;
  if (this.isExpired) {
    console.log("link expired");
    this._router.navigate(['/forgot-password']);
    this.alertService.danger("link expired");
  }
  else{
    console.log("not expired");
    this._authService.signIn(environment.agencyCode,this.Email , this.Password).subscribe(response => {
      console.log(response);
      if (response.isActive) {
        this._utilityService.setAppSettings(response);
        this._utilityService.saveWebSettings(response.accessToken);}
        //user login for reset password
    });
    this.initForm();
    this.recallJsFuntions();
  }
    
      // Link is valid, proceed with the reset password action
   
   }
 //Password validation
 base64UrlDecode(encoded: string): string {
  const paddingIndex = encoded.indexOf('=');
  const encodedSubstring = paddingIndex !== -1 ? encoded.substring(0, paddingIndex) : encoded;
  const decodedSubstring = atob(encodedSubstring);
  return decodedSubstring;
}
   passwordMatchValidator(formGroup: FormGroup) {
    const passwordControl = formGroup.get('newPassword');
    const confirmPasswordControl = formGroup.get('confirmPassword');
    const passwordValue = passwordControl.value;
    const confirmPasswordValue = confirmPasswordControl.value;
  
    const errors = {};
  
    if (passwordValue !== confirmPasswordValue) {
      errors['passwordMismatch'] = true;
    }
  
    if (passwordValue.length < 6) {
      errors['minlength'] = true;
    }
  
    if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/.test(passwordValue)) {
      errors['pattern'] = true;
    }
  
    passwordControl.setErrors(Object.keys(errors).length > 0 ? errors : null);
  }
  initForm(){
    this.resetPasswordForm = this.formBuilder.group({
      newPassword: ['', Validators.required,],
      confirmPassword: ['', Validators.required],
      currentPassword: [this.Password, Validators.required]
    }, { validator: this.passwordMatchValidator });
  }
//resetting password
  onResetPassword(){
    this.isSubmitted = true;
    this.isLoginAction = true;
    if (this.resetPasswordForm.valid) {
      this.isLoginAction = false;
      let currentPassword= this.resetPasswordForm.controls.currentPassword.value;
      let newPassword = this.resetPasswordForm.controls.newPassword.value;
      console.log(currentPassword);
      console.log(newPassword);
      
      
      this.userService.resetPassword(currentPassword,newPassword).subscribe(updated=> {
        this.isLoginAction = false;
        console.log(updated);
          if(updated.wasSuccesfull){
            this.alertService.success("Password reset successfully");
            this._authService.signIn(environment.agencyCode, environment.emailAddress, environment.password).subscribe(response => {
              if (response) {
                this._utilityService.setAppSettings(response);
                this._utilityService.saveWebSettings(response.accessToken);
              }
              this._router.navigate(['/sign-in']);
            }, error => {
        
            });
          } else {
            this.alertService.danger(updated.error.message);
          }
        },error=>{
          this.isLoginAction = false;
          this.alertService.danger(error.error.message);
        });
    }
      else {
        const passwordControl = this.resetPasswordForm.get('newPassword');
      if (passwordControl.errors) {
        if (passwordControl.errors.passwordMismatch) {
          this.alertService.warning('New password and confirm password mismatch error');
          return;
        }
        if (passwordControl.errors.minlength) {
          this.alertService.warning('New password must be at least 6 characters long');
          return;
        }
        if (passwordControl.errors.pattern) {
          this.alertService.warning('New password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character');
          return;
        }}
      }
  }
  recallJsFuntions() {
    this.routerSubscription = this._router.events
        .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
        .subscribe(event => {
            $.getScript('../assets/js/main.js');
            this.location = this._router.url;
            if (!(event instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0);
        });

}}
