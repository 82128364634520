import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RoutingConstants } from 'src/app/app.routing.contents';
import { GenericService } from 'src/app/services/generic-service/generic.service';
import { BookingRequest } from '../models/booking/booking-request.model';
import { BookingResponse } from '../models/booking/booking-response.model';
import { EquipmentGroup } from '../models/equipment-group/equipment-group.model';
import { SearchRequest } from '../models/search/search-request.model';
import { SearchResponse } from '../models/search/search-response.model';
import { SelectRequest } from '../models/select/select-request.model';
import { SelectResponse } from '../models/select/select-response.model';

@Injectable({
  providedIn: 'root'
})
export class SelfDriveService {

  constructor(private genericService: GenericService) { }

  selfDriveNeedDeliverySearchCriteria: any;

  search(request: SearchRequest): Observable<SearchResponse> {
    const serviceUrl = RoutingConstants.selfDrive.search;
    return this.genericService.postService(request, serviceUrl, true);
  }

  select(request: SelectRequest) : Observable<SelectResponse> {
    const serviceUrl = RoutingConstants.selfDrive.select;
    return this.genericService.postService(request, serviceUrl, true);
  }

  booking(request:BookingRequest): Observable<BookingResponse>{
    const serviceUrl = RoutingConstants.selfDrive.booking;
    return this.genericService.postService(request, serviceUrl, true);
  }

  getbookingRef(request: any) : Observable<any>{
    const serviceUrl = RoutingConstants.selfDrive.bookingByRef;
    return this.genericService.postService(request, serviceUrl, true);
  }

  public setSelectedCar(selectedItem: any) {
    localStorage.setItem('SelectedItem', JSON.stringify(selectedItem));
    
  }

  public getSelectedCar(): any {
    const selectedItem = localStorage.getItem('SelectedItem');
    return (selectedItem) ? JSON.parse(selectedItem) : null;
  }

  public getEquipmentGroup(): Observable<Array<EquipmentGroup>>{
    const serviceUrl = RoutingConstants.selfDrive.equipmentGroup;
    return this.genericService.getService(serviceUrl,true);
  }

  getMyBookingsSelfDrive(): Observable<any> {
    return this.genericService.postService({
      type: "Request",
      service: "MyBookings",
      content: {
        serviceType: "SelfDrive"
    }
    }, RoutingConstants.myAccount.bookingRefByServiceType, true);
  }

  getMyBookingsGears(): Observable<any> {
    return this.genericService.postService({
      type: "Request",
      service: "MyBookings",
      content: {
        serviceType: "GearRental"
    }
    }, RoutingConstants.myAccount.bookingRefByServiceType, true);
  }
}
