<form (submit)="onSubmitcarDeliveryLocationForm()" name="need-car-delivery" autocomplete="off">
    <div class="row">

        <div class="col-md-6">

            <div class="form-group transparent-field autotxt">
                <ngx-autocomplete [searchKeyword]="'name'" [inputId]="'searchEmployee'" [placeholderValue]="'Select Delivery City'" [entries]="deliveryLocations" autocomplete="off" class="form-control m-0 pt-0 bg-transparent border-0 transparent-field" (keyup)="onEnterLocation($event,1)"
                    [(ngModel)]="carDeliveryLocationForm.deliveryCity" name="delivery-city" (selectedValue)="onSelectItem($event,1)" (focus)="showMoreOptions=true">
                </ngx-autocomplete>
            </div>
        </div>

        <div class="col-md-6">
            <div class="form-group">
                <input autocomplete="off" type="text" class="form-control " name="delivery-address" placeholder="Delivery Address..." [(ngModel)]="carDeliveryLocationForm.deliveryAddress">
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12 left_text">
            <div class="form-check">
                <input type="checkbox" class="form-check-input col_adress  col_add " id="car-rental-collection-address" name="check-different-location" (change)="onChangeCollectionLocation()" [(ngModel)]="carDeliveryLocationForm.isEnableDifferentCollectionAddress">
                <label class="form-check-label" name="col_adress" for="car-rental-collection-address">Change Collection
                    Address</label>
            </div>
        </div>
    </div>

    <div class="row" [hidden]="!carDeliveryLocationForm.isEnableDifferentCollectionAddress">
        <div class="col-md-6">
            <div class="form-group transparent-field autotxt">
                <ngx-autocomplete #collectionCity [searchKeyword]="'name'" [inputId]="'searchEmployee'" [placeholderValue]="'Select Collection City'" [entries]="collectionCities" autocomplete="off" class="m-0 pt-0 bg-transparent border-0 transparent-field" (keyup)="onEnterLocation($event,2)" [(ngModel)]="carDeliveryLocationForm.collectionCity"
                    name="collection-city" (selectedValue)="onSelectItem($event,2)">
                </ngx-autocomplete>
            </div>
        </div>

        <div class="col-md-6">
            <div class="form-group">
                <input type="text" class="form-control " name="collection-address" placeholder="Collection Address..." [(ngModel)]="carDeliveryLocationForm.collectionAddress">
            </div>
        </div>

    </div>

    <div class="row align-items-center">
        <div class="col-md-6">
            <div class="form-group">
                <div class="form-group ">

                    <input type="text" class="form-control" placeholder="Delivery Date" name="delivery-date"
                            [minDate]="minDateDelivery" [maxDate]="maxDatePickUp" #dp="bsDatepicker" bsDatepicker 
                            [(ngModel)]="carDeliveryLocationForm.deliveryDate" [readonly]="true" (ngModelChange)="dateSelect()">
                </div>

            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group">
                <div class="form-group select">
                    <select class="form-control" [(ngModel)]="carDeliveryLocationForm.deliveryTime" name="delivery-time" (change)="getcarDeliveryFilterdTime()">
                        <option value="">Time</option>
                        <option *ngFor="let time of times" value="{{time}}">
                            {{time}}
                        </option>
                    </select>
                </div>
            </div>
        </div>
    </div>


    <div class="row align-items-center">
        <div class="col-md-6">
            <div class="form-group">
                <!-- <label>RETURN DATE</label> -->
                <input type="text" class="form-control" placeholder="Collection Date" 
                [minDate]="minDateCollection" [maxDate]="maxDateReturn" #dp="bsDatepicker" 
                bsDatepicker [(ngModel)]="carDeliveryLocationForm.collectionDate" [readonly]="true" name="collection-date">
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group">
                <!--    <label>TIME</label> -->
                <div class="form-group select">
                    <select class="form-control" [(ngModel)]="carDeliveryLocationForm.collectionTime" name="collection-time">
                        <option value="">Time</option>
                        <option *ngFor="let time of filteredSelectedTime" value="{{time}}">
                            {{time}}
                        </option>
                    </select>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-6">
            <div class="form-group pb-md-0 ">
                <select placeholder="No. of Persons" [(ngModel)]="carDeliveryLocationForm.numberOfPerson"
                    class="form-control ng-pristine ng-invalid ng-touched" id="Persons"
                    name="Persons">
                    <option value="" selected>No. of Persons</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                </select>
            </div>
        </div>
        <div class="col-md-6 ">
            <div class="">
                <div class="form-group pb-3 pb-md-0">
                    <button type="submit" class="btn  hekaya-button">Book Drive</button>
                    <!-- routerLink="/self-drive/self-drive-result" -->
                </div>
            </div>
            
        </div>
    </div>

</form>