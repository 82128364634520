import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UtilityService } from '../utility-service/utility.service';
import { environment } from '../../../environments/environment';
import { env } from 'process';

@Injectable({
  providedIn: 'root'
})
export class GenericService {

  apiUrl: string = environment.apiUrl;
  
  constructor(
    private httpClient: HttpClient
    , private _utilityService: UtilityService
  ) { }

  getService(serviceUrl: string, isRequiredToken: boolean): Observable<any> {

    let httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    }

    if (isRequiredToken) {
      let accessToken: string = this._utilityService.getToken();

      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + accessToken
        })
      };
    }

    let url: string = this.apiUrl + serviceUrl;
    return this.httpClient.get(url, httpOptions);
  }

  


  postService(request: any, serviceUrl: string, isRequiredToken: boolean): Observable<any> {
    let url: string = this.apiUrl + serviceUrl;
    let httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    }

    if (isRequiredToken) {
      let accessToken: string = this._utilityService.getToken();

      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + accessToken
        })
      };
    }

    return this.httpClient.post(url, request, httpOptions);
  }

}
