<app-self-drive-nav *ngIf="!(location == '/error' || location == '/coming-soon'
    || location == '/sign-in'
    || location == '/sign-up'
    ||location == '/forgot-password')">
</app-self-drive-nav>
<div *ngIf="isModalShown" [config]="{ show: true }" bsModal #autoShownModal="bs-modal"
     class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-auto-name">
  <div class="modal-dialog modal-xl" style="border-radius:50px; margin-top:60px;height:90% !important;width:90% !important;box-shadow: rgba(255, 255, 255, 0.5);" >
    <div class="modal-content" style="height:90%">
      <div class="modal-header" style="background-color: #ae8f47 !important;"><img src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/99/OOjs_UI_icon_alert-yellow.svg/1200px-OOjs_UI_icon_alert-yellow.svg.png" style="height:50px;width:50px;">
        <h4 id="dialog-auto-name" class="modal-title pull-left" style="color:white ;flex: 1; display: flex; justify-content: center; align-items: center; font-family:'janna';font-weight:bolder;padding-top: 50px;"></h4>
        <button type="button"  class="close"   data-dismiss="modal" (click)="autoShownModal.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div> 
      <div class="modal-body" style="background-color:black; flex: 1; display: flex; justify-content: center; align-items: center;">
        <p style="color:white !important; font-size: larger;font-family:'janna';font-size:larger;"><strong>Page Under Maintenance</strong></p>
      </div>
      <!-- <div class="modal-body" style="background-color:white; flex: 1; display: flex; justify-content: center; align-items: center;">
        <p style="color:black !important; font-size: larger;font-family:'janna';font-size:xx-large;"><strong>Page Under Maintenance and please write to us for quotation <a href="info@hekaya.co">info@hekaya.co</a></strong></p>
      </div>
       -->
    </div>
  </div>
</div>
<div class="main-banner-img banner-bg1">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="main-banner-content-box">
                    <ul class="nav nav-tabs row no-gutters" id="myTab" role="tablist">
                        <!-- Tab Head -->
                        <li class="nav-item col first_tab">
                            <a class="nav-link active" id="rentACar-tab" data-toggle="tab" href="#rentACar_TabContent"
                                role="tab" aria-controls="home" aria-selected="true">
                                <span class="icon_size">Self Drive
                                    <img src="../../../../../assets/img/self-drive/formTitle.png" class="book_img" alt="" loading="lazy">
                                </span>
                                <br>
                                <small>(Car rental + Gear rental)</small>
                            </a>
                        </li>
        
                        <li class="nav-item col last_tab">
                            <a class="nav-link" #contactTab id="contact-tab" data-toggle="tab" href="#lease_enquiry" role="tab"
                                aria-controls="contact" aria-selected="true">
                                <span class="icon_size">Gear Rental</span>
                                <br>
                                <small>(Gear rental without car)</small>
                            </a>
                        </li>
        
                        <!-- // Tab Head-->
                    </ul>
                    <div class="tab-content" id="myTabContent">
                        <!-- Self Drive -->
                        <div class="tab-pane fade show active" id="rentACar_TabContent" role="tabpanel"
                            aria-labelledby="home-tab">
                            <div class="search_box">
        
                                <ul class="nav mb-4 nav-pills sub-tabs mb-3" id="pills-tab" role="tablist">
                                    <!-- Pick up Location-->
                                    <li class="nav-item ">
                                        <a class="nav-link nav-pills active" style="font-size: 12px;" id="pills-home-tab" data-toggle="pill" href="#rentACar_PickUpLocation_TabContent" role="tab" aria-controls="pills-home" aria-selected="true">PICK-UP FROM LOCATION</a>
                                    </li>
                                    <!-- Pick up Location-->
        
                                    <!-- Need Delivery-->
                                    <li class="nav-item ">
                                        <a class="nav-link nav-pills " style="font-size: 12px;" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">NEED DELIVERY</a>
                                    </li>
                                    <!-- Need Delivery-->
                                </ul>
        
                                <div class="tab-content" id="pills-tabContent">
        
                                    <div class="tab-pane fade show active" id="rentACar_PickUpLocation_TabContent"
                                        role="tabpanel" aria-labelledby="pills-home-tab">
                                        <div class="row">
                                            <div class="col-md-6" style="text-align:left;"></div>
                                            <div class="col-md-6"></div>
        
                                        </div>
                                        <form [formGroup]="selfDriveValidForm" (submit)="isSubmiteSelfDrive()">
        
                                            <div class="row align-items-center">
                                                <div class="col-12">
                                                    <div class="form-group select">
                                                        <select placeholder="Location..." formControlName="Location"
                                                            class="form-control ng-pristine ng-invalid ng-touched">
                                                            <option value="">Choose Location</option>
                                                            <option *ngFor="let location of locations" value="{{location.id}}">
                                                                {{location.locationName}} ({{location.locationCode}})
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
        
                                            <div class="row align-items-center">
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <div class="form-group ">
        
                                                            <input type="text" class="form-control"
                                                                placeholder="Collection Date" name="delivery-date"
                                                                [minDate]="minDateDelivery" [maxDate]="maxDatePickUp"
                                                                #dp="bsDatepicker" bsDatepicker formControlName="fromDate"
                                                                [readonly]="true" (ngModelChange)=dateSelect()>
                                                        </div>
        
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <div class="form-group select">
                                                            <select class="form-control" formControlName="pickUpTime"
                                                                placeholder="Collection Time" (change)="getSefdriveFilterdTime()">
                                                                <option value="">Collection Time</option>
                                                                <option *ngFor="let time of times" value="{{time}}">
                                                                    {{time}}
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                            <div class="row align-items-center">
                                                
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <input type="text" class="form-control" placeholder="Return Date"
                                                            [minDate]="minDateCollection" [maxDate]="maxDateReturn"
                                                            #dp="bsDatepicker" bsDatepicker formControlName="toDate"
                                                            [readonly]="true" name="collection-date">
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <div class="form-group select">
                                                            <select class="form-control" formControlName="retureTime"
                                                                placeholder="Return Time">
                                                                <option value="">Return Time</option>
                                                                <option *ngFor="let time of filteredSelectedTime" value="{{time}}">
                                                                    {{time}}
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="form-group pb-md-0 ">
                                                        <select placeholder="No. of Persons" formControlName="numberOfPerson"
                                                            class="form-control ng-pristine ng-invalid ng-touched" id="Persons"
                                                            name="Persons">
                                                            <option value="" selected>No. of Persons</option>
                                                            <option value="1">1</option>
                                                            <option value="2">2</option>
                                                            <option value="3">3</option>
                                                            <option value="4">4</option>
                                                            <option value="5">5</option>
                                                            <option value="6">6</option>
                                                            <option value="7">7</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-md-6 ">
                                                    <div class="">
                                                        <div class="form-group pb-3 pb-md-0">
                                                            <button type="submit" class="btn  hekaya-button">Book Drive</button>
                                                            <!-- routerLink="/self-drive/self-drive-result" -->
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                            </div>
        
                                        </form>
                                    </div>
                                    <!--Self Drive-->
        
                                    <!-- Need Delivery-->
                                    <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                                        <app-need-delivery [times]="times"></app-need-delivery>
                                    </div>
                                    <!-- Need Delivery-->
                                </div>
        
                            </div>
                        </div>
        
                        <!-- Gear Rentals -->
        
                        <div class="tab-pane fade" id="lease_enquiry" role="tabpanel" aria-labelledby="home-tab">
                            <div class="search_box">
        
                                <ul class="nav mb-4 nav-pills sub-tabs mb-3" id="pills-tab" role="tablist">
                                    <!-- Pick up Location-->
                                    <li class="nav-item ">
                                        <a class="nav-link nav-pills active" style="font-size: 12px;" id="pills-home-tab" data-toggle="pill" href="#gearRental_PickUpLocation_TabContent" role="tab" aria-controls="pills-home" aria-selected="true">PICK-UP FROM LOCATION</a>
                                    </li>
                                    <!-- Pick up Location-->
        
                                    <!-- Need Delivery-->
                                    <li class="nav-item ">
                                        <a class="nav-link nav-pills " style="font-size: 12px;" id="pills-profile-tab" data-toggle="pill" href="#gearRental_DeliveryLocation_TabContent" role="tab" aria-controls="pills-profile" aria-selected="false">NEED DELIVERY</a>
                                    </li>
                                    <!-- Need Delivery-->
                                </ul>
        
                                <div class="tab-content">
        
                                    <div class="tab-pane fade show active" role="tabpanel" aria-labelledby="pills-home-tab" id="gearRental_PickUpLocation_TabContent">
                                        <div class="row">
                                            <div class="col-md-6" style="text-align:left;"></div>
                                            <div class="col-md-6"></div>
        
                                        </div>
                                        <form [formGroup]="gearRentalValidForm" (submit)="isSubmiteGearRental()">
        
                                            <div class="row align-items-center">
                                                <div class="col-12">
                                                    <div class="form-group select">
                                                        <select placeholder="Location..." formControlName="location"
                                                            class="form-control ng-pristine ng-invalid ng-touched">
                                                            <option value="">Choose Location</option>
                                                            <option *ngFor="let location of gearlocations" value="{{location.id}}">
                                                                {{location.locationName}} ({{location.locationCode}})
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
        
                                            <div class="row align-items-center">
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <div class="form-group ">
        
                                                            <input type="text" class="form-control"
                                                                placeholder="Collection Date" name="delivery-date"
                                                                [minDate]="minDateDelivery" [maxDate]="maxDatePickUp"
                                                                #dp="bsDatepicker" bsDatepicker formControlName="fromDate"
                                                                [readonly]="true" (ngModelChange)=geardateSelect()>
                                                        </div>
        
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <div class="form-group select">
                                                            <select class="form-control" formControlName="pickUpTime"
                                                                placeholder="Collection Time" (change)="getGearRentalFilterdTime()">
                                                                <option value="">Collection Time</option>
                                                                <option *ngFor="let time of times" value="{{time}}">
                                                                    {{time}}
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
        
        
                                            <div class="row align-items-center">
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <input type="text" class="form-control" placeholder="Return Date"
                                                            [minDate]="minDateCollection" [maxDate]="maxDateReturn"
                                                            #dp="bsDatepicker" bsDatepicker formControlName="toDate"
                                                            [readonly]="true" name="collection-date">
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <div class="form-group select">
                                                            <select class="form-control" formControlName="retureTime"
                                                                placeholder="Return Time">
                                                                <option value="">Return Time</option>
                                                                <option *ngFor="let time of filteredSelectedTime" value="{{time}}">
                                                                    {{time}}
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
        
        
                                            <div class="text-right pr-0">
                                                <div>
                                                    <button type="submit" class="btn  hekaya-button">Book Gear</button>
                                                    <!-- routerLink="/self-drive/self-drive-result" -->
                                                </div>
                                            </div>
                                        </form>
                                    </div>
        
                                    <!-- Need Delivery-->
                                    <div class="tab-pane fade" id="gearRental_DeliveryLocation_TabContent" role="tabpanel" aria-labelledby="pills-profile-tab">
                                        <app-need-gear-delivery [times]="times"></app-need-gear-delivery>
                                    </div>
                                    <!-- Need Delivery-->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- <div class="main-categories-box">
        <ul>
            <li>
                <a routerLink="/">
                    <span class="icon">
                        <img src="../../../../../assets/img/icon1.png" alt="">
                    </span> Rent a Car
                </a>
            </li>

            <li>
                <a routerLink="/pick-up-and-drop-off">
                    <span class="icon">
                        <img src="../../../../../assets/img/icon2.png" alt="">
                    </span> Pick & Drop

                </a>
            </li>

            <li>
                <a routerLink="/selfdrive" >
                    <span class="icon">
                        <img src="../../../../../assets/img/icon4.png" alt="">
                    </span> Self Drive
                </a>
            </li>

            <li>
                <a href="https://hekaya.co/default.aspx">
                    <span class="icon">
                        <img src="../../../../../assets/img/icon3.png" alt="">
                    </span> Packages
                </a>
            </li>


        </ul>
    </div> -->


    
</div>
<div class="main-categories-box">
    <ul>
        <li>
            <a [href]="carRental">
                <span class="icon">
                    <img src="../../../../../assets/img/icon1.png" alt="" loading="lazy">
                </span> Rent a Car
            </a>
        </li>

        <li>
            <a [href]="pickPark">
                <span class="icon">
                    <img src="../../../../../assets/img/icon2.png" alt="" loading="lazy">
                </span> Pick & Drop

            </a>
        </li>

        <li>
            <a [href]="selfDrive">
                <span class="icon">
                    <img src="../../../../../assets/img/icon4.png" alt="" loading="lazy">
                </span> Self Drive
            </a>
        </li>

        <li>
            <a href="https://hekaya.co/default.aspx">
                <span class="icon">
                    <img src="../../../../../assets/img/icon3.png" alt="" loading="lazy">
                </span> Packages
            </a>
        </li>


    </ul>
</div>

 <!-- new index -->


 <div class="container">
    <div class="row top-margin">
        <div class="col-md-12">
            <p class="small-text">WHAT IS <span class="color-hekayat">HEKAYAT</span> EXPERIENCE ?</p>
        </div>
    </div>
    <div class="row bot-margin">
        <div class="col-md-6 col-lg-6 col-sm-12 text-justify">

            <h1 class="main-text">Your One Stop Shop<br>
                to Discover Oman<br>
                In Your Way
            </h1>
        </div>
        <div class="col-md-6 col-lg-6 col-sm-12 text-justify">
            <p><span class="color-hekayat bold-text"> Hekayat means "stories ":</span> The world of experience and
                adventure, through which we give you a unique experience captivating your hearts and keeps in your
                memories, to share with your loves whenever you are.</p>
            <p>Discover the beauty of Oman by yourself with hekayat experience and live real adventure.</p>

        </div>
    </div>
</div>

<div class="container-fluid">
    <div class="row bg-dark">
        <div class="container-fluid">
            <div class="row bg-dark ">
               <div class="col-md-1 col-lg-1 col-sm-12 col-padding padding-0"></div>
                <div class="col-md-5 col-lg-5 col-sm-12 col-padding padding-0 ">
                    <img src="../../../../../assets/img/self-drive/self/new-index/HomePageImage1.jpg" class="img-fluid img1" loading="lazy" />
                    <img src="../../../../../assets/img/self-drive/self/new-index/car3.png" class="img-fluid img-3" loading="lazy" />
                    <div class="row">
                        <div class="col-md-12 col-lg-12 col-sm-12 text-left self-camp-padding">
                            <br>
                            <h2 class="self-camp">Self-Camp</h2>
                            <p class="self-camp-p">Experience the wild in Oman by self-camp.<br>Be Ready ! Your Camera will
                                shot <br> lovely photos of different landscape</p>
                        </div>
                    </div>

                </div>

                <div class="col-md-5 col-lg-5 col-sm-12 col-padding col-pad2">

                    <div class="row">
                        <div class="col-md-12 col-lg-12 col-sm-12 text-left cat-section" >
                            <h2 class="self-Drive">Self-Drive</h2>
                            <p class="self-Drive-p">Discover the beauty of Oman, by yourself with <br> hekayat experience to
                                live a real adventure that <br>will last for long.</p>

                        </div>
                    </div>
                    <br>    <br>    <br>  <br>  <br>  <br>  <br>  <br>
                    <img src="../../../../../assets/img/self-drive/self/new-index/HomePageImage2.jpg" class="img-fluid img1" loading="lazy" />
                </div>
                <div class="col-md-1 col-lg-1 col-sm-12 col-padding padding-0"></div>
            </div>
        </div>
    </div>
</div>





<div class="container">
    <div class="row mt-5">
        <div class="col-md-6 col-lg-6 col-sm-12">
            <img src="../../../../../assets/img/self-drive/self/new-index/HomePageGearRentalImage.jpg" class="img-fluid camp-gear-img" loading="lazy"/>
        </div>
        <div class="col-md-6 col-lg-6 col-sm-12" style="padding-left: 7%;padding-top:5%">
            <h2 class="self-camp-gear ">Camping Gear <br> Rental</h2>
            <p class="self-camp-gear-p "><br>You do not have to buy anything. <br> You can rent all gear needed.</p>
        </div>
    </div>
</div>

<div class="container-fluid">
    <div class="row bg-dark top-margin2">
        <div class="col-md-3 col-lg-3 col-sm-12">
            <div class="whitebox">
                <h2 class="self-camp margin text-dark">Route Plan</h2>
                <p class="self-camp-p margin-p text-dark" >Multi route plan for you to Choose <br> to meet your expectations.</p>
            </div>
        </div>
        <div class="col-md-9 col-lg-9 col-sm-12 text-right" style="padding-right: 100px;">
             <img src="../../../../../assets/img/self-drive/self/new-index/HomePageLargeImage.jpg" class="img-fluid img-4" style="width:900px;" loading="lazy"/>
        </div>
    </div>
</div>


<div class="container-fluid">
    <div class="row top-row-margin">
        <div class="col-md-12 col-lg-12 col-sm-12">
            <header class="hero">
                <div class="center-content">
                    <h2 class="guidance text-center">Guidence and More</h2>
                    <p class="self-Guidence-p text-center">All information and guidence you will need in Oman</p>
                    <!-- <button class="btn btn-primary hekaya-button">Find More</button> -->
                </div>

            </header>
        </div>
    </div>
</div>

<div class="container pt-3">
    <div class="row">
        <div class="col-md-12 col-lg-12 col-sm-12 text-center">
            <h2 class="hekaya-title">Why <span class="color-hekayat">Hekayat ?</span></h2>
            <p class="first-line" style="margin-bottom: 0px;">
                Want to visit Sultanate of Oman, In Your Way! </p>
               <p> Hekayat Experience: Gives you the opportunity to have
                extraordinary trip, to make unforgettable new stories.<br> At Hekayat you will be able to:
            </p>

            <!-- <button class="btn btn-info hekaya-button">Explore More</button> -->
        </div>

    </div>
</div>

<div class="container-fluid">
    <div class="row top-row-margin">
        <div class="col-md-1 col-lg-1 col-sm-12  point-align"></div>
        <div class="col-md-3 col-lg-3 col-sm-12  point-align">
            <img src="../../../../../assets/img/self-drive/self/new-index/1.png" class="img-fluid" style="height:50px" loading="lazy"/>
                <p> Make is much easier to <br> Discover Oman, just in your way</p>

                <img src="../../../../../assets/img/self-drive/self/new-index/2.png" class="img-fluid" style="height:50px" loading="lazy"/>
                <p>Plan your self-drive and self-camp safely and probably.</p>
                <img src="../../../../../assets/img/self-drive/self/new-index/3.png" class="img-fluid" style="height:50px" loading="lazy"/>
                <p>Choose your needed Vehicle.</p>
                <img src="../../../../../assets/img/self-drive/self/new-index/4.png" class="img-fluid" style="height:50px" loading="lazy"/>
                <p>Understand what to expected <br> and skills required</p>
        </div>
        <div class="col-md-4 col-lg-4 col-sm-12 text-center">
            <img src="../../../../../assets/img/self-drive/self/new-index/car5.png" class="img-fluid car-5"/>
            <br><img src="../../../../../assets/img/self-drive/self/new-index/9.png" class="img-fluid" style="height:50px" loading="lazy"/>
            <p class="center-point">Find out all necessary guidence,<br>  maps and information at one window.</p>

        </div>
        <div class="col-md-3 col-lg-3 col-sm-12 point-align2">

            <img src="../../../../../assets/img/self-drive/self/new-index/5.png" class="img-fluid" style="height:50px" loading="lazy"/>
            <p> Get all necessary tools and <br> equipment’s (No need to buy anything).</p>
            <img src="../../../../../assets/img/self-drive/self/new-index/6.png" class="img-fluid" style="height:50px" loading="lazy"/>
            <p>Get other equipment’s you need to experience more</p>
            <img src="../../../../../assets/img/self-drive/self/new-index/7.png" class="img-fluid" style="height:45px" loading="lazy"/>
            <p>Ask for more and get the assistance you need</p>
            <img src="../../../../../assets/img/self-drive/self/new-index/8.png" class="img-fluid" style="height:50px" loading="lazy"/>
            <p>Get 24/7 support. </p>
        </div>
        <div class="col-md-1 col-lg-1 col-sm-12  point-align"></div>
    </div>
</div>

 <!-- end new index -->





<div class="container-fluid pt-5">
    <div class="row">
        <div class="col-md-12 col-lg-12-col-sm-12 banner-home" style="background-size:cover !important;">
            <div class="row">
                <div class="col-md-6 col-lg-6 col-sm-6 text-left">
                    <h1 class="banner-text">Visit <br>Oman</h1>
                </div>
                <div class="col-md-6 col-lg-6 col-sm-6 text-right">
                    <h1 class="banner-text txt-bottom">Explore <br>Oman</h1>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="hekaya-container" style="margin-top: 70px;margin-bottom:70px">
    <div class="row">
        <div class="col-md-12 col-lg-12 col-sm-12 text-center">
            <h2 class="hekaya-title"><span class="title-color">Planning </span> Your Road Trip Itinerary</h2>
            <p class="">
                Plan your Oman road trip with self-camping and hotel stay combination, as you wish so that you see all
                the beauty the Sultanate has to offer. Hekayat make it easier to discover Oman, just in your way. Below
                is your Hekayat quick navigation guide to start planning and define your route plan and so on.
            </p>
            <button class="btn btn-info hekaya-button" routerLink="/Plannin-your-Road-Trip" style="width: auto;">Find More</button><br>
        </div>
    </div>
</div>


<app-self-drive-footer *ngIf="!(location == '/error' || location == '/coming-soon' || location == '/sign-in'
     || location == '/sign-up'
     ||location == '/forgot-password')">
</app-self-drive-footer>
