<app-header-one></app-header-one>
<div class="menu_bar">
  
</div>

<div class="container-fluid bg_color" >
    <div class="row">
        <div class="col-md-1"></div>
        <div class="col-md-10">
                        
                <div class="fleet_box">
                    <div class="row car_row">
                        <div class="col-md-4 col-sm-12 first_col right_border">
                            <h6>NISSAN SUNNY </h6>
                            <span class="small_text"> (or similar)</span><br>
                            <img alt="Image canot Displayed" src="https://images-na.ssl-images-amazon.com/images/I/61UYdfUBAoL._SX425_.jpg" class="img-fluid">    
                            <br> <span class="small_text"> Economy</span>
                        </div>
                        <div class="third_col_pad right_border col-md-4  col-sm-6 ">
                            <p> <i class='bx bxs-user'></i> 5 Seats  </p>
                            <p> <i class='bx bxs-user'></i> 5 Door  </p>
                            <p> <i class='bx bxs-user'></i> Auto  </p>
                            
                        </div>
                        <div class=" third_col_pad right_border col-md-4 col-sm-6 ">
                            <p> <i class='bx bxs-user'></i> 2 Luggage(s)  </p>
                            <p> <i class='bx bxs-user'></i> 1.5 L Engine  </p>
                            <p> <i class='bx bxs-user'></i>  AC </p>
                        </div>
                        
                    </div>
                </div>
        </div>
        <div class="col-md-1"></div>
            
    </div>
    <div class="row ">
        <div class="col-md-2"></div>
        <div class="col-md-8">
            <ul class="nav nav-pills mb-3 red" id="pills-tab" role="tablist" >
                <li class="nav-item butoon_width pill-1">
                  <a class="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">PICK-UP FROM LOCATION</a>
                </li>
                <li class="nav-item butoon_width pill-1 pill_item_gap " >
                  <a class="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">NEED A CAR DELIVERY</a>
                </li>
             
              </ul>
              <div class="tab-content" id="pills-tabContent">
                <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                    <form>
                        <div class="form-group">
                            <select class="form-control">
                                 <option>Pickup Location</option>
                            </select>
                        </div>
                        <div class="form-group return_loc hide_control">
                            <select  class="form-control">
                                 <option>Return Location</option>
                            </select>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="return_check" (click)="change_return()">
                            <label class="form-check-label" for="defaultCheck1">
                                Return car to a different location 
                            </label>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <input type="text" class="form-control" value="Pickup Date" onfocus="(this.type='date')">
                                </div>
                                <div class="form-group">
                                    <input type="text" class="form-control" value="Return Date" onfocus="(this.type='date')">
                                </div>
                                <div class="form-group">
                                    <select  class="form-control">
                                         <option>Select Category</option>
                                         <option>CARS</option>
                                         <option>SUV</option>
                                         <option>PEOPLE MOVERS</option>
                                          
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <select  class="form-control">
                                         <option>Pickup Time</option>
                                         <option>00.30</option>
                                         <option>01.00</option>
                                         <option>01.30</option>
                                         <option>02.00</option>
                                         <option>02.30</option>
                                    </select>
                                </div>
                                <div class="form-group">
                                    <select  class="form-control">
                                         <option>Return Time</option>
                                         <option>00.30</option>
                                         <option>01.00</option>
                                         <option>01.30</option>
                                         <option>02.00</option>
                                         <option>02.30</option>
                                    </select>
                                </div>
                            </div>
                            
                        </div>
                        <div class="row">
                            <div class="col-md-12 bt">
                                <button routerLink="/newbooking" class="button_margin btn btn-warning btn-lg">Book Now</button>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                     <div class="container">
                         <div class="row">
                             <div class="col-md-12">
                                 SELECT DELIVERY CITY *
                                <div class="form-group">
                                    <select  class="form-control">
                                         <option>Select City</option>
                                         <option>MUSCAT </option>
                                         <option>SOHAR</option>
                                         <option>SALALAH</option>
                                        
                                    </select>
                                </div>
                                DELIVERY ADDRESS *
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Delivery Address">
                                </div>

                              
                                <div class="form-group hide_control collection_city">
                                    SELECT COLLECTION CITY *
                                    <select  class="form-control " >
                                         <option>Select Collection  City</option>
                                         <option>MUSCAT </option>
                                         <option>SOHAR</option>
                                         <option>SALALAH</option>
                                        
                                    </select>
                                </div>
                              
                                <div class="form-group hide_control collection_address">
                                    COLLECTION ADDRESS *
                                    <input type="text" class="form-control"   placeholder="Collection Address">
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="collection1" (click)="change_collection()">
                                    <label class="form-check-label" for="defaultCheck1">
                                        Change collection address
                                    </label>
                                </div>
                             </div>
                         </div>
                         <div class="row">
                             <div class="col-md-6">
                                <div class="form-group">
                                    <input type="text" class="form-control" value="Pickup Date" onfocus="(this.type='date')">
                                </div>
                                <div class="form-group">
                                    <input type="text" class="form-control" value="Return Date" onfocus="(this.type='date')">
                                </div>
                                <div class="form-group">
                                    <select  class="form-control">
                                         <option>Select Category</option>
                                         <option>CARS</option>
                                         <option>SUV</option>
                                         <option>PEOPLE MOVERS</option>
                                          
                                    </select>
                                </div>
                             </div>
                             <div class="col-md-6">
                                <div class="form-group">
                                    <select  class="form-control">
                                         <option>Pickup Time</option>
                                         <option>00.30</option>
                                         <option>01.00</option>
                                         <option>01.30</option>
                                         <option>02.00</option>
                                         <option>02.30</option>
                                    </select>
                                </div>
                                <div class="form-group">
                                    <select  class="form-control">
                                         <option>Return Time</option>
                                         <option>00.30</option>
                                         <option>01.00</option>
                                         <option>01.30</option>
                                         <option>02.00</option>
                                         <option>02.30</option>
                                    </select>
                                </div>  
                            </div>
                         </div>
                         <div class="row">
                            <div class="col-md-12 bt">
                                <button routerLink="/newbooking" class="button_margin btn btn-warning btn-lg">Book Now</button>
                            </div>
                        </div>
                     </div>

                </div>
               
              </div>
        </div>
        <div class="col-md-2"></div>
    </div>
    
</div>
 
<div class="container-fluid row_margin">
    
    <div class="row footbar_bg">
        <div class="col-md-3 bottom_space">
            <div class="foot_bar">
                <li class="bx bx-dollar-circle  icon_size"></li>
                <h6>No Hidden Charges</h6>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris tempor, tortor varius placerat pharetra, risus dolor interdum  </p>
            </div>
        </div>
        <div class="col-md-3 bottom_space">
            <div class="foot_bar">
                <li class="bx bxs-map  icon_size"></li>
                <h6>Go Anywhere</h6>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris tempor, tortor varius placerat pharetra, risus dolor interdum  </p>
            </div>
        </div>
        <div class="col-md-3 bottom_space">
            <div class="foot_bar">
                <li class="bx bx-money  icon_size"></li>
                <h6>Flexi Pricing package</h6>
                 
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris tempor, tortor varius placerat pharetra, risus dolor interdum  </p>
            </div>
        </div>
        <div class="col-md-3 bottom_space">
            <div class="foot_bar">
                <li class="bx bx-time  icon_size"></li>
                <h6>24x7 Roadside Assistance </h6>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris tempor, tortor varius placerat pharetra, risus dolor interdum  </p>
            </div>
        </div>
    </div>
    </div>

    