<app-self-drive-nav *ngIf="!(location == '/error' || location == '/coming-soon' 
    || location == '/sign-in' 
    || location == '/sign-up'
    ||location == '/forgot-password')">
</app-self-drive-nav>

<div class="page-title-area page-title-bg3">
  <div class="hekaya-container">
    <div class="page-title-content">
      <h2>Hekayat Route Plan</h2>
      <!-- <h4></h4> -->
    </div>
  </div>
</div>




<div class="hekaya-container">
  <div class="row text-center hekayat-titl top-row-margin">
    <div class="col-md-12 col-sm-12 col-lg-12">
      <h2 class="hekayat-titl"><span class="hekayat-color">Hekayat</span> Route Plans</h2>
      <p>At Hekayat, we have chosen the most common routes which shall maximize your experience. Choose you route plan
        from the below or combine two or more routes together. Ensure to understand how to navigate and understand the
        requirements for your safety and convenience.</p>
      
    </div>

  </div>
</div>


<section class="mt-4">
  <div class="text-center">
    <button class="btn text-center explore-button btn-md" (click)="onClick()" >Find More</button> 
   
  </div>
</section>



<div class="hekaya-container" *ngIf="isAdmin">
  <div class="row top-row-margin">
    <div class="col-md-12 col-sm-12 col-lg-12">
      <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
        <ol class="carousel-indicators">
          <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="4"></li>
         
        </ol>
        <div class="carousel-inner">
          <div class="carousel-item active">

            <img class="d-block w-100" src="../../../../assets/img/self-drive/hekayat-route/1.jpg"
              alt="First slide" loading="lazy">
          </div>
          <div class="carousel-item">
            <img class="d-block w-100" src="../../../../assets/img/self-drive/hekayat-route/2.jpg"
              alt="Second slide" loading="lazy">
          </div>
          <div class="carousel-item">
            <img class="d-block w-100" src="../../../../assets/img/self-drive/hekayat-route/3.jpg" alt="Third slide" loading="lazy">
          </div>
          <div class="carousel-item">
            <img class="d-block w-100" src="../../../../assets/img/self-drive/hekayat-route/4.jpg"
              alt="Fourth slide" loading="lazy">
          </div>
          <div class="carousel-item">
            <img class="d-block w-100" src="../../../../assets/img/self-drive/hekayat-route/5.jpg" alt="Fifth slide" loading="lazy">
          </div>
          
        </div>
        <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a>
      </div>
    </div>
  </div>
</div>

<div class="hekaya-container" *ngIf="!isAdmin">
  <div class="row top-row-margin">
    <div class="col-md-12 col-sm-12 col-lg-12">
      <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
        <ol class="carousel-indicators">
          <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="4"></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="5"></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="6"></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="7"></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="8"></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="9"></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="10"></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="11"></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="12"></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="13"></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="14"></li>
        </ol>
        <div class="carousel-inner">
          <div class="carousel-item active">

            <img class="d-block w-100" src="../../../../assets/img/self-drive/hekayat-route/1.jpg"
              alt="First slide" loading="lazy">
          </div>
          <div class="carousel-item">
            <img class="d-block w-100" src="../../../../assets/img/self-drive/hekayat-route/2.jpg"
              alt="Second slide" loading="lazy">
          </div>
          <div class="carousel-item">
            <img class="d-block w-100" src="../../../../assets/img/self-drive/hekayat-route/3.jpg" alt="Third slide" loading="lazy">
          </div>
          <div class="carousel-item">
            <img class="d-block w-100" src="../../../../assets/img/self-drive/hekayat-route/4.jpg"
              alt="Fourth slide" loading="lazy">
          </div>
          <div class="carousel-item">
            <img class="d-block w-100" src="../../../../assets/img/self-drive/hekayat-route/5.jpg" alt="Fifth slide" loading="lazy">
          </div>
          <div class="carousel-item">
            <img class="d-block w-100" src="../../../../assets/img/self-drive/hekayat-route/6.jpg" alt="Sixth slide" loading="lazy">
          </div>
          <div class="carousel-item">
            <img class="d-block w-100" src="../../../../assets/img/self-drive/hekayat-route/7.jpg"
              alt="Seventh slide" loading="lazy">
          </div>
          <div class="carousel-item">
            <img class="d-block w-100" src="../../../../assets/img/self-drive/hekayat-route/8.jpg" alt="Eigth slide" loading="lazy">
          </div>
          <div class="carousel-item">
            <img class="d-block w-100" src="../../../../assets/img/self-drive/hekayat-route/9.jpg"
              alt="Nineth slide" loading="lazy">
          </div>
          <div class="carousel-item">
            <img class="d-block w-100" src="../../../../assets/img/self-drive/hekayat-route/10.jpg"
              alt="Tenth slide" loading="lazy">
          </div>
          <div class="carousel-item">
            <img class="d-block w-100" src="../../../../assets/img/self-drive/hekayat-route/11.jpg"
              alt="Eleventh slide" loading="lazy">
          </div>
          <div class="carousel-item">
            <img class="d-block w-100" src="../../../../assets/img/self-drive/hekayat-route/12.jpg"
              alt="Twelth slide" loading="lazy">
          </div>
          <div class="carousel-item">
            <img class="d-block w-100" src="../../../../assets/img/self-drive/hekayat-route/13.jpg"
              alt="Thirteenth slide" loading="lazy">
          </div>
          <div class="carousel-item">
            <img class="d-block w-100" src="../../../../assets/img/self-drive/hekayat-route/14.jpg"
              alt="Forteen slide" loading="lazy">
          </div>
          <div class="carousel-item">
            <img class="d-block w-100" src="../../../../assets/img/self-drive/hekayat-route/15.jpg"
              alt="Fifteen slide" loading="lazy">
          </div>
          <div class="carousel-item">
            <img class="d-block w-100" src="../../../../assets/img/self-drive/hekayat-route/19.jpg"
              alt="Fifteen slide" loading="lazy">
          </div>
        </div>
        <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a>
      </div>
    </div>
  </div>
</div>





<div class="hekaya-container">
  <div class="row text-center hekayat-titl top-row-margin">
    <div class="col-md-12 col-sm-12 col-lg-12">
      <h2 class="hekayat-titl"><span class="hekayat-color">Remarks</span></h2>
    </div>
  </div>
  
</div>

<section class="listing-area  pb-50" style="padding-top: 50px;">
  <div class="container">
    <div class="listing-slides owl-carousel owl-theme text-center">
      <div class="single-listing-box">
        <div class="card equipment-cad-PCE ">
          <div class="card-body text-center">
           
            <h5 class="card-title" style="color: #b58e5f;">01</h5>
            <p class="text-justify">Maps are designed to help plan your route, expected seen sites/
              habitats / attractions, suitable vehicle type and equipment’s needed.</p>
          </div>
        </div>
      </div>
      <div class="single-listing-box">
        <div class="card equipment-cad-VCE ">
          <div class="card-body text-center">
            <h5 class="card-title" style="color: #b58e5f;">02</h5>
            <p class="text-justify">Some main route plans can be merged in one route plan (check maps).</p>
          </div>
        </div>
      </div>
      <div class="single-listing-box">
        <div class="card equipment-cad-OEL ">
          <div class="card-body text-center">
          
            <h5 class="card-title" style="color: #b58e5f;">03</h5>
            <p class="text-justify">Main route plan representing the main route and included visited cities
              wherein optional route representing site located between main route
              plan and can be visited, upon choice.</p>
          </div>
        </div>
      </div>
      <div class="single-listing-box">
        <div class="card equipment-cad-PCE ">
          <div class="card-body text-center">
           
            <h5 class="card-title" style="color: #b58e5f;">04</h5>
            <p class="text-justify">Habitat included listed are based on main route plan only. More habitats
              might include on optional route, as per your choice.</p>
          </div>
        </div>
      </div>
      <div class="single-listing-box">
        <div class="card equipment-cad-VCE ">
          <div class="card-body text-center">
           
            <h5 class="card-title" style="color: #b58e5f;">05</h5>
            <p class="text-justify">Check attraction site section to understand attractions presented at
              each area/city.</p>
          </div>
        </div>
      </div>
      <div class="single-listing-box">
        <div class="card equipment-cad-OEL ">
          <div class="card-body text-center">
           
            <h5 class="card-title" style="color: #b58e5f;">06</h5>
            <p class="text-justify">For your safety, ensure full understanding of Habitat Categories and
              Hekayat Driving Category and skills required for each category (please
              read more).</p>
          </div>
        </div>
      </div>
      <div class="single-listing-box">
        <div class="card equipment-cad-PCE ">
          <div class="card-body text-center">
           
            <h5 class="card-title" style="color: #b58e5f;">07</h5>
            <p class="text-justify">For your safety, ensure using proper vehicle category to enter attraction
              sites/areas as 4WD vehicle category is common required at many
              attractions including sandy dunes, mountain, some beaches, marshes
              and wadies areas.</p>
          </div>
        </div>
      </div>
      <div class="single-listing-box">
        <div class="card equipment-cad-VCE ">
          <div class="card-body text-center">
           
            <h5 class="card-title" style="color: #b58e5f;">08</h5>
            <p class="text-justify">Please follow all traffic rules and designated traffic signages at each area.</p>
          </div>
        </div>
      </div>
      <div class="single-listing-box">
        <div class="card equipment-cad-OEL ">
          <div class="card-body text-center">
           
            <h5 class="card-title" style="color: #b58e5f;">09</h5>
            <p class="text-justify">Please read and follow attraction site instructions and guidance.</p>
          </div>
        </div>
      </div>

    </div>

  </div>
</section>




<div class="hekaya-container">
  <div class="row top-row-margin">
    <div class="col-md-12 col-lg-12 col-sm-12">
      <header class="hero2 route_slide baner-padding">
        <div class="center-content">
          <div class="row">
            <div class="col-md-6 col-lg-6 col-sm-12">
              <h1 class="rout-banner-text">Visit<br> Oman</h1>
            </div>
            <div class="col-md-6 col-lg-6 col-sm-12">
              <h1 class="rout-banner-text right-content">Explore<br>Oman

              </h1>
            </div>
          </div>

        </div>
        <div class="center-content2">

        </div>
      </header>
    </div>
  </div>
</div>


<div class="hekaya-container">
  <div class="row text-center hekayat-titl top-row-margin">
    <div class="col-md-12 col-sm-12 col-lg-12">
      <h2 class="hekayat-titl"><span class="hekayat-color">Oman</span> Governorate Travel Guide</h2>
    </div>
  </div>
  <div class="row my-3" style="min-height:320px;">
    <div class="" style="display: contents; border: 1px solid #f7f1f1d4; border-radius: 50px;">
      <!-- <hr/> -->
      <div class="col-md-3">
        <ul class="nav nav-tabs tabs-left sideways" style="display: block;">
          <li class="active p-2" style="background: #f2f2f2; border: 1px solid white;">
            <a href="#muscat" data-toggle="tab" style="font-size: 25px;">Muscat</a>
          </li>
          <li class="p-2" style="background: #f2f2f2; border: 1px solid white;">
            <a href="#musandam" data-toggle="tab" style="font-size: 25px;">Musandam</a>
          </li>
          <li class="p-2" style="background: #f2f2f2; border: 1px solid white;">
            <a href="#b-north" data-toggle="tab" style="font-size: 25px;">Al Batinah North</a>
          </li>
          <li class="p-2" style="background: #f2f2f2; border: 1px solid white;">
            <a href="#b-south" data-toggle="tab" style="font-size: 25px;">Al Batinah South</a>
          </li>
          <li class="p-2" style="background: #f2f2f2; border: 1px solid white;">
            <a href="#dhahirah" data-toggle="tab" style="font-size: 25px;">Al Dhahirah</a>
          </li>
          <li class="p-2" style="background: #f2f2f2; border: 1px solid white;">
            <a href="#dakhiliyah" data-toggle="tab" style="font-size: 25px;">A'Dakhiliyah</a>
          </li>
          <li class="p-2" style="background: #f2f2f2; border: 1px solid white;">
            <a href="#buraymi" data-toggle="tab" style="font-size: 25px;">Al Buraymi</a>
          </li>
          <li class="p-2" style="background: #f2f2f2; border: 1px solid white;">
            <a href="#s-north" data-toggle="tab" style="font-size: 25px;">Al Sharqiyah North</a>
          </li>
          <li class="p-2" style="background: #f2f2f2; border: 1px solid white;">
            <a href="#s-south" data-toggle="tab" style="font-size: 25px;">Al Sharqiyah South</a>
          </li>
          <li class="p-2" style="background: #f2f2f2; border: 1px solid white;">
            <a href="#wusta" data-toggle="tab" style="font-size: 25px;">Al Wusta</a>
          </li>
          <li class="p-2" style="background: #f2f2f2; border: 1px solid white;">
            <a href="#dhofar" data-toggle="tab" style="font-size: 25px;">Dhofar</a>
          </li>
        </ul>
      </div>
      <div class="col-md-9">
        <div class="tab-content ">
          <div class="tab-pane active" id="muscat">
            <div class="row">
              <div class="col-md-6 p-0">
                <img src="../../../../assets/img/map/muscut.png" class="fluid" loading="lazy" />
              </div>
              <div class="col-md-6 p-0 ScrollStyle" style="background-color: #f2f2f2;">
                <div class="detail-box">
                  <div class="box-head">
                    <h5 style="padding-top: 10px;">Muscat</h5>
                  </div>
                  <div class="box-details p-2">
                    <p>Muscat Governorate is considered the pulsating heart of Oman. Breathtaking intermingling of
                      ancient cultural heritage and modern style. You will see houses, gates, old markets, small shops,
                      and winding roads redolent of authentic history, side by side with modern markets, shops,
                      buildings, and streets stamped with modern architecture. This allows Oman to preserve its historic
                      character, and at the same time enjoying its contemporary spirit. Muscat is renowned as one of the
                      cleanest Arab capitals and has gained the honour of winning the Cleanest Arab City Contest several
                      consecutive times. Muscat as a city has played a prominent historical role due to its strategic
                      location.</p>
                    <p><span class="descp-weight">Muscat Governorate Wilayt are:</span> Muscat, Mutrah, Bawshar, Al
                      Seeb, Qurayyat, and Al Amirat.</p>
                    <p><span class="descp-weight">Habitat:</span> Mountains, Sandy Beaches</p>
                    <p><span class="descp-weight">Main Activities:</span> Forts, Museum, Shopping, Diving, Marine Trips and
                      Cruise, Dolphin Watch, Diving.</p>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="tab-pane" id="musandam">
            <div class="row">
              <div class="col-md-6 p-0">
                <img src="../../../../assets/img/map/musandam.png" class="fluid" loading="lazy" />
              </div>
              <div class="col-md-6 p-0 ScrollStyle" style="background-color: #f2f2f2;">
                <div class="detail-box">
                  <div class="box-head">
                    <h5 style="padding-top: 10px;">Musandam</h5>
                  </div>
                  <div class="box-details p-2">
                    <p>The Musandam Peninsula is located on the northern border of the Sultanate of Oman. The high
                      mountains in this region rise for more than two thousand metres above sea level. The mountains
                      spread out in a naturally geometrical way. This area also contains the most important waterway,
                      The Strait of Hormuz.</p>
                    <p>The juxtaposition of sea and mountains is considered one of the exclusive features of this area.
                      Excursions in boats and traditional ships give the visitor unforgettable enjoyment, while diving
                      fans at the beautiful coral reefs can plunge to their hearts’ content. Archaeological sites also
                      abound in this area.</p>
                    <p>Khasab is the Governorate of Musandam's regional centre and is located 570 kilometres from
                      Muscat. Khasab, home to Khasab Port, it is located in the far north of the governorate and takes
                      its name from its fertile soil. Khasab Governorate is famous for its magnificent villages and the
                      thrilling roads that lead its mountain tops. Khasab can be reached by daily flights from Muscat,
                      by sea in fast ferries and by car through a road that cuts through the United Arab Emirates.</p>
                    <p><span class="descp-weight">Governorates of Musandam Wilayat are:</span> Khasab, Bukha, Diba,
                      Madha.</p>
                    <p><span class="descp-weight">Habitat:</span> Mountains, Sand Beaches, Rocky Shores, Main Coral Reef
                      Areas.</p>
                    <p><span class="descp-weight">Main Activities:</span> Off-roading, Marine Trips and Cruise, Diving,
                      Dolphin Watch, Camping.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane" id="b-north">
            <div class="row">
              <div class="col-md-6 p-0">
                <img src="../../../../assets/img/map/albatinah north.png" class="fluid" loading="lazy" />
              </div>
              <div class="col-md-6 p-0 ScrollStyle" style="background-color: #f2f2f2;">
                <div class="detail-box">
                  <div class="box-head">
                    <h5 style="padding-top: 10px;">Al Batinah North</h5>
                  </div>
                  <div class="box-details p-2">
                    <p>Omanis compare Al Hajar Mountains to the human backbone, so they call the Governorate which lies
                      on the Sea of Oman North Al Batinah.</p>
                    <p>North Al Batinah Governorate is the beach formed by the valleys descending from the mountains,
                      whose width varies between 15 and 80 kilometres. This is the one of the agricultural Governorate
                      in Oman, not to mention the nearby mountains and glittering beaches. North Al Batinah Governorate
                      is distinguished by the presence of some rare trees like Al Mashut in Wilayt Liwa and Ad Dibaj in
                      Wilayt As Suwayq.</p>
                    <p><span class="descp-weight">North Al Batinah Governorate Wilayat are:</span> Sohar, As Suwayq,
                      Al-Kabura, Saham, Lway, Shinas.</p>
                    <p><span class="descp-weight">Habitat:</span> Plain, Sandy Beaches, Islands, Main Coral Reef Area,
                      Wadis.</p>
                    <p><span class="descp-weight">Main Activities:</span> Forts, Marine Trips, Diving.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane" id="b-south">
            <div class="row">
              <div class="col-md-6 p-0">
                <img src="../../../../assets/img/map/albainah south.png" class="fluid" loading="lazy" />
              </div>
              <div class="col-md-6 p-0 ScrollStyle" style="background-color: #f2f2f2;">
                <div class="detail-box">
                  <div class="box-head">
                    <h5 style="padding-top: 10px;">Al Batinah South</h5>
                  </div>
                  <div class="box-details p-2">
                    <p>Omanis compare Al Hajar Mountains to the human backbone, so they call the Governorate which lies
                      on the Sea of Oman South Al Batinah, and the Governorate that lies west of the heights A'Dhahirah.
                    </p>
                    <p>South Al Batinah Governorate is the beach formed by the valleys descending from the mountains,
                      whose width varies between 15 and 80 kilometres. This is the another agricultural Governorate in
                      Oman, not to mention the nearby mountains and glittering beaches.</p>
                    <p><span class="descp-weight">South Al Batinah Governorate Wilayat are:</span> Barka, Wadi Al Mawil,
                      Nakhal, Al Awabi, ArRustaq, Al Masana’h.</p>
                    <p><span class="descp-weight">Habitat:</span> Sandy Beaches, Mountains, Clift's, Date Palm Garden,
                      Wadis.</p>
                    <p><span class="descp-weight">Main Activities:</span> Forts, Camping, Off-Roading, Trekking, Hiking, Heritage and Culture, Wadies Swimming.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane" id="dhahirah">
            <div class="row">
              <div class="col-md-6 p-0">
                <img src="../../../../assets/img/map/aldhahira.png" class="fluid" loading="lazy"/>
              </div>
              <div class="col-md-6 p-0 ScrollStyle" style="background-color: #f2f2f2;">
                <div class="detail-box">
                  <div class="box-head">
                    <h5 style="padding-top: 10px;">Al Dhahirah</h5>
                  </div>
                  <div class="box-details p-2">
                    <p>A'Dhahirah Governorate descends from the southern slopes of the western Al Hajar Mountains. It
                      forms a bridge to the caravan trade routes between Oman and the neighbouring countries, which is
                      why the region is called Wilayt Ibri (from “crossing”). A'Dhahirah includes Bat Tombs, which are
                      on the World Heritage List.</p>
                    <p>Wilayt Ibri is one of the regional centres in west Oman. It is lies 279 kilometres from the
                      capital city Muscat. The two cities are linked by two roads: one runs directly from Muscat and the
                      other cuts through Wilayat Ar Rustaq. This city has a unique location connecting the Sultanate
                      with the other regions in the Arabian Peninsula.</p>
                    <p><span class="descp-weight">A'Dhahirah Governorate Wilayah are:</span> Ibri, Yanqul, Dhank.</p>
                    <p><span class="descp-weight">Habitat:</span> Sandy Dunes, Wadies.</p>
                    <p><span class="descp-weight">Main Activities/Experiences:</span> Off-Roading, Wadies Swimming.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane" id="dakhiliyah">
            <div class="row">
              <div class="col-md-6 p-0">
                <img src="../../../../assets/img/map/AdDakhiliyah.png" class="fluid" loading="lazy"/>
              </div>
              <div class="col-md-6 p-0 ScrollStyle" style="background-color: #f2f2f2;">
                <div class="detail-box">
                  <div class="box-head">
                    <h5 style="padding-top: 10px;">A'Dakhiliyah</h5>
                  </div>
                  <div class="box-details p-2">
                    <p>A'Dakhiliyah Governorate occupies a distinctive location on the western slopes of Al Hajar
                      Mountains (the slopes of Al Jabal Al Akhdhar) towards the desert in the south.</p>
                    <p>A'Dakhiliyah Governorate has played a role of great significance in Oman’s history, particularly
                      with regard to the spread of Islam in Oman. Nizwa, the capital of Oman in the early days of Islam,
                      was the cradle of ardent intellectual activity and produced generations of Omani scientists,
                      scholars and historians. A'Dakhiliyah Governorate has played a significant role in linking the
                      coast to the interior of Oman, as it was the main trade route and the meeting place of caravans
                      for many centuries.</p>
                    <p><span class="descp-weight">A'Dakhiliyah Governorate Wilayat are:</span> Nizwa, Bidbid, Samail,
                      Izki, Manah, Bahla, Al Hamra, Adam.</p>
                    <p><span class="descp-weight">Habitat:</span> Mountain, Clift’s, Wadies, Caves, Date Palm Gardens.
                    </p>
                    <p><span class="descp-weight">Main Activities/Experiences:</span> Forts, Caving, Off-Roading,
                      Trekking, Hiking, Cycling, Wadi
                      Swimming, Heritage and Culture.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane" id="buraymi">
            <div class="row">
              <div class="col-md-6 p-0">
                <img src="../../../../assets/img/map/AlBuraymi.png" class="fluid" loading="lazy" />
              </div>
              <div class="col-md-6 p-0 ScrollStyle" style="background-color: #f2f2f2;">
                <div class="detail-box">
                  <div class="box-head">
                    <h5 style="padding-top: 10px;">Al Buraymi</h5>
                  </div>
                  <div class="box-details p-2">
                    <p>Al Buraymi Governorate is located in the north-western part of Oman. In ancient times it was
                      known by the name of Twam and Al Jaw. Al Buraymi Governorate is a semi-desert plain descending
                      from the southern slopes of the western Al Hajar Mountains. Ruins discovered in this governorate
                      indicate the existence of trade routes dating back to ancient times. The presence of pottery and
                      the remains of copper and other ruins in Al Buraymi indicate the existence of an ancient
                      civilisation.</p>
                    <p>Wilayt Al Buraymi lies 370 kilometres from Muscat. Visitors coming to Al Buraymi from Muscat
                      Governorate can reach it via two main routes: A'Dhahirah Road (Abri - Hafit) and Al Batinah Road
                      (Sohar-Wadi Al Jizi).</p>
                    <p><span class="descp-weight">Al Buraymi Governorate Wilayat are:</span> Al Buraymi, Mahadh, As
                      Sinaynah.</p>
                    <p><span class="descp-weight">Habitat:</span> Plain, Sandy Dunes.</p>
                    <p><span class="descp-weight">Main Activities/Experiences:</span> Shopping, Off-Roading.</p>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="tab-pane" id="s-north">
            <div class="row">
              <div class="col-md-6 p-0">
                <img src="../../../../assets/img/map/alsharagiyanorth.png" class="fluid" loading="lazy" />
              </div>
              <div class="col-md-6 p-0 ScrollStyle" style="background-color: #f2f2f2;">
                <div class="detail-box">
                  <div class="box-head">
                    <h5 style="padding-top: 10px;">Al Sharqiyah North</h5>
                  </div>
                  <div class="box-details p-2">
                    <p>The nature of this region blends two main environments, each having its own distinctive flavour:
                      The first environment is the sand dunes mirroring the depth of an artistic painting, vibrant with
                      the quiet nomadic life in a pastoral setting embracing the wealth of cattle, camels and horses
                      that North A'Sharqiyah Governorate Ais renowned for. This Governorate’s special attribute is
                      typified in Badiya, where horse and camel races take place, and where fans from the Sultanate and
                      abroad come to watch. Ruins discovered in this governorate indicate the existence of trade routes
                      dating back to ancient times. The presence of pottery and the remains of copper and other ruins in
                      Al Buraymi indicate the existence of an ancient civilisation.</p>
                    <p>The second environment is urban or agricultural and is the meeting point for both the nomadic and
                      coastal environments.</p>
                    <p><span class="descp-weight">North A'Sharqiyah Governorate Wilayat are:</span> Al Qabil, Al
                      Mudhaibi, Dimma & At Tayyin , Ibra, Wadi Bani Khalid.</p>
                    <p><span class="descp-weight">Habitat:</span> Mountain, Sandy Dunes, Wadies.</p>
                    <p><span class="descp-weight">Main Activities/Experiences:</span> Wadies Swimming, Off-Roading,
                      Trekking, Hiking, Cycling.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane" id="s-south">
            <div class="row">
              <div class="col-md-6 p-0">
                <img src="../../../../assets/img/map/alsharqiyah south.png" class="fluid" loading="lazy"/>
              </div>
              <div class="col-md-6 p-0 ScrollStyle" style="background-color: #f2f2f2;">
                <div class="detail-box">
                  <div class="box-head">
                    <h5 style="padding-top: 10px;">Al Sharqiyah South</h5>
                  </div>
                  <div class="box-details p-2">
                    <p>The nature of this region blends two main environments, each having its own distinctive flavour:
                      the coastline, hugging the Arabian Sea and part of the Sea of Oman, tells the story of hard work
                      done by hardy men. Their weapons are the boats they have built with their own strong hands. Their
                      ammunition is their nets spun by their dexterous fingers while chanting melodious work songs.
                      Under cover of night, they sail into the sea and wrangle with its high waves in a constant battle
                      between the desire to earn their living and come home safely. At the break of dawn, these
                      hard-working men return with their nets overflowing with fish, chanting the melodious refrain,
                      “Thanks be To Allah” for His boon and a safe trip back home…. This is the first environment of
                      South A'Sharqiyah Governorate, and the most prolific fishery of all the regions of Oman.</p>
                    <p>The third environment is urban or agricultural and is the meeting point for both the nomadic and
                      coastal environments.</p>
                    <p><span class="descp-weight">South A'Sharqiyah Governorate Wilayat are:</span> Al Kamil & Al Wafi,
                      Jalan Bani Bu Ali, Jalan Bani Bu Hassn, Masirah, Sur.</p>
                    <p><span class="descp-weight">Habitat:</span> Mountains, Clift’s, Wadies, Sandy Beach, Rocky Shores,
                      Caves.</p>
                    <p><span class="descp-weight">Main Activities/Experiences:</span> Off-Roading, Trekking, Hiking,
                      Camping, Diving, Marine Trips.</p>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="tab-pane" id="wusta">
            <div class="row">
              <div class="col-md-6 p-0">
                <img src="../../../../assets/img/map/alwusta.png" class="fluid" loading="lazy"/>
              </div>
              <div class="col-md-6 p-0 ScrollStyle" style="background-color: #f2f2f2;">
                <div class="detail-box">
                  <div class="box-head">
                    <h5 style="padding-top: 10px;">Al Wusta</h5>
                  </div>
                  <div class="box-details p-2">
                    <p>Al Wasta Governorate is located to the south of the Governorate of A'Dakhiliyah and A'Dhahirah
                      Governorate. It is flanked on the east by the Arabian Sea, on the west by The Empty Quarter, and
                      by Dhofar Governorate to the South. It occupies a large area in the middle of the Sultanate.</p>
                    <p>Its beaches stretch for vast distances along the Arabian Sea. These beaches are famous for their
                      cleanliness and the purity of their waters. This has resulted in an increase of marine plants
                      which give the water its green colour.</p>
                    <p><span class="descp-weight">Al Wasta Governorate Wilayat are:</span> Hima, Mahut, AD Duqum,
                      AL-Jazir.</p>
                    <p><span class="descp-weight">Habitat:</span> Plain, Sandy Dunes.</p>
                    <p><span class="descp-weight">Main Activities/Experiences:</span> Camping, Off-Roading, Wildlife
                      Watching, Marine Trips.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane" id="dhofar">
            <div class="row">
              <div class="col-md-6 p-0">
                <img src="../../../../assets/img/map/dhofar.png" class="fluid" loading="lazy"/>
              </div>
              <div class="col-md-6 p-0 ScrollStyle" style="background-color: #f2f2f2;">
                <div class="detail-box">
                  <div class="box-head">
                    <h5 style="padding-top: 10px;">Dhofar</h5>
                  </div>
                  <div class="box-details p-2">
                    <p>Dhofar Governorate is famous for its seasonal weather, locally known as monsoon or “Khareef” ,
                      when it witnesses its best period, clothed in lush greenery and its hills surrounded by white fog.
                      Light rains drizzle to cool the air. During this time, it is frequented by many visitors,
                      especially from within Oman and the neighbouring countries. Salalah Tourism Festival takes place
                      from 15 July to 31 August every year. The festival is part of Khareef(monsoon) that extends from
                      the end of July until the beginning of September.</p>
                    <p>Dhofar Governorate stretches over an area of one third of Oman and forms the Sultanate’s southern
                      part. Dhofar includes a distinctive natural diversity where the coast blends with the mountains
                      and the desert in wonderful harmony so that the mountains look like a fertile crescent, rising to
                      a height of 1,500 metres and then descending into a flat pl ain that embraces sandy beaches
                      stretching for hundreds of kilometres.</p>
                    <p><span class="descp-weight">Dhofar Governorate Wilayt are:</span> Salalah, Taqah, Mirbat, Sadah,
                      Shalim and Halaniyat Islands, Dalkoot, Rakhyut, Thamrit, Mokshin, Al Mazuna.</p>
                    <p><span class="descp-weight">Habitat:</span> Plain, Seasonal Monsoon, Mountains, Wadies, Natural
                      Water Springs,
                      Lagoons, Sandy Beach, Rocky Shores, Coconut Gardens, Sandy Dunes.</p>
                    <p><span class="descp-weight">Main Activities/Experiences:</span> Off-Roading, Trekking, Hiking,
                      Camping, Diving, Marine
                      Trips, Wadies Swimming, Forts, Heritage and Culture, Shopping.</p>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
     
    </div>
  </div>


</div>









<div class="hekaya-container" style="margin-bottom: 30px;">
  <div class="row top-row-margin">
    <div class="col-md-12 col-lg-12 col-sm-12">
      <header class="hero">
        <div class="center-content">
          <h1>Oman</h1>
          <h3>A word of authentic heritage</h3>
         

        </div>
        <div class="center-content2">
          
        </div>
      </header>
    </div>
  </div>
</div>









<app-self-drive-footer *ngIf="!(location == '/error' || location == '/coming-soon' || location == '/sign-in'
    || location == '/sign-up'
    ||location == '/forgot-password')">
</app-self-drive-footer>