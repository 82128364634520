
<header class="header-area">
    <div class="top-header d-none d-lg-block">
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="col-lg-4 col-md-12">
                    <!-- <ul class="header-contact-info">
                        
                        <li>Call: <a href="tel:+968 95682966">+968 95682966</a></li>
                    </ul> -->
                </div>
  
                <div class="col-lg-4 col-md-12">
  
                </div>
  
                <div class="col-lg-4 col-md-12">
                    <ul class="header-top-menu">
                        <li *ngIf="!appSettings?.isAdmin"><a routerLink="/self-drive/selfdrive-MyProfile"><i
                            class='bx bxs-user'></i> My
                            Profile</a></li>
                        <li *ngIf="!appSettings?.isAdmin"><a routerLink="/self-drive/MyBookings"><i
                                    class='bx bxs-book'></i> My
                                Bookings</a></li>
                        <li *ngIf="appSettings?.isAdmin"><a routerLink="/self-drive/selfdrive-Login"><i class='bx bx-log-in'></i> Login</a>
                        </li>
                        <li *ngIf="!appSettings?.isAdmin"><a class="pointer" (click)="onSignOut()"><i
                                    class='bx bx-log-in-circle'></i>Logout</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <!-- blue header -->
    <div class="navbar-area ">
        <div class="louise-responsive-nav">
            <div class="container">
                <div class="louise-responsive-menu">
                    <div class="logo">
                      <img src="../../../../assets/img/self-drive/main_2.png" style="max-width: 60%;" alt="logo" loading="lazy" >
                    </div>
                </div>
            </div>
        </div>
  
        <div class="louise-nav">
            <div class="container-fluid">
                <nav class="navbar navbar-expand-md navbar-light">
                  <a class="navbar-brand" routerLink="/selfdrive">
  
                    <!-- <img src="assets/img/hekayat_logo.png" alt="logo"> -->
                    <img src="../../../../assets/img/self-drive/main_2.png" style="max-width: 60%;" alt="logo"  loading="lazy" >
                </a>
  
                    <div class="collapse navbar-collapse mean-menu" style="margin-right: 70px;">
                      <ul class="navbar-nav ml-auto">
  
                        <li class="nav-item dropdown">
                          <a class="nav-link  dropdown-toggle nav-hov"  data-toggle="dropdown" style="font-size: 16px; "> Hekayat Experience</a>
                            <ul class="dropdown-menu dropdown-menu-right">
                              <li class="nav-item"><a class="nav-link"  routerLink="/selfdrive">Overview</a></li>
                              <li class="nav-item"><a class="nav-link" routerLink="/Plannin-your-Road-Trip" >Plan Your Itinerary</a></li>
                            </ul>
                        </li>
  
                        <li class="nav-item dropdown">
                          <a class="nav-link  dropdown-toggle nav-hov"  data-toggle="dropdown" style="font-size: 16px; "> About Oman</a>
                            <ul class="dropdown-menu dropdown-menu-right">
                              <li class="nav-item"><a class="nav-link"  routerLink="/SelfDriveHekaya">Overview</a></li>
                              <li class="nav-item"><a class="nav-link"  routerLink="/Oman-weather">Weather</a></li>
                            </ul>
                        </li>
  
                        <li class="nav-item"><a class="nav-link"  routerLink="/Driving-Category"> Hekayat Categories</a></li>
                        <li class="nav-item"><a class="nav-link"  routerLink="/Hekayat-route-map"> Hekayat Route Map</a></li>
                        <li class="nav-item"><a class="nav-link"  routerLink="/Hekayat-Guidence"> Guidance</a></li>

                        <li *ngIf="!appSettings?.isAdmin" class="nav-item d-block d-lg-none"><a routerLink="/self-drive/selfdrive-MyProfile" 
                          class="nav-link">My Profile</a>
                        </li>
                        <li *ngIf="!appSettings?.isAdmin" class="nav-item d-block d-lg-none"><a routerLink="/self-drive/MyBookings" 
                          class="nav-link"> My Bookings</a>
                        </li>
                        <li *ngIf="appSettings?.isAdmin" class="nav-item d-block d-lg-none"><a routerLink="/self-drive/selfdrive-Login"
                                class="nav-link">Login</a>
                        </li>
                        <li *ngIf="!appSettings?.isAdmin" class="nav-item d-block d-lg-none"><a
                                (click)="onSignOut()" class="nav-link">Logout</a>
                        </li>
                        <!-- <li class="nav-item"><a class="nav-link nav-hov"  routerLink="/Plannin-your-Road-Trip" style="font-size: 16px;">Plan Your Road Trip11</a></li>  -->
                      </ul>
                    </div>
                </nav>
            </div>
        </div>
    </div>
  </header>
  
  <ngx-alerts></ngx-alerts>
  