import { Component, OnInit, ViewChild,ElementRef,Renderer2 } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { UtilityService } from 'src/app/services/utility-service/utility.service';
import { environment } from '../../../../environments/environment';
import { SearchCarPopupComponent } from '../../shared/components/search-car-popup/search-car-popup.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  public appSettings: any;

  @ViewChild('searchCarPopupComponent') searchCarPopupComponent: SearchCarPopupComponent;
  meta: any;

  constructor(private _utilityService: UtilityService
    , private _authService: AuthenticationService, private elRef: ElementRef, private renderer: Renderer2) { }

  ngOnInit(): void {
   
    this._utilityService.getAppSettings().subscribe(appSettings => {
      if (appSettings) {
        this.appSettings = appSettings;
      }
    })
  }

  onSignOut() {
    this._authService.signIn(environment.agencyCode, environment.emailAddress, environment.password).subscribe(response => {
      if (response) {
        this._utilityService.setAppSettings(response);
        this._utilityService.saveWebSettings(response.accessToken);
      }
    }, error => {

    });
  }

  onOpenSearchCarPopup() {
    if (this.searchCarPopupComponent) {
      this.searchCarPopupComponent.openPopUp();
    }
  }

  activateMonthlyTab() {
   
  const monthlyRentalsNavLink = this.elRef.nativeElement.querySelector('#profile-tab');
  monthlyRentalsNavLink.classList.add('active');
  monthlyRentalsNavLink.setAttribute('aria-selected', 'true');
  const monthlyRentalsTabContent = document.querySelector('#MonthlyRentals');
  monthlyRentalsTabContent.classList.add('show');
  monthlyRentalsTabContent.classList.add('active');
    const dailyNavLink=this.elRef.nativeElement.querySelector('#rentACar-tab');
    dailyNavLink.classList.remove('active');
    dailyNavLink.removeAttribute('aria-selected');
    const leaseNavLink=this.elRef.nativeElement.querySelector('#contact-tab');
    leaseNavLink.classList.remove('active');
    leaseNavLink.removeAttribute('aria-selected');  
    const dailyTabContent1 = document.querySelector('#rentACar_PickUpLocation_TabContent');
dailyTabContent1.classList.remove('show');
dailyTabContent1.classList.remove('active');
const dailyTabContent2 = document.querySelector('#rentACar_TabContent');
dailyTabContent2.classList.remove('show');
dailyTabContent2.classList.remove('active');
const leaseTab= document.querySelector('#lease_enquiry');
leaseTab.classList.remove('show');
leaseTab.classList.remove('active');

   
   
  }
  activateDailyTab() {
    const dailyNavLink = this.elRef.nativeElement.querySelector('#rentACar-tab');
    dailyNavLink.classList.add('active');
    dailyNavLink.setAttribute('aria-selected', 'true');
    const dailyTabContent = document.querySelector('#rentACar_TabContent');
dailyTabContent.classList.add('show');
dailyTabContent.classList.add('active');
const dailyTabContent1 = document.querySelector('#rentACar_PickUpLocation_TabContent');
dailyTabContent1.classList.add('show');
dailyTabContent1.classList.add('active');
    const leaseNavLink = this.elRef.nativeElement.querySelector('#contact-tab');
    leaseNavLink.classList.remove('active');
    leaseNavLink.removeAttribute('aria-selected');

    const monthlyRentalsNavLink = this.elRef.nativeElement.querySelector('#profile-tab');
    monthlyRentalsNavLink.classList.remove('active');
    monthlyRentalsNavLink.removeAttribute('aria-selected');
    const monthlyRentalsTabContent = document.querySelector('#MonthlyRentals');
  monthlyRentalsTabContent.classList.remove('show');
  monthlyRentalsTabContent.classList.remove('active');
  const leaseTab= document.querySelector('#lease_enquiry');
leaseTab.classList.remove('show');
leaseTab.classList.remove('active');
  
  
}
activateWeeklyTab(){
  const dailyNavLink = this.elRef.nativeElement.querySelector('#rentACar-tab');
    dailyNavLink.classList.add('active');
    dailyNavLink.setAttribute('aria-selected', 'true');
    const dailyTabContent = document.querySelector('#rentACar_TabContent');
    dailyTabContent.classList.add('show');
    dailyTabContent.classList.add('active');
    const dailyTabContent1 = document.querySelector('#rentACar_PickUpLocation_TabContent');
dailyTabContent1.classList.add('show');
dailyTabContent1.classList.add('active');
    const leaseNavLink = this.elRef.nativeElement.querySelector('#contact-tab');
    leaseNavLink.classList.remove('active');
    leaseNavLink.removeAttribute('aria-selected');

    const monthlyRentalsNavLink = this.elRef.nativeElement.querySelector('#profile-tab');
    monthlyRentalsNavLink.classList.remove('active');
    monthlyRentalsNavLink.removeAttribute('aria-selected');
    const monthlyRentalsTabContent = document.querySelector('#MonthlyRentals');
    monthlyRentalsTabContent.classList.remove('show');
  monthlyRentalsTabContent.classList.remove('active');
  const leaseTab= document.querySelector('#lease_enquiry');
leaseTab.classList.remove('show');
leaseTab.classList.remove('active');
  
}

}
