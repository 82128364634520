<header class="header-area">
    <div class="navbar-area">
        <div class="louise-responsive-nav blkhead">
            <div class="container">
                <div class="row">
                    <div class="col-lg-4 col-6">
                        <div class="louise-responsive-menu">
                            <div class="logo d-flex align-items-center">
                                <a routerLink="/">
                                    <img src="https://hekaya.s3.ap-south-1.amazonaws.com/carRental/logo-left1.png" width="70px" height="70px" alt="logo" loading="lazy">
                                </a>
                                 <!-- <div class="ml-3"><img src="https://hekaya.s3.ap-south-1.amazonaws.com/carRental/Route%20Logo.jpg" alt="Route Logo" style="max-width:100px ;"></div> -->
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-12 d-none d-lg-block">
                        <div class="d-flex align-items-center justify-content-center h-100">
                        <div class="d-flex justify-content-center align-items-center newair">

                            <button type="button" class="linkbtn" (click)="onOpenSearchCarPopup();activateDailyTab()" id="dailyButton">
                                Daily
                            </button>
                            <button type="button" class="linkbtn" (click)="onOpenSearchCarPopup();activateWeeklyTab()" id="dailyButton">
                                Weekly
                            </button>
                            <button type="button" class="linkbtn" data-toggle="modal" (click)="onOpenSearchCarPopup(); activateMonthlyTab()" id="monthlyButton">
                                Monthly
                            </button>

                            <button class="airsearch ml-2">
                                <i class="bi bi-search" (click)="onOpenSearchCarPopup();activateWeeklyTab()" id="dailyButton"></i>

                            </button>
                        </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-6 d-flex justify-content-end">

                        <div class="d-flex align-items-center">
                           

                            <div class="btn-group">
                                <button type="button" class="dropstyle dropdown-toggle" data-toggle="dropdown"
                                    aria-expanded="false">
                                    <i class="bi bi-list list_i"></i>
                                    <div class="userico"><img src="https://hekaya.s3.ap-south-1.amazonaws.com/carRental/default.jpg" alt="man's image" loading="lazy"></div>
                                </button>

                                <div class="dropdown-menu dropdown-menu-right pt-0 pb-0">
                                    <ul class="list-group">
                                        <li class="list-group-item"><a href="javascript:void(0)"
                                            routerLink="/about">About Us
                                            </a>

                                        </li>

                                        <li class="list-group-item"><a href="javascript:void(0)"
                                            routerLink="/hekaya-services">Services
                                            </a>

                                        </li>
                                        <li class="list-group-item">
                                            <a href="https://car-rental.hekaya.co/blog">Blog</a>
                                        </li>
                                        <li class="list-group-item"><a routerLink="/contact" routerLinkActive="active"
                                                [routerLinkActiveOptions]="{exact: true}">Contact</a></li>
                                        <!-- <li *ngIf="!appSettings?.isAdmin" class="list-group-item d-block d-lg-none"><a
                                            routerLink="/my-account/profile">My
                                                Profile</a></li>
                                        <li *ngIf="!appSettings?.isAdmin" class="list-group-item d-block d-lg-none"><a
                                            routerLink="/my-account/bookings" class="nav-link">My Bookings</a></li> -->
                                        <!-- <li *ngIf="appSettings?.isAdmin" class="list-group-item d-block d-lg-none"><a
                                                routerLink="/sign-in">Login</a>
                                        </li> -->


                                        <li class="list-group-item" *ngIf="!appSettings?.isAdmin"><a
                                            routerLink="/my-account/profile"> My
                                                Profile</a></li>
                                        <li class="list-group-item" *ngIf="!appSettings?.isAdmin"><a
                                            routerLink="/my-account/bookings"> My
                                                Bookings</a></li>
                                        <li class="list-group-item" *ngIf="appSettings?.isAdmin"><a
                                                routerLink="/sign-in"> Login</a>
                                        </li>
                                        <li class="list-group-item" *ngIf="!appSettings?.isAdmin"><a class="pointer"
                                                (click)="onSignOut()">Logout</a></li>

                                    </ul>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="col-lg-4 col-md-12 d-block d-lg-none mt-2 mb-2">
                        <div class="d-flex justify-content-center align-items-center newair size_mb m-auto">
                            <button type="button" class="linkbtn" (click)="onOpenSearchCarPopup()">
                                Daily
                            </button>
                            <button type="button" class="linkbtn" data-toggle="modal" (click)="onOpenSearchCarPopup()">
                                Weekly
                            </button>
                            <button type="button" class="linkbtn" data-toggle="modal" (click)="onOpenSearchCarPopup()">
                                Monthly
                            </button>
                            <button class="airsearch ml-2">
                                <i class="bi bi-search" (click)="onOpenSearchCarPopup();activateWeeklyTab()" id="dailyButton"></i>

                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>
<!-- Search Car Popup-->
<app-search-car-popup #searchCarPopupComponent></app-search-car-popup>
<!-- Search Car Popup-->
<ngx-alerts></ngx-alerts>
